import uniqWith from 'lodash/uniqWith';

interface IBrandObject {
  brandCode: string;
  brandName: string;
}

const hotelBrandsList: IBrandObject[] = [
  {
    brandName: 'Wyndham Hotel Group Brands',
    brandCode: 'WR',
  },
  {
    brandName: 'TravelCLICK',
    brandCode: 'TC',
  },
  {
    brandName: 'City Lodge Hotels',
    brandCode: 'CG',
  },
  {
    brandName: 'Travelodge UK',
    brandCode: 'TG',
  },
  {
    brandName: 'Hilton Hotel Brands',
    brandCode: 'EH',
  },
  {
    brandName: 'Zucchetti Hospitality SRL',
    brandCode: 'OK',
  },
  {
    brandName: 'HRS',
    brandCode: 'HQ',
  },
  {
    brandName: 'Millennium/Copthorne',
    brandCode: 'MU',
  },
  {
    brandName: 'ReservHotel',
    brandCode: 'TA',
  },
  {
    brandName: 'Magnuson Hotels',
    brandCode: 'MS',
  },
  {
    brandName: 'Pegasus Solutions Inc.',
    brandCode: 'AX',
  },
  {
    brandName: 'Marriott Hotel Brands',
    brandCode: 'EM',
  },
  {
    brandName: 'Fastbooking',
    brandCode: 'FG',
  },
  {
    brandName: 'Accor Hotels',
    brandCode: 'RT',
  },
  {
    brandName: 'Choice Hotel Brands',
    brandCode: 'EC',
  },
  {
    brandName: 'GHA Brands',
    brandCode: 'GL',
  },
  {
    brandName: 'Louvre International Brands',
    brandCode: 'LI',
  },
  {
    brandName: 'Best Western Hotels \u0026 Resorts',
    brandCode: 'EW',
  },
  {
    brandName: 'Southern Sun Hotels (Tsogo Sun)',
    brandCode: 'SU',
  },
  {
    brandName: 'Carlson Hospitality Worldwide',
    brandCode: 'CW',
  },
  {
    brandName: 'Omnibees Hotel Distribution',
    brandCode: 'HB',
  },
  {
    brandName: 'Global Hyatt Corp.',
    brandCode: 'HY',
  },
  {
    brandName: 'XN Global Res',
    brandCode: 'XN',
  },
  {
    brandName: 'Great Hotels (GuestCentric)',
    brandCode: 'GW',
  },
  {
    brandName: 'BedsonLine',
    brandCode: '7H',
  },
  {
    brandName: 'Atel Hotels Network',
    brandCode: 'AC',
  },
  {
    brandName: 'Booking.Com',
    brandCode: '7B',
  },
  {
    brandName: 'NH Hotels Brands',
    brandCode: 'NH',
  },
  {
    brandName: 'Nikko Hotels/Hotel JAL',
    brandCode: 'NK',
  },
  {
    brandName: 'Scandic Hotels',
    brandCode: 'SH',
  },
  {
    brandName: 'Red Roof Brands',
    brandCode: 'RR',
  },
  {
    brandName: 'Hetras',
    brandCode: 'RM',
  },
  {
    brandName: 'Maritim',
    brandCode: 'MM',
  },
  {
    brandName: 'Worldhotels',
    brandCode: 'WW',
  },
  {
    brandName: 'Sonder USA Inc',
    brandCode: 'SS',
  },
  {
    brandName: 'G6 Hospitality LLC',
    brandCode: 'MX',
  },
  {
    brandName: 'Vantage Hotels',
    brandCode: 'VH',
  },
  {
    brandName: 'MGM Resorts International',
    brandCode: 'MV',
  },
  {
    brandName: 'Expedia Affiliate Network',
    brandCode: '7E',
  },
  {
    brandName: 'Preferred Brands',
    brandCode: 'PV',
  },
  {
    brandName: 'InterContinental Hotels Group',
    brandCode: '6C',
  },
];

export const hotelBrandsSelectOptions: { value: string; label: string }[] = uniqWith(
  hotelBrandsList.map((hotelBrand) => ({
    value: hotelBrand.brandCode,
    label: hotelBrand.brandName,
  })),
  (curr, next) => curr.label === next.label || curr.value === next.value,
);

export default hotelBrandsList;
