import MuiButton, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import { FunctionInterpolation, Theme } from '@emotion/react/dist/emotion-react.cjs';
import { forwardRef } from 'react';
import { IconName } from '../Icon/types';
import { RemoveMuiProps, ValueOf } from '../types';
import { Icon } from '../Icon';
import {
  icon_button_base,
  icon_button_standard,
  icon_button_info,
  icon_button_primary,
  icon_button_subtle,
  icon_button_shadow,
  icon_button_success,
  icon_button_error,
} from './IconButton.styles';
import { TTooltipProps, Tooltip } from '../Tooltip';
import { noop } from '../utils';

type MuiButtonProps = RemoveMuiProps<ButtonProps, 'color'>;
type TIconButtonVariants = 'standard' | 'outlined' | 'rounded' | 'outlineRounded';
export type TIconButtonKinds = 'standard' | 'subtle' | 'primary' | 'info' | 'success' | 'error';
type TIconButtonSizes = 'small' | 'medium' | 'large' | 'xlarge';
export const ICON_BUTTON_ROOT = 'BlocksIconButton-root';

export type TIconButtonProps = {
  /** The icon to display */
  icon: IconName;
  /** The size of the icon */
  size?: TIconButtonSizes;
  /** The variant of the button to use.
   * @default standard
   */
  variant?: TIconButtonVariants;
  /** The kind of the button to use.
   *  @default standard
   *  */
  kind?: TIconButtonKinds;
  /** Tooltip text to display on hover (if needed)
   *  @default ""
   *  */
  tooltip?: string;
  /** Loading state of the button
   *  @default false
   *  */
  loading?: boolean;
  /**
   * Disabled state of the button
   * @default false
   * */
  disabled?: boolean;
  /**
   * Determines whether the button has a shadow.
   * @default false
   * */
  withShadow?: boolean;

  popperProps?: TTooltipProps['PopperProps'];
} & MuiButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(
  (
    {
      disabled,
      loading,
      size = 'medium',
      type,
      variant = 'standard',
      kind = 'standard',
      className,
      tooltip = '',
      icon,
      withShadow = false,
      popperProps = {},
      onClick,
      ...variableProps
    }: TIconButtonProps,
    ref,
  ) => {
    const onClickHandler = disabled ? noop : onClick;
    return (
      <Tooltip
        variant="label"
        title={loading ? undefined : tooltip}
        PopperProps={{ disablePortal: true, popperOptions: { placement: 'top' }, ...popperProps }}
      >
        <MuiButton
          ref={ref}
          disableRipple
          onClick={onClickHandler}
          size={convertIconButtonSizeToMuiSize(size)}
          variant={renameVariant(variant)}
          type={type}
          css={[icon_button_base, getIconButtonKindConfigClass(kind), withShadow && icon_button_shadow]}
          className={clsx(
            className,
            'BlocksIconButton-root',
            `BlocksIconButton-${variant}`,
            size === 'xlarge' && `BlocksIconButton-CustomSize-${size}`,
            (loading || disabled) && 'Mui-disabled',
          )}
          color={undefined}
          aria-disabled={loading || disabled}
          {...variableProps}
        >
          <Icon
            name={!loading ? icon : 'Loading'}
            className={clsx('BlocksIconButton-Icon', loading && 'loading-icon')}
          />
        </MuiButton>
      </Tooltip>
    );
  },
);

/**
 * Helpers
 */
const mapCustomVariantsToMui = {
  standard: 'contained' as const,
  rounded: 'contained' as const,
  outlineRounded: 'contained' as const,
  outlined: 'text' as const,
};

const iconButtonKindConfig: { [key in TIconButtonKinds]: FunctionInterpolation<Theme> } = {
  standard: icon_button_standard,
  primary: icon_button_primary,
  info: icon_button_info,
  subtle: icon_button_subtle,
  success: icon_button_success,
  error: icon_button_error,
};

/**
 * Doesn't accept any variant config that isn't defined in `mapCustomVariantsToMui`
 *
 * ex:
 * ```js
 *  renameVariant('tertiary'); // FAILS to compile
 * ```
 */
function renameVariant(variant: keyof typeof mapCustomVariantsToMui): ValueOf<typeof mapCustomVariantsToMui> {
  return mapCustomVariantsToMui[variant];
}

/**
 * Returns the kind config class for the kind prop
 *
 * Doesn't accept any kind config that isn't defined in `TIconButtonKinds`
 * @param kind
 */
function getIconButtonKindConfigClass(kind: TIconButtonKinds) {
  return iconButtonKindConfig[kind];
}

/**
 * Converts Icon button size to Mui Button Size
 * Icon button supports 4 variants where as MUI supports only 3
 * Instead of extending the base, since that would pollute the general Button component as well
 * We are overriding the sizing at IconButton level
 *
 * @param size
 * @returns
 */
function convertIconButtonSizeToMuiSize(size: TIconButtonSizes): ButtonProps['size'] {
  if (size === 'xlarge') {
    return 'large';
  }
  return size;
}
