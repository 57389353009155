import { useTranslation } from 'react-i18next';

import { usePrimaryTraveler } from 'obt-common';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Button } from '@spotnana/blocks/src/Button';
import SearchFormTravelerSelector from 'src/v1-components/shared/SearchFormTravelerSelector';
import { AutocompleteExpandDirections } from 'src/constants/autocomplete';

import { IHandleTravelerChangeType } from 'src/hooks/useTravelerChangeConfirmation';
import { container, title_container, subtitle_text, bottom_container, back_button } from './styles';

interface IProps {
  onTalkToAgent: () => void;
  onBack: () => void;
}

function PopperStepSelectTraveler({ onTalkToAgent, onBack }: IProps) {
  const { t: tt } = useTranslation('WEB');
  const { isLoading, primaryTravelerId } = usePrimaryTraveler();

  const handleTravelerChange = ({ onTravelerChange }: IHandleTravelerChangeType) => {
    onTravelerChange();
  };

  return (
    <div css={container}>
      <div css={title_container}>
        <Typography variant="body1" css={subtitle_text}>
          {tt(
            'Please confirm the traveler name before proceeding in order to route you to the best available $t(travel consultant).',
          )}
        </Typography>

        <SearchFormTravelerSelector
          travelerSelectorExpandDirection={AutocompleteExpandDirections.Right}
          outerContainerStyles={{ width: '100%' }}
          clickAwayListernerStyles={{ width: '100%' }}
          inputContainerWidth="100%"
          handleTravelerChange={handleTravelerChange}
          userId={primaryTravelerId?.userId?.id ?? ''}
          isAdhocBooking={false}
        />
      </div>

      <div css={bottom_container}>
        <Button variant="primary" size="medium" onClick={onTalkToAgent} disabled={isLoading}>
          {tt('Confirm')}
        </Button>

        <Button variant="secondary" size="medium" onClick={onBack} css={back_button}>
          {tt('Back')}
        </Button>
      </div>
    </div>
  );
}

export { PopperStepSelectTraveler };
