import { FC, ReactNode } from 'react';
import { FormProvider as CoreFormProvider, useForm } from 'rhf-v7';
import {
  createUserOrgIdUsingUserId,
  useProfileReadQuery,
  createUserNameFromFullName,
  useFeatureFlag,
  TravelerBasicInfo,
  getFullNameFromNameObject,
  FareList,
} from 'obt-common';
import { useTranslation } from 'react-i18next';
import { ShareItineraryFormValues } from './types';

type FormProviderProps = {
  fareList: FareList;
  children?: ReactNode;
};

export const FormProvider: FC<FormProviderProps> = ({ fareList, children }) => {
  const { travelerUserId } = fareList;
  const isAutocompletePreferredNameEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const { t: tt } = useTranslation('WEB');

  const travelerUserOrgId = createUserOrgIdUsingUserId(travelerUserId);
  const { data: itineraryOwner } = useProfileReadQuery(travelerUserOrgId);
  const getTravelerName = isAutocompletePreferredNameEnabled ? createUserNameFromFullName : getFullNameFromNameObject;
  const travelerName = itineraryOwner?.user?.name ? getTravelerName(itineraryOwner.user.name) : '';

  const initialTravelers: TravelerBasicInfo[] = itineraryOwner
    ? [
        {
          userOrgId: itineraryOwner.userOrgId,
          email: itineraryOwner.user?.email,
          fullName: travelerName,
        },
      ]
    : [];

  const methods = useForm<ShareItineraryFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      travelers: initialTravelers,
      message: tt(
        "Hi {{name}}, \n\nI hope you're doing well. Please review the attached flight itinerary option and let us know which one you'd like to confirm. \n\nIf you have any questions or need further assistance, feel free to reach out.",
        { name: travelerName },
      ),
    },
    resolver: async (values) => {
      const errors: Record<string, string> = {};

      if (!values.travelers.length) {
        errors.travelers = tt('At least one traveler is required');
      }

      return {
        values,
        errors,
      };
    },
  });

  return <CoreFormProvider<ShareItineraryFormValues> {...methods}>{children}</CoreFormProvider>;
};
