import type { IAirRevalidateItineraryRequest, IAirRevalidateItineraryResponse } from '../../types/flight';
import type { IUseGetRequestHeader } from '../../hooks/useGetRequestHeader';
import type { InternalAPIError } from '../../types/common';

interface Params {
  requestHeader: IUseGetRequestHeader;
  revalidateRequest: IAirRevalidateItineraryRequest;
  form: any;
}

const revalidateAirItinerary = ({
  revalidateRequest,
  requestHeader,
  form,
}: Params): Promise<IAirRevalidateItineraryResponse> => {
  return new Promise<IAirRevalidateItineraryResponse>((resolve, reject) => {
    form.submit(
      '/v1/air-revalidate-itin',
      {
        mapDotToObject: 'mergeArray',
        data: revalidateRequest,
        headers: requestHeader.headers,
      },
      (_status: any, revalidateResponse: IAirRevalidateItineraryResponse | InternalAPIError) => {
        if (
          ('bookingId' in revalidateResponse || 'totalFare' in revalidateResponse) &&
          !('errorCode' in revalidateResponse)
        ) {
          resolve(revalidateResponse);
        } else {
          reject(revalidateResponse);
        }
      },
      (error: any) => {
        reject(error);
      },
    );
  });
};

export default async function revalidateItineraryViaCvv(
  revalidateRequest: IAirRevalidateItineraryRequest,
  requestHeader: IUseGetRequestHeader,
  form: any,
): Promise<IAirRevalidateItineraryResponse> {
  const result = await revalidateAirItinerary({
    revalidateRequest,
    requestHeader,
    form,
  });

  return result;
}
