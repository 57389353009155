/* eslint-disable import/prefer-default-export */
import type { QueryKey, UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { AnyObject } from 'immer/dist/internal';
import type { EventRsvpState } from '@spotnana/types/openapi/models/event-rsvp-state';
import type { EventCustomFieldResponsesRequest } from '@spotnana/types/openapi/models/event-custom-field-responses-request';
import type { CompanyRole } from '@spotnana/types/openapi/models/company-role';
import type { EventUserRsvp } from '@spotnana/types/openapi/models/event-user-rsvp';
import type { SetUserEventRsvpRequest } from '@spotnana/types/openapi/models/set-user-event-rsvp-request';
import type { BookingStatusType } from '@spotnana/types/openapi/models/booking-status-type';
import type { EventTravelersResponse } from '@spotnana/types/openapi/models/event-travelers-response';
import type { GetEventResponse } from '../types/api/v2/obt/model/get-event-response';
import api from '../api';
import { SpotnanaError } from '../api/SpotnanaError';
import type { CreateEventRequest, SpotnanaQueryMutationResult } from '../types';
import type { GetTravelerTripRequest, GetTravelerTripResponse } from '../types/api/v1/event/event_service';
import type { AddTravelersToEventRequest } from '../types/api/v2/obt/model/add-travelers-to-event-request';
import type { AddTravelersToEventResponse } from '../types/api/v2/obt/model/add-travelers-to-event-response';
import type { EntityNonUUIDId } from '../types/api/v2/obt/model/entity-non-uuidid';
import type { ListEventsResponse } from '../types/api/v2/obt/model/list-events-response';
import type { RemoveTravelersFromEventRequest } from '../types/api/v2/obt/model/remove-travelers-from-event-request';
import type { RemoveTravelersFromEventResponse } from '../types/api/v2/obt/model/remove-travelers-from-event-response';
import type { UpdateEventBasicInfoRequest } from '../types/api/v2/obt/model/update-event-basic-info-request';
import type { UpdateEventBookingGuidelinesRequest } from '../types/api/v2/obt/model/update-event-booking-guidelines-request';
import type { ValidateItineraryEventRequest } from '../types/api/v2/obt/model/validate-itinerary-event-request';
import type { ValidateItineraryEventResponse } from '../types/api/v2/obt/model/validate-itinerary-event-response';
import { defaultQueryClient } from './defaultQueryClient';
import type { EventPreference } from '../types/api/v2/obt/model/event-preference';
import type { GenerateEventImagesRequest } from '../types/api/v2/obt/model/generate-event-images-request';
import type { GenerateEventImagesResponse } from '../types/api/v2/obt/model/generate-event-images-response';
import type { ListEventsRequest } from '../types/api/v2/obt/model/list-events-request';
import { ListEventsRequestListEventTypeEnum } from '../types/api/v2/obt/model/list-events-request';
import { invalidateListBookingPaymentSourcesQuery } from './payment';

// Define a function to generate the query key based on eventId
const eventDetailsKey = (eventId?: string): unknown[] | string => {
  return eventId ? ['event-details', eventId] : 'event-details';
};

const eventCompanyPreferenceKey = (companyId: string, companyRole: CompanyRole) => {
  return ['event-company-applicable-preference', companyId, companyRole];
};

// Define a function to invalidate the event details query for a specific eventId
export const invalidateEventDetails = (eventId?: string): Promise<void> => {
  return defaultQueryClient.invalidateQueries(eventDetailsKey(eventId));
};

const eventSummariesKey = (
  category: ListEventsRequestListEventTypeEnum | undefined,
  args: AnyObject = {},
): unknown[] => {
  return ['events', 'list', category, args];
};

const invalidateEventSummariesKey = (eventCategory?: ListEventsRequestListEventTypeEnum): Promise<void> =>
  defaultQueryClient.invalidateQueries(eventSummariesKey(eventCategory));

const removeEventSummariesKey = (eventCategory?: ListEventsRequestListEventTypeEnum): void =>
  defaultQueryClient.removeQueries(eventSummariesKey(eventCategory));

const createEvent = async (requestBody: CreateEventRequest): Promise<EntityNonUUIDId> => {
  const data = await api('POST', 'eventBase', {
    data: requestBody,
  });
  const createEventResponse = data as EntityNonUUIDId;
  if (createEventResponse?.id) {
    return createEventResponse;
  }
  throw new SpotnanaError('Could not create event');
};

export const useCreateEvent = () =>
  useMutation((requestBody: CreateEventRequest) => createEvent(requestBody), {
    onSuccess: (_data) => {
      removeEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
    },
  });

const addCutomFields = async (requestBody: IAddCustomFields): Promise<void> => {
  await api('PUT', 'createEvent', {
    data: requestBody.request,
    urlParam: `/${requestBody.eventId}/custom-field-responses`,
  });
};

export const useAddCustomFields = () =>
  useMutation((requestBody: IAddCustomFields) => addCutomFields(requestBody), {
    onSuccess: (_, { eventId }) => {
      invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
      invalidateEventDetails(eventId);
    },
  });

interface IAddCustomFields {
  request: EventCustomFieldResponsesRequest;
  eventId: string;
}

const addTravelersToEvent = async (requestBody: IAddTravelersToEvent): Promise<AddTravelersToEventResponse> => {
  const data = await api('POST', 'eventBase', {
    data: requestBody.request,
    urlParam: `/${requestBody.eventId}/travelers/add`,
  });
  const addTravelersToEventResponse = data as AddTravelersToEventResponse;
  if (addTravelersToEventResponse?.addedUserIds) {
    return addTravelersToEventResponse;
  }
  throw new SpotnanaError('Could not add travelers to the event');
};

interface IAddTravelersToEvent {
  request: AddTravelersToEventRequest;
  eventId: string;
}

export const useAddTravelersToEvent = (
  props?: UseMutationOptions<AddTravelersToEventResponse, unknown, IAddTravelersToEvent, unknown>,
) =>
  useMutation((requestBody: IAddTravelersToEvent) => addTravelersToEvent(requestBody), {
    onSuccess: (_data, ...args) => {
      const [variables] = args;
      invalidateEventDetails(variables.eventId);
      invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
    },
    ...props,
  });

const removeTravelersFromEvent = async (
  requestBody: IRemoveTravelersFromEvent,
): Promise<RemoveTravelersFromEventResponse> => {
  const data = await api('POST', 'eventBase', {
    data: requestBody.request,
    urlParam: `/${requestBody.eventId}/travelers/remove`,
  });
  const removeTravelersFromEventResponse = data as RemoveTravelersFromEventResponse;
  if (removeTravelersFromEventResponse?.removedUserIds) {
    return removeTravelersFromEventResponse;
  }
  throw new SpotnanaError('Could not remove travelers to the event');
};

interface IRemoveTravelersFromEvent {
  request: RemoveTravelersFromEventRequest;
  eventId: string;
}

export const useRemoveTravelersFromEvent = (
  props?: UseMutationOptions<RemoveTravelersFromEventResponse, unknown, IRemoveTravelersFromEvent, unknown>,
) =>
  useMutation((requestBody: IRemoveTravelersFromEvent) => removeTravelersFromEvent(requestBody), {
    onSuccess: (_data, ...args) => {
      const [variables] = args;
      invalidateEventDetails(variables.eventId);
    },
    ...props,
  });

const fetchEventSummariesInternal = async (requestData: ListEventsRequest): Promise<ListEventsResponse> => {
  const eventSummariesResponse = api('POST', 'listEvents', {
    data: requestData,
  });
  return eventSummariesResponse as unknown as ListEventsResponse;
};

// will be replaced with usePaginatedQuery once pagination PR is merged
export const useEventSummariesQuery = (
  type: ListEventsRequestListEventTypeEnum | undefined,
  requestData: ListEventsRequest,
  options: UseQueryOptions<ListEventsResponse, SpotnanaError, ListEventsResponse, QueryKey>,
) =>
  useQuery<ListEventsResponse, SpotnanaError>(
    eventSummariesKey(type, requestData),
    () => fetchEventSummariesInternal(requestData),
    options,
  );

const fetchEventTrip = async (requestBody: GetTravelerTripRequest): Promise<GetTravelerTripResponse> => {
  const data = await api('POST', 'eventTrip', {
    urlParam: `/${requestBody.eventId}/travelers/${requestBody.userId?.id}/trip`,
  });
  return data as unknown as GetTravelerTripResponse;
};

export const useGetEventTrip = (requestBody: GetTravelerTripRequest) =>
  useQuery<GetTravelerTripResponse>({
    queryKey: ['event-trip', requestBody],
    queryFn: () => fetchEventTrip(requestBody),
  });

const fetchEventDetails = async (eventId: string): Promise<GetEventResponse> => {
  const data = await api('GET', 'eventBase', {
    urlParam: `/${eventId}`,
  });
  return data as unknown as GetEventResponse;
};

export const useGetEventDetails = (
  eventId: string | undefined,
  options?: UseQueryOptions<GetEventResponse, unknown, GetEventResponse, QueryKey>,
) =>
  useQuery<GetEventResponse>({
    queryKey: eventDetailsKey(eventId),
    queryFn: () => fetchEventDetails(eventId as string),
    enabled: options?.enabled || !!eventId,
    ...options,
  });

const fetchEventCompanyPreference = async (companyId: string, companyRole: CompanyRole): Promise<EventPreference> => {
  const data = await api('GET', 'eventBase', {
    urlParam: `/company/${companyId}/applicable-preference?companyRole=${companyRole}`,
  });
  return data as unknown as EventPreference;
};

export const useGetEventCompanyPreference = (companyId: string, companyRole: CompanyRole) =>
  useQuery<EventPreference>({
    queryKey: eventCompanyPreferenceKey(companyId, companyRole),
    queryFn: () => fetchEventCompanyPreference(companyId, companyRole),
  });

const publishEvent = async (eventId: string): Promise<EntityNonUUIDId> => {
  const response = await api(
    'POST',
    'eventBase',
    {
      urlParam: `/${eventId}/publish`,
    },
    {
      allowParallelRequests: true,
    },
  );
  const publishEventResponse = response as EntityNonUUIDId;
  if (publishEventResponse?.id) {
    return publishEventResponse;
  }
  throw new SpotnanaError('Could not create event.');
};

export const usePublishEvent = (
  eventId: string,
  options?: UseMutationOptions<EntityNonUUIDId, unknown, void, unknown>,
) =>
  useMutation(() => publishEvent(eventId), {
    ...options,
    onSuccess: (_data, ...args) => {
      options?.onSuccess?.(_data, ...args);
      invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
      invalidateEventDetails(eventId);
    },
  });

interface IUpdateEventBasicInfo {
  eventId: string;
  basicInfo: UpdateEventBasicInfoRequest;
}

const updateEventBasicInfo = async (requestBody: IUpdateEventBasicInfo): Promise<string> => {
  const response = await api('POST', 'eventBase', {
    urlParam: `/${requestBody.eventId}/edit-basic-info`,
    data: requestBody.basicInfo,
  });

  const updateResponse = response as string;
  if (updateResponse) {
    return updateResponse;
  }

  throw new Error('Could not update event basic info');
};

export const useUpdateEventBasicInfo = (eventId?: string) =>
  useMutation((requestBody: IUpdateEventBasicInfo) => updateEventBasicInfo(requestBody), {
    onSuccess: (_data) => {
      invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
      invalidateEventDetails(eventId);
    },
  });
interface IUpdateEventBookingGuidelines {
  eventId: string;
  bookingGuidelinesAndPaymentSources: UpdateEventBookingGuidelinesRequest;
}

const updateEventBookingGuidelines = async (requestBody: IUpdateEventBookingGuidelines): Promise<string> => {
  const response = await api('POST', 'eventBase', {
    urlParam: `/${requestBody.eventId}/edit-booking-guidelines`,
    data: requestBody.bookingGuidelinesAndPaymentSources,
  });

  const updateResponse = response as string;
  if (updateResponse) {
    return updateResponse;
  }

  throw new Error('Could not update event booking guideline');
};

export const useUpdateEventBookingGuidelines = (
  options?: UseMutationOptions<string, unknown, IUpdateEventBookingGuidelines, unknown>,
) =>
  useMutation((requestBody: IUpdateEventBookingGuidelines) => updateEventBookingGuidelines(requestBody), {
    ...options,
    onSuccess: (_data, ...args) => {
      const [variables] = args;
      invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
      invalidateEventDetails(variables.eventId);
      invalidateListBookingPaymentSourcesQuery();
    },
  });

const validateEvent = async (
  eventId: string,
  requestBody: ValidateItineraryEventRequest,
): Promise<ValidateItineraryEventResponse> => {
  const response = (await api(
    'POST',
    'eventEdit',
    {
      urlParam: `/${eventId}/itinerary-validate`,
      data: requestBody,
    },
    { allowParallelRequests: true },
  )) as ValidateItineraryEventResponse;

  return response;
};

export const useValidateItinerary = (
  options: Omit<
    UseMutationOptions<
      ValidateItineraryEventResponse,
      unknown,
      { eventId: string; body: ValidateItineraryEventRequest },
      unknown
    >,
    'mutationFn'
  > = {},
) => {
  return useMutation({
    mutationFn: ({ eventId, body }: { eventId: string; body: ValidateItineraryEventRequest }) =>
      validateEvent(eventId, body),
    ...options,
  });
};

export interface ISendEventInviteProps {
  eventId: string;
  userIds?: { id: string }[];
}

// API function to send invite to travelers
const sendEventInvite = async ({ eventId, userIds }: ISendEventInviteProps): Promise<void> => {
  const payload = {
    userIds,
  };

  await api(
    'POST',
    'eventBase',
    {
      urlParam: `/${eventId}/invite/send`,
      data: payload,
    },
    { allowParallelRequests: true },
  );
};

// React hook to use sendEventInvite function
export const useSendEventInvite = (options?: UseMutationOptions<void, unknown, ISendEventInviteProps, unknown>) =>
  useMutation((requestBody: ISendEventInviteProps) => sendEventInvite(requestBody), {
    ...options,
    onSuccess: (_data, ...args) => {
      options?.onSuccess?.(_data, ...args);
    },
  });

interface ISendTestInviteEventProps {
  eventId: string;
}

const sendTestInviteRequest = async ({ eventId }: ISendTestInviteEventProps): Promise<void> => {
  await api('POST', 'eventBase', {
    urlParam: `/${eventId}/invite/test`,
  });
};

export const useSendTestInvite = (eventId: string, options?: UseMutationOptions<void, unknown, void, unknown>) =>
  useMutation(() => sendTestInviteRequest({ eventId }), {
    ...options,
    onSuccess: (_data, ...args) => {
      options?.onSuccess?.(_data, ...args);
    },
  });

interface ISendEventRSVPProps {
  eventId: string;
  travelerId: EntityNonUUIDId;
  requestBody: SetUserEventRsvpRequest;
}

const sendEventRSVPRequest = async ({ eventId, travelerId, requestBody }: ISendEventRSVPProps): Promise<void> => {
  await api('POST', 'eventBase', {
    data: requestBody,
    urlParam: `/${eventId}/travelers/${travelerId.id}/rsvp`,
  });
};

export const useSendEventRSVPRequest = () =>
  useMutation((requestBody: ISendEventRSVPProps) => sendEventRSVPRequest(requestBody), {});

interface IGetEventRSVPProps {
  eventId: string;
  travelerId: EntityNonUUIDId;
}

const getEventRSVP = async ({ eventId, travelerId }: IGetEventRSVPProps): Promise<EventUserRsvp> => {
  const data = await api('GET', 'eventBase', {
    urlParam: `/${eventId}/travelers/${travelerId.id}/rsvp`,
  });
  return data as unknown as EventUserRsvp;
};

export const useGetTravelerRSVP = (request: IGetEventRSVPProps, enabled = true) =>
  useQuery<EventUserRsvp>({
    queryKey: ['traveler-rsvp', JSON.stringify(request)],
    queryFn: () => getEventRSVP(request),
    enabled: !!request && enabled,
  });

/**
 * Get Event Preference
 */

const eventPreferenceKey = ['event-preference'];

const DEFAULT_EVENT_PREFERENCE: EventPreference = {
  eventEnabled: false,
  eventGuestConfig: { companyGuestEnabled: false },
};

export const invalidateEventPreference = () => defaultQueryClient.invalidateQueries(eventPreferenceKey);

const fetchEventPreference = async (): Promise<EventPreference> => {
  try {
    const data = await api('GET', 'eventBase', {
      urlParam: `/preference`,
    });
    return data ? (data as unknown as EventPreference) : DEFAULT_EVENT_PREFERENCE;
  } catch {
    return DEFAULT_EVENT_PREFERENCE;
  }
};

export const useGetEventPreference = () =>
  useQuery<EventPreference>({
    queryKey: eventPreferenceKey,
    queryFn: fetchEventPreference,
  });

const generateEventImages = async (requestBody: GenerateEventImagesRequest): Promise<GenerateEventImagesResponse> => {
  try {
    const data = await api('POST', 'eventBase', {
      urlParam: '/images/generate',
      data: requestBody,
    });
    return data as GenerateEventImagesResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useGenerateEventImages = (
  key?: string,
): SpotnanaQueryMutationResult<GenerateEventImagesResponse | null, GenerateEventImagesRequest> => {
  return useMutation((requestBody: GenerateEventImagesRequest) => generateEventImages(requestBody), {
    mutationKey: key,
  });
};

/**
 * Delete Draft Event
 */

type DeleteDraftEventParams = {
  eventId: string;
};
function deleteDraftEvent(params: DeleteDraftEventParams): Promise<void> {
  return api('DELETE', 'eventBase', { urlParam: `/${params.eventId}` }) as Promise<void>;
}
export const useDeleteDraftEventMutation = (
  options?: UseMutationOptions<void, SpotnanaError, DeleteDraftEventParams, SpotnanaError>,
): SpotnanaQueryMutationResult<void, DeleteDraftEventParams> =>
  useMutation((requestBody: DeleteDraftEventParams) => deleteDraftEvent(requestBody), {
    ...options,
    onSuccess: async (_data, ...args) => {
      await invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming);
      options?.onSuccess?.(_data, ...args);
    },
  });

/**
 * Cancel Published Event
 */

type CancelPublishEventParams = {
  eventId: string;
};
function cancelPublishEvent(params: CancelPublishEventParams): Promise<void> {
  return api('POST', 'eventBase', { urlParam: `/${params.eventId}/cancel` }) as Promise<void>;
}
export const useCancelPublishEventMutation = (
  options?: UseMutationOptions<void, SpotnanaError, CancelPublishEventParams, SpotnanaError>,
): SpotnanaQueryMutationResult<void, CancelPublishEventParams> =>
  useMutation((requestBody: CancelPublishEventParams) => cancelPublishEvent(requestBody), {
    ...options,
    onSuccess: async (_data, ...args) => {
      const [variables] = args;
      await Promise.all([
        invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.Upcoming),
        invalidateEventSummariesKey(ListEventsRequestListEventTypeEnum.CancelledEvent),
        defaultQueryClient.removeQueries(eventDetailsKey(variables.eventId)),
      ]);
      options?.onSuccess?.(_data, ...args);
    },
  });

export type EventTravelerFilter =
  | {
      type: 'TRAVELER_SEARCH_FILTER';
      searchTerm: string;
    }
  | {
      type: 'INVITATION_STATUS_FILTER';
      rsvpState: EventRsvpState[];
    }
  | {
      type: 'AIR_BOOKING_STATUS_FILTER';
      bookingStatus: BookingStatusType[];
    }
  | {
      type: 'HOTEL_BOOKING_STATUS_FILTER';
      bookingStatus: BookingStatusType[];
    }
  | {
      type: 'RAIL_BOOKING_STATUS_FILTER';
      bookingStatus: BookingStatusType[];
    }
  | {
      type: 'CAR_BOOKING_STATUS_FILTER';
      bookingStatus: BookingStatusType[];
    };

export const fetchEventTravelerList = async (
  args: { eventId: string },
  requestBody: { offset: unknown; limit: unknown; filters: EventTravelerFilter[] },
): Promise<EventTravelersResponse> => {
  const response = await api('POST', 'eventBase', {
    urlParam: `/${args.eventId}/travelers-list`,
    data: {
      offset: requestBody.offset,
      limit: requestBody.limit,
      filters: requestBody.filters,
    },
  });
  return response as EventTravelersResponse;
};

type EventTravelerListQueryKeyProps = {
  eventId: string;
  // BE is misbehaving if we use number instead of string
  paginationParams: { offset: unknown; limit: unknown };
  filters?: EventTravelerFilter[];
} & Record<string, unknown>;

export const makeEventTravelerListQueryKey = ({
  eventId,
  paginationParams,
  filters,
  ...rest
}: EventTravelerListQueryKeyProps) =>
  ['events', 'traveler-list', { paginationParams, eventId, filters, ...rest }] as const;
