import { css } from '@emotion/react';
import { themed } from '../utils';

export const main_styles = themed(
  ({ palette }) => css`
    /* stylelint-disable */
    width: 100%;
    .state-error > &,
    header {
      margin: auto;
      position: relative;
      display: flex;
      justify-content: center;
    }

    header a {
      margin: 20px 0 0 0;
      display: block;
    }

    header img {
      display: block;
      height: 100%;
      width: 100%;
    }

    header h1 {
      text-align: left;
      color: rgb(51, 51, 51);
      display: inline-block;
      margin: 20px 0 0 0;
    }

    pre {
      line-height: 1.1;
      background: rgb(34, 34, 34);
      color: ${palette.surface.base};
      margin: 0;
      padding: 10px;
      font-size: 12px;
      overflow: auto;
      max-height: 400px;
    }

    pre::-webkit-scrollbar {
      background: transparent;
      width: 10px;
    }

    pre::-webkit-scrollbar-thumb {
      background: ${palette.bg.buttonDisabled};
    }

    .component-picker-menu {
      width: 200px;
    }

    .RTEDropdown {
      z-index: 10;
      display: block;
      position: absolute;
      box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      min-height: 40px;
      background-color: ${palette.surface.base};
    }

    .RTEDropdown .item {
      margin: 0 8px 0 8px;
      padding: 8px;
      color: ${palette.text.primary};
      cursor: pointer;
      line-height: 16px;
      font-size: 15px;
      display: flex;
      align-content: center;
      flex-direction: row;
      flex-shrink: 0;
      justify-content: space-between;
      background-color: ${palette.surface.base};
      border-radius: 8px;
      border: 0;
      min-width: 100px;
    }

    .RTEDropdown .item .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      min-width: 150px;
    }

    .toolbar .toolbar-item .text {
      display: flex;
      line-height: 20px;
      vertical-align: middle;
      font-size: 14px;
      color: rgb(119, 119, 119);
      text-overflow: ellipsis;
      overflow: hidden;
      height: 20px;
      text-align: left;
      padding-right: 10px;
    }

    .RTEDropdown .item.fontsize-item,
    .RTEDropdown .item.fontsize-item .text {
      min-width: unset;
    }

    .RTEDropdown .item .active {
      display: flex;
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    .RTEDropdown .item:first-child {
      margin-top: 8px;
    }

    .RTEDropdown .item:last-child {
      margin-bottom: 8px;
    }

    .RTEDropdown .item:hover {
      background-color: ${palette.bg.buttonDisabled};
    }

    .RTEDropdown .item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      line-height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .RTEDropdown .divider {
      width: auto;
      background-color: ${palette.bg.buttonDisabled};
      margin: 4px 8px;
      height: 1px;
    }

    @media screen and (max-width: 1100px) {
      .RTEDropdown-button-text {
        display: none !important;
      }
      .font-size .RTEDropdown-button-text {
        display: flex !important;
      }
    }

    .switches {
      z-index: 6;
      position: fixed;
      left: 10px;
      bottom: 70px;
      animation: slide-in 0.4s ease;
    }

    @keyframes slide-in {
      0% {
        opacity: 0;
        transform: translateX(-200px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .switch {
      display: block;
      color: rgb(68, 68, 68);
      margin: 5px 0;
      background-color: rgba(238, 238, 238, 0.7);
      padding: 5px 10px;
      border-radius: 10px;
    }

    .switch label {
      margin-right: 5px;
      line-height: 24px;
      width: 100px;
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
    }

    .switch button {
      background-color: rgb(206, 208, 212);
      height: 24px;
      box-sizing: border-box;
      border-radius: 12px;
      width: 44px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      outline: none;
      cursor: pointer;
      transition: background-color 0.1s;
      border: 2px solid transparent;
    }

    .switch button:focus-visible {
      border-color: rgb(0, 0, 255);
    }

    .switch button span {
      top: 0px;
      left: 0px;
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background-color: ${palette.surface.base};
      transition: transform 0.2s;
    }

    .switch button[aria-checked='true'] {
      background-color: rgb(24, 119, 242);
    }

    .switch button[aria-checked='true'] span {
      transform: translateX(20px);
    }

    .actions {
      position: absolute;
      text-align: right;
      margin: 10px;
      bottom: 0;
      right: 0;
    }

    .actions.tree-view {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .actions i {
      background-size: contain;
      display: inline-block;
      height: 15px;
      width: 15px;
      vertical-align: -0.25em;
    }

    i.chevron-down {
      background-color: transparent;
      background-size: contain;
      display: inline-block;
      height: 8px;
      width: 8px;
    }

    .action-button {
      background-color: ${palette.bg.buttonDisabled};
      border: 0;
      padding: 8px 12px;
      position: relative;
      margin-left: 5px;
      border-radius: 15px;
      color: rgb(34, 34, 34);
      display: inline-block;
      cursor: pointer;
    }

    .action-button:hover {
      background-color: rgb(221, 221, 221);
      color: ${palette.text.primary};
    }

    .action-button-mic.active {
      animation: mic-pulsate-color 3s infinite;
    }
    button.action-button:disabled {
      opacity: 0.6;
      background: ${palette.bg.buttonDisabled};
      cursor: not-allowed;
    }

    .connecting {
      font-size: 15px;
      color: ${palette.text.disabled};
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 10px;
      left: 10px;
      user-select: none;
      white-space: nowrap;
      display: inline-block;
      pointer-events: none;
    }

    .ltr {
      text-align: left;
    }

    .rtl {
      text-align: right;
    }

    .toolbar {
      display: flex;
      margin-bottom: 1px;
      background: ${palette.surface.base};
      padding: 4px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      vertical-align: middle;
      overflow: auto;
      height: 36px;
      box-sizing: content-box;
      position: sticky;
      border-bottom: 1px solid ${palette.bg.buttonDisabled};
      top: 0;
      z-index: 2;
    }

    .toolbar button.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
    }

    .toolbar button.toolbar-item:disabled {
      cursor: not-allowed;
    }

    .toolbar button.toolbar-item.spaced {
      margin-right: 2px;
    }

    .toolbar button.toolbar-item i.format {
      background-size: contain;
      height: 18px;
      width: 18px;
      vertical-align: -0.25em;
      display: flex;
      opacity: 0.6;
    }

    .toolbar .toolbar-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;
    }

    .toolbar .toolbar-item.font-family .text {
      display: block;
      max-width: 40px;
    }

    .toolbar button.toolbar-item:disabled .icon,
    .toolbar button.toolbar-item:disabled .text,
    .toolbar button.toolbar-item:disabled i.format,
    .toolbar button.toolbar-item:disabled .chevron-down {
      opacity: 0.2;
    }

    .toolbar button.toolbar-item.active {
      background-color: rgba(223, 232, 250, 0.3);
    }

    button.item i {
      opacity: 0.6;
    }

    button.item.RTEDropdown-item-active i {
      opacity: 1;
    }

    .toolbar button.toolbar-item.active i {
      opacity: 1;
    }

    .toolbar .toolbar-item:hover:not([disabled]) {
      background-color: ${palette.bg.buttonDisabled};
    }

    .toolbar i.chevron-down {
      margin-top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      user-select: none;
    }

    .toolbar i.chevron-down.inside {
      width: 16px;
      height: 16px;
      display: flex;
      margin-left: -25px;
      margin-top: 11px;
      margin-right: 10px;
      pointer-events: none;
    }

    .toolbar .divider {
      width: 1px;
      background-color: ${palette.bg.buttonDisabled};
      margin: 0 4px;
    }

    .sticky-note-container {
      position: absolute;
      z-index: 9;
      width: 120px;
      display: inline-block;
    }

    .spacer {
      letter-spacing: -2px;
    }

    button.item.RTEDropdown-item-active {
      background-color: rgba(223, 232, 250, 0.3);
    }

    .PlaygroundEditorTheme__blockCursor {
      display: block;
      pointer-events: none;
      position: absolute;
    }

    .PlaygroundEditorTheme__blockCursor:after {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      width: 20px;
      border-top: 1px solid ${palette.border.info};
      animation: CursorBlink 1.1s steps(2, start) infinite;
    }

    @keyframes CursorBlink {
      to {
        visibility: hidden;
      }
    }

    .spotnana-text-editor--editor__shell {
      &.state-error {
        border: 1px solid ${palette.border.error} !important;
      }
      .state-success > &,
      &.state-success {
        border: 1px solid ${palette.border.success} !important;
      }
      margin: auto;
      border: 1px solid ${palette.border.light};
      border-radius: 10px;
      color: ${palette.text.secondary};
      position: relative;
      line-height: 1.7;
      font-weight: 400;
    }

    .spotnana-text-editor--editor__container {
      background: ${palette.surface.base};
      position: relative;
      display: block;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .spotnana-text-editor--editor__scroller-container {
      min-height: 300px;
      border: 0;
      display: flex;
      position: relative;
      outline: 0;
      z-index: 0;
      overflow: auto;
    }

    .spotnana-text-editor--editor__inner-container {
      flex: auto;
      position: relative;
      z-index: -1;
    }

    .spotnana-text-editor--content-editable__root {
      border: 0;
      font-size: 15px;
      display: block;
      position: relative;
      tab-size: 1;
      outline: 0;
      padding: 8px 28px 40px;
      height: 100%;
    }

    @media (max-width: 1025px) {
      .spotnana-text-editor--content-editable__root {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .spotnana-text-editor--placeholder__root {
      font-size: 15px;
      color: ${palette.text.disabled};
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 8px;
      left: 28px;
      right: 28px;
      user-select: none;
      white-space: nowrap;
      display: inline-block;
      pointer-events: none;
    }
  `,
);
