import markerSDK, { ISetCustomData, MarkerWidget } from '@marker.io/browser';
import {
  getSessionId,
  ITraveler,
  ProcessedError,
  useFeatureFlag,
  useFetchCompanyBasicInfo,
  useLoggedInUser,
  useOrganizationReadBasicInfoQuery,
} from 'obt-common';
import { useEffect, useState } from 'react';
import useApiTraceInfo from 'src/hooks/useApiTraceInfo';

// Destination: DevRev (Internal Prod)
const markerApiProjectKey = '65f0885776767487a6ebaa2a';

interface IProps {
  primaryTraveler: ITraveler | undefined;
  error?: ProcessedError;
}

const useMarkerIo = ({ primaryTraveler, error }: IProps) => {
  const { getTraceInfo } = useApiTraceInfo();
  const isBugFilingFeatureEnabled = useFeatureFlag('FE_INFRA_BUG_FILLING');
  const { data: loggedInUser } = useLoggedInUser();
  const email = loggedInUser?.userBusinessInfo?.email ?? loggedInUser?.user?.email ?? null;
  const name = loggedInUser?.user?.name;

  const [marker, setMarker] = useState<MarkerWidget>();

  const companyId = primaryTraveler?.userBusinessInfo?.organizationId?.id ?? '';
  const { data: companyBasicInfo } = useFetchCompanyBasicInfo(companyId, !!companyId, undefined);
  const primaryTravelerCompany = companyBasicInfo?.name ?? '';
  const primaryTravelerEmail = primaryTraveler?.userBusinessInfo?.email ?? primaryTraveler?.user?.email ?? '';
  const primaryTravelerUserId = primaryTraveler?.userOrgId?.userId?.id ?? '';
  const primaryTravelerOrgId = primaryTraveler?.userOrgId?.organizationId?.id ?? '';
  const primaryTravelerContractTmcId = primaryTraveler?.userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id ?? '';
  const { data: orgDetail } = useOrganizationReadBasicInfoQuery(primaryTraveler?.userBusinessInfo?.organizationId);
  const { data: contractTmcOrgDetail } = useOrganizationReadBasicInfoQuery(
    primaryTraveler?.userOrgId?.tmcBasicInfo?.contractingTmc?.id,
  );

  useEffect(() => {
    if (isBugFilingFeatureEnabled && !marker) {
      markerSDK
        .loadWidget({
          destination: markerApiProjectKey,
          silent: true,
        })
        .then((widget) => {
          setMarker(widget);
        });
    }
  }, [isBugFilingFeatureEnabled, marker]);

  useEffect(() => {
    if (marker && email) {
      marker.setReporter({ email, fullName: [name?.given, name?.family1].join(' ') });
    }
  }, [email, name, marker]);

  const handleMarkerIoFormSubmit = (): void => {
    if (!marker) {
      return;
    }

    let customData: ISetCustomData = {
      debugToolLinkQueue: JSON.stringify(getTraceInfo().debugToolLinkQueue),
      primaryTravelerCompany,
      primaryTravelerEmail,
      primaryTravelerUserId,
      primaryTravelerOrgId,
      primaryTravelerContractTmcId,
      primaryTravelerOrgName: orgDetail?.name ?? '',
      primaryTravelerContractTmcName: contractTmcOrgDetail?.name ?? '',
      sessionId: getSessionId(),
    };

    if (error) {
      const { errorCode, originalErrorMessage } = error;
      customData = {
        ...customData,
        errorCode,
        sourceErrorMessage: originalErrorMessage,
      };
    }

    marker.setCustomData(customData);
    marker.capture();
  };

  return { marker, handleMarkerIoFormSubmit };
};

export { useMarkerIo };
