/* eslint-disable no-underscore-dangle */

import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import memoize from 'lodash/memoize';
import type {
  AirSeatMapResponse,
  CabinSection,
  ColumnSection,
  ColumnSectionColumn,
  FacilitySection,
  FlightSeatMap,
  RowSection,
  SeatSection,
  SeatSectionPrice,
} from '../types/api/v1/obt/air/air_seat_map_response';
import { SeatSectionSeatLimitation } from '../types/api/v1/obt/air/air_seat_map_response';
import type {
  ICabinSection,
  IFlightSeatMap,
  IRow,
  ISeat,
  IRowInfo,
  ICabinSectionRowsWithSeatGroup,
  IFacilitySectionFacility,
} from '../types/flight';
import {
  IRowInfoType,
  RowSectionRowType,
  SeatSectionSeatType,
  AirSeatMapLocation,
  SeatSectionSeatFacility,
} from '../types/flight';
import { MoneyUtil } from '../utils/Money';
import { emptySeat } from '../constants';

export default class AirSeatMapResponseManager {
  constructor(protected readonly response: AirSeatMapResponse) {
    if (!response) {
      throw new Error('Invalid Air Seat Map Response passed to AirSeatMapResponseManager');
    }
  }

  public hasSeatMapsForAnyFlight(): boolean {
    return this.response.seatMaps.some((_, index) => this.isSeatSelectionEnabled(index));
  }

  public getflightIndicesWithSeatMaps(): number[] {
    const flightIndices: number[] = [];
    this.response.seatMaps.forEach((_, flightIndex) => {
      if (this.isSeatSelectionEnabled(flightIndex)) {
        flightIndices.push(flightIndex);
      }
    });
    return flightIndices;
  }

  public getFlightIndicesWithSeatMapsForMultipax(): number[] {
    const paxSeatMaps = this.response.paxSeatMaps || [];

    if (paxSeatMaps.length === 0) {
      return this.getflightIndicesWithSeatMaps();
    }

    const primaryPaxSeatmapIndices = paxSeatMaps[0].flightSeatMapIndices;

    const flightIndices: number[] = [];
    primaryPaxSeatmapIndices.forEach((seatMapIndex: number, flightIndex: number) => {
      if (this.isSeatSelectionEnabled(seatMapIndex)) {
        flightIndices.push(flightIndex);
      }
    });

    return flightIndices;
  }

  public isSeatSelectionEnabled(seatMapIndex: number): boolean {
    return this.response.seatMaps[seatMapIndex]?.cabinSections.length > 0;
  }

  public static getSeatPrice(price?: SeatSectionPrice): MoneyUtil {
    if (!price || !price.totalAmount) {
      return MoneyUtil.zeroMoney();
    }

    return MoneyUtil.parse(price.totalAmount);
  }

  private static getSeatInfoForRowColumnNumberAndSeatmap(
    rowNumber: number,
    columnNumber: string,
    seatMap: IFlightSeatMap,
  ): ISeat | null {
    const { cabinSections } = seatMap;

    const cabinSectionWithSeat = cabinSections.find((cabinSection) => {
      const minRow = cabinSection.rows[0].rowNumber;
      const maxRow = cabinSection.rows[cabinSection.rows.length - 1].rowNumber;

      if (rowNumber < minRow || rowNumber > maxRow) {
        return false;
      }

      const hasColumn = cabinSection.columnSections
        .flatMap((sectionInfo) => sectionInfo.columns)
        .some((columnInfo) => columnInfo.columnNumber === columnNumber);

      if (!hasColumn) {
        return false;
      }

      return true;
    });

    if (!cabinSectionWithSeat) {
      return null;
    }

    const rowWithSeat = cabinSectionWithSeat.rows.find((row) => row.rowNumber === rowNumber);

    if (!rowWithSeat) {
      return null;
    }

    const seatInfo = rowWithSeat.seats.find((seat) => seat.columnInfo.columnNumber === columnNumber);

    if (!seatInfo) {
      return null;
    }

    return seatInfo;
  }

  public GetFlightSeatMapsForPaxAndFlight(passengerId: string, flightIndex: number): IFlightSeatMap | undefined {
    const paxSeatMaps = this.response.paxSeatMaps || [];
    const paxSeatMapInfo = paxSeatMaps.find((seatInfo) => seatInfo.userOrgId?.userId?.id === passengerId);

    const flightSeatMapIndex = paxSeatMapInfo?.flightSeatMapIndices[flightIndex] ?? -1;
    if (flightSeatMapIndex < 0) {
      return undefined;
    }

    const flightSeatMap = this.GetFlightSeatMaps(flightSeatMapIndex);
    return flightSeatMap;
  }

  public getSeatPriceForPaxAndSeatNumber(passengerId: string, flightIndex: number, selectedSeat: string): MoneyUtil {
    const seatInfo = this.getSeatInfoForPaxAndSeatNumber(passengerId, flightIndex, selectedSeat);

    if (!seatInfo) {
      return MoneyUtil.zeroMoney();
    }

    return AirSeatMapResponseManager.getSeatPrice(seatInfo.price);
  }

  /**
   * Returns null if selectedSeat is passed as empty string.
   */
  private getSeatInfoForPaxAndSeatNumber(passengerId: string, flightIndex: number, selectedSeat: string): ISeat | null {
    if (!selectedSeat) {
      return null;
    }

    const flightSeatMap = this.GetFlightSeatMapsForPaxAndFlight(passengerId, flightIndex);
    if (!flightSeatMap) {
      return null;
    }

    const rowNumber = parseInt(selectedSeat, 10);
    const columnName = selectedSeat.split(rowNumber.toString())[1];

    if (Number.isNaN(rowNumber) || !columnName) {
      return null;
    }

    return AirSeatMapResponseManager.getSeatInfoForRowColumnNumberAndSeatmap(rowNumber, columnName, flightSeatMap);
  }

  public getIsSeatAvailableForPaxAndSeatNumber(
    passengerId: string,
    flightIndex: number,
    selectedSeat: string,
  ): boolean {
    const seatInfo = this.getSeatInfoForPaxAndSeatNumber(passengerId, flightIndex, selectedSeat);

    return !!seatInfo?.isAvailable;
  }

  private static getRowForFacilities(
    rowNumber: number,
    columnSections: ColumnSection[],
    facilities: IFacilitySectionFacility[],
  ): IRowInfo | null {
    const leftFacilities = AirSeatMapResponseManager.clubFacilitiesIfSimilar(
      facilities.filter(({ location }) => location === AirSeatMapLocation.LEFT),
    );
    const centerFacilities = AirSeatMapResponseManager.clubFacilitiesIfSimilar(
      facilities.filter(
        ({ location }) =>
          location === AirSeatMapLocation.CENTER ||
          location === AirSeatMapLocation.LEFT_CENTER ||
          location === AirSeatMapLocation.RIGHT_CENTER,
      ),
    );
    const rightFacilities = AirSeatMapResponseManager.clubFacilitiesIfSimilar(
      facilities.filter(({ location }) => location === AirSeatMapLocation.RIGHT),
    );

    if (leftFacilities.length === 0 && centerFacilities.length === 0 && rightFacilities.length === 0) {
      return null;
    }

    const numberOfSections = columnSections.length;
    const facilitySections: IFacilitySectionFacility[][] = columnSections.map((_, sectionIndex) => {
      if (sectionIndex === 0) {
        return leftFacilities;
      }

      if (numberOfSections > 1 && sectionIndex === numberOfSections - 1) {
        return rightFacilities;
      }

      if (numberOfSections === 3 && sectionIndex === 1) {
        return centerFacilities;
      }

      return [];
    });

    const isFacilitiesEmpty = facilitySections.every((facilitySectionInfo) => facilitySectionInfo.length < 1);
    if (isFacilitiesEmpty) {
      return null;
    }

    return {
      rowNumber,
      seatGroups: [],
      rowType: IRowInfoType.FACILITY,
      facilitySections,
    };
  }

  private static clubFacilitiesIfSimilar(facilities: IFacilitySectionFacility[]): IFacilitySectionFacility[] {
    return uniqBy(facilities, (facility) => facility.facilityType);
  }

  private static _getCabinSectionsWithVisibleColumnAndRows(
    cabinSections: ICabinSection[],
  ): ICabinSectionRowsWithSeatGroup[] {
    const visibleColumnNumbers: Set<string> = new Set();

    cabinSections.forEach((cabinSectionInfo) => {
      const { rows } = cabinSectionInfo;

      rows.forEach((rowInfo) => {
        if (rowInfo.rowTypes.includes(RowSectionRowType.NO_ROW)) {
          return;
        }

        rowInfo.seats.forEach((seatInfo) => {
          if (seatInfo.type === SeatSectionSeatType.NO_SEAT) {
            return;
          }

          visibleColumnNumbers.add(seatInfo.columnInfo.columnNumber);
        });
      });
    });

    const cabinSectionsWithVisibleColumnAndRows: ICabinSectionRowsWithSeatGroup[] = cabinSections.map(
      (cabinSectionInfo) => {
        const { columnSections, rows, ...restCabinSection } = cabinSectionInfo;

        const visibleColumnSections: ColumnSection[] = [];
        columnSections.forEach((sectionInfo) => {
          const visibleSectionInfo: ColumnSectionColumn[] = [];
          sectionInfo.columns.forEach((columnSectionColumn) => {
            if (!visibleColumnNumbers.has(columnSectionColumn.columnNumber)) {
              return;
            }

            visibleSectionInfo.push(columnSectionColumn);
          });

          visibleColumnSections.push({ columns: visibleSectionInfo });
        });

        const visibleRows: IRowInfo[] = [];
        rows.forEach((rowInfo) => {
          if (rowInfo.rowTypes.includes(RowSectionRowType.NO_ROW)) {
            return;
          }

          // Add row for showing exit markers.
          const hasExitRow = rowInfo.rowTypes.includes(RowSectionRowType.EXIT);
          if (hasExitRow) {
            visibleRows.push({
              rowNumber: rowInfo.rowNumber,
              rowType: IRowInfoType.EXIT,
              seatGroups: [],
            });
          }

          // Separate front and rear facility if the row doesn't have exit rowType.
          const frontFacilitySections: FacilitySection[] = [];
          const rearFacilitySections: FacilitySection[] = [];
          if (!hasExitRow) {
            rowInfo.facilitySections.forEach((facilitySectionInfo) => {
              if (facilitySectionInfo.location === AirSeatMapLocation.FRONT) {
                frontFacilitySections.push(facilitySectionInfo);
              } else if (facilitySectionInfo.location === AirSeatMapLocation.REAR) {
                rearFacilitySections.push(facilitySectionInfo);
              }
            });
          }

          // Add rows for each of the front facility.
          frontFacilitySections.forEach((facilitySectionInfo) => {
            const facilityRow = AirSeatMapResponseManager.getRowForFacilities(
              rowInfo.rowNumber,
              visibleColumnSections,
              facilitySectionInfo.facilities,
            );
            if (facilityRow) {
              visibleRows.push(facilityRow);
            }
          });

          // Add actual row with info for seats.
          const seatGroups: ISeat[][] = [];
          const seatsKeyedByColumnNumber = keyBy(rowInfo.seats, 'columnInfo.columnNumber');
          visibleColumnSections.forEach((columnSectionInfo) => {
            const seatGroup: ISeat[] = [];
            columnSectionInfo.columns.forEach((columnInfo) => {
              const seatInfo = seatsKeyedByColumnNumber[columnInfo.columnNumber];
              if (!seatInfo) {
                // This is added so that frontend render empty space.
                seatGroup.push({
                  ...emptySeat,
                  columnInfo,
                  type: SeatSectionSeatType.UNRECOGNIZED,
                  seatNumber: rowInfo.rowNumber + columnInfo.columnNumber,
                });
              } else {
                seatGroup.push(seatInfo);
              }
            });

            seatGroups.push(seatGroup);
          });
          visibleRows.push({
            rowNumber: rowInfo.rowNumber,
            rowType: IRowInfoType.ROW,
            seatGroups,
          });

          // Add rows for each of the rear facility.
          rearFacilitySections.forEach((facilitySectionInfo) => {
            const facilityRow = AirSeatMapResponseManager.getRowForFacilities(
              rowInfo.rowNumber,
              visibleColumnSections,
              facilitySectionInfo.facilities,
            );
            if (facilityRow) {
              visibleRows.push(facilityRow);
            }
          });
        });

        return {
          ...restCabinSection,
          columnSections: visibleColumnSections,
          rows: visibleRows,
        };
      },
    );
    return cabinSectionsWithVisibleColumnAndRows;
  }

  public static getCabinSectionsWithVisibleColumnAndRows = memoize(
    AirSeatMapResponseManager._getCabinSectionsWithVisibleColumnAndRows,
    JSON.stringify,
  );

  /**
   * @deprecated Use GetFlightSeatMapsForPaxAndFlight
   * TODO: Change this to private method once public usages are removed.
   */
  public GetFlightSeatMaps(seatmapIndex: number): IFlightSeatMap {
    return AirSeatMapResponseManager.GetFlightSeatMap(this.response.seatMaps[seatmapIndex]);
  }

  private static GetFlightSeatMap(seatMap: FlightSeatMap): IFlightSeatMap {
    return {
      wingRows: seatMap.wingRows,
      cabinSections: seatMap.cabinSections.map(AirSeatMapResponseManager.GetCabinSection),
    };
  }

  private static GetCabinSection({
    rowSections,
    cabin,
    columnSections,
    ...restCabinSection
  }: CabinSection): ICabinSection {
    const columnSectionColumns = columnSections.flatMap((cS) => cS.columns);
    const columnInfoByColumnNumber = keyBy(columnSectionColumns, 'columnNumber');

    return {
      ...restCabinSection,
      columnSections,
      cabin,
      rows: rowSections
        .map((info) => AirSeatMapResponseManager.GetRows(info, columnInfoByColumnNumber))
        .flat()
        .sort((row1, row2) => row1.rowNumber - row2.rowNumber),
    };
  }

  private static GetRows(
    rowSection: RowSection,
    columnInfoByColumnNumber: Record<string, ColumnSectionColumn>,
  ): IRow[] {
    const rows: IRow[] = [];
    if (rowSection.rowNumbers === undefined) {
      return rows;
    }
    for (let i = rowSection.rowNumbers.min; i <= rowSection.rowNumbers.max; i += 1) {
      rows.push({
        rowNumber: i,
        seats: AirSeatMapResponseManager.GetSeats(
          i,
          rowSection.rowTypes,
          rowSection.seatSections,
          rowSection.availableSeats[i - rowSection.rowNumbers.min].columnNumber,
          columnInfoByColumnNumber,
        ),
        rowTypes: rowSection.rowTypes || [],
        facilitySections: rowSection.facilitySections,
      });
    }
    return rows;
  }

  private static GetSeats(
    rowNumber: number,
    rowTypes: RowSectionRowType[],
    seatSections: SeatSection[],
    availableSeats: string[],
    columnInfoByColumnNumber: Record<string, ColumnSectionColumn>,
  ): ISeat[] {
    const seats: ISeat[] = [];
    seatSections.forEach(({ columnNumbers, facilities, limitations, ...restSeatSection }) =>
      columnNumbers.forEach((columnNumber) => {
        const columnInfo = columnInfoByColumnNumber[columnNumber];
        const seatNumber = `${rowNumber}${columnNumber}`;
        seats.push({
          ...restSeatSection,
          seatNumber,
          columnInfo,
          isAvailable: availableSeats.includes(columnNumber),
          isPreferred: facilities.includes(SeatSectionSeatFacility.PREFERRED_SEAT),
          isWheelchairAccessible: facilities.includes(SeatSectionSeatFacility.FACILITIES_FOR_HANDICAPPED_INCAPACITATED),
          isBassinet: facilities.includes(SeatSectionSeatFacility.BASSINET),
          isExtraLegroom:
            facilities.includes(SeatSectionSeatFacility.LEG_SPACE) ||
            rowTypes.includes(RowSectionRowType.EXTRA_LEG_ROOM),
          commercialName: restSeatSection.commercialName,
          facilities,
          limitations,
          isLoyaltyLevelRequired: limitations.includes(SeatSectionSeatLimitation.LOYALTY_LEVEL_REQUIRED),
        });
      }),
    );
    seats.sort((seat1, seat2) => (seat1.columnInfo.columnNumber > seat2.columnInfo.columnNumber ? 1 : -1));
    return seats;
  }
}
