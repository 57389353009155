import { css } from '@emotion/react';
import { themed } from '@spotnana/blocks/src/utils/styling/themed';

export const modal_content = themed(
  ({ palette }) => css`
    padding: 1rem;
    border-radius: 8px;
    background-color: ${palette.surface.background};
  `,
);

export const modal_line_item = css`
  display: flex;
  padding: 4px 0;
`;

export const modal_name_container = css`
  width: 140px;
`;

export const modal_footer = css`
  display: flex;
  gap: 12px;
`;

export const copy_to_clipboard_button = css`
  flex: 1;
  padding: 12px;
  font-size: 16px;
`;

export const divider_container = css`
  margin: 8px 0;
`;
