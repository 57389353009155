/* eslint-disable import/prefer-default-export */
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult, UseQueryOptions } from 'react-query';
import { useMutation, useQueries, useQuery } from 'react-query';

import type { SuspendRequestReasonEnum } from '@spotnana/types/openapi/models/suspend-request';
import type {
  ListGroupBookingRequest,
  ListGroupBookingRequestGroupTypeEnum,
} from '@spotnana/types/openapi/models/list-group-booking-request';
import type { ListGroupBookingResponse } from '@spotnana/types/openapi/models/list-group-booking-response';
import type { TripQcInfo } from '@spotnana/types/openapi/models/trip-qc-info';
import type { ValidatePnrResponse } from '@spotnana/types/openapi/models/validate-pnr-response';
import type { AirEditPnrResponse } from '../types/api/v2/obt/model/air-edit-pnr-response';
import type { AirRevalidateHoldResponse } from '../types/api/v2/obt/model/air-revalidate-hold-response';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type {
  AirPnrExchangeDetailsResponse,
  AppliedTripFeatures,
  GetVirtualCardResponse,
  IMoney,
  InternalAPIError,
  IPnrCancellationDetailsRequest,
  IUserOrgId,
  OBTFeatureConfig,
  PnrCancellationDetails,
  PnrData,
  SpotnanaQueryMutationResult,
  SpotnanaQueryOptions,
  SpotnanaQueryResult,
  TriggerEmailRequest,
  TripPartnerInfoResponse,
  TripV3DetailsResponse,
  UserId,
  UserOrgIdOrNull,
} from '../types';
import type {
  AirCancelPnrRequestCancellationOverride,
  AirCancelPnrRequestCancelType,
  AirCancelPnrResponse,
} from '../types/api/v1/obt/pnr/air_cancel_pnr';
import type { CancelPnrRequest, CancelPnrResponse } from '../types/api/v1/obt/pnr/cancel_pnr';
import type { CreateTripRequest } from '../types/api/v1/obt/trip/create_trip_request';
import type { CreatePartnerTripResponse, CreateTripResponse } from '../types/api/v1/obt/trip/create_trip_response';
import type { ListTripsRequest, ListTripsResponse } from '../types/api/v1/obt/trip/list_trips';
import type {
  DownloadInvoiceResponse,
  GetTripDetailsRequest,
  GetTripDetailsResponse,
  GetVirtualCardRequest,
} from '../types/api/v1/obt/trip/trip_details';
import type { UpdateTripRequest } from '../types/api/v1/obt/trip/update_trip_request';
import type { AirEditPnrRequest } from '../types/api/v2/obt/model/air-edit-pnr-request';
import type { AirTicketPnrResponse } from '../types/api/v2/obt/model/air-ticket-pnr-response';
import type { CreateAgentNoteRequest } from '../types/api/v2/obt/model/create-agent-note-request';
import type { EditTravelerNoteRequest } from '../types/api/v2/obt/model/edit-traveler-note-request';
import type { ListAgentNotesResponse } from '../types/api/v2/obt/model/list-agent-notes-response';
import type { ListTravelerNotesResponse } from '../types/api/v2/obt/model/list-traveler-notes-response';
import type { ListTripActivityRequest } from '../types/api/v2/obt/model/list-trip-activity-request';
import type { ListTripActivityResponse } from '../types/api/v2/obt/model/list-trip-activity-response';
import type { ListTripRemarksRequest } from '../types/api/v2/obt/model/list-trip-remarks-request';
import type { ListTripRemarksResponse } from '../types/api/v2/obt/model/list-trip-remarks-response';
import type { PnrQcResultResponse } from '../types/api/v2/obt/model/pnr-qc-result-response';
import type { SendPaymentCommunicationRequest } from '../types/api/v2/obt/model/send-payment-communication-request';
import type { TravelerPnrVisibilityRequest } from '../types/api/v2/obt/model/traveler-pnr-visibility-request';
import type { UpdateVirtualCardRequest } from '../types/api/v2/obt/model/update-virtual-card-request';
import { defaultQueryClient } from './defaultQueryClient';
import type { FareAmount } from '../types/api/v1/obt/air/air_search_response';
import type { RailCancelPnrRequest } from '../types/api/v2/obt/model/rail-cancel-pnr-request';
import type { RailCancelPnrResponse } from '../types/api/v2/obt/model/rail-cancel-pnr-response';
import { invalidateTripSummaries } from './tripSummary';
import type { PnrCancelRequest } from '../types/api/v2/obt/model/pnr-cancel-request';
import type { FinalizeRequest } from '../types/api/v2/obt/model/finalize-request';
import type { SplitPnrResponse } from '../types/api/v2/obt/model/split-pnr-response';
import type { SplitPnrRequest } from '../types/api/v2/obt/model/split-pnr-request';
import type { ListApprovalsRequest } from '../types/api/v2/obt/model/list-approvals-request';
import type { ListApprovalResponse } from '../types/api/v2/obt/model/list-approval-response';
import type { ProcessPnrApprovalRequest } from '../types/api/v2/obt/model/process-pnr-approval-request';
import type { TripPreBookAnswersResponse } from '../types/api/v2/obt/model/trip-pre-book-answers-response';
import type { CreateGroupBookingRequest } from '../types/api/v2/obt/model/create-group-booking-request';
import type { CreateGroupBookingResponse } from '../types/api/v2/obt/model/create-group-booking-response';
import type { ReadGroupBookingResponse } from '../types/api/v2/obt/model/read-group-booking-response';
import type { GetSpendLimitsResponse } from '../types/api/v2/obt/model/get-spend-limits-response';
import type { CreatePartnerTripRequest } from '../types/api/v2/obt/model/create-partner-trip-request';
import type { AddUsersToGroupRequest } from '../types/api/v2/obt/model/add-user-to-group-request';
import type { RailPnrExchangeDetailsResponse } from '../types/api/v2/obt/model/rail-pnr-exchange-details-response';
import type { PnrQcInfo } from '../types/api/v2/obt/model/pnr-qc-info';

import { delay } from '../utils';

const TRIPS_V1_QUERY_KEY_BASE = 'tripsV1';
const TRIPS_V3_QUERY_KEY_BASE = 'tripsV3';

const getTripV1DetailsQueryKey = (tripId: string) => [TRIPS_V1_QUERY_KEY_BASE, 'details', tripId];
export type TripV1DetailsQueryKeyType = ReturnType<typeof getTripV1DetailsQueryKey>;

export const getTripV3DetailsQueryKey = (tripId: string) => [TRIPS_V3_QUERY_KEY_BASE, 'details', tripId];
export type TripV3DetailsQueryKeyType = ReturnType<typeof getTripV3DetailsQueryKey>;

export const getTripV3SummariesByCompanyQueryKey = (companyId: string, options?: Record<string, unknown>) => [
  TRIPS_V3_QUERY_KEY_BASE,
  'listByCompany',
  companyId,
  options,
];
export type TripV3SummariesByCompanyQueryKeyType = ReturnType<typeof getTripV3DetailsQueryKey>;

const getGroupTripDetailsQueryKey = (groupId: string) => ['group-bookings', groupId];
export type GroupTripDetailsQueryKeyType = ReturnType<typeof getGroupTripDetailsQueryKey>;

const listTripsKey = (userOrgId: IUserOrgId | undefined): unknown[] => ['list-trips', userOrgId?.userId];

interface ListGroupTripKeysParam {
  ownerId: UserId;
  groupType?: ListGroupBookingRequestGroupTypeEnum;
}
const listGroupTripsKey = (request: ListGroupTripKeysParam) => ['list-group-booking', request];
export type ListGroupTripsKey = ReturnType<typeof listGroupTripsKey>;

const pnrQcInfoKey = ({ tripId, pnrId }: PnrQcInfoRequest): unknown[] => [
  'pnr-qc-info',
  `tripId=${tripId}`,
  `pnrId=${pnrId}`,
];

const tripQcInfoKey = (tripId: string): unknown[] => ['trip-qc-info', `tripId=${tripId}`];

const getTripsV3ReadPnrQueryKey = ({ tripId, pnrId }: ITripsV3ExistingPnrParamsBase) => [
  TRIPS_V3_QUERY_KEY_BASE,
  'readPnr',
  `tripId=${tripId}`,
  `pnrId=${pnrId}`,
];

const validatePnrKey = ({ tripId, pnrId }: ITripsV3ExistingPnrParamsBase): [string, string, string] => [
  'validate-pnr',
  tripId,
  pnrId,
];

const getTripsFeaturesKey = ({ tripId }: ITripsFeatures) => ['tripFeatures', `tripId=${tripId}`];

const getTripPreBookAnswersKey = ({ tripId }: ITripPreBookAnswers) => ['tripPreBookAnswers', tripId];

const getRevalidateHoldKey = ({ pnrId }: IRevalidateHoldKey) => ['revalidate-hold', pnrId];

export const invalidateTripPnrDetails = (tripId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTripV1DetailsQueryKey(tripId));

export const removeTripPnrDetails = (tripId: string): void =>
  defaultQueryClient.removeQueries(getTripV1DetailsQueryKey(tripId));

export const invalidateListTripsKey = (userOrgId: IUserOrgId): Promise<void> =>
  defaultQueryClient.invalidateQueries(listTripsKey(userOrgId));

export const invalidateTripV3Details = (tripId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTripV3DetailsQueryKey(tripId));

export const invalidateTripSummariesByCompany = (userOrgId: IUserOrgId): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTripV3SummariesByCompanyQueryKey(userOrgId.userId?.id as string));

export const invalidateTripV1andV3Details = (tripId: string): void => {
  invalidateTripPnrDetails(tripId);
  invalidateTripV3Details(tripId);
};

export const invalidateReadPnrV3 = (tripId: string, pnrId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTripsV3ReadPnrQueryKey({ tripId, pnrId }));

export const invalidateListGroupTripsKey = (ownerId: UserId): Promise<void> =>
  defaultQueryClient.invalidateQueries(listGroupTripsKey({ ownerId }));

export const invalidateGroupTripDetailsKey = (groupId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getGroupTripDetailsQueryKey(groupId));

export const invalidateTripFeatures = (tripId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTripsFeaturesKey({ tripId }));

export const invalidateTripQcInfo = (tripId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(tripQcInfoKey(tripId));

const pnrCancellationKey = 'pnr-cancellation';
const pnrExchangeKey = 'pnr-cancellation';
const getPnrCancellationKey = (pnrId: string, tripId: string) => [pnrCancellationKey, pnrId, tripId];
const getPnrExchangeKey = (pnrId: string) => [pnrExchangeKey, pnrId];
const getPartnerBudgetInfoKey = (tripId: string, bookerEmailId: string) => [
  'partner-budget-info',
  tripId,
  bookerEmailId,
];

const getPreBookAnswersKey = (tripId: string) => ['prebook-answers', tripId];

export const invalidatePnrCancellationKey = (pnrId: string, tripId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getPnrCancellationKey(pnrId, tripId));

export const fetchAllTrips = async (userOrgId: IUserOrgId | undefined): Promise<ListTripsResponse> => {
  const listTripsRequest: ListTripsRequest = { userOrgId };
  const data = await api('POST', 'listTrips', {
    data: listTripsRequest,
  });
  const listTripsResponse = data as ListTripsResponse;
  if (listTripsResponse?.trips) {
    return listTripsResponse;
  }
  throw new SpotnanaError('Cannot parse Trip list response');
};

export const fetchAllGroupTrips = async (request: ListGroupBookingRequest): Promise<ListGroupBookingResponse> => {
  const data = await api('POST', 'listGroupBookings', {
    data: request,
  });

  const listGroupTripsResponse = data as ListGroupBookingResponse;

  if (listGroupTripsResponse?.groupBookingList) {
    return listGroupTripsResponse;
  }

  throw new SpotnanaError('Cannot parse Group Trip list response');
};

const createTrip = async (requestBody: CreateTripRequest): Promise<CreateTripResponse> => {
  const data = await api('POST', 'createTrip', {
    data: requestBody,
  });
  const createTripResponse = data as CreateTripResponse;
  if (createTripResponse?.tripId) {
    return createTripResponse;
  }
  throw new SpotnanaError('Could not create trip');
};

const createGroupTrip = async (requestBody: CreateGroupBookingRequest): Promise<CreateGroupBookingResponse> => {
  const data = await api('POST', 'groupBookings', {
    data: requestBody,
  });

  const createGroupTripResponse = data as CreateGroupBookingResponse;
  if (createGroupTripResponse?.groupBooking) {
    return createGroupTripResponse;
  }

  throw new SpotnanaError('Could not create group trip');
};

export interface ModifiedAddUsersToGroupTripRequest {
  groupId: string;
  request: AddUsersToGroupRequest;
}

const addUsersToGroupTrip = async ({
  groupId,
  request,
}: ModifiedAddUsersToGroupTripRequest): Promise<ReadGroupBookingResponse> => {
  const data = await api('POST', 'groupBookings', {
    data: request,
    urlParam: `/${groupId}/users/add`,
  });

  const addUsersToGroupTripResponse = data as ReadGroupBookingResponse;

  if (addUsersToGroupTripResponse?.groupBooking) {
    return addUsersToGroupTripResponse;
  }

  throw new SpotnanaError('Could not add user to group');
};

export type AirCancelPnrRequestModified = {
  pnrId: string;
  cancelType: AirCancelPnrRequestCancelType;
  optionId?: string;
  cancellationOverride: AirCancelPnrRequestCancellationOverride | null;
};

const cancelAirPnr = async ({
  pnrId,
  cancelType,
  optionId,
  cancellationOverride,
}: AirCancelPnrRequestModified): Promise<AirCancelPnrResponse | null> => {
  const data = await api('POST', 'airCancelPnr', {
    data: {
      pnrId,
      cancelType,
      optionId,
      cancellationOverride: cancellationOverride || undefined,
    },
  });
  const cancelPnrResponse = data as CancelPnrResponse;
  if ('confirmationId' in cancelPnrResponse) {
    return cancelPnrResponse;
  }

  throw new SpotnanaError('Could not cancel flight PNR');
};

const cancelPnr = async ({ pnrId }: CancelPnrRequest): Promise<CancelPnrResponse | null> => {
  const data = await api('POST', 'cancelPnr', {
    data: { pnrId },
  });

  const cancelPnrResponse = data as CancelPnrResponse;

  if ('confirmationId' in cancelPnrResponse) {
    return cancelPnrResponse;
  }

  throw new SpotnanaError('Could not cancel PNR');
};

export const useListTripsQuery = (userOrgId: IUserOrgId | undefined, options?: UseQueryOptions<ListTripsResponse>) =>
  useQuery<ListTripsResponse>({
    ...options,
    queryKey: listTripsKey(userOrgId),
    queryFn: () => fetchAllTrips(userOrgId),
  });

export const useCreateTrip = () =>
  useMutation((requestBody: CreateTripRequest) => createTrip(requestBody), {
    onSuccess: (_data, requestBody) => {
      invalidateListTripsKey(requestBody.userOrgId as IUserOrgId);
      invalidateTripSummaries(requestBody.userOrgId as IUserOrgId);
    },
  });

/**
 * @param ownerId - used to invalidate group trips list API
 */
export const useCreateGroupTrip = () =>
  useMutation((requestBody: CreateGroupBookingRequest) => createGroupTrip(requestBody), {
    onSuccess: (_data) => {
      if (!_data.groupBooking.userTripGroup) {
        return;
      }

      const { ownerId } = _data.groupBooking.userTripGroup;

      invalidateListGroupTripsKey(ownerId as UserId);
    },
  });

export const useAddUsersToGroupTrip = () =>
  useMutation(({ groupId, request }: ModifiedAddUsersToGroupTripRequest) => addUsersToGroupTrip({ groupId, request }), {
    onSuccess: (_data) => {
      if (!_data.groupBooking.userTripGroup) {
        return;
      }

      const { ownerId, id } = _data.groupBooking.userTripGroup;
      invalidateGroupTripDetailsKey(id as string);
      invalidateListGroupTripsKey(ownerId as UserId);
    },
  });

export const useCancelAirPnr = (userOrgId: UserOrgIdOrNull, tripId: string, pnrId: string) =>
  useMutation((requestBody: AirCancelPnrRequestModified) => cancelAirPnr(requestBody), {
    onSuccess: (_data) => {
      if (userOrgId) {
        invalidateTripSummaries(userOrgId as IUserOrgId);
        invalidateListTripsKey(userOrgId as IUserOrgId);
        invalidatePnrCancellationKey(pnrId, tripId);
      }

      invalidateTripV1andV3Details(tripId);
    },
    onError: () => {
      invalidateTripSummaries(userOrgId as IUserOrgId);
      invalidateListTripsKey(userOrgId as IUserOrgId);
      invalidatePnrCancellationKey(pnrId, tripId);

      invalidateTripV1andV3Details(tripId);
    },
  });

export const useCancelPnr = (userOrgId: UserOrgIdOrNull, tripId: string) =>
  useMutation((requestBody: CancelPnrRequest) => cancelPnr(requestBody), {
    onSuccess: (_data) => {
      invalidateTripSummaries(userOrgId as IUserOrgId);
      invalidateListTripsKey(userOrgId as IUserOrgId);

      invalidateTripV1andV3Details(tripId);
    },
  });

const updateTripTitle = async (requestBody: UpdateTripRequest): Promise<void> => {
  await api('POST', 'updateTripDetails', {
    data: requestBody,
  });
};

const getTripInvoice = async (requestBody: { tripId: string }): Promise<void> => {
  await api('GET', 'getTripInvoice', {
    params: requestBody,
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/pdf',
    },
  });
};

export const useUpdateTripTitle = (options?: UseMutationOptions<void, unknown, UpdateTripRequest, unknown>) =>
  useMutation((requestBody: UpdateTripRequest) => updateTripTitle(requestBody), {
    ...options,
    onSuccess: (_data, requestBody: UpdateTripRequest, context) => {
      options?.onSuccess?.(_data, requestBody, context);
      invalidateTripV1andV3Details(requestBody.tripId);
    },
  });

export const useGetInvoicePdf = () => useMutation((requestBody: { tripId: string }) => getTripInvoice(requestBody));

const editPnr = async (requestBody: AirEditPnrRequest): Promise<AirEditPnrResponse> => {
  const data = await api('POST', 'editPnr', { data: requestBody, urlParam: `/${requestBody.pnrId}/update` });
  return data as AirEditPnrResponse;
};

export const useEditPnrMutation = (
  tripId?: string,
): UseMutationResult<AirEditPnrResponse, SpotnanaError, AirEditPnrRequest> =>
  useMutation((requestBody: AirEditPnrRequest) => editPnr(requestBody), {
    onSuccess: () => {
      if (tripId) {
        invalidateTripV1andV3Details(tripId);
      }
    },
  });

export const useListGroupTripsQuery = (
  listGroupBookingRequest: ListGroupBookingRequest,
  options?: SpotnanaQueryOptions<ListGroupBookingResponse, ListGroupTripsKey, SpotnanaError>,
): SpotnanaQueryResult<ListGroupBookingResponse> =>
  useQuery<ListGroupBookingResponse, SpotnanaError, ListGroupBookingResponse, ListGroupTripsKey>({
    queryKey: listGroupTripsKey(listGroupBookingRequest),
    queryFn: () => fetchAllGroupTrips(listGroupBookingRequest),
    ...options,
  });

const revalidateHold = async (pnrId: string): Promise<AirRevalidateHoldResponse> => {
  const data = await api('POST', 'revalidateHold', { urlParam: `/${pnrId}/revalidate-hold` });

  return data as AirRevalidateHoldResponse;
};

export const useRevalidateHoldQuery = (pnrId: string): SpotnanaQueryResult<AirRevalidateHoldResponse> =>
  useQuery({ queryKey: getRevalidateHoldKey({ pnrId }), queryFn: () => revalidateHold(pnrId) });

export const useRevalidateHoldMutation = (): UseMutationResult<AirRevalidateHoldResponse, SpotnanaError, string> =>
  useMutation((pnrId: string) => revalidateHold(pnrId));

export type ConfirmHoldRequest = {
  pnrId: string;
  totalFare: IMoney;
};

export enum ConfirmHoldStatusEnum {
  confirmedHold = 'CONFIRMED_HOLD',
  fareChanged = 'FARE_CHANGED',
}

export type ConfirmHoldResponse = {
  holdStatus: ConfirmHoldStatusEnum;
  updatedTotalFare: FareAmount;
};

const confirmHold = async (requestBody: ConfirmHoldRequest): Promise<ConfirmHoldResponse> => {
  const data = await api('POST', 'confirmHold', {
    urlParam: `/${requestBody.pnrId}/confirm-hold`,
    data: {
      totalFare: requestBody.totalFare,
    },
  });

  return data as ConfirmHoldResponse;
};

export const useConfirmHoldMutation = (): UseMutationResult<ConfirmHoldResponse, SpotnanaError, ConfirmHoldRequest> =>
  useMutation((requestBody: ConfirmHoldRequest) => confirmHold(requestBody));

const triggerEmail = async (requestBody: TriggerEmailRequest, pnrId: string, tripId: string): Promise<void> => {
  await api('POST', 'trips', {
    data: requestBody,
    urlParam: `/${tripId}/pnrs/${pnrId}/trigger-email`,
  });
};

export const useTriggerEmailMutation = (
  tripId: string,
  pnrId: string,
): UseMutationResult<void, SpotnanaError, TriggerEmailRequest> =>
  useMutation((requestBody: TriggerEmailRequest) => triggerEmail(requestBody, pnrId, tripId));

const downloadInvoice = async (requestBody: { pnrId: string; tripId: string }): Promise<DownloadInvoiceResponse> => {
  const data = await api('POST', 'trips', {
    urlParam: `/${requestBody.tripId}/pnrs/${requestBody.pnrId}/download-invoice`,
  });
  return data as DownloadInvoiceResponse;
};

export const useDownloadInvoiceMutation = (): UseMutationResult<
  DownloadInvoiceResponse,
  SpotnanaError,
  { pnrId: string; tripId: string }
> => useMutation((requestBody: { pnrId: string; tripId: string }) => downloadInvoice(requestBody));

const getListTripActivities = async (
  tripId: string,
  request: ListTripActivityRequest,
): Promise<ListTripActivityResponse | undefined> => {
  try {
    const data = await api('POST', 'trips', {
      urlParam: `/${tripId}/activities/fetch`,
      data: request,
    });
    const result = data as ListTripActivityResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const tripActivityQueriesKeyPrefix = 'trip-notes';
const getTripActivityKey = (tripId: string, request: ListTripActivityRequest) => [
  tripActivityQueriesKeyPrefix,
  tripId,
  request,
];

export const useListTripActivitiesQuery = (
  tripId: string,
  request: ListTripActivityRequest,
  enabled: boolean,
): SpotnanaQueryResult<ListTripActivityResponse | undefined> =>
  useQuery(getTripActivityKey(tripId, request), () => getListTripActivities(tripId, request), {
    keepPreviousData: true,
    enabled,
  });

export const invalidateTripActivities = (): Promise<void> =>
  defaultQueryClient.invalidateQueries(tripActivityQueriesKeyPrefix);

const getTripActivityRemarks = async (
  tripId: string,
  request: ListTripRemarksRequest,
): Promise<ListTripRemarksResponse | undefined> => {
  try {
    const data = await api('POST', 'trips', {
      urlParam: `/${tripId}/remarks/fetch`,
      data: request,
    });
    const result = data as ListTripRemarksResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const tripActivityRemarksKey = 'trip-remarks';
const getTripActivityRemarksKey = (tripId: string, request: ListTripRemarksRequest) => [
  tripActivityRemarksKey,
  tripId,
  request,
];

export const useListTripActivityRemarksQuery = (
  tripId: string,
  request: ListTripRemarksRequest,
  enabled: boolean,
): SpotnanaQueryResult<ListTripRemarksResponse | undefined> =>
  useQuery(getTripActivityRemarksKey(tripId, request), () => getTripActivityRemarks(tripId, request), {
    keepPreviousData: true,
    enabled,
  });

export const invalidateTripActivityRemarks = (): Promise<void> =>
  defaultQueryClient.invalidateQueries(tripActivityRemarksKey);

const getAgentNotes = async (tripId: string): Promise<ListAgentNotesResponse | undefined> => {
  try {
    const data = await api('GET', 'trips', {
      urlParam: `/${tripId}/agent-notes`,
    });
    const result = data as ListAgentNotesResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const agentNotesKey = 'agent-notes';
const getAgentNotesKey = (tripId: string) => [agentNotesKey, tripId];

export const useListAgentNotesQuery = (
  tripId: string,
  enabled: boolean,
): SpotnanaQueryResult<ListAgentNotesResponse | undefined> =>
  useQuery(getAgentNotesKey(tripId), () => getAgentNotes(tripId), {
    keepPreviousData: true,
    enabled,
  });

export const invalidateAgentNotes = (): Promise<void> => defaultQueryClient.invalidateQueries(agentNotesKey);

const createAgentNote = async (tripId: string, request: CreateAgentNoteRequest): Promise<void> => {
  try {
    await api('POST', 'trips', {
      urlParam: `/${tripId}/agent-notes`,
      data: request,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface IUseCreateAgentNoteRequest {
  tripId: string;
  request: CreateAgentNoteRequest;
}

export const useCreateAgentNote = (): SpotnanaQueryMutationResult<void, IUseCreateAgentNoteRequest> =>
  useMutation(({ tripId, request }: IUseCreateAgentNoteRequest) => createAgentNote(tripId, request), {
    onSuccess() {
      invalidateTripActivities();
      invalidateAgentNotes();
    },
  });

const deleteAgentNote = async (tripId: string, noteId: string): Promise<void> => {
  try {
    await api('DELETE', 'trips', {
      urlParam: `/${tripId}/agent-notes/${noteId}`,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface IUseDeleteAgentNoteRequest {
  tripId: string;
  noteId: string;
}

export const useDeleteAgentNote = (): SpotnanaQueryMutationResult<void, IUseDeleteAgentNoteRequest> =>
  useMutation(({ tripId, noteId }: IUseDeleteAgentNoteRequest) => deleteAgentNote(tripId, noteId), {
    onSuccess() {
      invalidateTripActivities();
      invalidateAgentNotes();
    },
  });

interface ITripNoteRequest {
  tripId: string;
}

const getTripNoteKey = (tripId: string) => `trip-notes-${tripId}`;

const fetchTripNotes = async ({ tripId }: ITripNoteRequest): Promise<ListTravelerNotesResponse> => {
  try {
    const tripSummariesResponse = await api('GET', 'tripShared', {
      urlParam: `/${tripId}/traveler-notes`,
    });
    return tripSummariesResponse as unknown as ListTravelerNotesResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useGetTripNotes = (tripNoteRequest: ITripNoteRequest): SpotnanaQueryResult<ListTravelerNotesResponse> =>
  useQuery(getTripNoteKey(tripNoteRequest.tripId), () => fetchTripNotes(tripNoteRequest), {
    enabled: !!tripNoteRequest.tripId,
    cacheTime: 0,
  });

interface IUseCreateTripNote {
  tripId: string;
  pnrId: string;
  legId: string;
  note: string;
}

const createTripNote = async ({ tripId, legId, pnrId, note }: IUseCreateTripNote): Promise<void> => {
  try {
    await api('POST', 'tripShared', {
      urlParam: `/${tripId}/pnrs/${pnrId}/legs/${legId}/traveler-notes`,
      data: { note },
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useCreateTripNote = (): SpotnanaQueryMutationResult<void, IUseCreateTripNote> =>
  useMutation((request: IUseCreateTripNote) => createTripNote(request), {
    onSuccess(_, { tripId }: IUseCreateTripNote) {
      defaultQueryClient.invalidateQueries(getTripNoteKey(tripId));
    },
  });

interface IUseUpdateTripNote {
  tripId: string;
  noteId: string;
  pnrId: string;
  legId: string;
  note: string;
}

const updateTripNote = async ({ tripId, note, pnrId, legId, noteId }: IUseUpdateTripNote): Promise<void> => {
  try {
    const data: EditTravelerNoteRequest = {
      note,
    };
    await api('PUT', 'tripShared', {
      urlParam: `/${tripId}/pnrs/${pnrId}/legs/${legId}/traveler-notes/${noteId}`,
      data,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useUpdateTripNote = (): SpotnanaQueryMutationResult<void, IUseUpdateTripNote> =>
  useMutation((request: IUseUpdateTripNote) => updateTripNote(request), {
    onSuccess(_, { tripId }: IUseUpdateTripNote) {
      defaultQueryClient.invalidateQueries(getTripNoteKey(tripId));
    },
  });

interface IUseDeleteTripNote {
  tripId: string;
  noteId: string;
  pnrId: string;
  legId: string;
}

const deleteTripNote = async ({ tripId, noteId, legId, pnrId }: IUseDeleteTripNote): Promise<void> => {
  try {
    await api('DELETE', 'tripShared', {
      urlParam: `/${tripId}/pnrs/${pnrId}/legs/${legId}/traveler-notes/${noteId}`,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useDeleteTripNote = (): SpotnanaQueryMutationResult<void, IUseDeleteTripNote> =>
  useMutation((request: IUseDeleteTripNote) => deleteTripNote(request), {
    onSuccess(_, { tripId }: IUseDeleteTripNote) {
      defaultQueryClient.invalidateQueries(getTripNoteKey(tripId));
    },
  });

interface IUseMarkTripNoteAsSeen {
  tripId: string;
  pnrId: string;
  legId: string;
  noteIds: string[];
}

const markTripNoteAsSeen = async ({ tripId, legId, pnrId, noteIds }: IUseMarkTripNoteAsSeen): Promise<void> => {
  try {
    await api('POST', 'tripShared', {
      urlParam: `/${tripId}/pnrs/${pnrId}/legs/${legId}/traveler-notes/mark-as-seen`,
      data: {
        noteIds: noteIds.map((noteId) => ({ id: noteId })),
      },
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useMarkTripNoteAsSeen = (): SpotnanaQueryMutationResult<void, IUseMarkTripNoteAsSeen> =>
  useMutation((request: IUseMarkTripNoteAsSeen) => markTripNoteAsSeen(request), {
    onSuccess(_, { tripId }: IUseMarkTripNoteAsSeen) {
      defaultQueryClient.invalidateQueries(getTripNoteKey(tripId));
    },
  });

export const invalidateTripNotes = (tripId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTripNoteKey(tripId));

interface ISetPnrVisibilityRequest extends TravelerPnrVisibilityRequest {
  pnrId: string;
}

const setPnrVisibility = async ({ pnrId, ...data }: ISetPnrVisibilityRequest): Promise<void> => {
  try {
    await api('POST', 'pnrShared', {
      urlParam: `/${pnrId}/traveler-visibility`,
      data: {
        travelerPnrVisible: data.travelerVisibility,
      },
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useSetPnrVisibility = (): SpotnanaQueryMutationResult<
  void,
  ISetPnrVisibilityRequest & { tripId: string }
> =>
  useMutation(({ tripId, ...request }: ISetPnrVisibilityRequest & { tripId: string }) => setPnrVisibility(request), {
    onSuccess(_, { tripId }) {
      invalidateTripV1andV3Details(tripId);
    },
  });

const airPnrQualityCheckKey = (pnrId: string): [unknown, unknown] => ['air-pnr-quality-check', pnrId];

const airPnrQualityCheck = async (pnrId: string): Promise<PnrQcResultResponse> => {
  try {
    return (await api('GET', 'airPnrs', {
      urlParam: `/${pnrId}/quality-check`,
    })) as PnrQcResultResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useAirPnrQualityCheck = (
  pnrId: string,
  { enabled = true }: { enabled?: boolean } = {},
): SpotnanaQueryResult<PnrQcResultResponse> =>
  useQuery(airPnrQualityCheckKey(pnrId), () => airPnrQualityCheck(pnrId), {
    enabled,
  });

interface ITicketShellPnr {
  pnrId: string;
}

const ticketAirShellPnr = async ({ pnrId }: ITicketShellPnr): Promise<AirTicketPnrResponse> => {
  try {
    return (await api('POST', 'airPnrs', {
      urlParam: `/${pnrId}/book-ticket`,
    })) as AirTicketPnrResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useTicketAirShellPnrMutation = (): SpotnanaQueryMutationResult<AirTicketPnrResponse, ITicketShellPnr> =>
  useMutation((request: ITicketShellPnr) => ticketAirShellPnr(request));

const sendPaymentCommunication = async (
  requestBody: SendPaymentCommunicationRequest,
  pnrId: string,
  tripId: string,
): Promise<void> => {
  await api('POST', 'trips', {
    data: requestBody,
    urlParam: `/${tripId}/pnrs/${pnrId}/payment/virtual-card/send-communication`,
  });
};

export const useSendPaymentCommunicationMutation = (
  tripId: string,
  pnrId: string,
): UseMutationResult<void, SpotnanaError, SendPaymentCommunicationRequest> =>
  useMutation((requestBody: SendPaymentCommunicationRequest) => sendPaymentCommunication(requestBody, pnrId, tripId));

const updateVirtualCard = async (
  requestBody: UpdateVirtualCardRequest,
  pnrId: string,
  tripId: string,
): Promise<void> => {
  await api('POST', 'trips', {
    data: requestBody,
    urlParam: `/${tripId}/pnrs/${pnrId}/payment/virtual-card/update`,
  });
};

export const useUpdateVirtualCardMutation = (
  tripId: string,
  pnrId: string,
): UseMutationResult<void, SpotnanaError, UpdateVirtualCardRequest> =>
  useMutation((requestBody: UpdateVirtualCardRequest) => updateVirtualCard(requestBody, pnrId, tripId));

const getVirtualCardDetails = async (tripId: string, pnrId: string): Promise<GetVirtualCardResponse | undefined> => {
  try {
    const data = await api('GET', 'trips', {
      urlParam: `/${tripId}/pnrs/${pnrId}/payment/virtual-card`,
    });
    const result = data as GetVirtualCardResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const VIRTUAL_CARD_DETAILS_KEY = 'virtual-card-details';
const getVirtualCardDetailsKey = (request: GetVirtualCardRequest) => [VIRTUAL_CARD_DETAILS_KEY, request];

export const useVirtualCardDetails = (
  request: GetVirtualCardRequest,
  onSuccess: (data: GetVirtualCardResponse) => void,
): SpotnanaQueryResult<GetVirtualCardResponse | undefined> =>
  useQuery(getVirtualCardDetailsKey(request), () => getVirtualCardDetails(request.tripId, request.pnrId), {
    cacheTime: 0,
    onSuccess,
  });

const getPnrCancellationDetails = async ({
  tripId,
  pnrId,
}: {
  tripId: string;
  pnrId: string;
}): Promise<PnrCancellationDetails | undefined> => {
  try {
    const data = await api('GET', 'trips', {
      urlParam: `/${tripId}/pnrs/${pnrId}/cancellation-details`,
    });
    const result = data as PnrCancellationDetails;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const usePnrCancellationDetails = ({
  tripId,
  pnrId,
  isEnabled,
}: IPnrCancellationDetailsRequest): SpotnanaQueryResult<PnrCancellationDetails> =>
  useQuery(getPnrCancellationKey(pnrId, tripId), () => getPnrCancellationDetails({ tripId, pnrId }), {
    enabled: !!tripId && !!pnrId && isEnabled,
    cacheTime: 0,
  });

const getPnrExchangeDetails = async ({ pnrId }: { pnrId: string }): Promise<AirPnrExchangeDetailsResponse> => {
  try {
    const data = await api('GET', 'airPnrs', {
      urlParam: `/${pnrId}/exchange-details`,
    });
    const result = data as AirPnrExchangeDetailsResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const getRailPnrExchangeDetails = async ({ pnrId }: { pnrId: string }): Promise<RailPnrExchangeDetailsResponse> => {
  try {
    const data = await api('GET', 'railPNRs', {
      urlParam: `/${pnrId}/exchange-details`,
    });
    return data as RailPnrExchangeDetailsResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useRailPnrExchangeDetails = ({
  pnrId,
  isEnabled,
}: {
  pnrId: string;
  isEnabled: boolean;
}): SpotnanaQueryResult<RailPnrExchangeDetailsResponse> =>
  useQuery(getPnrExchangeKey(pnrId), () => getRailPnrExchangeDetails({ pnrId }), { enabled: isEnabled });

export const usePnrExchangeDetails = (pnrId: string): SpotnanaQueryResult<AirPnrExchangeDetailsResponse> =>
  useQuery(getPnrExchangeKey(pnrId), () => getPnrExchangeDetails({ pnrId }), { cacheTime: 0 });

const getPartnerInfoData = async ({
  tripId,
  bookerEmailId,
}: {
  tripId: string;
  bookerEmailId: string;
}): Promise<TripPartnerInfoResponse | undefined> => {
  try {
    const data = await api('GET', 'trips', {
      urlParam: `/${tripId}/booker-email/${bookerEmailId}/partner-info`,
    });
    const result = data as TripPartnerInfoResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface usePartnerBudgetInfoDataProps {
  tripId: string;
  bookerEmailId: string;
  enabled: boolean;
}

export const usePartnerBudgetInfoData = ({
  tripId,
  bookerEmailId,
  enabled = true,
}: usePartnerBudgetInfoDataProps): SpotnanaQueryResult<TripPartnerInfoResponse> =>
  useQuery(getPartnerBudgetInfoKey(tripId, bookerEmailId), () => getPartnerInfoData({ tripId, bookerEmailId }), {
    enabled,
  });

const getSpendLimits = async (userId: string): Promise<GetSpendLimitsResponse> => {
  try {
    const data = await api('GET', 'spendLimits', {
      params: { userId },
    });
    const result = data as GetSpendLimitsResponse;
    return result;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface useSpendLimitsParams {
  loggedInUserId: string;
  primaryTravelerUserId: string;
  options?: SpotnanaQueryOptions<GetSpendLimitsResponse, TripV1DetailsQueryKeyType, AxiosError<InternalAPIError>>;
}

export const useSpendLimits = ({
  loggedInUserId,
  primaryTravelerUserId,
  options,
}: useSpendLimitsParams): SpotnanaQueryResult<GetSpendLimitsResponse> =>
  useQuery(['spendLimits', loggedInUserId, primaryTravelerUserId], () => getSpendLimits(primaryTravelerUserId), {
    ...options,
  });

const createPartnerTrip = async (requestBody: CreatePartnerTripRequest): Promise<CreatePartnerTripResponse> => {
  const data = await api('POST', 'createPartnerTrip', {
    data: requestBody,
  });
  const createTripResponse = data as CreatePartnerTripResponse;
  if (createTripResponse) {
    return createTripResponse;
  }
  throw new SpotnanaError('Could not create trip');
};

export const useCreatePartnerTrip = () =>
  useMutation((requestBody: CreatePartnerTripRequest) => createPartnerTrip(requestBody), {
    onSuccess: (_data, requestBody) => {
      invalidateListTripsKey({ userId: requestBody.userId });
      invalidateTripSummaries({ userId: requestBody.userId });
    },
  });

type TCancelRailPNRParams = RailCancelPnrRequest & {
  pnrId: string;
};

const cancelRailPNR = async ({ pnrId, refundId }: TCancelRailPNRParams): Promise<RailCancelPnrResponse | null> => {
  const data = await api('POST', 'railPNRs', {
    urlParam: `/${pnrId}/cancel`,
    data: {
      refundId,
    },
  });
  const cancelPnrResponse = data as CancelPnrResponse;
  if ('confirmationId' in cancelPnrResponse) {
    return cancelPnrResponse;
  }

  throw new SpotnanaError('Could not cancel flight PNR');
};

type TUseCancelRailPNRParams = Pick<TCancelRailPNRParams, 'pnrId'> & {
  userOrgId: UserOrgIdOrNull;
  tripId: string;
};

export const useCancelRailPNR = ({ userOrgId, tripId, pnrId }: TUseCancelRailPNRParams) =>
  useMutation((requestBody: RailCancelPnrRequest) => cancelRailPNR({ ...requestBody, pnrId }), {
    onSuccess: async (_data) => {
      if (userOrgId) {
        await delay(3000);
        invalidateTripSummaries(userOrgId as IUserOrgId);
        invalidateListTripsKey(userOrgId as IUserOrgId);
        invalidatePnrCancellationKey(pnrId, tripId);
      }
      invalidateTripV1andV3Details(tripId);
    },
    onError: () => {
      invalidateTripSummaries(userOrgId as IUserOrgId);
      invalidateListTripsKey(userOrgId as IUserOrgId);
      invalidatePnrCancellationKey(pnrId, tripId);
      invalidateTripV1andV3Details(tripId);
    },
  });

const fetchTripDetailsQuery = async (data: GetTripDetailsRequest): Promise<GetTripDetailsResponse> => {
  const tripDetailsResponse = await api(
    'POST',
    'tripDetails',
    {
      data,
    },
    { allowParallelRequests: true },
  );
  return tripDetailsResponse as GetTripDetailsResponse;
};

const fetchGroupTripDetailsQuery = async (groupId: string): Promise<ReadGroupBookingResponse> => {
  const groupTripDetailsResponse = await api('GET', 'groupBookings', {
    urlParam: `/${groupId}`,
  });

  return groupTripDetailsResponse as ReadGroupBookingResponse;
};

export const useTripDetailsQuery = (
  tripId: string,
  queryOptions?: SpotnanaQueryOptions<GetTripDetailsResponse, TripV1DetailsQueryKeyType, AxiosError<InternalAPIError>>,
): SpotnanaQueryResult<GetTripDetailsResponse, AxiosError<InternalAPIError>> =>
  useQuery<GetTripDetailsResponse, AxiosError<InternalAPIError>, GetTripDetailsResponse, TripV1DetailsQueryKeyType>(
    getTripV1DetailsQueryKey(tripId),
    () => fetchTripDetailsQuery({ tripId }),
    queryOptions,
  );

export const useMultipleTripsDetailsQuery = (
  tripIds: string[],
  queryOptions?: SpotnanaQueryOptions<GetTripDetailsResponse, TripV1DetailsQueryKeyType, AxiosError<InternalAPIError>>,
): SpotnanaQueryResult<GetTripDetailsResponse>[] =>
  useQueries(
    tripIds.map((tripId) => ({
      queryKey: getTripV1DetailsQueryKey(tripId),
      queryFn: () => fetchTripDetailsQuery({ tripId }),
      queryOptions,
    })),
  ) as SpotnanaQueryResult<GetTripDetailsResponse>[];

async function fetchTripV3Details(tripId: string): Promise<TripV3DetailsResponse> {
  const tripV3DetailsResponse = await api('GET', 'tripsV3', {
    urlParam: `/${tripId}/detail`,
  });
  return tripV3DetailsResponse as TripV3DetailsResponse;
}

export function useTripV3DetailsQuery(
  tripId: string,
  queryOptions?: SpotnanaQueryOptions<TripV3DetailsResponse, TripV3DetailsQueryKeyType, AxiosError<InternalAPIError>>,
): SpotnanaQueryResult<TripV3DetailsResponse, AxiosError<InternalAPIError>> {
  return useQuery<
    TripV3DetailsResponse,
    AxiosError<InternalAPIError>,
    TripV3DetailsResponse,
    TripV3DetailsQueryKeyType
  >(
    getTripV3DetailsQueryKey(tripId),
    async () => {
      try {
        const data = await fetchTripV3Details(tripId);
        return data;
      } catch (e) {
        return Promise.resolve({
          basicTripInfo: {
            tripId,
            tripName: '',
          },
          pnrs: [],
        });
      }
    },
    queryOptions,
  );
}

export const useTripV3DetailsMutation = () => useMutation((tripId: string) => fetchTripV3Details(tripId));

export const useGroupTripDetailsQuery = (
  groupId: string,
  queryOptions?: SpotnanaQueryOptions<
    ReadGroupBookingResponse,
    GroupTripDetailsQueryKeyType,
    AxiosError<InternalAPIError>
  >,
): SpotnanaQueryResult<ReadGroupBookingResponse, AxiosError<InternalAPIError>> =>
  useQuery<
    ReadGroupBookingResponse,
    AxiosError<InternalAPIError>,
    ReadGroupBookingResponse,
    GroupTripDetailsQueryKeyType
  >(getGroupTripDetailsQueryKey(groupId), () => fetchGroupTripDetailsQuery(groupId), queryOptions);

interface ITripsV3ExistingPnrParamsBase {
  tripId: string;
  pnrId: string;
}

interface ITripsV3NewPnrParamsBase {
  tripId: string;
}

function getTripsV3PnrsUrlParam(tripId: string, pnrId?: string) {
  let result = `/${tripId}/pnrs`;

  if (pnrId) {
    result += `/${pnrId}`;
  }

  return result;
}

async function tripsV3ReadPnr({ tripId, pnrId }: ITripsV3ExistingPnrParamsBase): Promise<PnrData> {
  try {
    return api(
      'GET',
      'tripsV3',
      {
        urlParam: getTripsV3PnrsUrlParam(tripId, pnrId),
      },
      { headers: { 'X-Manual-Form-Read': 'true' } },
    ) as Promise<PnrData>;
  } catch (e) {
    throw new SpotnanaError(`Could not read pnr with tripId="${tripId}" and pnrId="${pnrId}". Error: ${e}`);
  }
}

type TripsV3ReadPnrQueryKeyType = ReturnType<typeof getTripsV3ReadPnrQueryKey>;

export function useTripsV3ReadPnrQuery(
  params: ITripsV3ExistingPnrParamsBase,
  options?: SpotnanaQueryOptions<PnrData, TripsV3ReadPnrQueryKeyType>,
): SpotnanaQueryResult<PnrData> {
  return useQuery<PnrData, SpotnanaError, PnrData, TripsV3ReadPnrQueryKeyType>(
    getTripsV3ReadPnrQueryKey(params),
    () => tripsV3ReadPnr(params),
    options,
  );
}

type ITripsV3CreatePnrResponse = {
  id: string;
};

interface ITripsV3CreatePnrParams extends ITripsV3NewPnrParamsBase {
  data: PnrData;
}

async function tripsV3CreatePnr({ tripId, data }: ITripsV3CreatePnrParams): Promise<ITripsV3CreatePnrResponse> {
  try {
    return api('POST', 'tripsV3', {
      urlParam: getTripsV3PnrsUrlParam(tripId),
      data,
    }) as Promise<ITripsV3CreatePnrResponse>;
  } catch (e) {
    throw new SpotnanaError(`Could not create pnr with tripId="${tripId}". Error: ${e}`);
  }
}

export function useTripsV3CreatePnrMutation(): SpotnanaQueryMutationResult<
  ITripsV3CreatePnrResponse,
  ITripsV3CreatePnrParams
> {
  return useMutation((requestParams) => tripsV3CreatePnr(requestParams), {
    onSuccess: (_data, requestParams) => {
      const {
        data: { travelers },
      } = requestParams;

      if (travelers?.[0]?.userOrgId) {
        invalidateTripSummaries(travelers[0].userOrgId as IUserOrgId);
        invalidateTripFeatures(requestParams.tripId);
      }
    },
  });
}

interface ITripsV3UpdatePnrParams extends ITripsV3ExistingPnrParamsBase {
  data: PnrData;
}

async function tripsV3UpdatePnr({ tripId, pnrId, data }: ITripsV3UpdatePnrParams): Promise<void> {
  try {
    return api('PUT', 'tripsV3', {
      urlParam: getTripsV3PnrsUrlParam(tripId, pnrId),
      data,
    }) as Promise<void>;
  } catch (e) {
    throw new SpotnanaError(`Could not update pnr with tripId="${tripId}" and pnrId="${pnrId}". Error: ${e}`);
  }
}

export function useTripsV3UpdatePnrMutation(): SpotnanaQueryMutationResult<void, ITripsV3UpdatePnrParams> {
  return useMutation(tripsV3UpdatePnr, {
    onSuccess: (_data, requestParams) => {
      const {
        tripId,
        pnrId,
        data: { travelers },
      } = requestParams;

      if (travelers?.[0]?.userOrgId) {
        invalidateTripSummaries(travelers[0].userOrgId as IUserOrgId);
      }

      defaultQueryClient.invalidateQueries(getTripsV3ReadPnrQueryKey({ tripId, pnrId }));
    },
  });
}

interface ITripsV3CancelPnrParams extends ITripsV3ExistingPnrParamsBase {
  data: PnrCancelRequest;
}

async function tripsV3CancelPnr({ tripId, pnrId, data }: ITripsV3CancelPnrParams): Promise<void> {
  try {
    return api('POST', 'tripsV3', {
      urlParam: `${getTripsV3PnrsUrlParam(tripId, pnrId)}/cancel`,
      data,
    }) as Promise<void>;
  } catch (e) {
    throw new SpotnanaError(`Could not cancel ticket(s) within tripId="${tripId}" and pnrId="${pnrId}". Error: ${e}`);
  }
}

export function useTripsV3CancelPnrMutation(): SpotnanaQueryMutationResult<void, ITripsV3CancelPnrParams> {
  return useMutation(tripsV3CancelPnr, {
    onSuccess: (_data, requestParams) => {
      const { tripId, pnrId } = requestParams;

      defaultQueryClient.invalidateQueries(getTripsV3ReadPnrQueryKey({ tripId, pnrId }));
      defaultQueryClient.invalidateQueries(getTripV1DetailsQueryKey(tripId));
    },
  });
}

const downloadTripItinerary = async (requestBody: { tripId: string }): Promise<DownloadInvoiceResponse> => {
  const data = await api('POST', 'tripsV3', {
    urlParam: `/${requestBody.tripId}/download-invoice`,
  });
  return data as DownloadInvoiceResponse;
};

export const useDownloadTripV3ItineraryMutation = (): UseMutationResult<
  DownloadInvoiceResponse,
  SpotnanaError,
  { tripId: string }
> => useMutation((requestBody: { tripId: string }) => downloadTripItinerary(requestBody));

interface PnrQcInfoRequest {
  tripId: string;
  pnrId: string;
}

const getPnrQcInfo = async (requestBody: { tripId: string; pnrId: string }): Promise<PnrQcInfo> => {
  const data = await api('GET', 'trips', {
    urlParam: `/${requestBody.tripId}/pnrs/${requestBody.pnrId}/mid-office/qc-info`,
  });
  return data as PnrQcInfo;
};

export const usePnrQcInfoQuery = ({ tripId, pnrId }: PnrQcInfoRequest): SpotnanaQueryResult<PnrQcInfo> =>
  useQuery(pnrQcInfoKey({ tripId, pnrId }), () => getPnrQcInfo({ tripId, pnrId }));

interface TripQcInfoRequest {
  tripId: string;
  enabled: boolean;
}

const getTripQcInfo = async (requestBody: { tripId: string }): Promise<TripQcInfo> => {
  const data = await api('GET', 'trips', {
    urlParam: `/${requestBody.tripId}/mid-office/qc-info`,
  });
  return data as TripQcInfo;
};

export const useTripQcInfoQuery = ({ tripId, enabled }: TripQcInfoRequest): SpotnanaQueryResult<TripQcInfo> =>
  useQuery(tripQcInfoKey(tripId), () => getTripQcInfo({ tripId }), { enabled });

const triggerItineraryEmail = async (requestBody: TriggerEmailRequest, tripId: string): Promise<void> => {
  await api('POST', 'tripsV3', {
    data: requestBody,
    urlParam: `/${tripId}/trigger-email`,
  });
};

export const useTriggerEmailV3Mutation = (
  tripId: string,
): UseMutationResult<void, SpotnanaError, TriggerEmailRequest> =>
  useMutation((requestBody: TriggerEmailRequest) => triggerItineraryEmail(requestBody, tripId));

interface FinalizePnrRequest {
  tripId: string;
  pnrId: string;
  finalizeRequest: FinalizeRequest;
}

const finalizePnr = async ({ tripId, pnrId, finalizeRequest }: FinalizePnrRequest): Promise<void> => {
  const data = await api('POST', 'tripsV3', {
    data: finalizeRequest,
    urlParam: `/${tripId}/pnrs/${pnrId}/finalize`,
  });
  return data as void;
};

export const useFinalizePnrMutation = (): UseMutationResult<void, SpotnanaError, FinalizePnrRequest> =>
  useMutation((request) => finalizePnr(request));

interface UnfinalizeRequest {
  tripId: string;
  pnrId: string;
  suspendReason: SuspendRequestReasonEnum;
}

const unfinalizePnr = async ({ tripId, pnrId, suspendReason }: UnfinalizeRequest): Promise<void> => {
  const data = await api('POST', 'tripsV3', {
    data: { reason: suspendReason },
    urlParam: `/${tripId}/pnrs/${pnrId}/suspend`,
  });
  return data as void;
};

export const useUnfinalizePnrMutation = (): UseMutationResult<void, SpotnanaError, UnfinalizeRequest> =>
  useMutation((request) => unfinalizePnr(request), {
    onSuccess: (_, request) => invalidateTripV1andV3Details(request.tripId),
  });

const validatePnr = async ({ tripId, pnrId }: ITripsV3ExistingPnrParamsBase): Promise<ValidatePnrResponse> => {
  try {
    const data = await api('GET', 'tripsV3', {
      urlParam: `/${tripId}/pnrs/${pnrId}/validate`,
    });
    return data as ValidatePnrResponse;
  } catch (e) {
    throw new SpotnanaError(`Could not validate pnr with tripId="${tripId}" and pnrId="${pnrId}". Error: ${e}`);
  }
};

export const useValidatePnrQuery = (
  params: ITripsV3ExistingPnrParamsBase,
  options?: SpotnanaQueryOptions<ValidatePnrResponse, ReturnType<typeof validatePnrKey>>,
): SpotnanaQueryResult<ValidatePnrResponse> =>
  useQuery(validatePnrKey(params), () => validatePnr(params), {
    staleTime: 5000,
    ...options,
  });

/* trip features query code starts here */

interface ITripsFeatures {
  tripId: string;
}

interface IUseTripFeatures {
  tripId: string;
  options?: SpotnanaQueryOptions<AppliedTripFeatures, TripFeaturesKey>;
}

interface IRevalidateHoldKey {
  pnrId: string;
}

export type TripFeaturesKey = ReturnType<typeof getTripsFeaturesKey>;

const getTripFeatures = async (tripId: string): Promise<AppliedTripFeatures> => {
  try {
    const data = await api('GET', 'trips', {
      urlParam: `/${tripId}/features`,
    });
    return data as AppliedTripFeatures;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useTripFeaturesQuery = ({ tripId, options }: IUseTripFeatures): SpotnanaQueryResult<AppliedTripFeatures> =>
  useQuery(getTripsFeaturesKey({ tripId }), () => getTripFeatures(tripId), {
    staleTime: 0,
    ...options,
  });

const getTripSummaryFeatures = async (companyId: string): Promise<Array<OBTFeatureConfig>> => {
  try {
    const data = await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}/trip-summary-features`,
    });
    return data as Array<OBTFeatureConfig>;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

const getTripSummaryFeaturesKey = (companyId: string): [string, string] => ['trip-summary-features', companyId];

export const useTripSummaryFeaturesQuery = ({
  companyId,
  options,
}: {
  companyId: string;
  options?: SpotnanaQueryOptions<Array<OBTFeatureConfig>, TripFeaturesKey>;
}): SpotnanaQueryResult<Array<OBTFeatureConfig>> =>
  useQuery(getTripSummaryFeaturesKey(companyId), () => getTripSummaryFeatures(companyId), {
    enabled: !!companyId,
    ...options,
  });

/* trip features query code end here */

interface AirSplitPnrProps {
  tripId: string;
  pnrId: string;
  travelers: SplitPnrRequest;
}

const airSplitPnr = async ({ tripId, pnrId, travelers }: AirSplitPnrProps): Promise<SplitPnrResponse | null> => {
  const data = await api('POST', 'tripsV3', {
    urlParam: `/${tripId}/pnrs/${pnrId}/split`,
    data: travelers,
  });
  if (data) {
    return data as SplitPnrResponse;
  }

  throw new SpotnanaError('Could not split PNR');
};

export const useAirSplitPnr = (tripId: string, pnrId: string) =>
  useMutation((travelers: SplitPnrRequest) => airSplitPnr({ tripId, pnrId, travelers }));

const approvalsQueryKey = (request: ListApprovalsRequest, userId: IUserOrgId): unknown[] => {
  return ['approvals', request, userId];
};

export const fetchApprovals = async (request: ListApprovalsRequest): Promise<ListApprovalResponse | null> => {
  const response = await api('POST', 'approvals', { data: request });

  if (response) {
    return response as ListApprovalResponse;
  }

  return null;
};

export const useFetchApprovals = (
  request: ListApprovalsRequest,
  userId: IUserOrgId,
  options?: UseQueryOptions<ListApprovalResponse | null, SpotnanaError>,
) =>
  useQuery<ListApprovalResponse | null, SpotnanaError>(
    approvalsQueryKey(request, userId),
    () => fetchApprovals(request),
    {
      ...options,
    },
  );

const processPnrApproval = async (pnrId: string, request: ProcessPnrApprovalRequest): Promise<void> => {
  await api('POST', 'pnrShared', {
    urlParam: `/${pnrId}/process-approval`,
    data: request,
  });
};

export const invalidateApprovals = () => {
  defaultQueryClient.invalidateQueries(['approvals']);
};

export const useProcessPnrApproval = (pnrId: string) =>
  useMutation((request: ProcessPnrApprovalRequest) => processPnrApproval(pnrId, request), {
    onSuccess: () => {
      // waiting for 4 seconds to fetch the updated approvals
      setTimeout(() => {
        invalidateApprovals();
      }, 4000);
    },
  });

/* trip pre-book answers query code starts here */

interface ITripPreBookAnswers {
  tripId: string;
}

interface IUseTripPreBookAnswers {
  tripId: string;
  options?: SpotnanaQueryOptions<TripPreBookAnswersResponse, TripPreBookAnswersKey>;
}

export type TripPreBookAnswersKey = ReturnType<typeof getTripsFeaturesKey>;

const getTripPreBookAnswers = async (tripId: string): Promise<TripPreBookAnswersResponse> => {
  try {
    const data = await api('GET', 'trips', {
      urlParam: `/${tripId}/pre-book-answers`,
    });
    return data as TripPreBookAnswersResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useTripPreBookAnswersQuery = ({
  tripId,
  options,
}: IUseTripPreBookAnswers): SpotnanaQueryResult<TripPreBookAnswersResponse> =>
  useQuery(getTripPreBookAnswersKey({ tripId }), () => getTripPreBookAnswers(tripId), {
    cacheTime: 0.5 * 60 * 1000, // Default cache time to 30s to ensure it's available for next booking
    ...options,
  });

export const useMultipleTripPreBookAnswersQuery = (
  preBookAnswerRequest: { tripId: string; enabled: boolean }[],
): SpotnanaQueryResult<TripPreBookAnswersResponse>[] =>
  useQueries(
    preBookAnswerRequest.map((preBookRequest) => {
      const { tripId, enabled } = preBookRequest;

      return {
        queryKey: getPreBookAnswersKey(tripId),
        queryFn: () => getTripPreBookAnswers(tripId),
        ...{ enabled, cacheTime: 0.5 * 60 * 1000 },
      };
    }),
  ) as SpotnanaQueryResult<TripPreBookAnswersResponse>[];

/* trip pre-book answers query code end here */
