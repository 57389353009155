import { PropsWithChildren, ReactElement, useLayoutEffect } from 'react';
import { TokenExchangeTypes, EmbedEventTypes } from '@spotnana-tech/embed-helpers';

import { useAuth, tokenExchange, safeJsonStringify, webSessionStorage, StorageKeys, logger, storage } from 'obt-common';
import { AxiosError } from 'axios';
import { collectOrgId } from 'src/utils/orgId/orgId';

export const TokenExchange = ({ children }: PropsWithChildren): ReactElement => {
  const { authenticateUserByTokens } = useAuth();
  // Using URLSearchParams because we are outside of BrowserRouter.
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const idp = params.get('idp');

  const loginViaTokens = async () => {
    try {
      // Clear the ordId and travelerId  everytime when the token exchange flow is initialized to remove all leftovers from
      // previous session (ex: organizationID)
      // TODO: remove this logic once we implement clearing the entire app before starting the token exchange flow
      await storage.removeItem(StorageKeys.PRIMARY_TRAVELER_ID);
      await storage.removeItem(StorageKeys.ORG_ID);
      await collectOrgId();

      await authenticateUserByTokens();
      params.delete('idp');
      window.location.replace(`${window.location.pathname}?${params.toString()}`);
    } catch (err) {
      logger.error(new Error(`TOKEN_EXCHANGE_ERROR: ${err.message}`, { cause: err }));
      window.parent.postMessage(
        {
          from: 'spotnana-embed',
          type: TokenExchangeTypes.TOKEN_EXCHANGE_ERROR,
          payload: {
            errorCode: EmbedEventTypes.INVALID_TOKEN,
            errorMessage: err?.message,
            errorStack: err?.stack,
            data: safeJsonStringify((err as AxiosError)?.response?.data, ''),
            url: (err as AxiosError)?.request?.responseURL || (err as AxiosError)?.response?.config?.url,
          },
        },
        '*',
      );
    }
  };

  useLayoutEffect(() => {
    // Authenticate user if idp is present, even if user is already loggedin
    if (idp && tokenExchange.includes(idp)) {
      // Store the idp in session storage so we can use it to retrigger the token exchange flow later.
      webSessionStorage.setItem(StorageKeys.TOKEN_EXCHANGE_IDP, idp);
      loginViaTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
