import type { Money } from '../../common/money';
import type { Int32Range, DoubleRange } from '../../common/range';
import type { Cabin } from '../air/air_common';
import type { PostalAddress } from '../../common/postal_address';
import type { TravelClass } from '../rail/rail_common';
import type { CarType, EngineType } from '../car/car_common';
import type { Length } from '../../common/length';
import type { TravelType } from '../../common/tmc';
import type { SplitPaymentInfo } from './policy_common';

export interface RestrictedContinents {
  isLinked?: boolean | undefined;
  props?: RestrictedContinentsProps | undefined;
}

export interface RestrictedContinentsProps {
  restrictions: RestrictedContinentsPropsRestriction[];
}

export interface RestrictedContinentsPropsRestriction {
  /** ISO 2-letter continent code. */
  continent: string;
  type: RestrictedContinentsPropsRestrictionType;
}

export enum RestrictedContinentsPropsRestrictionType {
  UNKNOWN = 0,
  BLOCK_TRAVEL_TO_CONTINENT = 1,
  ALLOW_WITHIN_CONTINENT = 2,
  UNRECOGNIZED = -1,
}

export interface RestrictedCountries {
  isLinked?: boolean | undefined;
  props?: RestrictedCountriesProps | undefined;
}

export interface RestrictedCountriesProps {
  restrictions: RestrictedCountriesPropsRestriction[];
}

export interface RestrictedCountriesPropsRestriction {
  /** ISO 2-letter country code. */
  country: string;
  type: RestrictedCountriesPropsRestrictionType;
}

export enum RestrictedCountriesPropsRestrictionType {
  UNKNOWN = 0,
  BLOCK_TRAVEL_TO_COUNTRY = 1,
  ALLOW_WITHIN_COUNTRY = 2,
  UNRECOGNIZED = -1,
}

export interface VariableMaxPrice {
  isLinked?: boolean | undefined;
  props?: VariableMaxPriceProps | undefined;
}

export interface VariableMaxPriceProps {
  field: VariableMaxPricePropsField;
  priceType: VariableMaxPricePropsPriceType;
  maxPrice?: Money;
}

/**
 * On which field value, the policy is applied. For example: booking price under a budget
 * can be upto budget balance only.
 */
export enum VariableMaxPricePropsField {
  /** TOTAL - Total is default ie total price cant exceed more than max price allowed. */
  TOTAL = 0,
  BUDGET_BALANCE = 1,
  UNRECOGNIZED = -1,
}

/**
 * Price type like fixed value, dynamic value, median value, etc.
 * Later we can extend it to introduce more types like average, median price etc.
 */
export enum VariableMaxPricePropsPriceType {
  /** FIXED_VALUE - Fixed means value passed during policy creation. */
  FIXED_VALUE = 0,
  /**
   * DYNAMIC_VALUE - Dynamic means, it would depend on field value at the time of shopping.
   * Value would be filled during shopping only in this case.
   */
  DYNAMIC_VALUE = 1,
  UNRECOGNIZED = -1,
}

export interface LocationRestrictionsProps {
  restrictedLocations: LocationRestrictionsPropsRestrictedLocationWithMessage[];
  /** Travel type for which it is restricted. */
  travelType: TravelType;
}

/**
 * Proto containing details about location code and message to be shown to
 * user in case of violation.
 */
export interface LocationRestrictionsPropsRestrictedLocationWithMessage {
  /** Unique location code. */
  locationCode: string;
  /** Violation message to be shown to user. */
  message: string;
}

export interface RestrictedAirlines {
  isLinked?: boolean | undefined;
  props?: RestrictedAirlinesProps | undefined;
}

export interface RestrictedAirlinesProps {
  /** IATA airline codes. */
  airlines: string[];
}

export interface RestrictedAircrafts {
  isLinked?: boolean | undefined;
  props?: RestrictedAircraftsProps | undefined;
}

export interface RestrictedAircraftsProps {
  /** IATA aircraft codes. */
  aircrafts: string[];
}

export interface MaxFlightBookingPriceByDuration {
  isLinked?: boolean | undefined;
  props?: MaxFlightBookingPriceByDurationProps | undefined;
}

export interface MaxFlightBookingPriceByDurationProps {
  maxPriceForDurationList: MaxFlightBookingPriceByDurationPropsMaxPriceForDuration[];
}

export interface MaxFlightBookingPriceByDurationPropsMaxPriceForDuration {
  maxPrice?: Money;
  /**
   * Upper limit of time range.
   *
   * @deprecated
   */
  durationInMinutes: number;
  /**
   * Duration range (in minutes) with lower limit (inclusive), upper limit
   * (exclusive). If the last duration range represents interval of the form
   * [x, infinity), max value should be set as 0.
   */
  durationRange?: Int32Range;
}

export interface DifferenceBetweenFlightFareAndMedianFare {
  isLinked?: boolean | undefined;
  props?: DifferenceBetweenFlightFareAndMedianFareProps | undefined;
}

export interface DifferenceBetweenFlightFareAndMedianFareProps {
  /**
   * Percentage difference between flight's total fare and median fare for all
   * flights for that destination should be less than or equal to this.
   */
  percentage: number;
}

export interface Co2EmissionPerPassengerPerKm {
  isLinked?: boolean | undefined;
  props?: Co2EmissionPerPassengerPerKmProps | undefined;
}

export interface Co2EmissionPerPassengerPerKmProps {
  /** Maximum allowed Co2 emission (in grams) per passenger per flight distance covered (in Kms). */
  emission: number;
}

export interface MaxFlightBookingPrice {
  isLinked?: boolean | undefined;
  props?: MaxFlightBookingPriceProps | undefined;
}

export interface MaxFlightBookingPriceProps {
  type?: MaxFlightBookingPricePropsType;
  sign?: MaxFlightBookingPricePropsSign;
  money?: Money | undefined;
  percentage?: number | undefined;
  /** Maximum allowed price can be with or without taxes. */
  isTaxIncluded?: boolean;
}

/**
 * Price of a flight will be evaluated against some function of all the flight prices
 * in air search. This can be median of all prices or cheapest among all etc.
 */
export enum MaxFlightBookingPricePropsType {
  MINIMUM = 0,
  MEDIAN = 1,
  LOWEST_LOGICAL_FARE = 2,
  UNRECOGNIZED = -1,
}

/** Max Allowed Price can be either greater than the median price or cheapest price or lesser than the median price. */
export enum MaxFlightBookingPricePropsSign {
  MORE = 0,
  LESS = 1,
  UNRECOGNIZED = -1,
}

export interface HighestFlightCabinByDuration {
  isLinked?: boolean | undefined;
  props?: HighestFlightCabinByDurationProps | undefined;
  splitPaymentInfo?: SplitPaymentInfo;
}

export interface HighestFlightCabinByDurationProps {
  highestCabinForDurationList: HighestFlightCabinByDurationPropsHighestCabinForDuration[];
  highestCabinForMilageList: HighestFlightCabinByDurationPropsHighestCabinForMileage[];
}

export interface HighestFlightCabinByDurationPropsHighestCabinForDuration {
  cabin: Cabin;
  /**
   * Upper limit of time range.
   *
   * @deprecated
   */
  durationInMinutes: number;
  /**
   * Duration range (in minutes) with lower limit (inclusive), upper limit
   * (exclusive). If the last duration range represents interval of the form
   * [x, infinity), max value should be set as 0.
   */
  durationRange?: Int32Range;
}

export interface HighestFlightCabinByDurationPropsHighestCabinForMileage {
  cabin: Cabin;
  /**
   * Duration range (in minutes) with lower limit (inclusive), upper limit
   * (exclusive). If the last duration range represents interval of the form
   * [x, infinity), max value should be set as 0.
   */
  mileRange?: Int32Range;
}

export interface HighestFlightCabinOvernight {
  isLinked?: boolean | undefined;
  props?: HighestFlightCabinOvernightProps | undefined;
}

/**
 * This is added as a separate rule, as in future we can have customisations
 * based on arrival time ranges, and duration etc.
 */
export interface HighestFlightCabinOvernightProps {
  cabin: Cabin;
  overnightTimeParams?: OvernightTimeParams;
}

export interface OvernightTimeParams {
  /**
   *
   * @type {TimeLocal}
   * @memberof OvernightTimeParams
   */
  nightTimeStart: TimeLocal;
  /**
   *
   * @type {TimeLocal}
   * @memberof OvernightTimeParams
   */
  nightTimeEnd: TimeLocal;
  /**
   * Whether layover time should be included in the overnight time.
   * @type {boolean}
   * @memberof OvernightTimeParams
   */
  includeLayover: boolean;
  /**
   * The minimum night flight time needed to qualify as an overnight flight.
   * @type {number}
   * @memberof OvernightTimeParams
   */
  nightTimeOverlapMins: number;
}

export interface TimeLocal {
  hours: number;
  minutes: number;
}

export interface FlightSeatUpgrade {
  isLinked?: boolean | undefined;
  props?: FlightSeatUpgradeProps | undefined;
}

export interface FlightSeatUpgradeProps {
  isAllowed: boolean;
  /** Max price value if upgrade is allowed. */
  maxPrice: number;
}

export interface FlightCabinUpgrade {
  isLinked?: boolean | undefined;
  props?: FlightCabinUpgradeProps | undefined;
}

export interface FlightCabinUpgradeProps {
  /** Is update allowed if cheaper. */
  isAllowed: boolean;
}

export interface FlightAdvanceBookingWindow {
  isLinked?: boolean | undefined;
  props?: FlightAdvanceBookingWindowProps | undefined;
}

export interface FlightAdvanceBookingWindowProps {
  numDaysInAdvance: number;
}

export interface FlightTicketsRefundable {
  isLinked?: boolean | undefined;
  props?: FlightTicketsRefundableProps | undefined;
}

export interface FlightTicketsRefundableProps {
  type: FlightTicketsRefundablePropsType;
}

export enum FlightTicketsRefundablePropsType {
  UNKNOWN_TYPE = 0,
  NONE = 1,
  REFUNDABLE = 2,
  REFUNDABLE_WITH_PENALTY = 3,
  DONT_ALLOW = 4,
  UNRECOGNIZED = -1,
}

export interface FlightTicketsChangeable {
  isLinked?: boolean | undefined;
  props?: FlightTicketsChangeableProps | undefined;
}

export interface FlightTicketsChangeableProps {
  type: FlightTicketsChangeablePropsType;
}

export enum FlightTicketsChangeablePropsType {
  UNKNOWN_TYPE = 0,
  NONE = 1,
  CHANGEABLE = 2,
  CHANGEABLE_WITH_PENALTY = 3,
  DONT_ALLOW = 4,
  UNRECOGNIZED = -1,
}

export interface CabinClassNotAllowedProps {
  cabins: Cabin[];
}

export interface HotelMedianRateProps {
  /** Search radius for the hotel. */
  searchRadius?: Length;
  /** Rating range for the hotel. */
  ratingRange?: DoubleRange;
}

export interface LowestLogisticalFareProps {
  /**
   * Maximum layover duration (in hours) for the leg.
   *
   * @deprecated
   */
  maxLayoverDurationInHoursDomestic?: number;
  /** @deprecated */
  maxLayoverDurationInHoursInternational?: number;
  /**
   * Flight time window to consider when selecting a flight.
   *
   * @deprecated
   */
  flightTimeWindowInHoursDomestic?: number;
  /** @deprecated */
  flightTimeWindowInHoursInternational?: number;
  maxNumberOfStops?: LowestLogisticalFarePropsMaxNumberOfStops;
  airportConnectionChanges?: LowestLogisticalFarePropsAirportConnectionChanges;
  /** Whether to include/exclude certain carrier when doing llf calculation. */
  carrier?: LowestLogisticalFarePropsCarrier;
  /** Maximum layover duration (minutes) for the leg. */
  maxLayoverDurationMinutesDomestic?: number;
  maxLayoverDurationMinutesInternational?: number;
  /** Flight time window (minutes) to consider when selecting a flight. */
  flightTimeWindowMinutesDomestic?: number;
  flightTimeWindowMinutesInternational?: number;
  rejectMixCabinFlights: boolean;
}

/** Maximum stops for the leg. */
export enum LowestLogisticalFarePropsMaxNumberOfStops {
  UNKNOWN_STOPS = 0,
  ANY = 1,
  ONE_OR_LESS = 2,
  TWO_OR_LESS = 3,
  FEWEST = 4,
}

/** airports */
export enum LowestLogisticalFarePropsAirportConnectionChanges {
  ALLOWED = 0,
  NOT_ALLOWED = 1,
  UNRECOGNIZED = -1,
}

export interface LowestLogisticalFarePropsCarrier {
  type?: LowestLogisticalFarePropsCarrierType;
  airlines?: string[];
}

export enum LowestLogisticalFarePropsCarrierType {
  ANY = 0,
  /** PREFERRED - Only calculate llf for preferred flights. */
  PREFERRED = 1,
  /** EXCLUDE - Calculate llf for all flights except excluded flights. */
  EXCLUDE = 2,
  UNRECOGNIZED = -1,
}

export interface RestrictedHotels {
  isLinked?: boolean | undefined;
  props?: RestrictedHotelsProps | undefined;
}

export interface RestrictedHotelsProps {
  /** Sabre/Internal hotel codes. */
  hotels: string[];
}

export interface MaxHotelPriceByLocation {
  isLinked?: boolean | undefined;
  props?: MaxHotelPriceByLocationProps | undefined;
}

export interface MaxHotelPriceByLocationProps {
  maxPriceInLocationList: MaxHotelPriceByLocationPropsMaxPriceInLocation[];
  defaultMaxPrice?: Money;
  isTaxIncluded?: boolean;
}

export interface MaxHotelPriceByLocationPropsMaxPriceInLocation {
  maxPrice?: Money;
  address?: PostalAddress;
}

export interface HotelAdvanceBookingWindow {
  isLinked?: boolean | undefined;
  props?: HotelAdvanceBookingWindowProps | undefined;
}

export interface HotelAdvanceBookingWindowProps {
  numDaysInAdvance: number;
}

/**
 * This contains the list of preferred
 * Hotel Chain Code/Master Chain Code
 */
export interface HotelChainCodes {
  isLinked?: boolean | undefined;
  props?: HotelChainCodesProps | undefined;
}

export interface HotelChainCodesProps {
  codes: string[];
}

export interface HotelPaymentOptions {
  isLinked?: boolean | undefined;
  props?: HotelPaymentOptionsProps | undefined;
}

export interface HotelPaymentOptionsProps {
  types: HotelPaymentOptionsPropsType[];
}

export enum HotelPaymentOptionsPropsType {
  ALLOW_ALL = 0,
  PAY_NOW = 1,
  PAY_AT_PROPERTY = 2,
  PAY_AT_PROPERTY_DEPOSIT_REQUIRED = 3,
  UNRECOGNIZED = -1,
}

export interface HotelCancellation {
  isLinked?: boolean | undefined;
  props?: HotelCancellationProps | undefined;
}

export interface HotelCancellationProps {
  type: HotelCancellationPropsType;
}

export enum HotelCancellationPropsType {
  UNKNOWN_TYPE = 0,
  NONE = 1,
  REFUNDABLE = 2,
  REFUNDABLE_WITH_PENALTY = 3,
  DONT_ALLOW = 4,
  UNRECOGNIZED = -1,
}

export interface HotelRating {
  isLinked?: boolean | undefined;
  props?: HotelRatingProps | undefined;
}

export interface HotelRatingProps {
  ratingRange?: DoubleRange;
}

export interface HotelRateConditionsNotAllowedProps {
  types: HotelRateConditionsNotAllowedPropsType[];
}

export enum HotelRateConditionsNotAllowedPropsType {
  TYPE_UNKNOWN = 0,
  NON_REFUNDABLE = 1,
  PREPAID = 2,
  REQUIRES_DEPOSIT = 3,
  PAY_AT_PROPERTY = 4,
  UNRECOGNIZED = -1,
}

export interface HotelRestrictedKeywordsProps {
  restrictedKeywords: HotelRestrictedKeywordsPropsKeywords[];
}

export interface HotelRestrictedKeywordsPropsKeywords {
  keyword: string;
  reason: string;
}

export interface MaxHotelBookingPriceProps {
  type: MaxHotelBookingPricePropsType;
  money?: Money | undefined;
  percentage?: number | undefined;
  isTaxIncluded: boolean;
}

export enum MaxHotelBookingPricePropsType {
  TYPE_UNKNOWN = 0,
  MORE_THAN_MEDIAN = 1,
  LESS_THAN_MEDIAN = 2,
  UNRECOGNIZED = -1,
}

export interface MaxCarPricePerNumberOfDays {
  isLinked?: boolean | undefined;
  props?: MaxCarPricePerNumberOfDaysProps | undefined;
}

export interface MaxCarPricePerNumberOfDaysProps {
  maxPrice?: Money;
  /** Number of days for this max limit. */
  numDays: number;
}

export interface AllowedCarTypes {
  isLinked?: boolean | undefined;
  props?: AllowedCarTypesProps | undefined;
}

export interface AllowedCarTypesProps {
  carTypes: CarType[];
}

export interface NotAllowedCarEngineTypes {
  isLinked?: boolean | undefined;
  props?: CarEngineTypesProps | undefined;
}

export interface CarEngineTypesProps {
  carEngineTypes: EngineType[];
}

export interface RailAdvanceBookingWindowProps {
  numDaysInAdvance?: number;
}

export interface MaxRailBookingPriceByDurationProps {
  maxPriceForDurationList: MaxRailBookingPriceByDurationPropsMaxPriceForDuration[];
}

export interface MaxRailBookingPriceByDurationPropsMaxPriceForDuration {
  maxPrice?: Money;
  /**
   * Duration range (in minutes) with lower limit (inclusive), upper limit
   * (exclusive). If the last duration range represents interval of the form
   * [x, infinity), max value should be set as 0.
   */
  durationRange?: Int32Range;
}

export interface HighestRailTravelClassByDurationProps {
  highestTravelClassForDurationList: HighestRailTravelClassByDurationPropsHighestTravelClassForDuration[];
}

export interface HighestRailTravelClassByDurationPropsHighestTravelClassForDuration {
  travelClass: TravelClass;
  /**
   * Duration range (in minutes) with lower limit (inclusive), upper limit
   * (exclusive). If the last duration range represents interval of the form
   * [x, infinity), max value should be set as 0.
   */
  durationRange?: Int32Range;
}

export interface RailTicketsRefundableProps {
  type: RailTicketsRefundablePropsType;
}

export enum RailTicketsRefundablePropsType {
  UNKNOWN_TYPE = 0,
  REFUNDABLE_WITHOUT_FEES = 1,
  REFUNDABLE_WITH_OR_WITHOUT_FEES = 2,
  NON_REFUNDABLE = 3,
  UNRECOGNIZED = -1,
}

export interface AllowedAirAddonsProps {
  addons: AllowedAirAddonsPropsAddon[];
}

/** Add-ons which can be selected as part of an air reservation. */
export enum AllowedAirAddonsPropsAddon {
  ADDON_UNKNOWN = 0,
  SEAT = 1,
  BAGGAGE = 2,
  WIFI = 3,
  CARBON_OFFSET = 4,
  EARLY_BIRD = 5,
  ALL = 6,
  NONE = 7,
  UNRECOGNIZED = -1,
}
