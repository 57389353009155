/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { OBTFeaturePreferences } from './obtfeature-preferences';

/**
 * OBT Features configuration
 * @export
 * @interface OBTFeatureConfig
 */
export interface OBTFeatureConfig {
  /**
   * Type of OBT Feature
   * @type {string}
   * @memberof OBTFeatureConfig
   */
  obtFeatureType: OBTFeatureConfigObtFeatureTypeEnum;
  /**
   * Configuration for the corresponding feature type
   * @type {Array<OBTFeaturePreferences>}
   * @memberof OBTFeatureConfig
   */
  obtFeaturePreferences: Array<OBTFeaturePreferences>;
}

export const OBTFeatureConfigObtFeatureTypeEnum = {
  SpotnanaReference: 'SPOTNANA_REFERENCE',
  DownloadItinerary: 'DOWNLOAD_ITINERARY',
  DownloadInvoice: 'DOWNLOAD_INVOICE',
  ShareViaEmail: 'SHARE_VIA_EMAIL',
  BackToTrips: 'BACK_TO_TRIPS',
  AirChange: 'AIR_CHANGE',
  AirCancel: 'AIR_CANCEL',
  AirUpdate: 'AIR_UPDATE',
  AirSeat: 'AIR_SEAT',
  AirFinalize: 'AIR_FINALIZE',
  AirUnfinalize: 'AIR_UNFINALIZE',
  AirBaggage: 'AIR_BAGGAGE',
  AirTsaNumber: 'AIR_TSA_NUMBER',
  AirRequestUpgrade: 'AIR_REQUEST_UPGRADE',
  AirAddOutsideBooking: 'AIR_ADD_OUTSIDE_BOOKING',
  AirAddShellPnr: 'AIR_ADD_SHELL_PNR',
  AirAddObtBooking: 'AIR_ADD_OBT_BOOKING',
  AirTravelerSeat: 'AIR_TRAVELER_SEAT',
  AirCompleteBooking: 'AIR_COMPLETE_BOOKING',
  AirUploadDocuments: 'AIR_UPLOAD_DOCUMENTS',
  AirAddAgentTask: 'AIR_ADD_AGENT_TASK',
  AirPaymentDetails: 'AIR_PAYMENT_DETAILS',
  AirRedressNumber: 'AIR_REDRESS_NUMBER',
  AirLoyaltyPrograms: 'AIR_LOYALTY_PROGRAMS',
  AirPreferredMeal: 'AIR_PREFERRED_MEAL',
  AirSpecialServiceRequest: 'AIR_SPECIAL_SERVICE_REQUEST',
  AirOtherServiceInformation: 'AIR_OTHER_SERVICE_INFORMATION',
  AirRebookFlight: 'AIR_REBOOK_FLIGHT',
  AirManageBooking: 'AIR_MANAGE_BOOKING',
  AirEditTravelerSeat: 'AIR_EDIT_TRAVELER_SEAT',
  AirTicketNumber: 'AIR_TICKET_NUMBER',
  AirEditTsaNumber: 'AIR_EDIT_TSA_NUMBER',
  AirEditRedressNumber: 'AIR_EDIT_REDRESS_NUMBER',
  AirFullFareRules: 'AIR_FULL_FARE_RULES',
  AirEditLoyaltyPrograms: 'AIR_EDIT_LOYALTY_PROGRAMS',
  AirEditPreferredMeal: 'AIR_EDIT_PREFERRED_MEAL',
  AirCouponStatus: 'AIR_COUPON_STATUS',
  AirEarlyBirdStatus: 'AIR_EARLY_BIRD_STATUS',
  AirBuyEarlyBirdCheckIn: 'AIR_BUY_EARLY_BIRD_CHECK_IN',
  AirWebCheckIn: 'AIR_WEB_CHECK_IN',
  AirUndoWebCheckIn: 'AIR_UNDO_WEB_CHECK_IN',
  AirRetryPayment: 'AIR_RETRY_PAYMENT',
  AirFareConditionsDisclaimer: 'AIR_FARE_CONDITIONS_DISCLAIMER',
  AirPassengerDetailsDisclaimer: 'AIR_PASSENGER_DETAILS_DISCLAIMER',
  AirPaymentMethodDisclaimer: 'AIR_PAYMENT_METHOD_DISCLAIMER',
  AirExitSeatMapDisclaimer: 'AIR_EXIT_SEAT_MAP_DISCLAIMER',
  AirSearchSpecialPrices: 'AIR_SEARCH_SPECIAL_PRICES_TAG',
  Co2EmissionNotSupported: 'CO2_EMISSION_NOT_SUPPORTED',
  Co2EmissionPercentage: 'CO2_EMISSION_PERCENTAGE',
  Co2EmissionNumber: 'CO2_EMISSION_NUMBER',
  AirClone: 'AIR_CLONE',
  PromoCode: 'PROMOTION_CODE_REQUIREMENT',
  HotelChange: 'HOTEL_CHANGE',
  HotelCancel: 'HOTEL_CANCEL',
  HotelAddOutsideBooking: 'HOTEL_ADD_OUTSIDE_BOOKING',
  HotelAddShellPnr: 'HOTEL_ADD_SHELL_PNR',
  HotelAddObtBooking: 'HOTEL_ADD_OBT_BOOKING',
  HotelManageBooking: 'HOTEL_MANAGE_BOOKING',
  HotelPaymentDetails: 'HOTEL_PAYMENT_DETAILS',
  HotelUpdate: 'HOTEL_UPDATE',
  HotelAddAgentTask: 'HOTEL_ADD_AGENT_TASK',
  HotelRebook: 'HOTEL_REBOOK',
  HotelUploadDocuments: 'HOTEL_UPLOAD_DOCUMENTS',
  HotelClone: 'HOTEL_CLONE',
  HotelFinalize: 'HOTEL_FINALIZE',
  HotelUnfinalize: 'HOTEL_UNFINALIZE',
  HotelStarRating: 'HOTEL_STAR_RATING',
  CarChange: 'CAR_CHANGE',
  CarCancel: 'CAR_CANCEL',
  CarAddOutsideBooking: 'CAR_ADD_OUTSIDE_BOOKING',
  CarAddShellPnr: 'CAR_ADD_SHELL_PNR',
  LimoAddShellPnr: 'LIMO_ADD_SHELL_PNR',
  CarAddObtBooking: 'CAR_ADD_OBT_BOOKING',
  CarManageBooking: 'CAR_MANAGE_BOOKING',
  CarPaymentDetails: 'CAR_PAYMENT_DETAILS',
  CarUpdate: 'CAR_UPDATE',
  CarAddAgentTask: 'CAR_ADD_AGENT_TASK',
  CarUploadDocuments: 'CAR_UPLOAD_DOCUMENTS',
  CarClone: 'CAR_CLONE',
  CarFinalize: 'CAR_FINALIZE',
  CarUnfinalize: 'CAR_UNFINALIZE',
  CarStrikeThroughPricing: 'CAR_STRIKETHROUGH_PRICING',
  RailChange: 'RAIL_CHANGE',
  RailCancel: 'RAIL_CANCEL',
  RailAddOutsideBooking: 'RAIL_ADD_OUTSIDE_BOOKING',
  RailClone: 'RAIL_CLONE',
  RailAddObtBooking: 'RAIL_ADD_OBT_BOOKING',
  LimoAddOutsideBooking: 'LIMO_ADD_OUTSIDE_BOOKING',
  MiscAddOutsideBooking: 'MISC_ADD_OUTSIDE_BOOKING',
  HotelSearchParamsBar: 'HOTEL_SEARCH_PARAMS_BAR',
  HotelSearchSoldOutToggle: 'HOTEL_SEARCH_SOLD_OUT_TOGGLE',
  HotelSearchFreeCancellationToggle: 'HOTEL_SEARCH_FREE_CANCELLATION_TOGGLE',
  HotelSearchChainFilter: 'HOTEL_SEARCH_CHAIN_FILTER',
  HotelSearchBrandFilter: 'HOTEL_SEARCH_BRAND_FILTER',
  HotelSearchEcoCertifiedFilter: 'HOTEL_SEARCH_ECO_CERTIFIED_FILTER',
  HotelSearchLoyaltyProgramsFilter: 'HOTEL_SEARCH_LOYALTY_PROGRAMS_FILTER',
  HotelCancellationFilter: 'HOTEL_CANCELLATION_FILTER',
  HotelSearchPriceFilter: 'HOTEL_SEARCH_PRICE_FILTER',
  HotelSearchCancellationFilter: 'HOTEL_SEARCH_CANCELLATION_FILTER',
  HotelSearchSpecialPricesLabel: 'HOTEL_SEARCH_SPECIAL_PRICES_TAG',
  HotelSearchStarRatingFilter: 'HOTEL_SEARCH_STAR_RATING_FILTER',
  HotelAggregatorLogos: 'HOTEL_AGGREGATOR_LOGOS',
  HotelStrikeThroughPricing: 'HOTEL_DETAILS_STRIKETHROUGH_PRICING',
  HotelPaymentFilter: 'HOTEL_PAYMENT_FILTER',
  HotelSearchRateTypeFilter: 'HOTEL_SEARCH_RATE_TYPE_FILTER',
  HotelSearchPropertyTypeFilter: 'HOTEL_SEARCH_PROPERTY_TYPE_FILTER',
  HotelSearchAmenitiesFilter: 'HOTEL_SEARCH_AMENITIES_FILTER',
  HotelSearchPreferredFilter: 'HOTEL_SEARCH_PREFERRED_FILTER',
  HotelDetailsEarnLoyaltyPointsFilter: 'HOTEL_DETAILS_EARN_LOYALTY_POINTS_FILTER',
  HotelDetailsNightlyPrices: 'HOTEL_DETAILS_NIGHTLY_PRICES',
  HotelDetailsBedsFilter: 'HOTEL_DETAILS_BEDS_FILTER',
  HotelDetailsCancellationFilter: 'HOTEL_DETAILS_CANCELLATION_FILTER',
  HotelDetailsShowConditionalRateFilter: 'HOTEL_DETAILS_RATE_TYPE_FILTER',
  HotelDetailsPaymentType: 'HOTEL_DETAILS_PAYMENT_TYPE',
  HotelDetailsAccessibleFilter: 'HOTEL_DETAILS_ACCESSIBLE_FILTER',
  HotelDetailsDatePicker: 'HOTEL_DETAILS_DATE_PICKER',
  HotelDetailsStarRating: 'HOTEL_DETAILS_STAR_RATING',
  TravelerGenderRequirement: 'TRAVELER_GENDER_REQUIREMENT',
  TravelerDateOfBirthRequirement: 'TRAVELER_DATE_OF_BIRTH_REQUIREMENT',
  TravelerEmailAddressRequirement: 'TRAVELER_EMAIL_ADDRESS_REQUIREMENT',
  TravelerPhoneNumberRequirement: 'TRAVELER_PHONE_NUMBER_REQUIREMENT',
  BookingContactEmailAddressRequirement: 'BOOKING_CONTACT_EMAIL_ADDRESS_REQUIREMENT',
  BookingContactPhoneNumberRequirement: 'BOOKING_CONTACT_PHONE_NUMBER_REQUIREMENT',
  UserProfileUpdateAtCheckout: 'USER_PROFILE_UPDATE_AT_CHECKOUT',
  TravelerEmergencyContactRequirement: 'TRAVELER_EMERGENCY_CONTACT_REQUIREMENT',
  TravelerTravelDocumentsRequirement: 'TRAVELER_TRAVEL_DOCUMENTS_REQUIREMENT',
  BookingConfirmationRequirement: 'BOOKING_CONFIRMATION_REQUIREMENT',
  BookingTripsRedirectionRequirement: 'REDIRECT_ON_CHECKOUT',
  TravelerTitleRequirement: 'TRAVELER_TITLE_REQUIREMENT',
  TravelerSuffixRequirement: 'TRAVELER_SUFFIX_REQUIREMENT',
  TravelerFirstNameRequirement: 'TRAVELER_FIRST_NAME_REQUIREMENT',
  TravelerMiddleNameRequirement: 'TRAVELER_MIDDLE_NAME_REQUIREMENT',
  TravelerLastNameRequirement: 'TRAVELER_LAST_NAME_REQUIREMENT',
  AdultDateOfBirthRequirement: 'ADULT_DATE_OF_BIRTH_REQUIREMENT',
  ChildDateOfBirthRequirement: 'CHILD_DATE_OF_BIRTH_REQUIREMENT',
  InfantDateOfBirthRequirement: 'INFANT_DATE_OF_BIRTH_REQUIREMENT',
  UserProfileDobPersistence: 'USER_PROFILE_DOB_PERSISTENCE',
  UserProfileGenderPersistence: 'USER_PROFILE_GENDER_PERSISTENCE',
  FeeBanner: 'FEE_BANNER',
  FareRulesCancellationBanner: 'FARE_RULES_CANCELLATION_BANNER',
  NonRefundableInfo: 'NON_REFUNDABLE_INFO',
  StatusCreditsInfo: 'STATUS_CREDITS_INFO',
  ChangesInfo: 'CHANGES_INFO',
  CheckedBagInfo: 'CHECKED_BAG_INFO',
  FareRoundUpRequirement: 'FARE_ROUND_UP_REQUIREMENT',
  CarVendorFilter: 'CAR_VENDOR_FILTER',
  CarEngineTypeFilter: 'CAR_ENGINE_TYPE_FILTER',
  CarPriceFilter: 'CAR_PRICE_FILTER',
  CarVehicleCategoryFilter: 'CAR_VEHICLE_CATEGORY_FILTER',
  CarTransmissionFilter: 'CAR_TRANSMISSION_FILTER',
  CarVehicleSpecsFilter: 'CAR_VEHICLE_SPECS_FILTER',
  CarSearchParamsBar: 'CAR_SEARCH_PARAMS_BAR',
  CarSearchPreferredFilter: 'CAR_SEARCH_PREFERRED_FILTER',
  PaymentSection: 'PAYMENT_SECTION',
  ShowSubtextAddBooking: 'SHOW_SUBTEXT_ADD_BOOKING',
  SkipAgeValidation: 'SKIP_AGE_VALIDATION',
  OverrideNegotiatedFareDisclaimer: 'OVERRIDE_NEGOTIATED_FARE_DISCLAIMER',
  MultipleLoyaltyRequirement: 'MULTIPLE_LOYALTY_REQUIREMENT',
  ContactUsRequirement: 'CONTACT_US',
  AppStoreLinkRequirement: 'APP_STORE_LINK',
  ConfirmationFeedbackRequirement: 'CONFIRMATION_FEEDBACK',
  AirSplitPnr: 'AIR_SPLIT_PNR',
  TripCreate: 'TRIP_CREATE',
  HotelExpandRoomDetailsAndPolicies: 'HOTEL_EXPAND_ROOM_DETAILS_AND_POLICIES',
  HotelFareBreakdownToggle: 'HOTEL_FARE_BREAKDOWN_TOGGLE',
  CarFareBreakdownToggle: 'CAR_FARE_BREAKDOWN_TOGGLE',
  AirTicketingCodes: 'AIR_TICKETING_CODES',
  TripTravelerEmail: 'TRIP_TRAVELER_EMAIL',
} as const;

export type OBTFeatureConfigObtFeatureTypeEnum =
  (typeof OBTFeatureConfigObtFeatureTypeEnum)[keyof typeof OBTFeatureConfigObtFeatureTypeEnum];
