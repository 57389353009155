import { css } from '@emotion/react';

// Define separate types for dimensions, paddings, spacings, and corner radii
type Dimensions =
  | 'dimension-one'
  | 'dimension-two'
  | 'dimension-three'
  | 'dimension-four'
  | 'dimension-five'
  | 'dimension-six'
  | 'dimension-seven'
  | 'dimension-eight'
  | 'dimension-nine'
  | 'dimension-ten';
type Paddings =
  | 'padding-none'
  | 'padding-extremeTight'
  | 'padding-superTight'
  | 'padding-tight'
  | 'padding-comfortable'
  | 'padding-compact'
  | 'padding-cozy'
  | 'padding-extraCozy'
  | 'padding-buttonSmall'
  | 'padding-buttonMedium';
export type Spacings =
  | 'spacing-smallest'
  | 'spacing-extraSmall'
  | 'spacing-small'
  | 'spacing-medium'
  | 'spacing-large'
  | 'spacing-extraLarge'
  | 'spacing-doubleXl'
  | 'spacing-largest';
type CornerRadii =
  | 'cornerRadii-outer'
  | 'cornerRadii-standard'
  | 'cornerRadii-small'
  | 'cornerRadii-buttonStandard'
  | 'cornerRadii-buttonSmall'
  | 'cornerRadii-full';

type SpotnanaBlocksTokens = Dimensions | Paddings | Spacings | CornerRadii;

// Helper function to generate the CSS variable string for a given token
export const getFoundationVar = (token: SpotnanaBlocksTokens) => `--spotnanaBlocks--${token}`;

export const getDimensions = () => {
  return css`
    ${getFoundationVar('dimension-one')}: 2px;
    ${getFoundationVar('dimension-two')}: 4px;
    ${getFoundationVar('dimension-three')}: 6px;
    ${getFoundationVar('dimension-four')}: 8px;
    ${getFoundationVar('dimension-five')}: 10px;
    ${getFoundationVar('dimension-six')}: 12px;
    ${getFoundationVar('dimension-seven')}: 16px;
    ${getFoundationVar('dimension-eight')}: 20px;
    ${getFoundationVar('dimension-nine')}: 24px;
    ${getFoundationVar('dimension-ten')}: 32px;
    /* Tablets */
    @media (min-width: 768px) {
      ${getFoundationVar('dimension-eight')}: 22px;
      ${getFoundationVar('dimension-nine')}: 28px;
      ${getFoundationVar('dimension-ten')}: 40px;
    }
    /* Small Desktop and above */
    @media (min-width: 1024px) {
      ${getFoundationVar('dimension-eight')}: 24px;
      ${getFoundationVar('dimension-nine')}: 32px;
      ${getFoundationVar('dimension-ten')}: 48px;
    }
  `;
};

export const getPaddings = () => {
  return css`
    ${getFoundationVar('padding-none')}: 0px;
    ${getFoundationVar('padding-extremeTight')}: var(--spotnanaBlocks--dimension-one);
    ${getFoundationVar('padding-superTight')}: var(--spotnanaBlocks--dimension-two);
    ${getFoundationVar('padding-buttonSmall')}: var(--spotnanaBlocks--dimension-three);
    ${getFoundationVar('padding-tight')}: var(--spotnanaBlocks--dimension-four);
    ${getFoundationVar('padding-buttonMedium')}: var(--spotnanaBlocks--dimension-five);
    ${getFoundationVar('padding-compact')}: var(--spotnanaBlocks--dimension-six);
    ${getFoundationVar('padding-comfortable')}: var(--spotnanaBlocks--dimension-seven);
    ${getFoundationVar('padding-cozy')}: var(--spotnanaBlocks--dimension-eight);
    ${getFoundationVar('padding-extraCozy')}: var(--spotnanaBlocks--dimension-nine);
  `;
};

export const getSpacings = () => {
  return css`
    ${getFoundationVar('spacing-smallest')}: var(--spotnanaBlocks--dimension-one);
    ${getFoundationVar('spacing-extraSmall')}: var(--spotnanaBlocks--dimension-two);
    ${getFoundationVar('spacing-small')}: var(--spotnanaBlocks--dimension-four);
    ${getFoundationVar('spacing-medium')}: var(--spotnanaBlocks--dimension-six);
    ${getFoundationVar('spacing-large')}: var(--spotnanaBlocks--dimension-seven);
    ${getFoundationVar('spacing-extraLarge')}: var(--spotnanaBlocks--dimension-eight);
    ${getFoundationVar('spacing-doubleXl')}: var(--spotnanaBlocks--dimension-nine);
    ${getFoundationVar('spacing-largest')}: var(--spotnanaBlocks--dimension-ten);
  `;
};

export const getCornerRadii = () => {
  return css`
    ${getFoundationVar('cornerRadii-outer')}: var(--spotnanaBlocks--dimension-four);
    ${getFoundationVar('cornerRadii-standard')}: var(--spotnanaBlocks--dimension-four);
    ${getFoundationVar('cornerRadii-small')}: var(--spotnanaBlocks--dimension-two);
    ${getFoundationVar('cornerRadii-buttonStandard')}: var(--spotnanaBlocks--dimension-four);
    ${getFoundationVar('cornerRadii-buttonSmall')}: var(--spotnanaBlocks--dimension-three);
    ${getFoundationVar('cornerRadii-full')}: var(--spotnanaBlocks--dimension-ten);
    /* Tablets */
    @media (min-width: 768px) {
      ${getFoundationVar('cornerRadii-outer')}: var(--spotnanaBlocks--dimension-five);
    }
    /* Small Desktop and above */
    @media (min-width: 1024px) {
      ${getFoundationVar('cornerRadii-outer')}: var(--spotnanaBlocks--dimension-six);
    }
  `;
};
