/* eslint-disable @typescript-eslint/no-explicit-any */
import first from 'lodash/first';
import type { CountryCode } from 'libphonenumber-js';
import type { TFunction } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
import errorMessages from '../constants/errorMessage';
import { maxAddressLengthAaAutoEnroll, minPassengerAgeAaAutoEnroll } from '../constants/flights/index';
import type { IEmergencyContactInfo, INationalDoc, IPhoneNumber, ITravelerPersonalInfo } from '../types/traveler';
import { IdentityDocsTabLabels } from '../types/traveler';
import type { PhoneNumber } from '../types/api/v1/common/phone_number';
import type { PostalAddress } from '../types/api/v1/common/postal_address';
import type { KnownTravelerNumber, Passport, RedressNumber } from '../types/api/v1/obt/common/user';
import type { LoyaltyInfo } from '../types/api/v1/obt/common/traveler_personal_info';
import type { LoyaltyInfo as LoyaltyInfoV2, RailLoyaltySelectListItemProduct } from '../types';
import { UserTitleEnum } from '../types';
import getPostalCodeRegex from '../constants/profile/postalcodeRegex';
import {
  hasDuplicateValuesInArray,
  isAlphaNumeric,
  isArrayNotEmpty,
  isNumeric,
  isValidEmail,
  isValidForm,
  validateLeadingTrailingWhitespace,
  validateMultipleConsecutiveWhitespace,
  validateRequiredField,
} from './common';
import PhoneFormatter from '../utils/Phone';
import { PassengerTypeEnum } from '../types/api/v1/obt/air/air_common';
import { dateUtil } from '../date-utils';
import type { SupportedBillingCurrencyKeys } from '../constants/admin/billingCurrencies';
import { WelcomeErrorCodes } from '../constants/auth';
import { defineMessage } from '../translations/defineMessage';
import type {
  IAddressValidationState,
  IPhoneNumberValidationState,
  IPassportValidationState,
  IEmergencyContactValidationState,
  ILoyaltyValidationState,
  ILabelValidationState,
  ICreditCardLabelState,
  ICreditCardState,
  IPaymentValidationState,
  IPersonalValidationState,
  ITravelDocValidationState,
  ICurrencyValidationState,
} from './traveler.types';
import { CountriesWithNationalID } from '../constants/traveler';

const getCommonValidationError = (field: string): string | null => {
  if (!validateLeadingTrailingWhitespace(field)) {
    return errorMessages.common.LEADING_TRAILING_WHITESPACE;
  }

  if (validateMultipleConsecutiveWhitespace(field)) {
    return errorMessages.common.MULTIPLE_CONSEUCTIVE_WHITESPACE;
  }

  return null;
};

/* Valid traveler name should have english alphabets only */
const validateNameWithoutSpecialCharFormat = (nameComponent: string): string => {
  const isValidNameFormat = /^[a-zA-Z ]*$/.test(nameComponent);
  if (!isValidNameFormat) {
    return errorMessages.traveler.INVALID_FORMAT.NAME;
  }
  return '';
};

export const validateTitle = (title: { value: UserTitleEnum } | null, isRequired?: boolean): string => {
  if ((!title || title.value === UserTitleEnum.TitleUnknown) && isRequired) {
    return errorMessages.traveler.EMPTY.TITLE;
  }
  return '';
};

// TODO (Piyush): Explore using React-Hook-Form for validations
export const validateNameComponent = (
  nameComponent: string,
  emptyValErr: string,
  isSpecialCharInNameAllowed = false,
): string => {
  if (!validateRequiredField(nameComponent)) {
    return emptyValErr;
  }

  const commonValidationErr: string | null = getCommonValidationError(nameComponent);
  if (commonValidationErr) {
    return commonValidationErr;
  }

  const nameFormatError: string = validateNameWithoutSpecialCharFormat(nameComponent);
  if (!isSpecialCharInNameAllowed && nameFormatError) {
    return nameFormatError;
  }

  return '';
};

export const validateFirstName = (firstName: string, isSpecialCharInNameAllowed = false): string =>
  validateNameComponent(firstName, errorMessages.traveler.EMPTY.FIRST_NAME, isSpecialCharInNameAllowed);

export const validateMiddleName = (middleName: string, isSpecialCharInNameAllowed = false): string => {
  if (middleName.length) {
    const commonValidationErr: string | null = getCommonValidationError(middleName);
    if (commonValidationErr) {
      return commonValidationErr;
    }

    const nameFormatError: string = validateNameWithoutSpecialCharFormat(middleName);
    if (!isSpecialCharInNameAllowed && nameFormatError) {
      return nameFormatError;
    }
  }
  return '';
};

export const validateLastName = (lastName: string, isSpecialCharInNameAllowed = false): string => {
  const commonNameValidationErr: string = validateNameComponent(
    lastName,
    errorMessages.traveler.EMPTY.LAST_NAME,
    isSpecialCharInNameAllowed,
  );
  if (commonNameValidationErr) {
    return commonNameValidationErr;
  }

  if (lastName.length < 2) {
    return errorMessages.traveler.LENGTH.LAST_NAME;
  }

  return '';
};

export const validateSuffixName = (suffixName: string, isSpecialCharInNameAllowed = false): string => {
  if (suffixName.length) {
    const commonValidationErr: string | null = getCommonValidationError(suffixName);
    if (commonValidationErr) {
      return commonValidationErr;
    }

    const nameFormatError: string = validateNameWithoutSpecialCharFormat(suffixName);
    if (!isSpecialCharInNameAllowed && nameFormatError) {
      return nameFormatError;
    }
  }
  return '';
};

const validatePostalAddresses = (addresses: PostalAddress[]): string => {
  if (!addresses.length) {
    return '';
  }
  const defaultCount: number = addresses.filter((address) => address.isDefault).length;
  if (defaultCount !== 1) {
    // TODO (shashank): Why do we check == 1 while the message says at least one?
    return errorMessages.traveler.EMPTY.ATLEAST_ONE_DEFAULT_ADDRESS;
  }

  return '';
};

export const validatePhoneNumber = (phoneNumber: IPhoneNumber, t: TFunction, isRequired = true): string => {
  const isInputEmpty = !validateRequiredField(phoneNumber.rawInput);
  if (isInputEmpty && isRequired) {
    return errorMessages.traveler.EMPTY.PHONE_NUMBER;
  }
  if (isInputEmpty && !isRequired) {
    return '';
  }
  return t(PhoneFormatter.validatePhoneNumber(phoneNumber.rawInput, phoneNumber.isoCountryCode as CountryCode));
};

export const validateEmailAddress = (
  email: string,
  isEmailRequired?: boolean,
  v2 = false,
): string | WelcomeErrorCodes => {
  const data = email.trim();
  if (!data && !isEmailRequired) return '';
  if (!data && isEmailRequired) {
    if (v2) {
      return WelcomeErrorCodes.EMPTY_EMAIL;
    }
    return 'Personal Email is required';
  }
  if (!isValidEmail(data)) {
    if (v2) {
      return WelcomeErrorCodes.INVALID_EMAIL_FORMAT;
    }
    return errorMessages.traveler.INVALID_FORMAT.EMAIL;
  }
  return '';
};

export const validateNameLength = (name: string): string =>
  name.length < 2 ? errorMessages.traveler.LENGTH.LAST_NAME : '';

export const validateRedressNumber = (redressNumber: string): string => {
  if (!redressNumber) {
    return '';
  }
  const commonValidationErr: string | null = getCommonValidationError(redressNumber);
  if (commonValidationErr) {
    return commonValidationErr;
  }
  // allow numeric values only
  const isValidRedressNumber = isNumeric(redressNumber);
  if (!isValidRedressNumber) {
    return errorMessages.traveler.INVALID_FORMAT.REDRESS_NUMBER;
  }
  return '';
};

export const validateKnownTravelerNumber = (knownTravelerNumber: string): string => {
  if (!knownTravelerNumber) {
    return '';
  }
  const commonValidationErr: string | null = getCommonValidationError(knownTravelerNumber);
  if (commonValidationErr) {
    return commonValidationErr;
  }
  // allow alphanumeric characters only
  const isValidKTNNumber = isAlphaNumeric(knownTravelerNumber);
  if (!isValidKTNNumber) {
    return errorMessages.traveler.INVALID_FORMAT.KNOWN_TRAVELER_NUMBER;
  }
  return '';
};

export const validateKTN = (ktn: KnownTravelerNumber | undefined): string => {
  if (ktn && Boolean(ktn.issueCountry.length) !== Boolean(ktn.number.length)) {
    if (!ktn.issueCountry) {
      return errorMessages.traveler.EMPTY.NO_COUNTRY_CODE;
    }
    return errorMessages.traveler.EMPTY.KNOWN_TRAVELER_NUM;
  }
  return validateKnownTravelerNumber(ktn?.number ?? '');
};

export const validateRedress = (redress: RedressNumber | undefined): string => {
  if (redress && Boolean(redress.issueCountry.length) !== Boolean(redress.number.length)) {
    if (!redress.issueCountry) {
      return errorMessages.traveler.EMPTY.NO_COUNTRY_CODE;
    }
    return errorMessages.traveler.EMPTY.REDRESS_NUM;
  }

  return validateRedressNumber(redress?.number ?? '');
};

const validatePersonalDataElements = (
  state: ITravelerPersonalInfo,
  isEmailRequired?: boolean,
  isSpecialCharInNameAllowed?: boolean,
): IPersonalValidationState['error'] => ({
  firstName: validateFirstName(state.name.given.trim(), isSpecialCharInNameAllowed),
  middleName: validateMiddleName(state.name.middle.trim(), isSpecialCharInNameAllowed),
  lastName: validateLastName(state.name.family1.trim(), isSpecialCharInNameAllowed),
  suffixName: validateSuffixName(state.name.family2.trim()),
  preferredName: getCommonValidationError(state.name.preferred?.trim()) ?? '',
  email: validateEmailAddress(state.contactInfo.email, isEmailRequired),
  address: validatePostalAddresses(state.address),
});

export const validatePersonalDataForm = (
  state: ITravelerPersonalInfo,
  isEmailRequired?: boolean,
  isSpecialCharInNameAllowed?: boolean,
): IPersonalValidationState => {
  const error = validatePersonalDataElements(state, isEmailRequired, isSpecialCharInNameAllowed);
  return {
    error,
    isValid: isValidForm(error),
  };
};

const validatePhoneNumberElements = (
  state: PhoneNumber,
  t: TFunction,
  isRequired: boolean,
  isHidden: boolean,
): IPhoneNumberValidationState['error'] => {
  if (isHidden) {
    return {
      countryCode: '',
      phoneNumber: '',
    };
  }
  const isCountryCodePresent =
    !isRequired || (validateRequiredField(state.countryCode) && validateRequiredField(state.isoCountryCode));
  const isPhoneNumberPresent = !isRequired || validateRequiredField(state.rawInput);

  return {
    countryCode: !isCountryCodePresent ? errorMessages.traveler.EMPTY.COUNTRY_CODE_PHONE : '',
    phoneNumber: !isPhoneNumberPresent
      ? errorMessages.traveler.EMPTY.PHONE_NUMBER
      : validatePhoneNumber(state, t, isRequired),
  };
};

export const validatePhoneNumberDataForm = (
  state: PhoneNumber,
  t: TFunction,
  isRequired = true,
  isHidden = false,
): IPhoneNumberValidationState => {
  const error = validatePhoneNumberElements(state, t, isRequired, isHidden);
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validatePostalCode = (countryCode: string, postalCode: string): string => {
  if (countryCode) {
    const regex = getPostalCodeRegex(countryCode);
    return regex.test(postalCode) ? '' : errorMessages.traveler.INVALID_FORMAT.POSTAL_CODE;
  }
  return '';
};

interface IValidateAddressDataForm {
  state: PostalAddress;
  addressNameRequired?: boolean;
  stateRequired?: boolean;
}

const validateAddressElements = ({
  state,
  addressNameRequired,
  stateRequired,
}: IValidateAddressDataForm): IAddressValidationState['error'] => ({
  addressName:
    addressNameRequired && !validateRequiredField(state.description) ? errorMessages.traveler.EMPTY.ADDRESS_NAME : '',
  addressLine1: !validateRequiredField(first(state.addressLines) ?? '')
    ? errorMessages.traveler.EMPTY.ADDRESS_LINE1
    : '',
  country: !validateRequiredField(state.regionCode) ? errorMessages.traveler.EMPTY.COUNTRY : '',
  city: !validateRequiredField(state.locality) ? errorMessages.traveler.EMPTY.CITY : '',
  postalcode: !validateRequiredField(state.postalCode)
    ? errorMessages.traveler.EMPTY.POSTALCODE
    : validatePostalCode(state.regionCode, state.postalCode),
  ...(stateRequired
    ? {
        state: !validateRequiredField(state.administrativeArea) ? errorMessages.traveler.EMPTY.STATE : '',
      }
    : {}),
});

export const validateAddressDataForm = ({
  state,
  addressNameRequired = true,
  stateRequired = false,
}: IValidateAddressDataForm): IAddressValidationState => {
  const error = validateAddressElements({ state, addressNameRequired, stateRequired });
  return {
    error,
    isValid: isValidForm(error),
  };
};

const getPostalCodeErrorString = (regionCode: string, postalCode: string, required: boolean): string => {
  const isNotEmpty = validateRequiredField(postalCode);

  if (isNotEmpty) {
    return validatePostalCode(regionCode, postalCode);
  }

  if (required) {
    return errorMessages.traveler.EMPTY.POSTALCODE;
  }

  return '';
};

export const validateCompanyOnboardingElements = ({
  state,
  addressNameRequired = true,
  cityRequired = false,
  postalCodeRequired = false,
}: {
  state: PostalAddress;
  addressNameRequired?: boolean;
  cityRequired?: boolean;
  postalCodeRequired?: boolean;
}): IAddressValidationState['error'] => ({
  addressName:
    addressNameRequired && !validateRequiredField(state.description) ? errorMessages.traveler.EMPTY.ADDRESS_NAME : '',
  addressLine1: !validateRequiredField(first(state.addressLines) ?? '')
    ? errorMessages.traveler.EMPTY.ADDRESS_LINE1
    : '',
  country: !validateRequiredField(state.regionCode) ? errorMessages.traveler.EMPTY.COUNTRY : '',
  city: cityRequired && !validateRequiredField(state.locality) ? errorMessages.traveler.EMPTY.CITY : '',
  postalcode: getPostalCodeErrorString(state.regionCode, state.postalCode, postalCodeRequired),
});

export const validateCompanyOnboardingAddress = (props: {
  state: PostalAddress;
  addressNameRequired?: boolean;
  postalCodeRequired?: boolean;
  cityRequired?: boolean;
}): IAddressValidationState => {
  const error = validateCompanyOnboardingElements(props);
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validateDocumentNumber = (id: string, docType: IdentityDocsTabLabels, idLength = 25): string => {
  const commonValidationErr: string | null = getCommonValidationError(id);
  if (commonValidationErr) {
    return commonValidationErr;
  }

  if (!validateRequiredField(id)) {
    if (docType === IdentityDocsTabLabels.PASSPORT) {
      return errorMessages.traveler.EMPTY.PASSPORT_ID;
    }
    return errorMessages.traveler.EMPTY.NATIONAL_ID;
  }

  if (!isAlphaNumeric(id)) {
    if (docType === IdentityDocsTabLabels.PASSPORT) {
      return errorMessages.traveler.INVALID_FORMAT.PASSPORT_NUMBER;
    }
    return errorMessages.traveler.INVALID_FORMAT.NATIONAL_ID_NUMBER;
  }

  if (id.length > idLength) {
    if (docType === IdentityDocsTabLabels.PASSPORT) {
      return errorMessages.traveler.LENGTH.PASSPORT_NUMBER;
    }
    return errorMessages.traveler.LENGTH.NATIONAL_ID_NUMBER;
  }

  return '';
};

const validatePassportElements = (state: Passport): IPassportValidationState['error'] => ({
  passportId: validateDocumentNumber(state.docId, IdentityDocsTabLabels.PASSPORT),
  issueCountry: !validateRequiredField(state.issueCountry) ? errorMessages.traveler.EMPTY.ISSUE_COUNTRY : '',
  nationalityCountry: !validateRequiredField(state.nationalityCountry)
    ? errorMessages.traveler.EMPTY.NATIONALITY_COUNTRY
    : '',
  expiryDate: !validateRequiredField(state.expiryDate?.iso8601 ?? '')
    ? errorMessages.traveler.EMPTY.PASSPORT_EXPIRY
    : '',
});

export const validatePassportDataForm = (state: Passport): IPassportValidationState => {
  const error = validatePassportElements(state);
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validatePassportDataFormV2 = (
  state: Passport,
  allPassports: Passport[],
  isConsent: boolean,
): ITravelDocValidationState => {
  const allPassportNumbers = allPassports.map((passport) => passport.docId);
  let docId = '';
  if (hasDuplicateValuesInArray([state.docId, ...allPassportNumbers])) {
    docId = errorMessages.traveler.DUPLICATE.PASSPORT_NUMBER;
  } else {
    docId = validateDocumentNumber(state.docId, IdentityDocsTabLabels.PASSPORT);
  }

  let expiryDate = '';
  if (!validateRequiredField(state.expiryDate?.iso8601 ?? '')) {
    expiryDate = errorMessages.traveler.EMPTY.PASSPORT_EXPIRY;
  }
  if (state.expiryDate?.iso8601 && state.expiryDate?.iso8601 === state.issuedDate?.iso8601) {
    expiryDate = errorMessages.traveler.INVALID_FORMAT.PASSPORT_EXPIRY_DATE;
  }

  const error = {
    docId,
    issueCountry: !validateRequiredField(state.issueCountry) ? errorMessages.traveler.EMPTY.ISSUE_COUNTRY : '',
    nationalityCountry: !validateRequiredField(state.nationalityCountry)
      ? errorMessages.traveler.EMPTY.NATIONALITY_COUNTRY
      : '',
    expiryDate,
    type: '',
    consent: !isConsent ? defineMessage('Please check the box to proceed') : '',
  };
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validateNationalDocDataFormV2 = (
  state: INationalDoc,
  allNationalDocs: INationalDoc[],
  isConsent: boolean,
): ITravelDocValidationState => {
  const allNationalDocNumbers = allNationalDocs.map((nationalDoc) => nationalDoc.docId);
  let docId = '';
  if (hasDuplicateValuesInArray([state.docId, ...allNationalDocNumbers])) {
    docId = errorMessages.traveler.DUPLICATE.NATIONAL_ID_NUMBER;
  } else {
    docId = validateDocumentNumber(state.docId, IdentityDocsTabLabels.NATIONAL_ID, 29);
  }

  let expiryDate = '';
  if (state.expiryDate?.iso8601 && state.expiryDate?.iso8601 === state.issuedDate?.iso8601) {
    expiryDate = errorMessages.traveler.INVALID_FORMAT.NATIONAL_ID_EXPIRY_DATE;
  }

  const error = {
    docId,
    issueCountry: !validateRequiredField(state.issueCountry) ? errorMessages.traveler.EMPTY.ISSUE_COUNTRY : '',
    nationalityCountry: '',
    expiryDate,
    type:
      CountriesWithNationalID.includes(state.issueCountry) && !state.type
        ? errorMessages.traveler.EMPTY.NATIONAL_ID_TYPE
        : '',
    consent: !isConsent ? defineMessage('Please check the box to proceed') : '',
  };
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validateKTNV2 = (
  state: KnownTravelerNumber | undefined,
  isConsent: boolean,
): ITravelDocValidationState => {
  let docId = '';
  if (state?.number === '') {
    docId = errorMessages.traveler.EMPTY.KNOWN_TRAVELER_NUM;
  } else {
    docId = validateKnownTravelerNumber(state?.number ?? '');
  }

  const error = {
    docId,
    issueCountry: !validateRequiredField(state?.issueCountry ?? '') ? errorMessages.traveler.EMPTY.ISSUE_COUNTRY : '',
    nationalityCountry: '',
    expiryDate: '',
    type: '',
    consent: !isConsent ? defineMessage('Please check the box to proceed') : '',
  };
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validateRedressNumberV2 = (
  state: RedressNumber | undefined,
  isConsent: boolean,
): ITravelDocValidationState => {
  let docId = '';
  if (state?.number === '') {
    docId = errorMessages.traveler.EMPTY.REDRESS_NUM;
  } else {
    docId = validateRedressNumber(state?.number ?? '');
  }

  const error = {
    docId,
    issueCountry: !validateRequiredField(state?.issueCountry ?? '') ? errorMessages.traveler.EMPTY.ISSUE_COUNTRY : '',
    nationalityCountry: '',
    expiryDate: '',
    type: '',
    consent: !isConsent ? defineMessage('Please check the box to proceed') : '',
  };
  return {
    error,
    isValid: isValidForm(error),
  };
};

const validatePaymentCardElements = (
  state: ICreditCardState,
  isCardOwnershipLabelEnabled: boolean,
): IPaymentValidationState['error'] => ({
  name: first(state.name.errorMessages) ?? '',
  number: first(state.number.errorMessages) ?? '',
  expiryDate: first(state.expiryDate.errorMessages) ?? '',
  cvv: first(state.cvv.errorMessages) ?? '',
  applicableTo: !isArrayNotEmpty(state.applicableTo) ? errorMessages.traveler.EMPTY.PAYMENT_CARD_APPLICABLE_TO : '',
  ownershipLabel:
    isUndefined(state.ownershipLabel) && isCardOwnershipLabelEnabled
      ? errorMessages.traveler.EMPTY.OWNERSHIP_LABEL
      : '',
});

const validateEmergencyContactElements = (
  state: IEmergencyContactInfo,
  validateDesignation: boolean,
): IEmergencyContactValidationState['error'] => ({
  firstName: validateFirstName(state.name?.given ?? ''),
  lastName: validateLastName(state.name?.family1 ?? ''),
  designation:
    validateDesignation && !validateRequiredField(state?.designation ?? '')
      ? errorMessages.admin.EMPTY.EMERGENCY_CONTACT_DESIGNATION
      : '',
  email: state.email ? validateEmailAddress(state.email) : '',
});

export const validateEmergencyContactInfo = (
  state: IEmergencyContactInfo,
  validateDesignation = false,
): IEmergencyContactValidationState => {
  const error = validateEmergencyContactElements(state, validateDesignation);
  return {
    error,
    isValid: isValidForm(error),
  };
};

interface ValidatePaymentCardDataFormProps {
  state: ICreditCardState;
  isCardOwnershipLabelEnabled: boolean;
}

export const validatePaymentCardDataForm = ({
  state,
  isCardOwnershipLabelEnabled,
}: ValidatePaymentCardDataFormProps): IPaymentValidationState => {
  const error = validatePaymentCardElements(state, isCardOwnershipLabelEnabled);
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validatePaymentCardCurrency = (currency?: SupportedBillingCurrencyKeys): ICurrencyValidationState => {
  if (!currency?.trim()) {
    return {
      error: errorMessages.traveler.EMPTY.CURRENCY_CODE,
      isValid: false,
    };
  }

  return {
    error: '',
    isValid: true,
  };
};

export const validatePaymentCardLabel = (state: ICreditCardLabelState): ILabelValidationState => {
  const { cardLabel, existingCardLabels } = state;
  let error = '';

  const getValidationState = (): ILabelValidationState => ({
    error,
    isValid: isValidForm(error),
  });
  if (!cardLabel.trim()) {
    error = errorMessages.traveler.EMPTY.PAYMENT_CARD_LABEL;
    return getValidationState();
  }

  if (
    !!existingCardLabels.length &&
    existingCardLabels.some((item) => item.toLowerCase().trim() === cardLabel.toLowerCase().trim())
  ) {
    error = errorMessages.traveler.DUPLICATE.PAYMENT_CARD_LABEL;

    return getValidationState();
  }

  return getValidationState();
};

export const validateLoyaltyCardNumber = (cardNumber: string, allowEmptyCardNumber = false): string => {
  if (allowEmptyCardNumber === true && cardNumber === '') {
    return '';
  }
  if (!validateRequiredField(cardNumber)) {
    return errorMessages.traveler.EMPTY.LOYALTY_CARD_ID;
  }
  if (!validateLeadingTrailingWhitespace(cardNumber)) {
    return errorMessages.common.LEADING_TRAILING_WHITESPACE;
  }
  if (!isAlphaNumeric(cardNumber)) {
    return errorMessages.traveler.INVALID_FORMAT.LOYALTY_CARD_ID;
  }
  if (cardNumber.length > 30) {
    return errorMessages.traveler.LENGTH.LOYALTY_CARD_ID;
  }
  return '';
};

const validateLoyaltyElements = <T extends LoyaltyInfo | LoyaltyInfoV2>(
  state: T,
): ILoyaltyValidationState['error'] => ({
  program: !validateRequiredField(state.issuedBy) ? errorMessages.traveler.EMPTY.LOYALTY_PROGRAM : '',
  cardNumber: validateLoyaltyCardNumber(state.id || ''),
});

export const validateLoyaltyFormData = <T extends LoyaltyInfo | LoyaltyInfoV2>(state: T): ILoyaltyValidationState => {
  const error = validateLoyaltyElements<T>(state);
  return {
    error,
    isValid: isValidForm(error),
  };
};

export const validateDateOfBirth = ({
  dob,
  paxType,
  lastFlightDepartureDateTime,
  tt,
}: {
  dob: string;
  paxType: PassengerTypeEnum;
  lastFlightDepartureDateTime: string | undefined;
  tt: TFunction;
}): string | boolean => {
  const dobDateUtil = dateUtil(dob);
  const referenceDateUtil = dateUtil(lastFlightDepartureDateTime);
  const age = referenceDateUtil.diff(dobDateUtil, 'years');

  switch (paxType) {
    case PassengerTypeEnum.INFANT:
    case PassengerTypeEnum.INFANT_ON_LAP:
      if (age >= 2) {
        return `${tt('Traveler must be under 24 months for the entire trip to qualify for an infant fare.')}`;
      }
      break;
    case PassengerTypeEnum.CHILD:
      if (age < 2) {
        return `${tt('Traveler must be 2 or older.')}`;
      }
      if (age >= 18) {
        return `${tt('Traveler must be under 18 for the entire trip to qualify for this fare.')}`;
      }
      break;
    case PassengerTypeEnum.ADULT:
      if (age < 18) {
        return `${tt('Traveler must be 18 or older.')}`;
      }
      break;
    default:
      break;
  }

  return true;
};

export const isValidAddressLineMaxLength = (addressLine: string): boolean =>
  addressLine.length <= maxAddressLengthAaAutoEnroll;

export const isValidDOBMinAge = (birthday: string): boolean => {
  const dobDateUtil = dateUtil(birthday);
  const currentDateUtil = dateUtil();

  const age = currentDateUtil.diff(dobDateUtil, 'years');

  return age > minPassengerAgeAaAutoEnroll;
};

export const validateRailCard = (selectedOption?: RailLoyaltySelectListItemProduct): string => {
  if (!selectedOption) {
    return '';
  }

  const programNumber = (selectedOption.cardNumber ?? '').trim();
  const cardNumberNotRequired = !selectedOption.isCardNumberRequired;
  const cardNumberLengthRule = selectedOption.numberLength;
  const cardNumberPrefixRule = selectedOption.numberPrefix;
  const cardNumberRegex = selectedOption.numberValidationRegex
    ? new RegExp(selectedOption.numberValidationRegex)
    : undefined;

  if (cardNumberNotRequired && !programNumber) {
    return '';
  }

  if (!!cardNumberLengthRule && programNumber.length !== cardNumberLengthRule) {
    return `The card number must be ${cardNumberLengthRule} characters.`;
  }

  if (!!cardNumberPrefixRule && !programNumber.startsWith(cardNumberPrefixRule)) {
    return `The card number must begin with ${cardNumberPrefixRule}`;
  }

  if (!!cardNumberRegex && !cardNumberRegex.test(programNumber)) {
    return 'The card number format is incorrect. Please check and try again.';
  }

  return '';
};
