import type {
  PolicyId,
  PolicyType,
  PolicyRuleType,
  PreventBooking,
  PolicyCategory,
  SplitPaymentInfo,
} from './policy_common';
import type {
  RestrictedContinents,
  RestrictedCountries,
  VariableMaxPrice,
  RestrictedAirlines,
  RestrictedAircrafts,
  MaxFlightBookingPriceByDuration,
  HighestFlightCabinByDuration,
  FlightSeatUpgrade,
  FlightCabinUpgrade,
  FlightAdvanceBookingWindow,
  FlightTicketsRefundable,
  FlightTicketsChangeable,
  HighestFlightCabinOvernight,
  DifferenceBetweenFlightFareAndMedianFare,
  Co2EmissionPerPassengerPerKm,
  MaxFlightBookingPrice,
  RestrictedHotels,
  MaxHotelPriceByLocation,
  HotelAdvanceBookingWindow,
  HotelCancellation,
  HotelRating,
  HotelRestrictedKeywordsProps,
  HotelChainCodes,
  HotelPaymentOptions,
  MaxCarPricePerNumberOfDays,
  AllowedCarTypes,
  CabinClassNotAllowedProps,
  AllowedCarTypesProps,
  HotelRateConditionsNotAllowedProps,
  RailAdvanceBookingWindowProps,
  MaxRailBookingPriceByDurationProps,
  HighestRailTravelClassByDurationProps,
  RailTicketsRefundableProps,
  LowestLogisticalFareProps,
  HotelMedianRateProps,
  LocationRestrictionsProps,
  FlightAdvanceBookingWindowProps,
  AllowedAirAddonsProps,
  CarEngineTypesProps,
  NotAllowedCarEngineTypes,
  MaxHotelBookingPriceProps,
} from './policy_rules';
import type { Persona, UserOrgId } from '../common/user_org_id';
import type { LegalEntityId, OfficeId } from '../common/organization';
import type { UserDefinedEntity } from './user_defined_entity';
import type { InternalPolicyType } from './internal_policy';
import type { TravelRegionType } from '../common/travel_region';
import { HrFeedPolicyDetails } from '@spotnana/types/openapi/models';

export interface Policy {
  policyId?: PolicyId;
  type: PolicyType;
  name: string;
  currency?: PolicyCurrency;
  /** @deprecated */
  approvalInfo?: PolicyApprovalInfo;
  usersGroups: PolicyUserGroup[];
  /** @deprecated */
  inPolicyApprovalInfo?: PolicyApprovalInfo;
  /** @deprecated */
  outOfPolicyApprovalInfo?: PolicyApprovalInfo;
  version: number;
  /**
   * When a restrictive policy is applied, if anything is out of policy due to it,
   * other policy results won't matter.
   */
  isRestrictive: boolean;
  rules: PolicyUniversalRule[];
  /**
   * OOP reason codes question in the policy
   * Different reason codes maybe present for different travel types.
   */
  airOopReasonCodes?: PolicyOopReasonCodes;
  hotelOopReasonCodes?: PolicyOopReasonCodes;
  carOopReasonCodes?: PolicyOopReasonCodes;
  railOopReasonCodes?: PolicyOopReasonCodes;
  /**
   * We now allow multiple approval info so that we can have different approval
   * info for different travel types.
   * Eg: For air we can have hard approval and for hotel, rail and car
   * we can have soft approval.
   */
  inPolicyApprovalInfos: PolicyApprovalInfo[];
  outOfPolicyApprovalInfos: PolicyApprovalInfo[];
  lowestLogicalFare?: PolicyLowestLogicalFare;
  /**
   * List of attributes/filters to find candidate hotels for calculating median
   * rate in hotel search.
   */
  hotelMedianRateNightly?: PolicyHotelMedianRate;

  /** Category for which the policy is configured. Each category here should have a default policy. */
  category: PolicyCategory;
  /** The parent policy of current policy where linked fields are derived from */
  parentPolicyId?: PolicyId;
  /** If true, the policy membership is determined via HR feed. (read only field - this is ignored in create/update requests) */
  isMembershipViaHrFeed: boolean;
  /** Details about the membership via HR feed. (read only field - this is ignored in create/update requests) */
  hrFeedPolicyDetails?: HrFeedPolicyDetails;
  /** Common Rules. */
  restrictedContinents?: RestrictedContinents;
  restrictedCountries?: RestrictedCountries;
  /** Variable max price can be used when values/fields are dynamic. */
  variableMaxPrice?: VariableMaxPrice;
  /** Air Specific Rules. */
  restrictedAirlines?: RestrictedAirlines;
  restrictedAircrafts?: RestrictedAircrafts;
  maxFlightBookingPriceByDuration?: MaxFlightBookingPriceByDuration;
  /** @deprecated */
  highestFlightCabinByDuration?: HighestFlightCabinByDuration;
  flightSeatUpgrade?: FlightSeatUpgrade;
  flightCabinUpgrade?: FlightCabinUpgrade;
  flightAdvanceBookingWindow?: FlightAdvanceBookingWindow;
  flightTicketsRefundable?: FlightTicketsRefundable;
  flightTicketsChangeable?: FlightTicketsChangeable;
  highestFlightCabinByDurationDomestic?: HighestFlightCabinByDuration;
  highestFlightCabinByDurationInternational?: HighestFlightCabinByDuration;
  highestFlightCabinOvernight?: HighestFlightCabinOvernight;
  /** Median fare was calculated based on number of stops , cabin class and flight duration. */
  differenceBetweenFlightFareAndMedianFare?: DifferenceBetweenFlightFareAndMedianFare;
  /** Total Co2 emission per passenger per km should be lesser or equal to this. */
  co2EmissionPerPassengerPerKm?: Co2EmissionPerPassengerPerKm;
  /** Max allowed flight booking price for that selected cabin. */
  maxFlightBookingPriceDomestic?: MaxFlightBookingPrice;
  maxFlightBookingPriceInternational?: MaxFlightBookingPrice;
  /** Hotel Specific Rules. */
  restrictedHotels?: RestrictedHotels;
  maxHotelPriceByLocation?: MaxHotelPriceByLocation;
  hotelAdvanceBookingWindow?: HotelAdvanceBookingWindow;
  hotelCancellation?: HotelCancellation;
  hotelRating?: HotelRating;
  hotelChainCodes?: HotelChainCodes;
  hotelPaymentOptions?: HotelPaymentOptions;
  /** Car Specific Rules. */
  maxCarPricePerNumberOfDays?: MaxCarPricePerNumberOfDays;
  allowedCarTypes?: AllowedCarTypes;
  notAllowedCarEngineTypes?: NotAllowedCarEngineTypes;
}

export interface PolicyCurrency {
  isLinked?: boolean | undefined;
  isoCode?: string | undefined;
}

export interface PolicyApprovalInfo {
  approvalProcessType: PolicyApprovalInfoApprovalProcessType;
  designatedApprovers: UserOrgId[];
  isManagerApprover: boolean;
  isEmployeeLevelDesignatedApprover?: boolean;
  shouldNotifyManager?: boolean;
  travelTypes: InternalPolicyType[];
  approvalConditions: PolicyApprovalInfoApprovalCondition[];
  defaultApprovers: UserOrgId[];
}

export enum PolicyApprovalInfoApprovalProcessType {
  UNKNOWN = 0,
  NONE = 1,
  SOFT_APPROVAL = 2,
  HARD_APPROVAL = 3,
  PASSIVE_APPROVAL = 4,
  PREVENT_BOOKING = 5,
  UNRECOGNIZED = -1,
}

export interface PolicyApprovalInfoApprovalCondition {
  approvalRules: PolicyApprovalInfoApprovalRule[];
}

export interface PolicyApprovalInfoApprovalRule {
  travelRegionRule?: PolicyApprovalInfoTravelRegionRule | undefined;
}

export interface PolicyApprovalInfoTravelRegionRule {
  travelRegionType: TravelRegionType;
}

export interface PolicyUserGroup {
  userOrgIds: UserOrgId[];
  legalEntityIds: LegalEntityId[];
  officeIds: OfficeId[];
  departments: string[];
  costCenters: string[];
  grades: string[];
  positionTitles: string[];
  personas: Persona[];
  countryCodes: string[];
  workerTypes: string[];
  accountingCodes: string[];
}

/** Out of policy reason codes */
export interface PolicyOopReasonCodes {
  isLinked: boolean | undefined;
  props?: PolicyOopReasonCodesProps | undefined;
}

export interface PolicyOopReasonCodesProps {
  /** UDEs each representing one question containing multiple reason codes as options. */
  reasonCodesUde: UserDefinedEntity[];
}

export interface PolicyLowestLogicalFare {
  isLinked: boolean | undefined;
  props?: LowestLogisticalFareProps | undefined;
}

export interface PolicyHotelMedianRate {
  isLinked?: boolean | undefined;
  props?: HotelMedianRateProps | undefined;
}

export interface PolicyUniversalRule {
  isLinked?: boolean | undefined;
  props?: PolicyUniversalRuleUniversalProps | undefined;
  ruleType: PolicyRuleType;
  preventBooking?: PreventBooking | undefined;
  splitPaymentInfo?: SplitPaymentInfo | undefined;
}

export interface PolicyUniversalRuleUniversalProps {
  cabinClassNotAllowedDomesticProps?: CabinClassNotAllowedProps | undefined;
  cabinClassNotAllowedInternationalProps?: CabinClassNotAllowedProps | undefined;
  carTypesNotAllowedProps?: AllowedCarTypesProps | undefined;
  hotelRateConditionsNotAllowedProps?: HotelRateConditionsNotAllowedProps | undefined;
  railAdvanceBookingWindowProps?: RailAdvanceBookingWindowProps | undefined;
  maxRailBookingPriceByDurationProps?: MaxRailBookingPriceByDurationProps | undefined;
  highestRailTravelClassByDurationProps?: HighestRailTravelClassByDurationProps | undefined;
  railTicketsRefundableProps?: RailTicketsRefundableProps | undefined;
  hotelRestrictedKeywordsProps?: HotelRestrictedKeywordsProps | undefined;
  /**
   * Location restrictions can be used to restrict booking for a specific location.
   * This is used for creating dynamic policies only.
   */
  locationRestrictionsProps?: LocationRestrictionsProps | undefined;
  flightAdvanceBookingWindowDomesticProps?: FlightAdvanceBookingWindowProps | undefined;
  flightAdvanceBookingWindowInternationalProps?: FlightAdvanceBookingWindowProps | undefined;
  /** Add-ons which can be booked on a central card as part of an air reservation. */
  allowedAirAddonsProps?: AllowedAirAddonsProps | undefined;
  /** Maximum allowed price for a hotel booking based on already calculated rate statistics such as median price. */
  maxHotelBookingPriceProps?: MaxHotelBookingPriceProps | undefined;
  /** Car engine types not allowed props is used to check if car engine type is allowed or not. */
  carEngineTypesNotAllowedProps?: CarEngineTypesProps | undefined;
}
