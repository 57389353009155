import { dateUtil } from '../../date-utils';
import { ISO_8601_DATE_ONLY_REGEXP } from '../constants';

// Workaround for the issue with converting dates for Asia/Singapore timezone before 1982 year
// The initial bug: ST-72733
// The react native issue: https://github.com/facebook/react-native/issues/46110
// TODO: Delete this code after the issue is fixed
export const adjustDateForTimezoneIssue = (date: string) => {
  let parsedDate = dateUtil(date);

  if (ISO_8601_DATE_ONLY_REGEXP.test(date) && parsedDate.year() < 1982) {
    parsedDate = parsedDate.add(30, 'minutes');
  }

  return parsedDate.toDate();
};
