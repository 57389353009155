/* eslint-disable no-restricted-syntax */
import { defaultQueryClient, defaultQueryClientOptions } from 'obt-common';
import interceptor from './lowLevelRequestsInterceptor';

declare global {
  interface Window {
    disableQueryCache: () => void;
    enableQueryCache: () => void;
    invalidateQueries: (keys: string[]) => Promise<void>;
  }
}

const INTERCEPTOR_LOCAL_STORAGE_KEY = 'use-low-level-interceptor';
const INTERCEPTOR_QUERY_PARAM_KEY = 'use-low-level-interceptor';

const initInterceptor = (): void => {
  try {
    if (shouldActivateInterceptor()) {
      interceptor.setup();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Cannot initialize low-level-interceptor, error:', err);
  }
};

const disableQueryCache = (): void => {
  defaultQueryClient.setDefaultOptions({
    ...defaultQueryClientOptions,
    queries: {
      ...defaultQueryClientOptions.queries,
      staleTime: 0,
    },
  });
};

const enableQueryCache = (): void => {
  defaultQueryClient.setDefaultOptions({
    ...defaultQueryClientOptions,
    queries: {
      ...defaultQueryClientOptions.queries,
      staleTime: Infinity,
    },
  });
};

const invalidateQueries = (keys: string[]): Promise<void> => defaultQueryClient.invalidateQueries(keys);

const setCacheFunctionsToWindow = () => {
  window.disableQueryCache = disableQueryCache;
  window.enableQueryCache = enableQueryCache;
  window.invalidateQueries = invalidateQueries;
};

export const shouldActivateInterceptor = () => {
  if (
    typeof window.localStorage === 'object' &&
    window.localStorage !== null &&
    typeof window.localStorage.setItem === 'function' &&
    typeof window.localStorage.getItem === 'function'
  ) {
    if (window.localStorage.getItem(INTERCEPTOR_LOCAL_STORAGE_KEY) === 'true') {
      setCacheFunctionsToWindow();
      return true;
    }

    if (window?.location?.search?.includes(`${INTERCEPTOR_QUERY_PARAM_KEY}=true`)) {
      window.localStorage.setItem(INTERCEPTOR_LOCAL_STORAGE_KEY, 'true');
      setCacheFunctionsToWindow();
      return true;
    }
  }

  return false;
};

export default initInterceptor;
