import { InternalBlocksTheme } from '../blocksTheme';
import {
  fontFamily as baseFm,
  fontWeight as baseFw,
  letterSpacingEm,
  remLineHeight as baseLh,
  remFontSize as baseFs,
  paraSpacingRem,
  listSpacing as baseListSpacing,
} from '../ux-specs/typography';

export type TTypeVariantConfig = {
  letterSpacing: string;
  fontWeight: Record<keyof typeof baseFw, number>;
  remFontSize: Record<keyof typeof baseFs, string>;
  remLineHeight: Record<keyof typeof baseLh, string>;
  paraSpacing: string;
  listSpacing: Record<keyof typeof baseListSpacing, string>;
};
/**
 * Object representation of Spotnana Typography Foundations: Type Styles
 * https://www.figma.com/file/8jiC4TAALE8kCj2oSc5thh/%F0%9F%8C%88-Design-System-2022?node-id=2108%3A34123&t=SR2ZJYmJ8iV8AZ5D-4
 *
 *  ******** 🚨🚨🚨 NOTE 🚨🚨🚨 *******
 *  The values of `default`, `regular`, `semibold`, etc (meta-variants) MUST always be CSSObject, no random properties
 *  that CSS can't understand. You can refer to how they are used in src/Typography/Typography.styles.ts
 */

const strikeoutCss = {
  display: 'inline-block',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    left: '-0.1em',
    right: '-0.1em',
    top: '0.38em',
    bottom: '0.38em',
    backgroundImage:
      'linear-gradient(to left top, transparent calc(50% - 0.063rem), currentColor calc(50% - 0.063rem), currentColor calc(50% + 0.063rem), transparent calc(50% + 0.063rem))',
  },
};

const typeVariantSpecBase = ({
  letterSpacing = letterSpacingEm,
  fontWeight = baseFw,
  remFontSize = baseFs,
  remLineHeight = baseLh,
  paraSpacing = paraSpacingRem,
  listSpacing = baseListSpacing,
}: TTypeVariantConfig) => ({
  letterSpacing,
  display: {
    default: {
      fontWeight: fontWeight.w600,
      fontSize: remFontSize.s48,
      lineHeight: remLineHeight.h60,
      letterSpacing,
    },
  },
  header1: {
    default: {
      fontWeight: fontWeight.w600,
      fontSize: remFontSize.s32,
      lineHeight: remLineHeight.h40,
      letterSpacing,
    },
  },
  header2: {
    default: {
      fontWeight: fontWeight.w600,
      fontSize: remFontSize.s28,
      lineHeight: remLineHeight.h36,
    },
    regular: { fontWeight: fontWeight.w400 },
  },
  header3: {
    default: {
      fontWeight: fontWeight.w600,
      fontSize: remFontSize.s24,
      lineHeight: remLineHeight.h30,
    },
    medium: { fontWeight: fontWeight.w500 },
    regular: { fontWeight: fontWeight.w400 },
  },
  header4: {
    default: {
      fontWeight: fontWeight.w600,
      fontSize: remFontSize.s20,
      lineHeight: remLineHeight.h28,
    },
    medium: { fontWeight: fontWeight.w500 },
    regular: { fontWeight: fontWeight.w400 },
    strikeout: strikeoutCss,
  },
  header5: {
    default: {
      fontWeight: fontWeight.w600,
      fontSize: remFontSize.s18,
      lineHeight: remLineHeight.h28,
    },
    regular: { fontWeight: fontWeight.w400 },
    medium: { fontWeight: fontWeight.w500 },
    strikeout: strikeoutCss,
  },
  body1: {
    paraSpacing,
    listSpacing: listSpacing.s8,
    default: {
      fontWeight: fontWeight.w400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    medium: { fontWeight: fontWeight.w500 },
    semibold: { fontWeight: fontWeight.w600 },
    strikeout: { fontWeight: fontWeight.w500, ...strikeoutCss },
  },
  body2: {
    paraSpacing,
    listSpacing: listSpacing.s8,
    default: {
      fontWeight: fontWeight.w400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    medium: { fontWeight: fontWeight.w500 },
    semibold: { fontWeight: fontWeight.w600 },
    strikeout: { fontWeight: fontWeight.w500, ...strikeoutCss },
  },
  body3: {
    paraSpacing,
    listSpacing: listSpacing.s4,
    default: {
      fontWeight: fontWeight.w400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    medium: { fontWeight: fontWeight.w500 },
    semibold: { fontWeight: fontWeight.w600 },
    strikeout: { fontWeight: fontWeight.w500, ...strikeoutCss },
  },
});

export const makeTypeVariantSpec = ({
  letterSpacing = letterSpacingEm,
  fontWeight = baseFw,
  remFontSize = baseFs,
  remLineHeight = baseLh,
  paraSpacing = paraSpacingRem,
  listSpacing = baseListSpacing,
}: Partial<TTypeVariantConfig> = {}) => {
  const base = typeVariantSpecBase({
    letterSpacing,
    fontWeight,
    remFontSize,
    remLineHeight,
    paraSpacing,
    listSpacing,
  });

  return Object.assign(base, {
    recipes: {
      label: { ...base.body2.default, ...base.body2.medium },
    },
  });
};

/**
 * Typography Object for MUI.
 *
 * We don't use MUI Typography component, but still provide it our default type styling to keep the
 * components that might internally use Typography theme variables consistent with our type design.
 *
 * We are adding Spotnana typography semantic tokens
 * Note: If a new typography semantic token is needed, contact UX so while adding the token UX docs get updated.
 *       No hard coded values should be present here, if new atomic token is needed then it requires UX approval.
 */
export const makeMuiTypographySpec = ({ fontWeight, typography, fontFamily = baseFm }: InternalBlocksTheme) => ({
  fontFamily: fontFamily.join(','),
  fontWeightRegular: fontWeight.w400,
  fontWeightMedium: fontWeight.w500,
  fontWeightBold: fontWeight.w600,
  display: typography.display.default,
  h1: typography.header1.default,
  h2: typography.header2.default,
  h3: typography.header3.default,
  h4: typography.header4.default,
  h5: typography.header5.default,
  h6: undefined,
  body1: typography.body1.default,
  body2: typography.body2.default,
  body3: typography.body3.default,
  caption: undefined,
  overline: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
});
