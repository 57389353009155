import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import React, { forwardRef, Ref, useId, useRef } from 'react';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { useScroll } from 'ahooks';
import { useMediaQuery } from '../utils/useMediaQuery';
import { breakpoints } from '../utils/styling';
import { clsx } from '../utils/classNames';
import { Typography } from '../Typography';
import { Button } from '../Button';

import {
  modal,
  modal_close_button_container,
  modal_close_button,
  modal_header,
  modal_content,
  modal_footer,
  loader,
} from './Modal.styles';
import { IconButton } from '../IconButton';
import { ModalContextProvider } from './ModalContext';
import { CircularProgress } from '../CircularProgress';

const blockName = 'BlocksModal';

const TransitionSlideUp = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type TModalHeaderConfig =
  | {
      title: string;
      subTitle?: string;
    }
  | {
      /**
       * @deprecated Leaving that as an escape hatch for code that is not easy to migrate to using title/subTitle
       */
      content: ReactNode;
    };
type TModalHeaderProps = TModalHeaderConfig & {
  id: string;
  hideCloseButton?: boolean;
};
export const ModalHeader: FC<TModalHeaderProps> = ({ id, hideCloseButton, ...props }) => {
  if ('content' in props) {
    return (
      <div css={modal_header} className={clsx(`${blockName}-header`, !hideCloseButton && 'with-hide-button')}>
        {typeof props.content === 'object'
          ? React.cloneElement(props.content as React.ReactElement, { id })
          : props.content}
      </div>
    );
  }

  const { title, subTitle } = props;

  if (!props.title) {
    return null;
  }

  return (
    <div css={modal_header} className={clsx(`${blockName}-header`, !hideCloseButton && 'with-hide-button')}>
      <Typography id={id} variant="header4" kind="semibold" className={`${blockName}-header-title`}>
        {title}
      </Typography>

      {subTitle && (
        <Typography variant="body2" color="secondary" className={`${blockName}-header-subTitle`}>
          {subTitle}
        </Typography>
      )}
    </div>
  );
};

type TModalActionConfig = {
  text: string;
  onClick: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
};
type TModalFooterConfig =
  | {
      primaryAction: TModalActionConfig;
      secondaryAction?: TModalActionConfig;
      columnStack?: boolean;
    }
  | {
      content: ReactNode;
    };
type TModalFooterProps = TModalFooterConfig & {
  fullWidth?: boolean;
  highlight?: boolean;
};
const ModalFooter: FC<TModalFooterProps> = (props) => {
  if ('content' in props) {
    return (
      <div css={modal_footer} className={clsx(`${blockName}-footer`, 'type-content', props.highlight && 'highlight')}>
        {props.content}
      </div>
    );
  }

  const { primaryAction, secondaryAction, fullWidth, highlight, columnStack } = props;

  return (
    <div
      css={modal_footer}
      className={clsx(
        `${blockName}-footer`,
        'type-actions',
        fullWidth && 'fullWidth',
        columnStack && 'stacking-column',
        highlight && 'highlight',
      )}
    >
      {!!secondaryAction && (
        <Button
          variant="secondary"
          onClick={secondaryAction.onClick}
          loading={secondaryAction.loading}
          disabled={secondaryAction.disabled}
          fullWidth={fullWidth}
        >
          {secondaryAction.text}
        </Button>
      )}

      <Button
        variant="primary"
        onClick={primaryAction.onClick}
        loading={primaryAction.loading}
        disabled={primaryAction.disabled}
        loadingText={primaryAction.loadingText}
        fullWidth={fullWidth}
      >
        {primaryAction.text}
      </Button>
    </div>
  );
};

const MODAL_CONTEXT_VALUE = {
  insideModal: true,
};

export interface TModalProps extends Omit<DialogProps, 'onClose' | 'css' | 'title'> {
  onClose?: VoidFunction;
  header: TModalHeaderConfig;
  hideHeader?: boolean;
  footer?: TModalFooterConfig;
  hideCloseButton?: boolean;
  /** @note: 'xlarge' variant is deprecated, defaults to 'large'  */
  variant?: 'medium' | 'large' | 'xlarge';
  contentVariant?: 'standard' | 'multicard';
  isLoading?: boolean;
}

export const Modal: FC<PropsWithChildren<TModalProps>> = ({
  hideCloseButton = false,
  onClose,
  header,
  hideHeader = false,
  footer,
  variant = 'large',
  contentVariant = 'standard',
  className,
  PaperProps,
  children,
  isLoading,
  ...restProps
}) => {
  const { t: tt } = useTranslation('WEB');
  const headerId = useId();
  const contentRef = useRef(null);
  const contentScroll = useScroll(contentRef);

  const isTabletPlus = useMediaQuery(breakpoints.from('tablet'));
  const highlightFooter = !!contentScroll?.top && contentScroll.top > 0;

  return (
    <ModalContextProvider value={MODAL_CONTEXT_VALUE}>
      <Dialog
        onClose={onClose}
        fullScreen={!isTabletPlus}
        TransitionComponent={TransitionSlideUp}
        keepMounted
        css={modal}
        className={clsx(`${blockName}-root`, className)}
        PaperProps={{
          ...PaperProps,
          className: clsx(`${blockName}-paper`, `variant-${variant}`, PaperProps?.className),
          'aria-modal': true,
          'aria-labelledby': headerId,
        }}
        {...restProps}
      >
        {isLoading && (
          <div css={loader} data-testid="loader-container">
            <CircularProgress size={48} />
          </div>
        )}
        {!hideCloseButton && (
          <div css={modal_close_button_container}>
            <IconButton
              icon="CloseCross"
              size="large"
              tooltip={tt('Close')}
              onClick={onClose}
              css={modal_close_button}
            />
          </div>
        )}

        {!hideHeader && <ModalHeader {...header} id={headerId} hideCloseButton={hideCloseButton} />}
        {children && (
          <DialogContent
            ref={contentRef}
            css={modal_content}
            className={clsx(`${blockName}-content`, `variant-${contentVariant}`, !footer && 'no-footer')}
          >
            {children}
          </DialogContent>
        )}

        {footer && (
          <ModalFooter {...footer} fullWidth={variant === 'medium' || !isTabletPlus} highlight={highlightFooter} />
        )}
      </Dialog>
    </ModalContextProvider>
  );
};
