import { IconButton } from '@spotnana/blocks/src/IconButton';
import { useModalState } from '@spotnana/blocks/src/Modal';
import { useHasUserAccess, useJSONFeatureFlag, userRolesByFeature } from 'obt-common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShareableFareListsForLoggedInUser } from 'src/app/flights/SavedFaresPage/hooks/useShareableListsForLoggedInUser';
import { ShareItineraryListDrawer } from 'src/app/flights/SavedFaresPage/ShareItineraryListDrawer';

export const HeaderSavedFareListButton: FC = () => {
  const { open, closeModal, openModal } = useModalState();
  const { t: tt } = useTranslation('WEB');
  const { loggedInUserFareList, deleteFareList, removeFareFromFareList } = useShareableFareListsForLoggedInUser();
  const isShareItineraryEnabled = useJSONFeatureFlag('FE_AIR_SHARE_FARE_QUOTE_V2')?.enabled;
  const hasArrangerAndAboveAccess = useHasUserAccess(userRolesByFeature.travelerArrangerAndAbove);

  if (!isShareItineraryEnabled) {
    return null;
  }

  if (!hasArrangerAndAboveAccess) {
    return null;
  }
  return (
    <div>
      <IconButton
        icon="CompanyList"
        onClick={openModal}
        tooltip={tt('Saved itineraries')}
        variant="rounded"
        data-testid="saved_itineraries_icon_button"
      />
      <ShareItineraryListDrawer
        currentLists={loggedInUserFareList}
        deleteFareList={deleteFareList}
        onClose={closeModal}
        open={open}
        removeFareFromFareList={removeFareFromFareList}
      />
    </div>
  );
};
