import { useId } from 'react';
import { TAutocompleteOption } from '../../../Autocomplete/Autocomplete.types';
import { CircularProgress } from '../../../CircularProgress';
import { InputBase, InputHelperText } from '../../../Input';
import { input_placeholder_text } from '../../../Input/Input.styles';
import { useSelectConfig } from '../../../SelectRoot/SelectConfigContext';
import { autocomplete_loading_multiple, pseudo_input_content } from '../../../SelectRoot/SelectRoot.styles';
import { Typography } from '../../../Typography';
import { flex } from '../../../utils';
import { unfocused_input_container } from './UnfocusedInput.styles';

interface IUnfocusedInputProps<TValue extends object> {
  /**
   * selectedOptions: All currently selected options across searches
   */
  selectedOptions: TAutocompleteOption<TValue>[];
  /**
   * props required by InputBase passed from combobox which contains event handlers
   */
  inputProps: Omit<React.HTMLAttributes<HTMLElement>, 'color'> & { disabled?: boolean };
  /**
   * Content to be displayed. Candidate for slot. If not provided, just comma separated option.value would be displayed
   */
  UnfocusedInputContent?: React.FC<{ selectedOptions: TAutocompleteOption<TValue>[] }>;
  /**
   * Start `InputAdornment` for this component.
   */
  startAdornment?: React.ReactElement;
  /**
   * End `InputAdornment` for this component.
   */
  endAdornment?: React.ReactElement;
}

/**
 * This component is rendered when the user's focus is not on the Input.
 * If no selectedOptions, then show placeholder. Else show content as provided.
 * @aviral, we'll need to push UnfocusedInputContent into slots maybe?
 */
export function UnfocusedInput<TValue extends object>({
  selectedOptions,
  inputProps,
  UnfocusedInputContent,
  startAdornment,
  endAdornment,
}: IUnfocusedInputProps<TValue>) {
  const { placeholder, labelId, state, showLoader, helperText } = useSelectConfig<'combobox'>();
  const descriptionId = useId();

  const unfocusedContentId = `${descriptionId}-unfocused-content`;
  const helperTextId = `${descriptionId}-helper-text`;

  const ariaProps: React.AriaAttributes = {
    'aria-labelledby': labelId,
  };

  // add aria-placeholder if we are hiding the original one
  if (!startAdornment) {
    // extra `.` are added to give a pause, else screen-reader announces the placeholder directly after the label
    ariaProps['aria-placeholder'] = `... ${placeholder}`;
  }

  const descriptions = [
    unfocusedContentId,
    // if help-text is available, add aria-describedby
    helperText ? helperTextId : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  // add described by aria prop, to announce the unfocused content as well as help-text
  ariaProps['aria-describedby'] = descriptions;

  return (
    <>
      <InputBase
        inputProps={{
          ...inputProps,
          ...ariaProps,
          value: '',
          className: '',
          'aria-invalid': state === 'error' ? true : undefined,
        }}
        data-testid={`${labelId}_input_unfocused`}
        disabled={inputProps.disabled}
        placeholder={startAdornment ? placeholder : ''}
        startAdornment={startAdornment}
        endAdornment={
          endAdornment ?? (
            <div css={[pseudo_input_content, flex.justify.between]} id={unfocusedContentId}>
              {selectedOptions.length === 0 ? (
                !startAdornment && (
                  <Typography
                    aria-hidden="true"
                    variant="body1"
                    color="tertiary"
                    className="UnfocusedInputContainer"
                    css={input_placeholder_text}
                  >
                    {placeholder}
                  </Typography>
                )
              ) : (
                <div css={unfocused_input_container} className="UnfocusedInputContainer">
                  {UnfocusedInputContent ? (
                    <UnfocusedInputContent selectedOptions={selectedOptions} />
                  ) : (
                    selectedOptions.map((option) => option.label).join(',')
                  )}
                </div>
              )}
              {showLoader && <CircularProgress size={20} css={autocomplete_loading_multiple} />}
            </div>
          )
        }
        fullWidth
        value=""
        state={state}
      />
      <InputHelperText id={helperTextId} state={state} message={helperText} />
    </>
  );
}
