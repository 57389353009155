import { ComponentsProps } from '@mui/material';

import type { Theme, CSSObject, ComponentsOverrides } from '@mui/material';
import type { TButtonProps } from './Button';

/**
 * Atomic Button configuration for consumers to be able to conform to the design system
 * even if they need to create custom components that don't use Blocks directly.
 *
 * Please do not alter these values without reading design system docs
 * and understanding how auto-scaling works in terms of rem-to-px conversion.
 *
 * TODO: Link exact docs url here once docs are ready
 */
export const blocksConfig_Button: {
  sizing: Record<
    NonNullable<TButtonProps['size']>,
    {
      paddingY: number;
      paddingX: number;
      height: number;
      fontSize: number;
      borderRadiusPx: number;
    }
  >;
} = {
  sizing: {
    large: {
      paddingY: 0,
      paddingX: 1.5,
      height: 3.5,
      fontSize: 1,
      borderRadiusPx: 8,
    },
    medium: {
      paddingY: 0,
      paddingX: 1,
      height: 3,
      fontSize: 1,
      borderRadiusPx: 8,
    },
    small: {
      paddingY: 0,
      paddingX: 0.75,
      height: 2,
      fontSize: 0.875,
      borderRadiusPx: 6,
    },
  },
};

export const muiDefaultProps_Button: ComponentsProps['MuiButton'] = {
  type: 'button',
  size: 'medium',
  /** This is internal MUI variant name, is not exposed or used to consumers of Blocks */
  variant: 'contained',
  disableElevation: true,
};

type ButtonStyleOverrides = ComponentsOverrides<Theme>['MuiButton'];
export const muiStyleOverrides_Button: ButtonStyleOverrides = {
  root: ({ ownerState }) => ({
    textTransform: 'none',
    fontWeight: 600,
    ...getSizeBasedStyles(ownerState.size || 'medium'),
  }),
};

function getSizeBasedStyles(size: NonNullable<TButtonProps['size']>): CSSObject {
  return {
    paddingBottom: `${blocksConfig_Button.sizing[size].paddingY}rem`,
    paddingTop: `${blocksConfig_Button.sizing[size].paddingY}rem`,
    paddingLeft: `${blocksConfig_Button.sizing[size].paddingX}rem`,
    paddingRight: `${blocksConfig_Button.sizing[size].paddingX}rem`,
    height: `${blocksConfig_Button.sizing[size].height}rem`,
    fontSize: `${blocksConfig_Button.sizing[size].fontSize}rem`,
    borderRadius: `${blocksConfig_Button.sizing[size].borderRadiusPx}px`,
  };
}

interface VariantColorMap {
  variant: TButtonProps['variant'];
  color: string;
  paletteMode: 'light' | 'dark';
}
export const getColorAndVariant = ({ variant, color, paletteMode }: VariantColorMap) => {
  if (paletteMode === 'dark') {
    if (variant === 'secondary') {
      return { variant: 'primary' as TButtonProps['variant'], color: 'gray' };
    }
    if (variant === 'ghost' && color === 'primary') {
      return { variant: 'ghost' as TButtonProps['variant'], color: 'blue' };
    }
  }

  return { variant, color };
};
