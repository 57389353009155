import mapValues from 'lodash/mapValues';
import { useLoggedInUser } from '../providers/AuthProvider';
import { useFetchCompanyBasicInfo } from '../queries/admin/index';
import type { FeatureConfig } from '../types/api/v2/obt/model/feature-config';
import { FeatureNameEnum } from '../types/api/v2/obt/model/feature-name-enum';

export const FEATURE_FLAG_RECORD = {
  flightOOP: 'FLIGHT_RESULTS_OOP',
  hotelOOP: 'HOTEL_RESULTS_OOP',
  railOOP: 'RAIL_RESULTS_OOP',
  carOOP: 'CARS_RESULTS_OOP',
  paymentAddCard: 'PAYMENT_ADD_CARD',
  menuItemPolicy: 'MENU_ITEM_POLICIES',
  travelerSelection: 'TRAVELER_SELECTION',
  personalTravel: 'FEATURE_PERSONAL_TRAVEL',
  railBooking: 'PRODUCT_OBT_RAIL',
  menuItemCompanyRemarks: 'MENU_ITEM_COMPANY_REMARKS',
  homePageAddUser: 'HOMEPAGE_ADD_USER',
  menuItemCustomFields: 'MENU_ITEM_CUSTOM_FIELDS',
  paymentUnusedCredits: 'PAYMENT_UNUSED_CREDITS',
  emailUpdateCompanyAdmin: 'EMAIL_UPDATE_BY_COMPANY_ADMIN',
  nonprofileBookingSearchPage: 'ADHOC_BOOKING_SEARCH_PAGE',
  nonprofileBookingSaveTraveler: 'ADHOC_BOOKING_SAVE_TRAVELER',
  adminVirtualCardFeatureEnabled: 'ADMIN_VIRTUAL_CARD',
  centralCardServiceFeeFeatureEnabled: 'CENTRAL_CARD_SERVICE_FEE',
  hideSearchCriteria: 'HIDE_SEARCH_CRITERIA',
  serviceChargeConfigDisabled: 'SERVICE_CHARGE_CONFIG_DISABLED',
  hideCompanySettingMenu: 'HIDE_SETTINGS_MENU_ITEM_COMPANY',
  hideUserSettingMenu: 'HIDE_SETTINGS_MENU_ITEM_USERS',
  hidePolicyApprovalSection: 'HIDE_POLICY_APPROVAL_SECTION',
  hidePolicyCreation: 'HIDE_POLICY_CREATION',
  hideStarRatingRelatedPolicy: 'HIDE_POLICY_HOTEL_STAR_RATING',
  externalEventBookingUrl: 'EXTERNAL_EVENT_BOOKING_URL',
  hideSettingsText: 'HIDE_SETTINGS_TEXT_FROM_MENU',
  hideLeftNav: 'HIDE_LEFT_NAV',
  hidePolicyAirCarrier: 'HIDE_POLICY_AIR_CARRIER',
  hidePolicyAirCO2: 'HIDE_POLICY_AIR_CO2',
  hidePolicyHotelPriceLocationCustomisation: 'HIDE_POLICY_HOTEL_PRICE_LOCATION_CUSTOMISATION',
  hideProfilePreferencesAirlines: 'HIDE_PROFILE_PREFERENCES_AIRLINES',
  hideProfilePreferencesAlliances: 'HIDE_PROFILE_PREFERENCES_ALLIANCES',
  hideAir: 'PRODUCT_OBT_AIR_DISABLED',
  hideHotel: 'PRODUCT_OBT_HOTEL_DISABLED',
  hideCar: 'PRODUCT_OBT_CAR_DISABLED',
  hideLimo: 'PRODUCT_OBT_LIMO_DISABLED',
  hideConcierge: 'PRODUCT_OBT_CONCIERGE_DISABLED',
  eventBookingEnabled: 'EVENT_BOOKING_ENABLED',
  employeeLevelDesignatedApproverEnabled: 'EMPLOYEE_LEVEL_DESIGNATED_APPROVER_ENABLED',
  hideLeftNavEmbed: 'HIDE_LEFT_NAV_EMBED',
  hideGuestBookingSetting: FeatureNameEnum.HideGuestBookingSetting,
  userCreationPersonaTypeOptions: FeatureNameEnum.UserCreationPersonaTypeOptions,
  showServiceFeeTax: FeatureNameEnum.ShowServiceFeeTaxes,
  hideSherpaVisaWidget: 'HIDE_SHERPA_VISA_WIDGET',
  hideSupplierManagement: 'HIDE_SUPPLIER_MANAGEMENT',
  hideBookingRestrictionsByCountry: 'HIDE_BOOKING_RESTRICTIONS_BY_COUNTRY',
  editingDefaultPoliciesDisabled: 'EDITING_DEFAULT_POLICIES_DISABLED',
  preferredParentChainsHidden: 'HIDE_PREFERRED_PARENT_CHAINS',
  preferredHotelBrandsHidden: 'HIDE_PREFERRED_HOTEL_BRANDS',
  roomsOptionsAndStayPreferencesHidden: 'HIDE_ROOM_OPTIONS_AND_STAY_PREFERENCES',
  conditionalRatesPreferencesHidden: 'HIDE_CONDITIONAL_RATES_PREFERENCES',
  hideHotelsInSupplierManagement: 'HIDE_HOTELS_IN_SUPPLIER_MANAGEMENT',
  hideHotelBrandsInSupplierManagement: 'HIDE_HOTEL_BRANDS_IN_SUPPLIER_MANAGEMENT',
  defaultHotelSearchRadiusConfig: 'DEFAULT_HOTEL_SEARCH_RADIUS_CONFIG',
  linkedMarriottBonvoyRequired: 'LINKED_MARRIOTT_BONVOY_REQUIRED',
  homePageOrder: 'PRODUCT_OBT_HOMEPAGE_ORDER',
  hideCompanySupplierMenu: 'HIDE_COMPANY_SUPPLIER_MENU',
  hideIdleSessionTimeout: 'HIDE_IDLE_SESSION_TIMEOUT',
  policyConfiguredInHrFeed: 'POLICY_CONFIGURED_IN_HR_FEED',
};

type PartnerFeatureName = keyof typeof FEATURE_FLAG_RECORD;
export type PartnerFeatures = {
  features: Record<PartnerFeatureName, boolean>;
  featureValues: Record<Partial<PartnerFeatureName>, string> | Record<string, never>;
  isLoading: boolean;
};

export const DEFAULT_FEATURES: PartnerFeatures['features'] = {
  flightOOP: true,
  hotelOOP: true,
  railOOP: true,
  carOOP: true,
  paymentAddCard: true,
  menuItemPolicy: true,
  travelerSelection: true,
  personalTravel: true,
  railBooking: true,
  menuItemCompanyRemarks: true,
  homePageAddUser: true,
  menuItemCustomFields: true,
  paymentUnusedCredits: true,
  emailUpdateCompanyAdmin: true,
  nonprofileBookingSearchPage: false,
  nonprofileBookingSaveTraveler: false,
  adminVirtualCardFeatureEnabled: false,
  centralCardServiceFeeFeatureEnabled: false,
  hideSearchCriteria: false,
  serviceChargeConfigDisabled: false,
  hideCompanySettingMenu: false,
  hideUserSettingMenu: false,
  hidePolicyApprovalSection: false,
  hidePolicyCreation: false,
  hideStarRatingRelatedPolicy: false,
  hideSettingsText: false,
  hideLeftNav: false,
  hidePolicyAirCarrier: false,
  hidePolicyAirCO2: false,
  hidePolicyHotelPriceLocationCustomisation: false,
  hideProfilePreferencesAirlines: false,
  hideProfilePreferencesAlliances: false,
  hideAir: false,
  hideHotel: false,
  hideCar: false,
  hideLimo: false,
  hideConcierge: false,
  eventBookingEnabled: false,
  employeeLevelDesignatedApproverEnabled: false,
  hideLeftNavEmbed: false,
  hideGuestBookingSetting: false,
  userCreationPersonaTypeOptions: false,
  showServiceFeeTax: false,
  hideSherpaVisaWidget: false,
  hideSupplierManagement: false,
  hideBookingRestrictionsByCountry: false,
  editingDefaultPoliciesDisabled: false,
  linkedMarriottBonvoyRequired: false,
  preferredParentChainsHidden: false,
  preferredHotelBrandsHidden: false,
  roomsOptionsAndStayPreferencesHidden: false,
  conditionalRatesPreferencesHidden: false,
  externalEventBookingUrl: false,
  hideHotelsInSupplierManagement: false,
  defaultHotelSearchRadiusConfig: false,
  hideHotelBrandsInSupplierManagement: false,
  homePageOrder: false,
  hideCompanySupplierMenu: false,
  hideIdleSessionTimeout: false,
  policyConfiguredInHrFeed: false,
};

const isEnabled = (features: FeatureConfig[], name: string, partnerFeatureName: PartnerFeatureName): boolean => {
  const feature = features.find((element) => element.name === name);

  return (feature?.value ? feature?.value === 'true' : DEFAULT_FEATURES[partnerFeatureName]) ?? true;
};

const getValue = (features: FeatureConfig[], name: string): string => {
  const feature = features.find((element) => element.name === name);

  return feature?.value ?? '';
};

const usePartnerFeatures = ({ enabled = true } = {}): PartnerFeatures => {
  const { data: loggedInUser } = useLoggedInUser();
  const { data: basicinfo, isLoading } = useFetchCompanyBasicInfo(
    loggedInUser?.userOrgId?.organizationId?.id,
    enabled,
    undefined,
  );

  const features = basicinfo?.features;
  if (!features) {
    return { features: DEFAULT_FEATURES, featureValues: {}, isLoading };
  }

  return {
    features: mapValues(FEATURE_FLAG_RECORD, (featureFlag, featureFlagName) =>
      isEnabled(features, featureFlag, featureFlagName as PartnerFeatureName),
    ),
    featureValues: mapValues(FEATURE_FLAG_RECORD, (featureFlag) => getValue(features, featureFlag)),
    isLoading,
  };
};

export default usePartnerFeatures;
