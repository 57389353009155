import { jsx } from '@emotion/react';
import React, { Ref } from 'react';
import { clsx } from '../utils/classNames';
import { typography_css } from './Typography.styles';
import { BlocksTheme } from '../types';

/*
 *
 * We are not using MUI Typography component as it doesn't provide constructs to
 * express Spotnana Typography variants easily. Instead of a lot of custom programming
 * and styling overrides over MUI for a very simple component like Typography, we choose
 * to write our own clean & type-strict implementation.
 *
 */
export type AsHTMLTags =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'a'
  | 'label'
  | 'legend'
  | 'span'
  | 'abbr'
  | 'sub'
  | 'sup'
  | 'small'
  | 'strong'
  | 'em'
  | 'li'
  | 'div'
  | 'dt'
  | 'dd'
  | 'button';

export type TypoVariants =
  | 'display'
  | 'header1'
  | 'header2'
  | 'header3'
  | 'header4'
  | 'header5'
  | 'body1'
  | 'body2'
  | 'body3';

type TMapVariantToTag = Record<TypoVariants, AsHTMLTags>;

type KindMap = {
  header2: 'regular';
  header3: 'regular' | 'medium';
  header4: 'strikeout' | 'regular' | 'medium';
  header5: 'strikeout' | 'regular' | 'medium';
  body1: 'strikeout' | 'semibold' | 'medium';
  body2: 'strikeout' | 'semibold' | 'medium';
  body3: 'strikeout' | 'semibold' | 'medium';
};

type MakeVariantProps<Variant extends TypoVariants> = {
  variant: Variant;
  as?: AsHTMLTags;
  kind?: Variant extends keyof KindMap ? KindMap[Variant] : never;
};

/**
 * Type representation of Spotnana Typography Foundations: Type Styles
 * They are expressed as individual types not only to allow for any `as` prop, and to limit "kind" prop based on which "variant" it is,
 * but also to allow for adding easy specific variant-only props to any variant in the future.
 * https://www.figma.com/file/8jiC4TAALE8kCj2oSc5thh/%F0%9F%8C%88-Design-System-2022?node-id=2108%3A34123&t=SR2ZJYmJ8iV8AZ5D-4
 */

type TBaseProps = {
  children: React.ReactNode;
  color?: keyof BlocksTheme['palette']['text'];
};

export type TTypographyProps<As extends AsHTMLTags, Variant extends TypoVariants> = TBaseProps &
  MakeVariantProps<Variant> &
  React.HTMLAttributes<As extends 'a' ? HTMLAnchorElement : HTMLElement> & {
    ref?: Ref<HTMLElement>;
  };

const mapVariantToTag: Readonly<TMapVariantToTag> = Object.freeze({
  display: 'h1',
  header1: 'h1',
  header2: 'h2',
  header3: 'h3',
  header4: 'h4',
  header5: 'h5',
  body1: 'p',
  body2: 'p',
  body3: 'p',
});

const TypographyInternal = <As extends AsHTMLTags, Variant extends TypoVariants>(
  { children, as, kind, className, variant, color = 'primary', ...props }: TTypographyProps<As, Variant>,
  ref: React.Ref<HTMLElement>,
): JSX.Element => {
  return jsx(
    as || mapVariantToTag[variant] || 'span',
    {
      ...props,
      ref,
      css: typography_css,
      className: clsx(
        'BlocksTypography',
        className,
        `BlocksTypography-${variant}`,
        kind && `MetaVariant-${kind}`,
        `color-${color}`,
      ),
    },
    children,
  );
};

export const Typography = React.forwardRef(TypographyInternal);

export const typographyRecipes: {
  label: Pick<TTypographyProps<'label', 'body2'>, 'variant' | 'as' | 'kind'>;
} = {
  label: {
    variant: 'body2',
    as: 'label',
    kind: 'medium',
  },
};
