import * as React from 'react';
import { useTranslation } from 'react-i18next';
import first from 'lodash/first';
import last from 'lodash/last';

import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import Tooltip from '@spotnana/pixel-react/dist/Tooltip';
import { Typography } from '@spotnana/blocks/src/Typography';
import { breakpoints } from '@spotnana/blocks/src/utils/styling';
import { useMediaQuery } from '@spotnana/blocks/src/utils';

import {
  airlinesMap,
  AirSearchResponseManager,
  dateUtils,
  getAirlinesSubText,
  hasAirportChange,
  ICarbonEmissionsConfig,
  IFlightLegSummary,
  localizeTime,
  useFeatureFlag,
} from 'obt-common';

import { AirlineImage } from 'src/v2-components/flights/shared/AirlineImage';
import { IOBTShopFeaturesConfig } from 'obt-common/hooks/useOBTShopFeatures';
import { SerializedStyles } from '@emotion/react';
import {
  AirlineInfo,
  AirportCode,
  AirportName,
  Container,
  DetailsButton,
  DotSeparatorContainer,
  FlightNumberInfo,
  FlightStopContainer,
  SeparatorLeft,
  SeparatorRight,
  content_container,
  flight_stop_container,
  flight_time_container_left,
  flight_time_container_right,
  flight_time_typography,
  flight_airport_change,
  leg_duration_typography,
  leg_stops_typography,
  airline_info,
  ContentContainer,
} from './styles';
import Dot from '../../../../v1-components/shared/Dot';
import { Co2EmissionLabel } from '../FlightListItemSummary/Co2EmissionLabel';

const { getDateChangeIndicator, getDateDiff } = dateUtils;

interface IProps {
  summary: IFlightLegSummary;
  onFlightDetailsClick: (event: React.MouseEvent<Element>) => void;
  showFlightDetails: boolean;
  flightNumbersDisplayText: string;
  totalCo2Emission: ICarbonEmissionsConfig | null;
  airSearchResultsFeatures: IOBTShopFeaturesConfig;
  contentContainerCss?: SerializedStyles;
}

interface IFlightStopInfo {
  time: string;
  airportCode: string;
  airportName: string;
  dateChangeIndicator?: string;
  alignRight?: boolean;
}

const FlightStopInfo: React.FC<IFlightStopInfo> = ({
  time,
  airportCode,
  airportName,
  dateChangeIndicator = '',
  alignRight = false,
}) => {
  const { t: tt } = useTranslation('WEB');
  const isTablet = useMediaQuery(breakpoints.from('tablet'));

  return (
    <FlightStopContainer
      css={[flight_stop_container, alignRight ? flight_time_container_right : flight_time_container_left]}
    >
      <Typography
        variant={isTablet ? 'body1' : 'body2'}
        kind="semibold"
        data-testid="flight-time"
        role="heading"
        css={flight_time_typography}
      >
        {time}
        {dateChangeIndicator && (
          <Tooltip arrow title={tt('Next day')}>
            <Typography variant={isTablet ? 'body2' : 'body3'} kind="semibold" as="sup">
              {dateChangeIndicator}
            </Typography>
          </Tooltip>
        )}
      </Typography>

      <Typography variant="body2" data-testid="airport-code">
        <AirportCode>{airportCode}</AirportCode>
      </Typography>

      {isTablet && (
        <Typography variant="body2">
          <AirportName title={airportName} data-testid="airport-name">
            {airportName}
          </AirportName>
        </Typography>
      )}
    </FlightStopContainer>
  );
};

type FlightConnectorProps = {
  duration: string;
  children?: React.ReactNode;
};

const FlightConnector = ({ duration, children }: FlightConnectorProps): JSX.Element => (
  <Box minWidth="145px" flex="1" flexShrink={0} px={1}>
    <Flex alignItems="center" justifyContent="space-between">
      <SeparatorLeft data-testid="separator-left" />
      <Typography variant="body2" color="secondary" css={leg_duration_typography} data-testid="leg_trip-duration">
        {duration}
      </Typography>
      <SeparatorRight data-testid="separator-right" />
    </Flex>

    {children}
  </Box>
);

function FlightInfo({
  onFlightDetailsClick,
  summary,
  flightNumbersDisplayText,
  totalCo2Emission,
  airSearchResultsFeatures,
  showFlightDetails,
  contentContainerCss,
}: IProps): JSX.Element {
  const carbonEmissionsEnabled = useFeatureFlag('FE_CARBON_EMISSIONS_AIR_SEARCH');
  const hasCo2Emission = !!totalCo2Emission;
  const showCo2Emission = !!carbonEmissionsEnabled && hasCo2Emission;

  const { t: tCommon } = useTranslation('COMMON');
  const { t: tt } = useTranslation('WEB');

  const { departureDateTime, arrivalDateTime, airports, airlines, layovers, legDuration } = summary;

  const firstFlightMarketingAirline = first(airlines)?.marketing.airline ?? '';
  const airlinesSubtext = getAirlinesSubText(airlines);
  const multiStopInfo = AirSearchResponseManager.getStopsInfoForFlightResult(airports, layovers);

  const departureTime = localizeTime(departureDateTime, 'short') ?? '';
  const departureAirport = first(airports);
  const departureAirportCode = departureAirport?.code ?? '';
  const departureAirportName = departureAirport?.name ?? '';

  const arrivalTime = localizeTime(arrivalDateTime, 'short') ?? '';
  const arrivalAirport = last(airports);
  const arrivalAirportCode = arrivalAirport?.code ?? '';
  const arrivalAirportName = arrivalAirport?.name ?? '';

  const dateChange = departureDateTime && arrivalDateTime ? getDateDiff(departureDateTime, arrivalDateTime) : 0;
  const isNextDay = dateChange !== 0;
  const dateChangeIndicator = isNextDay ? getDateChangeIndicator(dateChange) : '';

  const multiStopInfoElement = multiStopInfo ? (
    <Typography variant="body2" color="secondary" css={leg_stops_typography} data-testid="leg_stops">
      {tCommon(multiStopInfo)}
    </Typography>
  ) : null;

  return (
    <Container>
      <Tooltip
        variation="light"
        placement="top"
        maxWidth="none"
        title={<Typography variant="body2">{airlinesMap[firstFlightMarketingAirline]}</Typography>}
      >
        <AirlineImage marketingAirline={firstFlightMarketingAirline} data-testid="leg_airline-logo" />
      </Tooltip>

      <ContentContainer css={contentContainerCss || content_container}>
        {showCo2Emission && (
          <Co2EmissionLabel totalCo2Emission={totalCo2Emission} airSearchResultsFeatures={airSearchResultsFeatures} />
        )}

        <Flex>
          <FlightStopInfo time={departureTime} airportCode={departureAirportCode} airportName={departureAirportName} />

          <FlightConnector duration={legDuration}>
            {hasAirportChange(summary) ? (
              <Box textAlign="center">
                <Typography variant="body2" color="secondary" css={flight_airport_change}>
                  {tt('Airport change')}
                </Typography>
              </Box>
            ) : (
              multiStopInfoElement
            )}
          </FlightConnector>

          <FlightStopInfo
            time={arrivalTime}
            airportCode={arrivalAirportCode}
            airportName={arrivalAirportName}
            dateChangeIndicator={dateChangeIndicator}
            alignRight
          />
        </Flex>
        <Flex justifyContent="space-between" mt="12px">
          <Flex alignItems="center">
            <Typography variant="body2" kind="semibold">
              <FlightNumberInfo>{flightNumbersDisplayText}</FlightNumberInfo>
            </Typography>
            {flightNumbersDisplayText && (
              <DotSeparatorContainer>
                <Dot small light />
              </DotSeparatorContainer>
            )}
            <Typography variant="body2" css={airline_info}>
              <AirlineInfo title={airlinesSubtext} data-testid="airline_subtext">
                {airlinesSubtext}
              </AirlineInfo>
            </Typography>
          </Flex>
          {showFlightDetails && (
            <DetailsButton variation="tertiary" onClick={onFlightDetailsClick} data-testid="leg-details">
              {tt('Details')}
            </DetailsButton>
          )}
        </Flex>
      </ContentContainer>
    </Container>
  );
}

export default FlightInfo;
