export interface IClientIdUserPoolId {
  clientId: string;
  userPoolId: string;
}

export interface IAccessRefreshTokens {
  access_token: string;
  refresh_token: string;
}

export interface ITokens {
  access_token: string;
  refresh_token: string;
  id_token: string;
}

export enum IAuthError {
  NotAuthorizedException = 'NotAuthorizedException',
}

export enum IAuthWorkflowType {
  GENERIC = 'GENERIC',
  TOKEN_EXCHANGE = 'TOKEN_EXCHANGE',
}

export type IAuthWorkflow =
  | { type: IAuthWorkflowType.GENERIC }
  | { type: IAuthWorkflowType.TOKEN_EXCHANGE; initiateTokenExchange: () => Promise<IAccessRefreshTokens> };

declare global {
  interface Window {
    genesysAuthCode: string;
  }
}
