import { assign, createMachine } from 'xstate';
import type { RazorpayErrorData, RazorpaySuccessData } from '../../constants';
import { CreatePnrStatus, RAZORPAY_REDIRECT_STATUS } from '../../constants';
import { airCreatePnr, airRevalidateItinerary } from '../../queries/flight';
import { getCreatePnrRequestV2 } from '../../transformers/flights';
import type {
  BeginBookingEvent,
  ContextAfterInitialAction,
  ContextAfterRevalidateItinerary,
  FlightBookingContext,
  FlightBookingEvents,
  FlightBookingTypeState,
  SuccessCreatePnrEvent,
} from './types';
import { UserAgent } from '../../types/common';
import { MoneyUtil } from '../../utils/Money';
import { HoldStatusEnum } from '../../types/api/v1/obt/trip/air_create_pnr_request';
import { AirSearchRequestBookingType } from '../../types/api/v1/obt/air/air_search_request';
import type { ConfirmAfterRazorpayAuth, SuccessRevalidateItineraryEvent } from '../shared/types';
import isFareChange from '../shared/guards/isFareChange';
import isEmptyRevalidateItineraryResponse from '../shared/guards/isEmptyRevalidateItineraryResponse';
import isBookingFailedError from '../shared/guards/isBookingFailedError';
import isFareExpiredError from '../shared/guards/isFareExpiredError';

const defaultContext: FlightBookingContext = {
  isTestBooking: false,
  userAgent: UserAgent.WEB,
  ancillaryResponseId: '',
  seatMapResponseId: '',
  travelers: [],
  attemptNo: 0,
  bookingCharges: [],
  bookingType: AirSearchRequestBookingType.NORMAL_BOOKING,
};

const setEventValuesToContext = (event: BeginBookingEvent) => ({
  tripId: event.tripId,
  oldTotalFare: event.totalFare,
  preBookAnswers: event.preBookAnswers,
  ancillaryResponseId: event.ancillaryResponseId,
  seatMapResponseId: event.seatMapResponseId,
  travelers: event.travelers,
  attemptNo: event.attemptNo,
  bookingCharges: event.bookingCharges,
  bookingType: event.bookingType,
});

/**
 * Use https://stately.ai/viz/9600e479-b839-4e3c-a144-24b052c67d84 to visualize the below state machine
 * (Please update the above if any changes are done to the machine)
 */
const flightBookingMachine = createMachine<FlightBookingContext, FlightBookingEvents, FlightBookingTypeState>(
  {
    predictableActionArguments: true,
    id: 'flightBooking',
    initial: 'idle',
    context: defaultContext,
    states: {
      idle: {
        meta: {
          message: 'Initial setup context',
        },
        on: {
          BEGIN_BOOKING: [
            {
              target: 'revalidateItinerary',
              actions: assign((_context, event) => setEventValuesToContext(event)),
            },
          ],
          BEGIN_BOOKING_HOLD: [
            {
              target: 'idle',
              actions: assign((_context, event) => ({ holdBookingDeadline: event.deadline })),
            },
          ],
        },
      },
      revalidateItinerary: {
        tags: ['loading'],
        meta: {
          message: 'Run revalidate itinerary BE request',
        },
        invoke: {
          id: 'airRevalidateItinerary',
          src: 'revalidateItineraryService',
          onDone: [
            {
              target: 'failure',
              cond: 'isEmptyRevalidateItineraryResponse',
              actions: assign({
                // Known issue
                // Assign action behaving strangely:https://xstate.js.org/docs/guides/typescript.html#troubleshooting
                error: (_context, _event) => new Error('Revalidate itinerary response is empty'),
              }),
            },
            {
              cond: 'isFareChange',
              target: 'fareChanged',
              actions: assign((_context, event) => ({
                revalidateItineraryResponse: event.data,
              })),
            },
            {
              target: 'createPnr',
              actions: assign({
                revalidateItineraryResponse: (_context, event) => event.data,
              }),
            },
          ],
          onError: [
            {
              cond: 'isFareExpiredError',
              target: 'fareExpired',
            },
            {
              cond: 'isBookingFailedError',
              target: 'bookingFailed',
              actions: assign({ error: (_context, event) => event.data }),
            },
            {
              target: 'failure',
              actions: assign({ error: (_context, event) => event.data }),
            },
          ],
        },
        exit: assign((_context, event) => ({
          attemptNo:
            (event as SuccessRevalidateItineraryEvent).data !== null &&
            (event as SuccessRevalidateItineraryEvent).data.attemptNo !== undefined
              ? (event as SuccessRevalidateItineraryEvent).data.attemptNo + 1
              : undefined,
        })),
      },
      fareExpired: {
        type: 'final',
        meta: {
          message: 'Revalidate itinerary failed with error fare expired',
        },
      },
      bookingFailed: {
        on: {
          ON_CANCEL_BOOKING_FAILED: {
            target: 'idle',
            actions: assign({ error: (_context, _event) => undefined }),
          },
        },
        meta: {
          message: 'Revalidate itinerary failed with error booking failed',
        },
      },
      fareChanged: {
        tags: ['loading'],
        meta: {
          message: 'Revalidate itinerary success but total fare was changed',
        },
        on: {
          CONFIRM_NEW_REVALIDATE_FIRE: {
            target: 'revalidateItinerary',
            actions: assign((context) => ({
              oldTotalFare: MoneyUtil.parse(context.revalidateItineraryResponse?.totalFare),
            })),
          },
          CANCEL_NEW_FARE: {
            target: 'idle',
          },
        },
      },
      createPnr: {
        tags: ['loading'],
        invoke: {
          id: 'airCreatePnr',
          src: 'airCreatePnrService',
          onDone: [
            {
              cond: 'isRequired3dSecure',
              target: '3dSecure',
              actions: assign({
                ccVerificationUrl: (_, event) => (event as SuccessCreatePnrEvent)?.data?.ccVerificationUrl,
                pnrNumber: (_, event) => (event as SuccessCreatePnrEvent)?.data?.pnrNumber,
                pnrId: (_, event) => (event as SuccessCreatePnrEvent)?.data?.pnrId,
                pnrStatus: (_, event) => (event as SuccessCreatePnrEvent)?.data?.pnrStatus,
                multiSourceBookingInfo: (_, event) => (event as SuccessCreatePnrEvent)?.data?.multiSourceBookingInfo,
              }),
            },
            {
              cond: 'isRequired3dSecure2',
              target: '3dSecure2',
              actions: assign({
                redirectUrl: (_, event) =>
                  (event as SuccessCreatePnrEvent)?.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfo[0].redirectUrl,
                cardId: (_, event) =>
                  (event as SuccessCreatePnrEvent)?.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfo[0].cardId,
                sessionId: (_, event) =>
                  (event as SuccessCreatePnrEvent)?.data.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfo[0].sessionId,
              }),
            },
            {
              cond: 'isRequiredRazorPayAuth',
              target: 'razorPayAuth',
              actions: assign({
                paymentAuthUrl: (_, event) =>
                  (event as SuccessCreatePnrEvent)?.data?.paymentVerificationInfo?.razorpayVerificationInfo
                    ?.paymentAuthUrl,
              }),
            },
            {
              target: 'success',
              actions: assign({
                pnrNumber: (_, event) => (event as SuccessCreatePnrEvent)?.data?.pnrNumber,
                pnrId: (_, event) => (event as SuccessCreatePnrEvent)?.data?.pnrId,
                pnrStatus: (_, event) => (event as SuccessCreatePnrEvent)?.data?.pnrStatus,
                multiSourceBookingInfo: (_, event) => (event as SuccessCreatePnrEvent)?.data?.multiSourceBookingInfo,
              }),
            },
          ],
          onError: [
            {
              cond: 'isFareExpiredError',
              target: 'fareExpired',
            },
            {
              cond: 'isBookingFailedError',
              target: 'bookingFailed',
            },
            {
              target: 'failure',
              actions: assign({ error: (_context, event) => event.data }),
            },
          ],
        },
      },
      failure: {
        on: {
          BEGIN_BOOKING: [
            {
              target: 'revalidateItinerary',
              actions: assign((_context, event) => ({
                error: undefined, // Reset error
                ...setEventValuesToContext(event),
              })),
            },
          ],
          ON_CANCEL_BOOKING_FAILED: {
            target: 'idle',
            actions: assign({ error: (_context, _event) => undefined }),
          },
        },
        meta: {
          message: 'The flight booking failed',
        },
      },
      '3dSecure': {
        type: 'final',
        meta: {
          message: 'Need to redirect to 3d secure page (or open WebView on mobile)',
        },
      },
      '3dSecure2': {
        meta: {
          message: 'Need to redirect to 3d secure 2 page (or open WebView on mobile)',
        },
        on: {
          CONFIRM_PNR: [
            {
              target: 'createPnr',
              actions: assign({
                is3ds2Success: (_, event) => event?.is3ds2Success,
              }),
            },
          ],
        },
      },
      razorPayAuthFailed: {
        type: 'final',
        meta: {
          message: 'RazorPay authentication failed',
        },
      },
      razorPayAuth: {
        meta: {
          message: 'Need to redirect to RazorPay auth page',
        },
        on: {
          CONFIRM_AFTER_RAZORPAY_AUTH: [
            {
              cond: 'isRazorPayAuthSuccess',
              target: 'createPnr',
              actions: assign({
                razorPayAuthResponse: (_, event) => event?.razorPayAuthResponse,
                razorPayAuthStatus: (_, event) => event?.razorPayAuthStatus,
                paymentAuthUrl: (_context, _event) => '',
              }),
            },
            {
              cond: 'isRazorPayAuthFailed',
              target: 'razorPayAuthFailed',
              actions: assign({
                error: (_context, event) => {
                  return new Error((event.razorPayAuthResponse as RazorpayErrorData).description);
                },
              }),
            },
          ],
          CANCEL_RAZORPAY_AUTH: [
            {
              target: 'idle',
              actions: assign({
                paymentAuthUrl: '',
              }),
            },
          ],
        },
      },
      success: {
        type: 'final',
        meta: {
          message: 'The flight booking succeeded',
        },
      },
    },
  },
  {
    services: {
      revalidateItineraryService: (context, _event) => {
        const typedContext = context as ContextAfterInitialAction;
        return airRevalidateItinerary({
          ancillaryResponseId: typedContext.ancillaryResponseId,
          seatMapResponseId: typedContext.seatMapResponseId,
          travelers: typedContext.travelers,
          doNotShareEmailAndPhone: typedContext?.doNotShareEmailAndPhone,
          tripId: typedContext.tripId,
          attemptNo: typedContext?.attemptNo,
          otherServiceInfos: [],
          bookingCharges: typedContext.bookingCharges,
          waiverCode: '',
          ticketDesignator: '',
          bookingType: typedContext.bookingType,
          qcEnabled: false,
          pnrRemarks: [],
          isIntermediate: false,
        });
      },
      airCreatePnrService: async (context, _event) => {
        const typedContext = context as ContextAfterRevalidateItinerary;

        const {
          holdBookingDeadline: holdDateTimeDeadline,
          is3ds2Success,
          cardId,
          sessionId,
          razorPayAuthResponse,
          razorPayAuthStatus,
        } = context;

        let postPaymentVerificationInfo;
        if (cardId && sessionId && is3ds2Success) {
          postPaymentVerificationInfo = {
            threeDSecure2PostVerificationInfo: {
              result: [{ cardId, sessionId, success: is3ds2Success }],
            },
          };
        } else if (razorPayAuthResponse && razorPayAuthStatus === RAZORPAY_REDIRECT_STATUS.SUCCESS) {
          postPaymentVerificationInfo = {
            razorpayPostVerificationInfo: {
              paymentId: (razorPayAuthResponse as RazorpaySuccessData).paymentId,
              orderId: (razorPayAuthResponse as RazorpaySuccessData).orderId,
              signature: (razorPayAuthResponse as RazorpaySuccessData).signature,
            },
          };
        }

        const createPnrRequest = getCreatePnrRequestV2({
          bookingId: typedContext.revalidateItineraryResponse.bookingId,
          trip: typedContext.tripId.id,
          isTestBooking: typedContext.isTestBooking,
          userAgent: typedContext.userAgent,
          postPaymentVerificationInfo,
        });
        if (holdDateTimeDeadline) {
          createPnrRequest.holdInfo = {
            holdStatus: HoldStatusEnum.REQUESTED,
          };
        }

        const result = await airCreatePnr(createPnrRequest);

        return result;
      },
    },
    guards: {
      isRequired3dSecure: (_context, event): boolean => {
        const typedEvent = event as SuccessCreatePnrEvent;
        return (
          typedEvent?.data?.pnrStatus === CreatePnrStatus.CC_VERIFICATION_REQUIRED &&
          !!typedEvent?.data?.ccVerificationUrl
        );
      },
      isRequired3dSecure2: (_context, event): boolean => {
        const typedEvent = event as SuccessCreatePnrEvent;
        return (
          typedEvent.data?.pnrStatus === CreatePnrStatus.CC_VERIFICATION_REQUIRED &&
          !!typedEvent.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo?.verificationInfo[0]
        );
      },
      isRequiredRazorPayAuth: (_context, event): boolean => {
        const typedEvent = event as SuccessCreatePnrEvent;
        return !!typedEvent.data?.paymentVerificationInfo?.razorpayVerificationInfo;
      },
      isRazorPayAuthSuccess: (_context, event): boolean => {
        const typedEvent = event as ConfirmAfterRazorpayAuth;
        return typedEvent.razorPayAuthStatus === RAZORPAY_REDIRECT_STATUS.SUCCESS;
      },
      isRazorPayAuthFailed: (_context, event): boolean => {
        const typedEvent = event as ConfirmAfterRazorpayAuth;
        return typedEvent.razorPayAuthStatus === RAZORPAY_REDIRECT_STATUS.FAILED;
      },
      isFareExpiredError,
      isBookingFailedError,
      isEmptyRevalidateItineraryResponse,
      isFareChange,
    },
  },
);

const mobileContext: FlightBookingContext = {
  ...defaultContext,
  userAgent: UserAgent.MOBILE,
};

const webContext: FlightBookingContext = {
  ...defaultContext,
  userAgent: UserAgent.WEB,
};

export const mobileFlightBookingMachine = flightBookingMachine.withContext(mobileContext);

export const webFlightBookingMachine = flightBookingMachine.withContext(webContext);
