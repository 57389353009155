import { IconButton } from '@spotnana/blocks/src/IconButton';
import { FC } from 'react';
import { Typography } from '@spotnana/blocks/src/Typography';
import { useTranslation } from 'react-i18next';
import { header_container, container } from './styles';

export enum ShareItineraryHeaderVariant {
  FARE_LIST = 'FARE_LIST',
  FARE_LIST_FARES = 'FARE_LIST_FARES',
}

interface IProps {
  onClose: VoidFunction;
  onRemoveFareList: VoidFunction;
  variant: ShareItineraryHeaderVariant;
  travelerName: string;
}

export const ShareItineraryHeader: FC<IProps> = ({ onClose, travelerName, onRemoveFareList, variant }) => {
  const { t: tt } = useTranslation('WEB');
  return (
    <div css={header_container}>
      <IconButton onClick={onClose} icon="CloseCross" data-testid="close_drawer_icon_button" />
      <div css={container}>
        <Typography variant="header4">
          {variant === ShareItineraryHeaderVariant.FARE_LIST
            ? tt('Saved itineraries')
            : tt('Itineraries for {{travelerName}}', { travelerName })}
        </Typography>
      </div>
      {variant === ShareItineraryHeaderVariant.FARE_LIST_FARES && (
        <IconButton onClick={onRemoveFareList} icon="Delete" data-testid="delete_fare_list_icon_button" />
      )}
    </div>
  );
};
