import produce from 'immer';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import type { VirtualCardMetadata } from '../../types/api/v2/obt/model/virtual-card-metadata';
import {
  airlinesMap,
  carTypeToNameMapper,
  dateFormats,
  DUMMY_CONFIRMATION_NUMBER_FOR_INCOMPLETE_OUTSIDE_BOOKINGS,
} from '../../constants';
import {
  getDateDiff,
  getDateTimeDiff,
  getDurationInDaysOrHours,
  getDurationMinutes,
  isBefore,
  isFutureDate,
  isSame,
  minutesToDurationString,
} from '../../date-utils';
import type {
  CustomFieldLocation,
  DateTimeLocal,
  PnrDetailsResponseWithId,
  PreferenceV2,
  PreferredTypeV2,
  SourceInfo,
  TripV3DetailsResponse,
  UserProfile,
} from '../../types';
import { BedType, BookingFlowEnum, Cabin, PnrStatus, PNRType } from '../../types';
import type { PaxInfoFareComponentLegInfo } from '../../types/api/v1/obt/air/air_search_response';
import type { IItineraryAirline } from '../../types/flight';
import type {
  ITripPnr,
  ITripV3FlightDetail,
  PaymentSourceBreakdownLine,
  TripV3Info,
  TripV3SplittedPnr,
} from '../../types/trip';
import { TripCategoryPath } from '../../types/trip';
import type { GetTripDetailsResponse } from '../../types/api/v1/obt/trip/trip_details';
import { Persona } from '../../types/api/v2/obt/model/persona';
import type { PnrTraveler } from '../../types/api/v2/obt/model/pnr-traveler';
import { UserFacingStatus } from '../../types/api/v2/obt/model/user-facing-status';
import {
  getBedTypeLabel,
  getHotelCancellationPolicyText,
  getLocationFullAddressV2,
  getNameStringFromName,
  getProfileDisplayText,
  MoneyUtil,
} from '../../utils';
import type {
  GetTripDetailsDataParams,
  PnrBookingDetails,
  TripDetailsCardCommonData,
  TripDetailsData,
  TripDetailsMiscCardData,
  TripPnrTravelData,
  PnrLeastLogicalFareInfo,
  PnrFilterOptions,
} from './types';
import { getVendorPreferencesV2 } from '../../utils/policies';
import HotelDetailsManager from '../HotelDetailsManager';
import V2TripDetailsResponseManager from '../V2TripDetailsResponseManager';
import type { Document } from '../../types/api/v2/obt/model/document';
import { PaymentV2ToV1Mapper } from '../../types/api/v2/obt/model/payment-method';
import type { Money } from '../../types/api/v1/common/money';
import { CreatedVia } from '../../types/api/v2/obt/model/created-via';
import { LimoPnrV3Manager } from '../pnr/limo/LimoPnrV3Manager';
import { RailPnrV3Manager } from '../pnr/rail/RailPnrV3Manager';
import { AirPnrV3Manager } from '../pnr/air/AirPnrV3Manager';
import { CarPnrV3Manager } from '../pnr/car/CarPnrV3Manager';
import { HotelPnrV3Manager } from '../pnr/hotel/HotelPnrV3Manager';
import { PnrV3Manager } from '../pnr/PnrV3Manager';
import { getPnrTravelSegments, isRoundTrip } from './utils';

const USER_FACING_CANCELLED_STATUSES: UserFacingStatus[] = [
  UserFacingStatus.CancelledStatus,
  UserFacingStatus.VoidedStatus,
];
const USER_FACING_CANCELLED_AND_CANCELLING_STATUSES: UserFacingStatus[] = [
  ...USER_FACING_CANCELLED_STATUSES,
  UserFacingStatus.CancellationInProgressStatus,
];
const USER_FACING_COMPLETED_STATUSES: UserFacingStatus[] = [
  UserFacingStatus.CompletedStatus,
  UserFacingStatus.RefundedStatus,
];
const PNR_CANCELLED_STATUSES: PnrStatus[] = [PnrStatus.Cancelled, PnrStatus.CancelledByVendor, PnrStatus.Voided];
const PNR_PENDING_STATUSES: PnrStatus[] = [PnrStatus.Pending, PnrStatus.Requested, PnrStatus.RequestPending];
const SHELL_PNR_PENDING_STATUSES: PnrStatus[] = [PnrStatus.Confirmed, PnrStatus.Pending];

export default class TripV3DetailsResponseManager {
  private readonly splittedPnrs: TripV3SplittedPnr[];

  private allPnrsTravelerUserProfiles: UserProfile[] = [];

  constructor(readonly response: TripV3DetailsResponse) {
    if (!response) {
      throw new Error('Invalid Trip Details Response passed to TripV3DetailsResponseManager');
    }

    this.splittedPnrs = this.GetSplittedPnrs(this.response.pnrs);
  }

  // ------ PRIVATE METHODS ------
  // prev getPersonaFromPnr
  public getPersonaFromPnrIndex(index = 0): Persona | undefined {
    return first(this.response.pnrs[index]?.data?.pnrTravelers)?.persona;
  }

  private getAllPrimaryTravellersPersonas(): Array<Persona | undefined> {
    return this.response.pnrs.map((_, i) => this.getPersonaFromPnrIndex(i));
  }

  private GetSplittedPnrs(pnrs: PnrDetailsResponseWithId[]): TripV3SplittedPnr[] {
    return pnrs.flatMap((pnr) => {
      let splitPnrs: TripV3SplittedPnr[] = [pnr];
      if (pnr.data?.airPnr) {
        splitPnrs = TripV3DetailsResponseManager.SplitAirPnrIfNeeded(pnr);
      } else if (pnr.data?.railPnr) {
        splitPnrs = TripV3DetailsResponseManager.SplitRailPnrIfNeeded(pnr);
      } else if (pnr.data?.limoPnr) {
        splitPnrs = TripV3DetailsResponseManager.SplitLimoPnrIfNeeded(pnr);
      }
      return splitPnrs;
    });
  }

  private isLastFlightOvernight(pnrs: TripV3SplittedPnr[]): boolean {
    const lastPnr = last(pnrs);
    const airPnr = lastPnr?.data?.airPnr;
    if (!airPnr) {
      return false;
    }

    const firstFlight = first(first(airPnr.legs)?.flights);
    const departureDateTime = firstFlight?.departureDateTime?.iso8601 ?? '';

    const lastFlight = last(first(airPnr.legs)?.flights);
    const arrivalDateTime = lastFlight?.arrivalDateTime?.iso8601 ?? '';

    const arrivalDateChange =
      departureDateTime && arrivalDateTime ? getDateDiff(departureDateTime, arrivalDateTime) : 0;

    const isOvernight = arrivalDateChange > 0;
    return isOvernight;
  }

  // ------ MIGRATED !!! ------
  public GetTripId(): string {
    return this.response.basicTripInfo.tripId ?? '';
  }

  public GetTripName(): string {
    return this.response.basicTripInfo.tripName ?? '';
  }

  public GetTripDescription(): string {
    return this.response.basicTripInfo.tripDescription ?? '';
  }

  public GetTripStartDate(): string {
    return this.response.basicTripInfo?.startDate?.iso8601 ?? '';
  }

  public GetTripEndDate(): string {
    return this.response.basicTripInfo?.endDate?.iso8601 ?? '';
  }

  public GetTripStartDateWithYear(): DateTimeLocal | undefined {
    return this.response.basicTripInfo?.startDate;
  }

  public GetTripEndDateWithYear(): DateTimeLocal | undefined {
    return this.response.basicTripInfo?.endDate;
  }

  public GetEventId(): string {
    return this.response.eventSummary?.id ?? '';
  }

  public GetTripEstimatedTotal() {
    return MoneyUtil.convertV2MoneyToMoneyUtil(this.response.tripPaymentInfo?.totalFare);
  }

  public GetTripPnrApprovalStatus(pnrIndex: number): UserFacingStatus | undefined {
    const pnr = this.GetSortedPnrDetails();
    return pnr[pnrIndex].data?.bookingStatus;
  }

  public GetAllFlightPnrs({ returnCancelledPNRs = false }: { returnCancelledPNRs?: boolean }): TripV3SplittedPnr[] {
    const pnrs = this.GetSortedPnrDetails();
    return pnrs.filter((pnr) => {
      const isAirPNR = TripV3DetailsResponseManager.GetPnrType(pnr) === PNRType.AIR;
      // If Air Pnr
      // If returnCancelledPNRs === true, return all cancelled
      // If returnCancelledPNRs === false, check if pnr is not in cancel state
      return isAirPNR && (returnCancelledPNRs || !TripV3DetailsResponseManager.IsPnrCancelled(pnr));
    });
  }

  public GetAllHotelPnrs(): TripV3SplittedPnr[] {
    const pnrs = this.GetSortedPnrDetails();
    return pnrs.filter((pnr) => TripV3DetailsResponseManager.GetPnrType(pnr) === PNRType.HOTEL);
  }

  public GetAllCarPnrs(): TripV3SplittedPnr[] {
    const pnrs = this.GetSortedPnrDetails();
    return pnrs.filter((pnr) => TripV3DetailsResponseManager.GetPnrType(pnr) === PNRType.CAR);
  }

  public GetPnrTravelerNames(pnrIndex: number): string[] {
    const pnr = this.GetSortedPnrDetails();
    const travelers = pnr[pnrIndex].data?.pnrTravelers?.map((traveler) =>
      getNameStringFromName(traveler.personalInfo?.name),
    );

    return travelers ?? [];
  }

  public GetPnrTravelerNamesByPnrId(pnrId: string): string[] {
    const pnr = this.getPnrById(pnrId);
    const travelers = pnr?.data?.pnrTravelers?.map((traveler) => getNameStringFromName(traveler.personalInfo?.name));

    return travelers ?? [];
  }

  public GetTripTravelerNames(): string[] {
    const travelers = this.response.pnrs.flatMap((pnr) => {
      const pnrTravelers = pnr.data?.pnrTravelers?.map((traveler) =>
        getNameStringFromName(traveler.personalInfo?.name),
      );
      return pnrTravelers ?? [];
    });
    const uniqueTravelers = [...new Set(travelers)].filter(Boolean);
    return uniqueTravelers;
  }

  public GetMiscTripDetails(pnrIndex: number) {
    const pnrs = this.GetSortedPnrDetails();
    const pnr = pnrs[pnrIndex];
    const miscInfo = pnr.data?.miscPnr;
    const duration = getDurationInDaysOrHours(
      miscInfo?.startDateTime.iso8601 ?? '',
      miscInfo?.endDateTime.iso8601 ?? '',
    );
    const bookingStatus = pnr.data?.bookingStatus;
    const isPnrOutOfPolicy = this.isTripPnrOutOfPolicy(pnrIndex);
    return {
      description: miscInfo?.description ?? '',
      startDate: miscInfo?.startDateTime,
      endDate: miscInfo?.endDateTime,
      confirmationNumber: miscInfo?.vendorConfirmationNumber ?? '',
      bookingId: pnr.pnrId ?? '',
      duration,
      bookingStatus,
      isPnrOutOfPolicy,
    };
  }

  public GetTripFlightDetails(pnrIndex: number) {
    const pnrs = this.GetSortedPnrDetails();
    const pnr = pnrs[pnrIndex];
    const { data: pnrData, pnrId } = pnr;
    const defaultsNoData = {
      legsDetails: [],
      travelerDetails: [],
      pnrId: '',
      legId: '',
    };

    if (!pnrData || !pnrId) {
      return defaultsNoData;
    }

    return new AirPnrV3Manager({ pnrData, pnrId }).airDetails();
  }

  public GetTripHotelDetails(pnrIndex: number) {
    const pnrs = this.GetSortedPnrDetails();
    const pnr = pnrs[pnrIndex];
    const hotelPnr = pnr.data?.hotelPnr;
    const bookingStatus = pnr.data?.bookingStatus;
    const isPnrOutOfPolicy = this.isTripPnrOutOfPolicy(pnrIndex);

    const startDate = hotelPnr?.checkInDateTime;
    const endDate = hotelPnr?.checkOutDateTime;
    const cancellationPolicy = hotelPnr?.room.cancellationPolicy
      ? getHotelCancellationPolicyText(hotelPnr?.room.cancellationPolicy)
      : '';
    const phoneNumber = {
      rawInput: hotelPnr?.hotelInfo.phone?.rawInput,
      countryCode: hotelPnr?.hotelInfo.phone?.countryCode,
    };

    const hotelDetails = {
      name: hotelPnr?.hotelInfo.name ?? '',
      chainName: hotelPnr?.hotelInfo.chainName ?? '',
      confirmationNumber: hotelPnr?.vendorConfirmationNumber ?? '',
      bookingId: pnr.pnrId ?? '',
      numberOfRooms: hotelPnr?.numberOfRooms.toString() ?? '',
      roomType: hotelPnr?.room.roomName ?? '',
      bedType: getBedTypeLabel(hotelPnr?.room.bedType ?? BedType.UnknownBedType) ?? '',
      checkIn: hotelPnr?.checkInDateTime,
      checkOut: hotelPnr?.checkOutDateTime,
      duration: `${HotelDetailsManager.GetTotalNights(
        hotelPnr?.checkInDateTime.iso8601 ?? '',
        hotelPnr?.checkOutDateTime.iso8601 ?? '',
      )}d`,
      contactDetails: {
        address: getLocationFullAddressV2(hotelPnr?.hotelInfo.address),
        phoneNumber,
        fax: first(hotelPnr?.hotelInfo.fax)?.rawInput ?? '',
        latLng: hotelPnr?.hotelInfo.coordinates,
      },
      cancellationPolicy,
      co2EmissionsValue: hotelPnr?.room.co2EmissionDetail?.co2EmissionValue ?? 0,
      bookingStatus,
      isPnrOutOfPolicy,
      specialRequests: hotelPnr?.hotelSpecialRequests,
      chainCode: hotelPnr?.hotelInfo.chainCode,
    };

    const passengers = pnr.data?.pnrTravelers?.map((pnrTraveler) => ({
      name: pnrTraveler.personalInfo?.name,
      id: pnrTraveler.userId.id,
    }));
    const passengerDetails = hotelPnr?.travelerInfos?.map((travelerInfo) => {
      const requiredPassenger = passengers?.find((passenger) => passenger.id === travelerInfo.userId?.id);
      return {
        name: getNameStringFromName(requiredPassenger?.name),
        loyaltyNumber: travelerInfo.loyaltyInfos?.map((l) => l.id).join(', '),
      };
    });

    return {
      startDate,
      endDate,
      hotelDetails,
      passengerDetails,
      pnrId: pnr.pnrId ?? '',
    };
  }

  public GetTripRailDetails(pnrIndex: number) {
    const pnrs = this.GetSortedPnrDetails();
    const { data: pnrData, pnrId } = pnrs[pnrIndex];
    const railDetailsDefaultNoData = {
      startDate: undefined,
      endDate: undefined,
      bookingStatus: undefined,
      railDetails: [
        {
          railwayDetails: {
            carrierName: '',
            timetableId: '',
            fareType: '',
            bookingId: '',
            co2Emissions: 0,
            collectionReference: undefined,
            carrierConfirmation: '',
            vehicleType: '',
            sourceConfirmation: '',
          },
          origin: { date: undefined, city: '' },
          destination: { date: undefined, city: '' },
          duration: '',
          layover: undefined,
          vendorName: '',
          passengerDetails: [{ name: '', loyaltyNumber: '', seatNumber: '', cardName: '' }],
          isOutOfPolicy: false,
        },
      ],
    };
    return pnrData && pnrId
      ? new RailPnrV3Manager({ pnrData, pnrId }).railDetailsAfterSplit()
      : railDetailsDefaultNoData;
  }

  public GetTripCarDetails(pnrIndex: number) {
    const pnrs = this.GetSortedPnrDetails();
    const pnr = pnrs[pnrIndex];
    const carPnr = pnr.data?.carPnr;
    const carInfo = carPnr?.carInfo;
    const vendorName = carInfo?.vendor.name;
    const vendorCode = carInfo?.vendor.code;
    const carName = carTypeToNameMapper(carInfo?.carTypeCode) ?? carPnr?.carInfo?.carSpec?.displayName;
    const carSpec = carInfo?.carSpec;

    const confirmationNumber = carPnr?.vendorConfirmationNumber;
    const isPnrOutOfPolicy = this.isTripPnrOutOfPolicy(pnrIndex);

    const pickupLocation = carInfo?.pickupLocation;
    const dropoffLocation = carInfo?.dropOffLocation;
    const amenities = carInfo?.carSpec.amenities;
    const mileage = carPnr?.carInfo.mileageAllowance;

    const pickupDateTime = carPnr?.pickupDateTime.iso8601;
    const dropoffDateTime = carPnr?.dropOffDateTime.iso8601;
    const noOfBookedDays = getDurationInDaysOrHours(pickupDateTime ?? '', dropoffDateTime ?? '');

    const cancellationPolicyText =
      pnr.data && pnr.pnrId
        ? new CarPnrV3Manager({ pnrData: pnr.data, pnrId: pnr.pnrId }).cancellationPolicyDetails({ withUtcDate: true })
        : { message: '', variables: {} };

    const passengers = pnr.data?.pnrTravelers?.map((pnrTraveler) => ({
      name: pnrTraveler.personalInfo?.name,
      id: pnrTraveler.userId.id,
    }));
    const passengerDetails = pnr.data?.pnrTravelers?.map((travelerInfo) => {
      const requiredPassenger = passengers?.find((passenger) => passenger.id === travelerInfo.userId?.id);
      return {
        name: getNameStringFromName(requiredPassenger?.name),
        loyaltyNumber: travelerInfo.loyalties?.map((l) => l.id).join(', '),
      };
    });
    const specialEquipment = (carPnr?.rate?.extras || []).map((carExtra) => carExtra.type);

    return {
      amenities: {
        numLargeBags: amenities?.numLargeBags ?? 0,
        numSeatBelts: amenities?.numSeatBelts ?? 0,
        numSmallBags: amenities?.numSmallBags ?? 0,
        numSeats: amenities?.numSeats ?? 0,
      },
      cancellationPolicyText,
      carName,

      carSpec,
      specialEquipment,
      confirmationNumber,
      bookingId: pnr.pnrId ?? '',
      dropoff: {
        date: carPnr?.dropOffDateTime,
        location: {
          address: getLocationFullAddressV2(dropoffLocation?.address),
          coordinates: dropoffLocation?.coordinates,
          phoneNumber: dropoffLocation?.contactInfo?.phone,
        },
      },
      noOfBookedDays,
      pickup: {
        date: carPnr?.pickupDateTime,
        location: {
          address: getLocationFullAddressV2(pickupLocation?.address),
          coordinates: pickupLocation?.coordinates,
          phoneNumber: pickupLocation?.contactInfo?.phone,
        },
      },
      validAmenities: !!amenities && (!!amenities.numLargeBags || !!amenities.numSeatBelts || !!amenities.numSmallBags),
      vendorName,
      vendorCode,
      co2EmissionsValue: carInfo?.co2EmissionDetail?.co2EmissionValue ?? 0,
      bookingStatus: pnr.data?.bookingStatus,
      isPnrOutOfPolicy,
      passengerDetails,
      mileage,
      pnrId: pnr.pnrId ?? '',
    };
  }

  public GetTripLimoDetails(pnrIndex: number) {
    const pnrs = this.GetSortedPnrDetails();
    const { pnrId, data: pnrData } = pnrs[pnrIndex];
    const limoDetailsDefaultNoData = {
      cancellationPolicyText: '',
      carName: undefined,
      startDate: undefined,
      endDate: undefined,
      validCancellationPolicy: false,
      bookingId: '',
      bookingStatus: undefined,
      isPnrOutOfPolicy: false,
      vendorName: '',
      confirmationNumber: '',
      driver: {
        name: '',
        phone: {
          rawInput: '',
          countryCode: 1,
        },
      },
      legsInfo: [
        {
          pickup: {
            date: {
              iso8601: '',
            },
            location: {
              address: '',
            },
            addressLines: [],
          },
          dropoff: {
            date: {
              iso8601: '',
            },
            location: {
              address: '',
            },
            addressLines: [],
          },
          duration: '',
          pickupNotes: '',
          dropoffNotes: '',
        },
      ],
    };

    if (!pnrData || !pnrId) {
      return limoDetailsDefaultNoData;
    }

    return new LimoPnrV3Manager({ pnrData, pnrId }).limoDetails();
  }

  public GetTripInfo(): TripV3Info {
    const isAdhocUserSaved = this.allPnrsTravelerUserProfiles[0]?.adhocUserInfo?.isSaved ?? false;

    return {
      id: this.GetTripId(),
      name: this.GetTripName(),
      description: this.GetTripDescription(),
      start: this.GetTripStartDate(),
      end: this.GetTripEndDate(),
      status: this.response.tripBookingStatus ?? UserFacingStatus.UnknownStatus,
      isPersonalTrip: this.isPersonalTrip(),
      isAdhocTrip: this.isAdhocTrip(),
      isAdhocUserSaved,
      // TODO: https://spotnana.slack.com/archives/C03EL234XJA/p1685957581167539
      applicationContext: {
        applicationId: '',
        applicationName: '',
      },
    };
  }

  public isCancelledTrip(): boolean {
    const status = this.response.tripBookingStatus;
    return status === UserFacingStatus.CancelledStatus;
  }

  public isPastTrip(): boolean {
    const status = this.response.tripBookingStatus;
    const endDate = this.GetTripEndDate();

    return (
      status === UserFacingStatus.CompletedStatus ||
      (status === UserFacingStatus.PendingStatus && !isFutureDate(endDate))
    );
  }

  public isTripPnrOutOfPolicy(pnrIndex: number): boolean {
    const pnrs = this.GetSortedPnrDetails();
    const { pnrId, data: pnrData } = pnrs[pnrIndex];

    if (!pnrId || !pnrData) {
      return false;
    }

    return new PnrV3Manager({ pnrData, pnrId }).isPnrOutOfPolicy();
  }

  public isPastOrCancelledTrip(): boolean {
    return this.isCancelledTrip() || this.isPastTrip();
  }

  private isPersonalTrip(): boolean {
    const allPrimaryTravellersPersonas = this.getAllPrimaryTravellersPersonas();
    return allPrimaryTravellersPersonas.length
      ? allPrimaryTravellersPersonas.every((persona) => persona === Persona.Personal)
      : false;
  }

  /** All persona in an adhoc trip, Multipax or not, will be same, and will
   *  be equal to ADHOC. And, Adhoc traveler will be the Primary traveler */
  public isAdhocTrip(): boolean {
    const allPrimaryTravellersPersonas = this.getAllPrimaryTravellersPersonas();
    return allPrimaryTravellersPersonas.length
      ? allPrimaryTravellersPersonas.every((persona) => persona === Persona.Adhoc)
      : false;
  }

  public HasBudgetInfo(): boolean {
    return !!first(first(this.response.pnrs)?.data?.costOfGoodsSold?.payments)?.fop?.paymentMetadata
      ?.customPaymentMethodMetadata?.brexBudgetMetadata;
  }

  public getPrimaryTraveler(): PnrTraveler | undefined {
    const primaryTraveler: PnrTraveler | undefined = first(
      this.response.pnrs.map((pnr) => first(pnr.data?.pnrTravelers))?.filter(Boolean),
    );
    return primaryTraveler;
  }

  public static GetPrimaryTravelerFromPnr(pnr: TripV3SplittedPnr): PnrTraveler | undefined {
    const primaryTraveler: PnrTraveler | undefined = first(pnr.data?.pnrTravelers);
    return primaryTraveler;
  }

  public getTripUrlType(): TripCategoryPath {
    if (this.isCancelledTrip()) {
      return TripCategoryPath.Cancelled;
    }

    if (this.isPastTrip()) {
      return TripCategoryPath.Past;
    }

    return TripCategoryPath.Upcoming;
  }

  public isShellPnr(pnr: TripV3SplittedPnr): boolean {
    return Boolean(pnr.data?.shellPnrInfo);
  }

  public isOutsideBooking(pnr: TripV3SplittedPnr): boolean {
    return Boolean(pnr.data?.createdVia === CreatedVia.Offline);
  }

  public getPnrById(pnrId: string): TripV3SplittedPnr | undefined {
    if (!pnrId) {
      return undefined;
    }

    return (
      this.response.pnrs.find((pnr) => pnr.pnrId === pnrId) ??
      this.response.pendingShellPnrs?.find((pnr) => pnr.pnrId === pnrId) ??
      this.response.pendingManualFormPnrs?.find((pnr) => pnr.pnrId === pnrId)
    );
  }

  public searchPnrById(pnrId: PnrDetailsResponseWithId['pnrId']): PnrDetailsResponseWithId | undefined {
    if (!pnrId) {
      return undefined;
    }

    return (
      this.response.pnrs.find((pnr) => pnr.pnrId === pnrId) ??
      this.response.pendingManualFormPnrs?.find((pnr) => pnr.pnrId === pnrId) ??
      this.response.pendingShellPnrs?.find((pnr) => pnr.pnrId === pnrId)
    );
  }

  public getPnrBySourcePnrId(sourcePnrId: SourceInfo['sourcePnrId']): PnrDetailsResponseWithId | undefined {
    if (!sourcePnrId) {
      return undefined;
    }
    return this.response.pnrs.find((pnr) => pnr.data?.sourceInfo?.sourcePnrId === sourcePnrId);
  }

  public getSourcePnrIdByPnr(pnrId: string | undefined): string | undefined {
    if (!pnrId) {
      return undefined;
    }
    return this.response.pnrs.find((pnr) => pnr.pnrId === pnrId)?.data?.sourceInfo?.sourcePnrId;
  }

  public GetItineraryAllAirlines(sourcePnrId: SourceInfo['sourcePnrId']): IItineraryAirline[] {
    if (!sourcePnrId) {
      return [];
    }

    const pnr = this.getPnrBySourcePnrId(sourcePnrId);
    if (!pnr || !pnr.data?.airPnr) {
      return [];
    }

    const itineraryAllAirlines: IItineraryAirline[] = [];
    pnr.data.airPnr.legs.forEach((leg, legIndex) => {
      leg.flights.forEach((flight, flightIndex) => {
        itineraryAllAirlines.push({
          legIndex,
          flightIndex,
          airline: {
            marketing: flight.marketing.airlineCode ?? '',
            operating: flight.operating.airlineCode ?? '',
          },
        });
      });
    });

    return itineraryAllAirlines;
  }

  public GetSortedPnrDetails({
    includePendingManualFormPnrs = false,
  }: { includePendingManualFormPnrs?: boolean } = {}): TripV3SplittedPnr[] {
    let { splittedPnrs } = this;

    /**
     * !! Attention
     * At the moment this works with (and applies to) hotel PNRs only
     */
    if (includePendingManualFormPnrs) {
      splittedPnrs = splittedPnrs.concat(
        this.GetSplittedPnrs(
          this.response.pendingManualFormPnrs?.map((pnr) => {
            return produce<TripV3SplittedPnr>(pnr, (draft) => {
              /**
               * Each pending manual form pnr should have `incomplete: true` flag
               */
              draft.incomplete = true;

              /**
               * Replace vendor confirmation id (equal to the specific value)
               * with an empty string
               */
              const { hotelPnr } = draft.data ?? {};
              if (hotelPnr?.vendorConfirmationNumber === DUMMY_CONFIRMATION_NUMBER_FOR_INCOMPLETE_OUTSIDE_BOOKINGS) {
                hotelPnr.vendorConfirmationNumber = '';
              }
            });
          }) ?? [],
        ),
      );
    }

    return splittedPnrs.sort((firstPnr, secondPnr) => {
      const firstPnrPriority = TripV3DetailsResponseManager.getPriorityIndexOfPnr(firstPnr);
      const secondPnrPriority = TripV3DetailsResponseManager.getPriorityIndexOfPnr(secondPnr);
      return firstPnrPriority - secondPnrPriority;
    });
  }

  public GetFilteredAndSortedPnrs = ({
    includeCancelled,
    includeHiddenForTravelers,
    includePendingManualFormPnrs,
  }: PnrFilterOptions) => {
    const sortedPnrs = this.GetSortedPnrDetails({ includePendingManualFormPnrs });

    return sortedPnrs?.filter((pnr) => {
      const isCancelled = pnr.data?.bookingStatus === UserFacingStatus.CancelledStatus;
      const isVisibleToTraveler = pnr.data?.travelerPnrVisibilityStatus === 'VISIBLE';

      const shouldIncludePnr = (includeCancelled || !isCancelled) && (includeHiddenForTravelers || isVisibleToTraveler);

      return shouldIncludePnr;
    });
  };

  static GetAirPnrLegStatus(pnr: TripV3SplittedPnr): UserFacingStatus | undefined {
    if (!pnr?.data?.airPnr) {
      return undefined;
    }
    return first(pnr.data.airPnr.legs)?.legStatus;
  }

  static GetRailPnrLegStatus(pnr: TripV3SplittedPnr): PnrStatus | undefined {
    if (!pnr?.data?.railPnr) {
      return undefined;
    }
    return pnr.data.railPnr.inwardJourney?.journeyStatus ?? pnr.data.railPnr.outwardJourney.journeyStatus;
  }

  static IsPnrStatusCancelled(pnrStatus: PnrStatus) {
    return PNR_CANCELLED_STATUSES.includes(pnrStatus);
  }

  static IsPnrStatusPending(pnrStatus: PnrStatus) {
    return PNR_PENDING_STATUSES.includes(pnrStatus);
  }

  static IsShellPnrPendingByStatus(pnrStatus: PnrStatus) {
    return SHELL_PNR_PENDING_STATUSES.includes(pnrStatus);
  }

  static IsPnrCancelled(pnr: TripV3SplittedPnr): boolean {
    if (!pnr.data) {
      return false;
    }

    if (pnr.data.airPnr) {
      const legStatus = TripV3DetailsResponseManager.GetAirPnrLegStatus(pnr);
      return USER_FACING_CANCELLED_STATUSES.includes(legStatus ?? UserFacingStatus.UnknownStatus);
    }

    const pnrStatus =
      pnr.data.hotelPnr?.pnrStatus ??
      pnr.data.carPnr?.pnrStatus ??
      TripV3DetailsResponseManager.GetRailPnrLegStatus(pnr) ??
      pnr.data.limoPnr?.pnrStatus ??
      pnr.data.miscPnr?.pnrStatus ??
      PnrStatus.Unknown;

    return TripV3DetailsResponseManager.IsPnrStatusCancelled(pnrStatus);
  }

  static IsPnrCompleted(pnr: TripV3SplittedPnr): boolean {
    if (!pnr.data) {
      return false;
    }

    if (pnr.data.airPnr) {
      const legStatus = TripV3DetailsResponseManager.GetAirPnrLegStatus(pnr);
      return USER_FACING_COMPLETED_STATUSES.includes(legStatus ?? UserFacingStatus.UnknownStatus);
    }

    // No PNR level status for completed status for hotel, car, rail, limo, misc
    return false;
  }

  static IsPnrCancelledOrCancelling(pnr: TripV3SplittedPnr): boolean {
    if (!pnr.data) {
      return false;
    }

    if (pnr.data.airPnr) {
      const legStatus = TripV3DetailsResponseManager.GetAirPnrLegStatus(pnr);
      return USER_FACING_CANCELLED_AND_CANCELLING_STATUSES.includes(legStatus ?? UserFacingStatus.UnknownStatus);
    }

    return this.IsPnrCancelled(pnr);
  }

  static IsSplittedPnr(pnr: TripV3SplittedPnr) {
    return !isNil(pnr.legInfoArrayIndex);
  }

  static GetPnrType(
    pnr: TripV3SplittedPnr,
  ): PNRType.AIR | PNRType.HOTEL | PNRType.CAR | PNRType.RAIL | PNRType.LIMO | PNRType.MISC {
    switch (true) {
      case Boolean(pnr.data?.airPnr):
        return PNRType.AIR;
      case Boolean(pnr.data?.hotelPnr):
        return PNRType.HOTEL;
      case Boolean(pnr.data?.carPnr):
        return PNRType.CAR;
      case Boolean(pnr.data?.railPnr):
        return PNRType.RAIL;
      case Boolean(pnr.data?.limoPnr):
        return PNRType.LIMO;
      case Boolean(pnr.data?.miscPnr):
        return PNRType.MISC;
      default:
        return PNRType.MISC;
    }
  }

  /**
   * Returns original leg index from the legs array of PNR (before splitting)
   * Needed to backtrack and connect splitted pnr to the same leg element in the original pnr
   */
  // prev GetAirLegInfoIndexFromSplitPnr
  static GetSplittedPnrOriginalLegIndex(splitPnr: TripV3SplittedPnr): number {
    return splitPnr.legInfoArrayIndex ?? 0;
  }

  static getPriorityIndexOfPnr(pnr: TripV3SplittedPnr): number {
    if (!pnr.data) {
      return 0;
    }

    let priorityIndex = 0;
    if (pnr.data.airPnr) {
      priorityIndex = first(pnr.data.airPnr.legs)?.sortingPriority ?? 0;
    } else if (pnr.data.hotelPnr) {
      priorityIndex = pnr.data.hotelPnr.sortingPriority ?? 0;
    } else if (pnr.data.carPnr) {
      priorityIndex = pnr.data.carPnr.sortingPriority ?? 0;
    } else if (pnr.data.railPnr) {
      const legIndex = this.GetSplittedPnrOriginalLegIndex(pnr);
      const isInwardLeg = pnr.data.railPnr.inwardJourney?.legs?.includes?.(legIndex);

      priorityIndex =
        (isInwardLeg
          ? pnr.data.railPnr.inwardJourney?.sortingPriority
          : pnr.data.railPnr.outwardJourney?.sortingPriority) ?? 0;
    } else if (pnr.data.limoPnr) {
      priorityIndex = first(pnr.data.limoPnr.legs)?.sortingPriority ?? 0;
    } else if (pnr.data.miscPnr) {
      priorityIndex = pnr.data.miscPnr.sortingPriority ?? 0;
    }
    return priorityIndex;
  }

  static SplitAirPnrIfNeeded(pnr: PnrDetailsResponseWithId): TripV3SplittedPnr[] {
    const airPnr = pnr.data?.airPnr;
    const airPnrLegs = airPnr?.legs;
    if (!airPnrLegs || airPnrLegs.length <= 1) {
      return [pnr];
    }

    const splittedLegs: TripV3SplittedPnr[] = airPnrLegs.map((leg, rootLegIndex) =>
      produce<TripV3SplittedPnr>(pnr, (draftSplittedPnr) => {
        const draftSplittedAirPnr = draftSplittedPnr.data?.airPnr;
        if (!draftSplittedAirPnr) {
          return;
        }

        draftSplittedAirPnr.legs = [leg];
        draftSplittedPnr.legInfoArrayIndex = rootLegIndex;

        if (draftSplittedAirPnr.travelerInfos) {
          draftSplittedAirPnr.travelerInfos = draftSplittedAirPnr.travelerInfos.map((travelerInfo) => {
            const splittedPnrTravelerInfo = produce(travelerInfo, (draftTravelerInfo) => {
              /**
               * Splitting tickets logic
               */
              const splittedTickets =
                draftTravelerInfo.tickets?.map((ticket) => {
                  const travelerInfoTicket = produce(ticket, (draftTicket) => {
                    draftTicket.flightCoupons = draftTicket.flightCoupons?.filter(
                      (flightCoupon) => flightCoupon.legIdx === rootLegIndex,
                    );

                    draftTicket.ancillaries = draftTicket.ancillaries?.filter(
                      (ancillary) => ancillary.legIndex === rootLegIndex || ancillary.legIndex === -1,
                    );
                  });

                  return travelerInfoTicket;
                }) ?? [];

              // take only those tickets for the leg that has at least 1 flight coupon after filtering coupons by legIndex
              draftTravelerInfo.tickets = splittedTickets.filter((ticket) => ticket.flightCoupons?.length);
              /**
               * End of splitting tickets logic
               */

              /**
               * Splitting booking info logic
               */
              draftTravelerInfo.booking.seats =
                draftTravelerInfo.booking?.seats?.filter((seat) => seat.legIdx === rootLegIndex) ?? [];
              draftTravelerInfo.booking.seats = draftTravelerInfo.booking?.seats?.map((seat) => {
                return { ...seat, legIdx: 0 };
              });

              draftTravelerInfo.booking.luggageDetails =
                draftTravelerInfo.booking?.luggageDetails?.filter(
                  (luggageDetail) => luggageDetail.legIdx === rootLegIndex,
                ) ?? [];

              draftTravelerInfo.booking.itinerary.flightFareBreakup =
                draftTravelerInfo.booking.itinerary.flightFareBreakup?.filter((flightFareBreakup) =>
                  flightFareBreakup.legIndices?.includes(rootLegIndex),
                ) ?? [];
              /**
               * End of splitting booking info logic
               */

              draftTravelerInfo.specialServiceRequestInfos = draftTravelerInfo.specialServiceRequestInfos?.filter(
                (ssrInfo) => ssrInfo.legIndex === rootLegIndex,
              );
            });

            return splittedPnrTravelerInfo;
          });
        }

        if (draftSplittedAirPnr.automatedExchangeInfo?.supportedExchanges) {
          draftSplittedAirPnr.automatedExchangeInfo.supportedExchanges =
            draftSplittedAirPnr.automatedExchangeInfo.supportedExchanges.filter((supportedExchange) =>
              supportedExchange.legInfos?.some((legInfo) => legInfo.legIdx === rootLegIndex),
            );
        }

        if (draftSplittedAirPnr.otherServiceInfos) {
          draftSplittedAirPnr.otherServiceInfos = draftSplittedAirPnr.otherServiceInfos.filter((otherServiceInfo) =>
            otherServiceInfo.flightIndexes?.some((flightIndex) => flightIndex.legIndex === rootLegIndex),
          );
        }
      }),
    );

    return splittedLegs;
  }

  static SplitRailPnrIfNeeded(pnr: PnrDetailsResponseWithId): TripV3SplittedPnr[] {
    const railPnr = pnr.data?.railPnr;
    if (!railPnr) {
      return [pnr];
    }

    const splittedPnrs: TripV3SplittedPnr[] = [];

    const splittedOutwardPnr = produce<TripV3SplittedPnr>(pnr, (draftPnr) => {
      const draftRailPnr = draftPnr.data?.railPnr;
      if (!draftRailPnr) {
        return;
      }

      draftRailPnr.inwardJourney = undefined;

      const outwardJourneyLegIndex = first(draftRailPnr.outwardJourney.legs) ?? 0;
      draftRailPnr.legInfos = draftRailPnr.legInfos.filter((_, legIdx) =>
        draftRailPnr.outwardJourney.legs.includes(legIdx),
      );
      draftPnr.legInfoArrayIndex = outwardJourneyLegIndex;
    });
    splittedPnrs.push(splittedOutwardPnr);

    if (railPnr.inwardJourney) {
      const splittedInwardPnr = produce<TripV3SplittedPnr>(pnr, (draftPnr) => {
        const draftRailPnr = draftPnr.data?.railPnr;
        if (!draftRailPnr || !draftRailPnr.inwardJourney) {
          return;
        }
        draftRailPnr.outwardJourney = { legs: [] };

        const inwardJourneyLegIndex = first(draftRailPnr.inwardJourney.legs) ?? 0;
        draftRailPnr.legInfos = draftRailPnr.legInfos.filter((_, legIdx) =>
          draftRailPnr.inwardJourney?.legs.includes(legIdx),
        );
        draftPnr.legInfoArrayIndex = inwardJourneyLegIndex;
      });
      splittedPnrs.push(splittedInwardPnr);
    }

    return splittedPnrs;
  }

  static SplitLimoPnrIfNeeded(pnr: PnrDetailsResponseWithId): TripV3SplittedPnr[] {
    const limoPnr = pnr.data?.limoPnr;
    if (!limoPnr) {
      return [pnr];
    }

    return limoPnr.legs.map((leg, legIndex) => {
      return produce<TripV3SplittedPnr>(pnr, (draftPnr) => {
        const draftLimoPnr = draftPnr.data?.limoPnr;
        if (!draftLimoPnr) {
          return;
        }

        draftLimoPnr.legs = [leg];

        draftPnr.legInfoArrayIndex = legIndex;
      });
    });
  }

  static GetPnrStartDate(pnr?: TripV3SplittedPnr): string {
    let start = '';
    if (!pnr?.data) {
      return start;
    }

    if (pnr.data.airPnr) {
      start = first(first(pnr.data.airPnr.legs)?.flights)?.departureDateTime?.iso8601 ?? '';
    } else if (pnr.data.hotelPnr) {
      start = pnr.data.hotelPnr.checkInDateTime.iso8601 ?? '';
    } else if (pnr.data.carPnr) {
      start = pnr.data.carPnr.pickupDateTime.iso8601 ?? '';
    } else if (pnr.data.railPnr) {
      start = first(pnr.data.railPnr.legInfos)?.departAt?.iso8601 ?? '';
    } else if (pnr.data.limoPnr) {
      start = first(pnr.data.limoPnr.legs)?.pickupDateTime.iso8601 ?? '';
    } else if (pnr.data.miscPnr) {
      start = pnr.data.miscPnr.startDateTime.iso8601 ?? '';
    }

    return start;
  }

  static GetPnrEndDate(pnr?: PnrDetailsResponseWithId): string {
    let end = '';
    if (!pnr?.data) {
      return end;
    }

    if (pnr.data.airPnr) {
      end = last(last(pnr.data.airPnr.legs)?.flights)?.arrivalDateTime?.iso8601 ?? '';
    } else if (pnr.data.hotelPnr) {
      end = pnr.data.hotelPnr.checkOutDateTime.iso8601 ?? '';
    } else if (pnr.data.carPnr) {
      end = pnr.data.carPnr.dropOffDateTime.iso8601 ?? '';
    } else if (pnr.data.railPnr) {
      end = last(pnr.data.railPnr.legInfos)?.arriveAt?.iso8601 ?? '';
    } else if (pnr.data.limoPnr) {
      end = last(pnr.data.limoPnr.legs)?.dropOffDateTime?.iso8601 ?? '';
    } else if (pnr.data.miscPnr) {
      end = pnr.data.miscPnr.endDateTime.iso8601 ?? '';
    }

    return end;
  }

  static GetAirLegInfoForPax(pnr: ITripPnr, paxIndex: number): PaxInfoFareComponentLegInfo[] {
    return pnr?.air?.air?.itineraries?.[0]?.rateOptions?.[0]?.paxInfo?.[paxIndex]?.fareComponents?.[0]?.legInfo ?? [];
  }

  static GetPreferredType(pnr: TripV3SplittedPnr): PreferredTypeV2[] {
    if (!pnr.data) {
      return [];
    }

    let preferredType: PreferredTypeV2[] = [];
    let preferences: PreferenceV2[] = [];

    if (pnr.data.airPnr) {
      preferredType =
        pnr.data.airPnr.legs[TripV3DetailsResponseManager.GetSplittedPnrOriginalLegIndex(pnr)]?.preferredTypes ?? [];
      preferences =
        pnr.data.airPnr.legs[TripV3DetailsResponseManager.GetSplittedPnrOriginalLegIndex(pnr)]?.preferences ?? [];
    } else if (pnr.data.hotelPnr) {
      preferredType = pnr.data.hotelPnr.preferredType ?? [];
      preferences = pnr.data.hotelPnr.preferences ?? [];
    } else if (pnr.data.carPnr) {
      preferredType = pnr.data.carPnr.carInfo.preferredType ?? [];
      preferences = pnr.data.carPnr.carInfo.preferences ?? [];
    }

    return getVendorPreferencesV2(preferredType, preferences);
  }

  static GetFlightDetails(pnr: TripV3SplittedPnr): ITripV3FlightDetail[] {
    const leg = first(pnr.data?.airPnr?.legs);
    const details: ITripV3FlightDetail[] =
      leg?.flights?.map((flight, flightIndex) => {
        const { flightUpdates, sourceStatus } = flight;

        // Arrival DateTime
        const arrivalDateTime = flight.arrivalDateTime.iso8601;
        const arrivalDateTimePrevious = flightUpdates?.previousArrivalDateTime?.iso8601;
        const isArrivalDateTimeChanged =
          (arrivalDateTimePrevious && !isSame(arrivalDateTime, arrivalDateTimePrevious, dateFormats.ISO)) || false;
        const isArrivalDateTimeDelayed =
          (arrivalDateTimePrevious && isBefore(arrivalDateTimePrevious, arrivalDateTime, dateFormats.ISO)) || false;
        const arrivalDateTimeDelayMinutes =
          (arrivalDateTimePrevious && getDateTimeDiff(arrivalDateTime, arrivalDateTimePrevious, dateFormats.ISO)) || 0;

        // Departure DateTime
        const departureDateTime = flight.departureDateTime.iso8601;
        const departureDateTimePrevious = flightUpdates?.previousDepartureDateTime?.iso8601;
        const isDepartureDateTimeChanged =
          (departureDateTimePrevious && !isSame(departureDateTime, departureDateTimePrevious, dateFormats.ISO)) ||
          false;
        const isDepartureDateTimeDelayed =
          (departureDateTimePrevious && isBefore(departureDateTimePrevious, departureDateTime, dateFormats.ISO)) ||
          false;
        const departureDateTimeDelayMinutes =
          (departureDateTimePrevious &&
            getDateTimeDiff(departureDateTime, departureDateTimePrevious, dateFormats.ISO)) ||
          0;

        // Departure Terminal
        const departureAirportTerminal = flight.departureGate?.terminal ?? '';
        const departureAirportTerminalPrevious = flightUpdates?.previousDepartureGate?.terminal;
        const isDepartureAirportTerminalChanged =
          (departureAirportTerminalPrevious && departureAirportTerminal !== departureAirportTerminalPrevious) || false;

        // Departure Gate
        const departureAirportGate = flight.departureGate?.gate ?? '';
        const departureAirportGatePrevious = flightUpdates?.previousDepartureGate?.gate;
        const isDepartureAirportGateChanged =
          (departureAirportGatePrevious && departureAirportGate !== departureAirportGatePrevious) || false;

        // Arrival Terminal
        const arrivalAirportTerminal = flight.arrivalGate?.terminal ?? '';
        const arrivalAirportTerminalPrevious = flightUpdates?.previousArrivalGate?.gate;
        const isArrivalAirportTerminalChanged =
          (arrivalAirportTerminalPrevious && arrivalAirportTerminal !== arrivalAirportTerminalPrevious) || false;

        // Connection Risks
        const connectionAlert = leg?.legUpdates?.connectionAlerts?.find(
          (cr) => cr.departingFlightIndex === flightIndex,
        );
        const isHavingConnectionRisk = !!connectionAlert;
        const { minConnectionDuration, previousConnectionDuration, updatedConnectionDuration } = connectionAlert || {};

        // Is Flight Cancelled (by Vendor)
        const isFlightCancelled = flight.flightStatus === PnrStatus.CancelledByVendor;

        // Is Flight Reinstated
        const isFlightReinstated = flight.flightStatus === PnrStatus.Reinstated;

        // const flightIndex = pnr.air?.flightInfo[flightNumber].flightIndex ?? 0;

        const flightOriginAirportInfo = pnr.data?.additionalMetadata?.airportInfo?.find(
          (ai) => ai.airportCode === flight.origin,
        );
        const flightDestinationAirportInfo = pnr.data?.additionalMetadata?.airportInfo?.find(
          (ai) => ai.airportCode === flight.destination,
        );

        const tripFlightDetail: ITripV3FlightDetail = {
          duration: minutesToDurationString(getDurationMinutes(flight.duration?.iso8601)),

          arrivalDateTime,
          arrivalDateTimePrevious,
          isArrivalDateTimeChanged,
          isArrivalDateTimeDelayed,
          arrivalDateTimeDelayMinutes,

          arrivalAirport: flight.destination,
          arrivalAirportName: flightDestinationAirportInfo?.airportName ?? '',
          arrivalAirportCityName: flightDestinationAirportInfo?.cityName ?? '',
          arrivalAirportTerminal,
          arrivalAirportTerminalPrevious,
          isArrivalAirportTerminalChanged,

          departureDateTime,
          departureDateTimePrevious,
          isDepartureDateTimeChanged,
          isDepartureDateTimeDelayed,
          departureDateTimeDelayMinutes,

          departureAirport: flight.origin,
          departureAirportName: flightOriginAirportInfo?.airportName ?? '',
          departureAirportCityName: flightOriginAirportInfo?.cityName ?? '',
          departureAirportTerminal,
          departureAirportTerminalPrevious,
          isDepartureAirportTerminalChanged,
          departureAirportGate,
          departureAirportGatePrevious,
          isDepartureAirportGateChanged,

          cabinType: flight.cabin ?? Cabin.UnknownCabin,
          brandName: leg?.brandName ?? '',
          bookingCode: flight.bookingCode ?? '',

          marketingAirlineLabel: airlinesMap[flight.marketing.airlineCode],
          marketing: flight.marketing,
          operating: flight.operating,
          operatingAirlineLabel: airlinesMap[flight.operating.airlineCode],
          // TODO: https://spotnana.slack.com/archives/C03EL234XJA/p1685355535372369
          // flight?.operatingAirlineName
          //   ? sentenceCase(flight?.operatingAirlineName)
          //   : airlinesMap[flight.operating.airline],
          marketingAirLine: flight.marketing.airlineCode,

          isHavingConnectionRisk,
          minConnectionDurationIso8601: minConnectionDuration?.iso8601,
          previousConnectionDurationIso8601: previousConnectionDuration?.iso8601,
          updatedConnectionDurationIso8601: updatedConnectionDuration?.iso8601,

          isFlightCancelled,
          isFlightReinstated,
          flightIndex,
          flightSourceStatus: sourceStatus ?? '',
        };

        return tripFlightDetail;
      }) ?? [];
    return details;
  }

  static GetPnrCTC(pnr: TripV3SplittedPnr) {
    return pnr.data?.costToCustomer;
  }

  static GetPnrCOGS(pnr: TripV3SplittedPnr) {
    return pnr.data?.costOfGoodsSold;
  }

  static GetPnrPaymentCard(pnr: TripV3SplittedPnr, travelerIndex = 0) {
    const ctcOrCogs = TripV3DetailsResponseManager.GetPnrCTC(pnr) ?? TripV3DetailsResponseManager.GetPnrCOGS(pnr);

    const allPayments = ctcOrCogs?.payments ?? [];
    const paymentForTravelerByIndex = allPayments.find((p) => p.travelerIndices.includes(travelerIndex));
    const payment = paymentForTravelerByIndex ?? first(allPayments);

    return payment?.fop?.card;
  }

  public setTravelerUserProfiles(travelerUserProfiles: UserProfile[]): void {
    this.allPnrsTravelerUserProfiles = travelerUserProfiles;
  }

  public getTravelerUserProfilesForPnr(pnr: TripV3SplittedPnr): UserProfile[] {
    /**
     * we iterate over `travelerUserIdsForPnr` first to keep the order of the travelers
     */
    return (
      pnr.data?.pnrTravelers?.map((pnrTraveler) => {
        const userId = pnrTraveler.userId.id;
        return this.allPnrsTravelerUserProfiles.find((userProfile) => userProfile.id === userId);
      }) ?? []
    ).filter(Boolean) as UserProfile[];
  }

  public getPrimaryTravelerUserProfile(): UserProfile | undefined {
    return first(this.allPnrsTravelerUserProfiles);
  }

  public GetTripDetailsData({
    includePendingManualFormPnrs = false,
    showCancelledItems,
    tripV2DetailsResponse,
  }: GetTripDetailsDataParams & {
    tripV2DetailsResponse: GetTripDetailsResponse;
  }): TripDetailsData {
    // V2 Data (TEMP), TODO: remove later
    // eslint-disable-next-line no-restricted-syntax
    const v2Manager = new V2TripDetailsResponseManager(tripV2DetailsResponse);
    const v2NormalizedData = v2Manager.getNormalizedDataFromV2TripDetailsResponseManager({
      includePendingManualFormPnrs,
    });
    const {
      allPnrs: allPnrsV2,
      totalCancelledPnrs: totalCancelledV2Pnrs,
      nonCancelledPnrs: nonCancelledPnrsV2,
    } = v2NormalizedData;
    const pnrsV2 = showCancelledItems ? [...allPnrsV2] : [...nonCancelledPnrsV2];

    // V3 Data
    const allPnrs = this.GetSortedPnrDetails({
      includePendingManualFormPnrs,
    });
    const nonCancelledPnrs = allPnrs.filter((pnr) => !TripV3DetailsResponseManager.IsPnrCancelled(pnr));
    const pnrs = showCancelledItems ? allPnrs : nonCancelledPnrs;
    const numActivePnrsWithoutShellPnrs = nonCancelledPnrs.filter((pnr) => {
      return pnr.data && pnr.data.bookingStatus !== UserFacingStatus.ApprovalDeniedStatus;
    }).length;
    // const totalCancelledV3Pnrs = Math.abs(allPnrs.length - nonCancelledPnrs.length);

    const pnrsForTripActivity = allPnrs.map((pnr) => ({
      sourcePnrId: pnr?.data?.sourceInfo?.sourcePnrId ?? '',
      pnrId: pnr.pnrId ?? '',
    }));

    const tripInfo = this.GetTripInfo();

    const isPastTrip = this.isPastTrip();

    const isLastFlightOvernight = this.isLastFlightOvernight(pnrs);
    const bookingFlow = tripInfo.isAdhocTrip ? BookingFlowEnum.ADHOC : BookingFlowEnum.TRAVELER;
    const showNoResults: boolean =
      !pnrs.length && !this.response.pendingShellPnrs?.length && !this.response.simplePnrs?.length;

    const isAirMultipax = pnrs.some((pnr) => pnr.data?.pnrTravelers && pnr.data.pnrTravelers?.length > 1);
    // Though Hotel multipax is not true multipax since the booking is made under the primary traveler's name
    // Still considering it as such to make it simpler for users
    const isHotelMultipax = pnrs.some(
      (pnr) =>
        pnr.data?.hotelPnr?.occupancy &&
        HotelDetailsManager.GetTotalTravelersFromOccupancyData(pnr.data.hotelPnr.occupancy) > 1,
    );
    const isMultiPax = isAirMultipax || isHotelMultipax;

    const showAddToTrip = Boolean(!tripInfo.isAdhocTrip || tripInfo.isAdhocUserSaved);

    const primaryTraveler = this.getPrimaryTraveler();
    const primaryTravelerName = primaryTraveler?.personalInfo?.name;
    const primaryTravelerEmail = primaryTraveler?.personalInfo?.email;
    const profileDisplayText = getProfileDisplayText(primaryTravelerName, primaryTravelerEmail);

    // TODO: replace with totalCancelledV3Pnrs once statuses are fixed on BE
    const totalCancelledPnrs = totalCancelledV2Pnrs; // totalCancelledV3Pnrs
    const trackTripDetailsTelemetryData: TripDetailsData['trackTripDetailsTelemetryData'] = {
      tripId: this.GetTripId(),
      tripInfo: {
        start: tripInfo.start,
        end: tripInfo.end,
        name: tripInfo.name,
        status: tripInfo.status,
      },
      totalPnrs: allPnrs.length,
      totalCancelledPnrs,
    };

    return {
      pnrsV2,
      pnrsV3: pnrs,

      numActivePnrsWithoutShellPnrs,
      totalCancelledPnrs,
      pnrsForTripActivity,

      tripInfo,
      isPastTrip,
      isLastFlightOvernight,

      profileDisplayText,
      bookingFlow,
      showNoResults,
      showAddToTrip,
      isMultiPax,
      primaryTravelerUserId: primaryTraveler?.userId?.id,

      trackTripDetailsTelemetryData,
      splittedSimplePnrs: [], // using from V2
      pnrIdsSortedByPriority: [], // using from V2
    };
  }

  public GetTripDetailsCardCommonData({ pnr }: { pnr: TripV3SplittedPnr }): TripDetailsCardCommonData {
    const tripInfo = this.GetTripInfo();

    return {
      tripStartDate: tripInfo.start,
      tripEndDate: tripInfo.end,
      isPastTrip: this.isPastTrip(),
      isPastOrCancelledTrip: this.isPastOrCancelledTrip(),

      pnrStartDate: TripV3DetailsResponseManager.GetPnrStartDate(pnr),
      isPnrCancelled: TripV3DetailsResponseManager.IsPnrCancelled(pnr),
      pnrType: TripV3DetailsResponseManager.GetPnrType(pnr),
      isAdhocTrip: Boolean(tripInfo.isAdhocTrip),
    };
  }

  public GetTripDetailsMiscCardData({ pnr }: { pnr: TripV3SplittedPnr }): TripDetailsMiscCardData {
    const tripInfo = this.GetTripInfo();

    const extrasInfo = pnr.data?.miscPnr?.rate.extras?.map((extra) => extra.type) || [];

    const freshnessInfo =
      pnr &&
      pnr.data &&
      pnr.data?.freshnessInfo?.latestVersionDateTime &&
      pnr.data?.freshnessInfo?.returnedVersionDateTime
        ? {
            latestVersionTime: pnr.data?.freshnessInfo?.latestVersionDateTime,
            returnedVersionTime: pnr.data?.freshnessInfo?.returnedVersionDateTime,
          }
        : undefined;

    const paymentCard = TripV3DetailsResponseManager.GetPnrPaymentCard(pnr);
    const paymentCardNumber = paymentCard?.number;
    const paymentCardCompany = paymentCard?.company;

    const pnrPaymentCardInfo =
      paymentCardNumber && paymentCardCompany
        ? {
            number: paymentCardNumber,
            company: paymentCardCompany,
          }
        : undefined;

    return {
      tripId: tripInfo.id,

      pnrId: pnr.pnrId ?? '',
      pnrStartDate: TripV3DetailsResponseManager.GetPnrStartDate(pnr),
      pnrEndDate: TripV3DetailsResponseManager.GetPnrEndDate(pnr),
      pnrStatus: pnr.data?.miscPnr?.pnrStatus ?? PnrStatus.Unknown,
      pnrDescription: pnr.data?.miscPnr?.description ?? '',
      pnrVendorConfirmationNumber: pnr.data?.miscPnr?.vendorConfirmationNumber ?? '',
      pnrRate: pnr.data?.miscPnr?.rate,
      pnrPaymentCardInfo,
      extrasInfo,
      bookingStatus: pnr.data?.bookingStatus ?? UserFacingStatus.UnknownStatus,

      uploadedDocuments: pnr.pnrId ? this.getDocumentsPresentInPnr(pnr.pnrId) : [],
      freshnessInfo,
      sourcePnrId: pnr.data?.sourceInfo?.sourcePnrId ?? '',
    };
  }

  static GetVirtualCardMetadata(pnr: TripV3SplittedPnr): VirtualCardMetadata | undefined {
    return first(pnr.data?.costOfGoodsSold?.payments)?.fop?.paymentMetadata?.virtualCardMetadata;
  }

  public GetAirPnrPaymentDetails(pnrId: string) {
    const pnr = this.getPnrById(pnrId);

    type FlightInfo = {
      origin: string;
      destination: string;
    };
    const flightInfos: FlightInfo[] = [];

    pnr?.data?.airPnr?.legs.forEach((leg) => {
      leg.flights.forEach((flight) => {
        flightInfos.push({
          origin: flight.origin,
          destination: flight.destination,
        });
      });
    });

    let totalMerchantFee = MoneyUtil.zeroMoney();

    pnr?.data?.airPnr?.travelerInfos?.forEach((travelerInfo) => {
      const travelerMerchantFee = travelerInfo.booking.itinerary.totalMerchantFees;
      if (totalMerchantFee.isZero()) {
        totalMerchantFee = MoneyUtil.convertV2MoneyToMoneyUtil(travelerMerchantFee);
      } else {
        totalMerchantFee = totalMerchantFee.add(MoneyUtil.convertV2MoneyToMoneyUtil(travelerMerchantFee));
      }
    });

    const serviceFeeBase = MoneyUtil.convertV2MoneyToMoneyUtil(pnr?.data?.serviceFees?.[0]?.fare?.base);
    const serviceFeeTax = MoneyUtil.convertV2MoneyToMoneyUtil(pnr?.data?.serviceFees?.[0]?.fare?.tax);
    const serviceFeeTotal = serviceFeeBase.add(serviceFeeTax);

    return {
      pnrTravelers: pnr?.data?.pnrTravelers ?? [],
      flightInfos,
      totalFare: pnr?.data?.totalFare,
      totalMerchantFee,
      travelerInfos: pnr?.data?.airPnr?.travelerInfos ?? [],
      paymentInfos: pnr?.data?.paymentInfo ?? [],
      serviceFees: { base: serviceFeeBase, tax: serviceFeeTax, total: serviceFeeTotal },
      invoiceDelayedBooking: pnr?.data?.invoiceDelayedBooking ?? false,
    };
  }

  public getPaymentSourceBreakdown(pnrId: string): PaymentSourceBreakdownLine[] {
    const pnr = this.getPnrById(pnrId);
    const paymentInfos = pnr?.data?.paymentInfo ?? [];
    const breakdown = paymentInfos.map(({ fop, totalCharge }) => {
      const { card } = fop;

      const convertedMoney: Money = {
        ...totalCharge,
        otherCoinage: totalCharge.otherCoinage?.map((otherCoinage) => {
          const coinageCode = PaymentV2ToV1Mapper[otherCoinage.coinageCode];
          return {
            ...otherCoinage,
            coinageCode,
          };
        }),
      };
      const amountValue = MoneyUtil.parse(convertedMoney);

      return {
        card,
        paymentMethod: fop.paymentMethod,
        amountValue,
      };
    });
    return breakdown;
  }

  public getDocumentsPresentInPnr(pnrId: string): Document[] {
    const pnr = this.getPnrById(pnrId);
    if (!(pnr && pnr.data && pnr.data.documents)) {
      return [];
    }
    return pnr.data.documents;
  }

  public getPnrTravelers(pnrId: string): PnrTraveler[] {
    const pnr = this.getPnrById(pnrId);
    if (!(pnr && pnr.data && pnr.data.pnrTravelers)) {
      return [];
    }

    return pnr.data.pnrTravelers;
  }

  public getTripPnrTravelData(locationFilter?: CustomFieldLocation): TripPnrTravelData[] {
    const { pnrs } = this.response;
    const tripTravelData: TripPnrTravelData[] = [];
    const activePnrs = pnrs.filter((pnrs) => {
      const { data } = pnrs;
      return (
        data &&
        data.bookingStatus !== UserFacingStatus.CancelledStatus &&
        data.bookingStatus !== UserFacingStatus.ApprovalDeniedStatus
      );
    });
    activePnrs.forEach((pnr) => {
      const pnrId = pnr.pnrId ?? '';
      const pnrV3Manager = pnr.data ? new PnrV3Manager({ pnrData: pnr.data, pnrId }) : undefined;
      const pnrTravelData = pnrV3Manager?.travelData() ?? [];
      const filteredPnrTravelData = pnrTravelData?.filter(
        (travelData) => !locationFilter || travelData.customFieldLocations.includes(locationFilter),
      );

      if (filteredPnrTravelData && filteredPnrTravelData.length > 0 && pnr.data && pnr.pnrId) {
        const pnrBookingDetails: PnrBookingDetails = {
          bookingId: pnr.pnrId,
          isRoundtrip: false,
        };
        const pnrType = TripV3DetailsResponseManager.GetPnrType(pnr);
        switch (pnrType) {
          case PNRType.AIR:
            pnrBookingDetails.locations = getPnrTravelSegments(pnr);
            break;
          case PNRType.HOTEL:
            pnrBookingDetails.name = new HotelPnrV3Manager({ pnrData: pnr.data, pnrId: pnr.pnrId }).hotelName();
            break;
          case PNRType.CAR:
            pnrBookingDetails.name = new CarPnrV3Manager({
              pnrData: pnr.data,
              pnrId: pnr.pnrId,
            }).vendorInfo().vendorName;
            break;
          case PNRType.RAIL: {
            pnrBookingDetails.locations = getPnrTravelSegments(pnr);
            break;
          }
          case PNRType.LIMO:
            pnrBookingDetails.name = new LimoPnrV3Manager({ pnrData: pnr.data, pnrId: pnr.pnrId }).vendorName();
            break;
          default:
            break;
        }

        if (isRoundTrip(pnrBookingDetails.locations)) {
          pnrBookingDetails.isRoundtrip = true;
          pnrBookingDetails.locations = pnrBookingDetails.locations?.slice(0, 1) ?? [];
        }

        tripTravelData.push({ pnrBookingDetails, pnrTravelData: filteredPnrTravelData });
      }
    });

    return tripTravelData;
  }

  public pnrsLlfInfo() {
    const { pnrs } = this.response;

    const pnrList: PnrLeastLogicalFareInfo[] = [];

    if (pnrs && pnrs.length > 0) {
      let tripTotalFare: MoneyUtil | undefined;
      let tripTotalLlfFare: MoneyUtil | undefined;
      let tripTotalDiffFare: MoneyUtil | undefined;

      pnrs.forEach((pnr) => {
        if (pnr.data?.llfPnrInfo && pnr.pnrId) {
          const travelSegments = getPnrTravelSegments(pnr);
          const totalFare = MoneyUtil.convertV2MoneyToMoneyUtil(pnr.data.totalFare);
          tripTotalFare = tripTotalFare ? tripTotalFare.add(totalFare) : totalFare;

          const llfFare = MoneyUtil.convertV2MoneyToMoneyUtil(pnr.data.llfPnrInfo?.totalFare);
          tripTotalLlfFare = tripTotalLlfFare ? tripTotalLlfFare.add(llfFare) : llfFare;

          const diffFare = totalFare.subtract(llfFare);
          tripTotalDiffFare = tripTotalDiffFare ? tripTotalDiffFare.add(diffFare) : diffFare;

          const info = {
            locations: travelSegments,
            totalFare,
            llfFare,
            diffFare,
            isRoundtrip: isRoundTrip(travelSegments),
            bookingId: pnr.pnrId,
          };
          pnrList.push(info);
        }
      });

      return {
        pnrs: pnrList,
        trip: {
          totalFare: tripTotalFare ?? MoneyUtil.zeroMoney(),
          llfFare: tripTotalLlfFare ?? MoneyUtil.zeroMoney(),
          diffFare: tripTotalDiffFare ?? MoneyUtil.zeroMoney(),
        },
      };
    }

    return {
      pnrs: pnrList,
      trip: {
        totalFare: MoneyUtil.zeroMoney(),
        llfFare: MoneyUtil.zeroMoney(),
        diffFare: MoneyUtil.zeroMoney(),
      },
    };
  }

  public getTripPnrMetadata(
    options: {
      includePendingManualFormPnrs?: boolean;
    } = {},
  ) {
    const allPnrs = this.GetSortedPnrDetails(options);

    const { cancelledPnrs, nonCancelledPnrs } = allPnrs.reduce<
      Record<'cancelledPnrs' | 'nonCancelledPnrs', TripV3SplittedPnr[]>
    >(
      (acc, pnr) => {
        if (TripV3DetailsResponseManager.IsPnrCancelled(pnr)) {
          acc.cancelledPnrs.push(pnr);
        } else {
          acc.nonCancelledPnrs.push(pnr);
        }
        return acc;
      },
      {
        cancelledPnrs: [],
        nonCancelledPnrs: [],
      },
    );

    const activePnrsWithoutShellPnrs = nonCancelledPnrs.filter((pnr) => {
      return pnr.data && pnr.data.bookingStatus !== UserFacingStatus.ApprovalDeniedStatus;
    });

    return {
      allPnrs,
      cancelledPnrs,
      nonCancelledPnrs,
      activePnrsWithoutShellPnrs,
    };
  }
}
