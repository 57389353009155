import { useTranslation } from 'react-i18next';
import type { Dayjs } from 'dayjs';
import { isDayjs } from 'dayjs';
import * as Moment from 'moment';
import Platform from '../../utils/Platform';

import { localizationKeys, timeFormats } from '../../constants/common';
import i18n from '..';
import { getFormattedDateTime } from '../../date-utils';
import type {
  ExtendedDateStyle,
  ExtendedDateTimeStyle,
  ExtendedTimeStyle,
  ExtendedMonthStyle,
} from './extendedDateTime';
import { getExtendedDateTimeFormatOptions } from './extendedDateTime';
import { adjustDateForTimezoneIssue } from '../utils/adjustDateForTimezoneIssue';

export const getPlainDateObj = (date: Dayjs | Moment.Moment | Date | string) => {
  let plainDateObj;
  if (Moment.default.isMoment(date)) {
    plainDateObj = date.toDate();
  } else if (isDayjs(date)) {
    plainDateObj = date.toDate();
  } else if (date instanceof Date) {
    plainDateObj = date;
  } else if (typeof date === 'string') {
    // Workaround for the bug with converting dates for Asia/Singapore timezone
    // Check adjustDateForTimezoneIssue function for more info
    // When the issue is resolved in React Native, revert back to: plainDateObj = dateUtil(date).toDate()
    plainDateObj = adjustDateForTimezoneIssue(date);
  }

  if (!plainDateObj) {
    throw new Error("date should be one of 'string, moment, dayjs, Date object' type");
  }

  return plainDateObj;
};

const localizePlainDateObj = (
  plainDate: Date,
  formatOptions: ExtendedDateTimeStyle,
  locale?: string,
): string | undefined => {
  const internalLocale = locale || (i18n.language ?? 'en');
  return new Intl.DateTimeFormat(internalLocale, getExtendedDateTimeFormatOptions(formatOptions)).format(plainDate);
};

const localizeDateTimeInternal = (
  date?: Dayjs | Moment.Moment | Date | string | null,
  formatOptions: ExtendedDateTimeStyle = {},
  locale?: string,
): string | undefined => {
  if (!date) {
    return typeof date === 'string' ? date : undefined;
  }

  const plainDateObj = getPlainDateObj(date);
  return localizePlainDateObj(plainDateObj, formatOptions, locale);
};

export const localizeDate = (
  date?: Dayjs | Moment.Moment | Date | string | null,
  dateStyle: ExtendedDateStyle = 'medium',
): string | undefined => {
  return localizeDateTimeInternal(date, { dateStyle });
};

export type LocalizeDateWithLocaleProps = {
  date?: Dayjs | Moment.Moment | Date | string | null;
  dateStyle?: ExtendedDateStyle;
  locale: string;
};
export const localizeDateWithLocale = ({
  date,
  dateStyle = 'medium',
  locale,
}: LocalizeDateWithLocaleProps): string | undefined => {
  return localizeDateTimeInternal(date, { dateStyle }, locale);
};

export const localizeMonth = (
  date?: Dayjs | Moment.Moment | Date | string | null,
  month: ExtendedMonthStyle = 'short',
): string | undefined => {
  return localizeDateTimeInternal(date, { month });
};

export const localizeTime = (
  date?: Dayjs | Moment.Moment | Date | string | null,
  timeStyle: ExtendedTimeStyle = 'medium',
): string | undefined => {
  if (Platform.OS === 'android') {
    return date ? getFormattedDateTime(getPlainDateObj(date), timeFormats.HR12) : undefined;
  }
  return localizeDateTimeInternal(date, { timeStyle });
};

export const localizeDateTime = (
  date?: Dayjs | Moment.Moment | Date | string | null,
  { dateStyle = 'medium', timeStyle = 'medium' }: ExtendedDateTimeStyle = {},
): string | undefined => {
  return localizeDateTimeInternal(date, { dateStyle, timeStyle });
};

export type LocalizeDateTimeWithLocaleProps = {
  date?: Dayjs | Moment.Moment | Date | string | null;
  dateTimeStyle?: ExtendedDateTimeStyle;
  locale: string;
};
export const localizeDateTimeWithLocale = ({
  date,
  dateTimeStyle,
  locale,
}: LocalizeDateTimeWithLocaleProps): string | undefined => {
  return localizeDateTimeInternal(
    date,
    { dateStyle: dateTimeStyle?.dateStyle || 'medium', timeStyle: dateTimeStyle?.timeStyle || 'medium' },
    locale,
  );
};

export const getLocalizeDateTimeTranslationOptions = (
  date: Moment.Moment | Dayjs | Date | string,
  formatOptions: ExtendedDateTimeStyle,
) => {
  const plainDateObj = getPlainDateObj(date);
  return {
    value: {
      dateTimeValue: plainDateObj,
      ...formatOptions,
    },
  };
};

export const getLocalizeDateTimeTranslationProps = (
  date: Moment.Moment | Dayjs | Date | string,
  formatOptions: ExtendedDateTimeStyle,
) => {
  const { LOCALIZED_DATETIME } = localizationKeys;
  return {
    key: LOCALIZED_DATETIME,
    options: getLocalizeDateTimeTranslationOptions(date, formatOptions),
  };
};

export const useLocalizeDateTime = (
  date: Moment.Moment | Dayjs | Date | string,
  formatOptions: ExtendedDateTimeStyle,
): string => {
  const { t } = useTranslation();
  const { key, options } = getLocalizeDateTimeTranslationProps(date, formatOptions);
  const dateTimeTranslatedString = t(key, options);

  return dateTimeTranslatedString;
};
