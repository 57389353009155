import { css } from '@emotion/react';
import { themed } from '../utils/styling/themed';
import { margins } from '../utils/styling/utilityClasses';
import { paddings } from '../utils/styling/utilityTokens';
import { breakpoints } from '../utils';

export const base = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.base};
    box-shadow: ${palette.shadow.componentShadow};
    border-radius: ${palette.border.radiusRegular};
    border: 1px solid ${palette.border.light};
    width: auto;
  `,
);

export const toast_base = themed(
  ({ palette, typography }) => css`
    background-color: ${palette.surface.base};
    ${paddings.y.comfortable}
    ${paddings.x.comfortable}
    position: relative;
    width: 320px;
    max-width: 320px;
    ${breakpoints.from('tablet')} {
      width: 400px;
      max-width: 400px;
    }

    border: none;
    border-radius: ${palette.border.radiusRegular} ${palette.border.radiusRegular} ${palette.border.radiusXSmall}
      ${palette.border.radiusXSmall};
    border-bottom: 4px solid;

    .MuiAlertTitle-root {
      ${typography.body2.default}
      ${typography.body2.semibold}
      /** Override Mui's default margin-top: -2px **/
      ${margins.y.m0}
    }

    .BlocksToast-message {
      ${typography.body2.default}
    }

    .MuiAlert-icon {
      display: inline-block;
    }

    .MuiAlert-action {
      ${paddings.yt.none}
    }
  `,
);

export const fade_in = css`
  animation: toast-fadein 0.1s ease-out forwards;

  @keyframes toast-fadein {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const fade_out = css`
  animation: toast-fadeout 0.1s ease-in forwards;

  @keyframes toast-fadeout {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0;
    }
  }
`;

export const warning_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.warning};
    }

    .MuiAlertTitle-root {
      color: ${palette.text.warning};
    }

    border-bottom: 4px solid ${palette.border.warning};

    .BlocksToast-message {
      color: ${palette.text.primary};
    }
  `,
);

export const error_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.error};
    }

    .MuiAlertTitle-root {
      color: ${palette.text.error};
    }

    border-bottom: 4px solid ${palette.border.error};

    .BlocksToast-message {
      color: ${palette.text.primary};
    }
  `,
);

export const success_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.success};
    }

    .MuiAlertTitle-root {
      color: ${palette.text.success};
    }

    border-bottom: 4px solid ${palette.border.success};

    .BlocksToast-message {
      color: ${palette.text.primary};
    }
  `,
);

export const info_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.infoLink};
    }

    .MuiAlertTitle-root {
      color: ${palette.text.link};
    }

    border-bottom: 4px solid ${palette.border.info};

    .BlocksToast-message {
      color: ${palette.text.primary};
    }
  `,
);

export const subtle_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.primary};
    }

    .BlocksToast-icon {
      animation: toast-spin 1s linear infinite;

      @keyframes toast-spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .MuiAlertTitle-root {
      color: ${palette.text.secondary};
    }

    border-bottom: 4px solid ${palette.border.active};

    .BlocksToast-message {
      color: ${palette.text.primary};
    }
  `,
);
