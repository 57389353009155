import last from 'lodash/last';
import first from 'lodash/first';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import type { Dictionary } from 'lodash';
import produce from 'immer';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import { BookingType } from '../types/api/v2/obt/model/booking-type';
import { getPercentageChange } from '../utils/getPercentageChange';
import { getTotalFareForFareAmount } from '../utils/Flights/rateOptionHelpers/getTotalFareForFareAmount';
import { getTotalPaxNumForPaxInfo } from '../utils/Flights/rateOptionHelpers/getTotalPaxNumForPaxInfo';
import { getPartialItineraryFareInfoFromRateOption } from '../utils/Flights/rateOptionHelpers/getPartialItineraryFareInfoForRateOption';
import type { Weight } from '../types/api/v1/obt/common/weight';

import { availableCabinFareCategories, availableNgsFareCategories } from '../constants/flights';
import { airlinesMap } from '../constants/flights/airlines';
import type {
  AirSearchResponse,
  Itinerary,
  DataFlightEquipment,
  MetadataAirline,
  RateOption,
  PaxInfoFareComponentLegInfo,
  PaxInfoFareComponentLegInfoFlightInfo,
  MetadataCurrentItinerary,
  MetadataFlightRestrictions,
  MetadataFlightRestrictionsRestriction,
  DataFlight,
  MetadataFareCategoryWithCode,
  FareAmount,
} from '../types/api/v1/obt/air/air_search_response';
import {
  FareTypeEnum,
  MetadataFareCategoryView,
  VendorProgramTypeEnum,
  RateOptionTicketType,
} from '../types/api/v1/obt/air/air_search_response';
import type { PaginationParams } from '../types/api/v1/obt/common/paginationParams';
import type { Upa, Uta, UtaPoints } from '../types/api/v1/obt/air/routehappy';
import type {
  IFlightAmenity,
  IFlightLegDetails,
  IFlightLegSummary,
  ILegUTA,
  Carrier,
  AmenityProps,
  UtaProps,
  IItinerarySelectedLeg,
  IFlightCodeDetails,
  IItineraryFareInfo,
  IItineraryFareInfoEnhanced,
  IItineraryFareInfoFarePassenger,
  IItineraryFareInfoFareFlight,
  IFlightInfo,
  IFlightMediaInfo,
  IFlightDetail,
  IAvailableFareCategoryViewType,
  IFlightLayoverInfo,
  IFlightWithStopsInfo,
  IItineraryCityInfo,
  ICurrentSelectedItineraryInfo,
  UnusedCreditInfo,
  IPolicyInfo,
  IRefundChangePolicy,
  IAirlineCodes,
  IFareShelfInfo,
  IGetLegSummaryProps,
  IGetLegDetailsProps,
  IGetEnhancedFareInfosForPreviousLegsProps,
  IGetAllFaresForRateOptions,
  GetFareInfoForRateOptionId,
  IGetPreferedLabelProps,
  IFareInfo,
  IGetItneraryFareInfoForRateOption,
  IFlightLegSummaryForLegIndex,
  IFlightLegSummaryForItinerary,
  IFlightLegSummaryInput,
  IRateOptionPreferredLabelProps,
  ItinerarySourceInfo,
  IItineraryFareInfoFareFlightEnhanced,
} from '../types/flight';
import {
  IFlightMediaInfoType,
  FlightDetailsInfoType,
  UtaPolicyAssessmentType,
  PolicyViolationInfoPredicate,
} from '../types/flight';
import { MoneyUtil } from '../utils/Money';
import {
  minutesToDurationString,
  getDurationString,
  getDateTimeDiff,
  getDurationMinutes,
  getDateDiff,
  getFormattedDateTime,
} from '../date-utils';
import type {
  FareCategory,
  PassengerAge,
  PassengerType,
  FlightNumber,
  Alliance,
} from '../types/api/v1/obt/air/air_common';
import {
  CabinEnum,
  FareCategoryCabinViewFareCategory,
  FareCategoryNGSFareCategory,
  PassengerTypeEnum,
} from '../types/api/v1/obt/air/air_common';
import type { Preference } from '../types/api/v1/obt/common/common';
import type { PolicyInfo } from '../types/api/v1/obt/common/policy_info';
import getLayoverInformationFromHiddenStop from '../utils/Flights/getLayoverInformationFromHiddenStop';
import getFlightDateTimeAirportInformation from '../utils/Flights/getFlightDateTimeAirportInformation';
import { getIsFareNegotiated } from '../utils/negotiatedFares';
import { dateFormats, timeFormats } from '../constants/common';
import { getFlightNumberDisplayText } from '../utils/Flights/getFlightNumberDisplayText';
import type { ThirdPartySource } from '../types/api/v1/obt/supplier/third_party_info';
import { defineMessage } from '../translations/defineMessage';
import { getItinerarySourceLabelAir } from '../utils/Flights/getItinerarySourceLabelAir';
import { mergeRateOptionRight } from '../utils/mergeRateOptionRight';

import type { ICarbonEmissionsConfig } from '../types/common';
import { FarePolicyManager } from './FarePolicyManager';
import { getDisplayTextUTA } from '../utils/Flights/getDisplayTextUTA';

export default class AirSearchResponseManager {
  constructor(protected readonly response: AirSearchResponse, routeHappyResponse?: AirSearchResponse) {
    if (!response) {
      throw new Error('Invalid Air Search Response passed to AirSearchResponseManager');
    }

    if (!routeHappyResponse) {
      return;
    }

    const allItineraries = routeHappyResponse.itineraries;
    const allRateOptions = allItineraries.flatMap((itinerary) => itinerary.rateOptions);
    const allRateOptionsById = keyBy(allRateOptions, (rateOption) => rateOption.rateOptionId);

    this.response = produce(response, (draftResponse) => {
      draftResponse.data.amenities = routeHappyResponse.data.amenities;
      draftResponse.data.utas = routeHappyResponse.data.utas;
      draftResponse.data.upas = routeHappyResponse.data.upas;

      if (draftResponse.currSelectedItinerary) {
        draftResponse.currSelectedItinerary.rateOptions.forEach((rateOption) => {
          const routeHappyRateOption = allRateOptionsById[rateOption.rateOptionId];
          if (routeHappyRateOption) {
            mergeRateOptionRight(rateOption, routeHappyRateOption);
          }
        });
      }

      draftResponse.itineraries.forEach((itinerary) => {
        itinerary.rateOptions.forEach((rateOption) => {
          const routeHappyRateOption = allRateOptionsById[rateOption.rateOptionId];
          if (routeHappyRateOption) {
            mergeRateOptionRight(rateOption, routeHappyRateOption);
          }
        });
      });
    });
  }

  /** *******************************
   ****** static methods **********
   ******************************* */

  private static GetFlightCabin(itinerary: Itinerary, legNumber: number, flightIndex: number): string {
    const flightCabin =
      itinerary.rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber].flightInfo[flightIndex].cabin;
    return CabinEnum[flightCabin];
  }

  /** *******************************
   ****** public methods **********
   ******************************* */

  public getCurrencyCode(): string {
    const itinerariesCount = this.GetItineraryCount();
    const currency = itinerariesCount > 0 && this.GetBaseFare(0).getCurrencyInfo();
    return currency ? currency.code : '';
  }

  public GetItineraryCount(): number {
    return this.response.itineraries.length;
  }

  public GetTicketType(): RateOptionTicketType {
    return this.GetTicketTypeForItineraryIndex(0);
  }

  public GetTicketTypeForItineraryIndex(itinIndex: number): RateOptionTicketType {
    return this.response.itineraries[itinIndex].rateOptions[0].ticketType;
  }

  public GetItineraryAllLegsForItineraryIndex(itinIndex: number): IItinerarySelectedLeg[] {
    const itinerary = this.response.itineraries[itinIndex];
    const legs = itinerary?.legIndices ?? [];
    return legs.map((legIndex, legNumber) => ({ legIndex, legNumber, isLastLeg: false }));
  }

  public GetItineraryAllAirlinesForItineraryIndex(itinIndex: number, currLegNumber?: number): IAirlineCodes[] {
    const itineraryLegs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);
    let filteredItineraryLegs = [...itineraryLegs];
    if (currLegNumber !== undefined) {
      filteredItineraryLegs = itineraryLegs.filter((itineraryLeg) => itineraryLeg.legNumber === currLegNumber);
    }

    const itineraryAirlines: IAirlineCodes[] = [];
    filteredItineraryLegs.forEach((itineraryLeg) => {
      const { legIndex } = itineraryLeg;
      const flightIndices = this.GetLegFlights(legIndex);
      flightIndices.forEach((flightIndex) => {
        const { operating, marketing } = this.GetFlightAirline(flightIndex);
        itineraryAirlines.push({ operating: operating.airline, marketing: marketing.airline });
      });
    });
    return itineraryAirlines;
  }

  public GetIsAnyUAFlightPresent(itinIndex: number, currLegNumber?: number): boolean {
    const itineraryAirlines = this.GetItineraryAllAirlinesForItineraryIndex(itinIndex, currLegNumber);
    return itineraryAirlines.some((itineraryAirline) => itineraryAirline.marketing === 'UA');
  }

  public GetSummaryLegDetailsForItineraryIndex(
    legNumber: number,
    legIndex: number,
    itinIndex: number,
  ): IFlightLegDetails[] {
    const itinerary = this.response.itineraries[itinIndex];
    const legDetails = this.GetLegDetailsFromLegIndexAndItinerary(legIndex, legNumber, itinerary);
    return [legDetails];
  }

  public GetItineraryAllFlightsForItineraryIndex(itinIndex: number): IFlightDetail[] {
    const itineraryLegs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);
    const itineraryFlights: IFlightDetail[] = [];

    itineraryLegs.forEach((itineraryLeg) => {
      const legDetails = this.GetSummaryLegDetailsForItineraryIndex(
        itineraryLeg.legNumber,
        itineraryLeg.legIndex,
        itinIndex,
      );
      itineraryFlights.push(...legDetails[0].flightDetails);
    });

    return itineraryFlights;
  }

  public isOutOfPolicyForItineraryAndRateOptionIndex(
    legNumber: number,
    itinIndex: number,
    rateOptionIndex: number,
  ): boolean {
    const farePolicyManager = new FarePolicyManager({
      bookingType: BookingType.Air,
      policyInfo: this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.policyInfos?.[legNumber],
    });
    return farePolicyManager.checkFareOutOfPolicy();
  }

  public getPolicyInfoForLegWithItineraryAndRateOptionIndex(
    legNumber: number,
    itinIndex: number,
    rateOptionIndex: number,
  ): IPolicyInfo | undefined {
    return this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.policyInfos?.[legNumber];
  }

  public GetLegUTAsForItineraryIndex(legNumber: number, itinIndex: number) {
    const itinerary = this.response.itineraries[itinIndex];
    return this.GetLegUTAs(itinerary, legNumber);
  }

  public GetShowRawFareRulesForItineraryIndex = (itinIndex = 0): boolean => {
    const itinerary = this.response.itineraries[itinIndex];
    const { showRawFareRules } = itinerary.rateOptions[0];
    return showRawFareRules;
  };

  public GetRefundChangePolicyForItineraryIndex(itinIndex: number): IRefundChangePolicy {
    const legs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);
    const ticketType = this.GetTicketTypeForItineraryIndex(itinIndex);

    const isApplicableItinerary = legs.length > 1 && ticketType === RateOptionTicketType.SINGLE;

    if (!isApplicableItinerary) {
      return {
        isNonChangeableOrRefundable: false,
        refundable: true,
        changeable: true,
      };
    }

    const legUTAs = this.GetLegUTAsForItineraryIndex(legs.length - 1, itinIndex);

    const isNonRefundable =
      legUTAs.find((legUta) => legUta.uta?.cancellation?.assessmentType)?.uta?.cancellation?.assessmentType ===
      UtaPolicyAssessmentType.RESTRICTION;

    const isNonChangeable =
      legUTAs.find((legUta) => legUta.uta?.advanceChange?.assessmentType)?.uta?.advanceChange?.assessmentType ===
      UtaPolicyAssessmentType.RESTRICTION;

    const isNonChangeableOrRefundable = isNonRefundable || isNonChangeable;

    return {
      isNonChangeableOrRefundable,
      refundable: !isNonRefundable,
      changeable: !isNonChangeable,
    };
  }

  /**
   * This method is used to get the itinerary index that is considered the closest
   * match to show the 'Previously Booked' label in the search result page
   *
   * Used in the Rebook Flights flow.
   */
  public GetClosestMatchFlightItineraryIndex() {
    const { itineraries } = this.response;
    const matchedIndex = itineraries.findIndex((itinerary) =>
      get(itinerary, 'rateOptions[0].cloneInfo.isClosestMatch'),
    );

    if (matchedIndex === -1) {
      return null;
    }

    return matchedIndex;
  }

  public GetPolicyInfos(): PolicyInfo[] {
    return this.response.itineraries[0].rateOptions[0].policyInfos;
  }

  public GetLegs(itinIndex: number): number[] {
    const { legIndices } = this.response.itineraries[itinIndex];
    return legIndices.map((legInfo: number) => legInfo);
  }

  public GetTotalFlightsTillLeg(selectedLegs: IItinerarySelectedLeg[], legIndex: number): number {
    return selectedLegs
      .filter((_leg, index) => index < legIndex)
      .reduce((total, leg) => total + this.response.data.legs[leg.legIndex].flightIndices.length, 0);
  }

  public GetBaseFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const base = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.totalFare?.base;
    return MoneyUtil.parse(base);
  }

  public GetChangeFee(itinIndex: number, rateOptionIndex: number): MoneyUtil {
    const changeFee = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.changeFee?.amount;
    return MoneyUtil.parse(changeFee);
  }

  public GetLegBaseFarePerPax(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const baseLegFarePerPax =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[0]
        .legFarePerPax?.base;

    return MoneyUtil.parse(baseLegFarePerPax);
  }

  public GetLegTaxFarePerPax(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const taxLegFarePerPax =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[0]
        .legFarePerPax?.tax;

    return MoneyUtil.parse(taxLegFarePerPax);
  }

  public GetLegFarePerPax(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    return this.GetLegBaseFarePerPax(itinIndex, rateOptionIndex).add(
      this.GetLegTaxFarePerPax(itinIndex, rateOptionIndex),
    );
  }

  public GetTotalLegBaseFare(itinIndex: number, rateOptionIndex = 0, legIndex = 0): MoneyUtil {
    const totalBaselegFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[legIndex]
        .totalLegFare?.base;

    return MoneyUtil.parse(totalBaselegFare);
  }

  public GetTotalLegTaxFare(itinIndex: number, rateOptionIndex = 0, legIndex = 0): MoneyUtil {
    const totalTaxlegFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[legIndex]
        .totalLegFare?.tax;

    return MoneyUtil.parse(totalTaxlegFare);
  }

  public GetTotalLegFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    return this.GetTotalLegBaseFare(itinIndex, rateOptionIndex).add(
      this.GetTotalLegTaxFare(itinIndex, rateOptionIndex),
    );
  }

  public getLegPreferences({ itinIndex, rateOptionIndex, legNumber }: IGetPreferedLabelProps): Preference[] {
    return (
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[legNumber]
        .preferences ?? []
    );
  }

  public getLegPreferencesForRateOptions({ rate, legNumber }: IRateOptionPreferredLabelProps): Preference[] {
    const passengers = rate.paxInfo;
    return passengers[0].fareComponents[0].legInfo[legNumber].preferences ?? [];
  }

  public GetCheapestPublishedBaseFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const base = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.totalFare?.base;
    const cheapestPublishedBaseFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.cheapestPublishedFare?.base;

    if (!cheapestPublishedBaseFare) {
      return MoneyUtil.zeroMoneyWithOriginal(base.convertedCurrency || 'USD', base.currencyCode);
    }

    return MoneyUtil.parse(cheapestPublishedBaseFare);
  }

  public GetLegCheapestPublishedBaseFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const base =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[0]
        .totalLegFare?.base;
    const legCheapestPublishedBaseFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[0]
        .totalCheapestLegFare?.base;

    if (!legCheapestPublishedBaseFare && base) {
      return MoneyUtil.zeroMoneyWithOriginal(base.convertedCurrency || 'USD', base.currencyCode);
    }

    return MoneyUtil.parse(legCheapestPublishedBaseFare);
  }

  public GetTax(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const tax = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.totalFare?.tax;
    return MoneyUtil.parse(tax);
  }

  public GetMerchantFee(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const { merchantFee } = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex];

    return MoneyUtil.parse(merchantFee);
  }

  public GetTotalPaxNum = (itinIndex: number, rateOptionIndex = 0): number =>
    sumBy(this.response.itineraries[itinIndex].rateOptions[rateOptionIndex].paxInfo, 'numPax') ?? 1;

  public GetCheapestPublishedTax(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const base = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.totalFare?.base;
    const cheapestPublishedTaxFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.cheapestPublishedFare?.tax;

    if (!cheapestPublishedTaxFare) {
      return MoneyUtil.zeroMoneyWithOriginal(base.convertedCurrency || 'USD', base.currencyCode);
    }
    return MoneyUtil.parse(cheapestPublishedTaxFare);
  }

  public GetLegCheapestPublishedTax(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    const tax =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[0]
        .totalLegFare?.tax;
    const cheapestPublishedTaxFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex]?.paxInfo[0].fareComponents[0].legInfo[0]
        .totalCheapestLegFare?.tax;

    if (!cheapestPublishedTaxFare && tax) {
      return MoneyUtil.zeroMoneyWithOriginal(tax.convertedCurrency || 'USD', tax.currencyCode);
    }
    return MoneyUtil.parse(cheapestPublishedTaxFare);
  }

  public GetPaxInfoIndex(
    paxType: PassengerTypeEnum,
    paxAge: PassengerAge | undefined,
    itinIndex: number,
    rateOptionIndex: number,
  ): number {
    const isChildPassenger = paxType === PassengerTypeEnum.CHILD;
    const { itineraries } = this.response;
    const { rateOptions } = itineraries[itinIndex];
    const paxInfoArray = rateOptions[rateOptionIndex].paxInfo;

    const index = paxInfoArray.findIndex((paxInfo) => {
      if (paxInfo.paxType !== paxType) {
        return false;
      }

      if (isChildPassenger) {
        return paxInfo.paxAge?.years === paxAge?.years;
      }

      return true;
    });

    return index;
  }

  public getPaxBaseFare(paxNumber: number, itinIndex: number, rateOptionIndex: number): MoneyUtil {
    const baseFare =
      this.response.itineraries[itinIndex].rateOptions[rateOptionIndex].paxInfo[paxNumber].farePerPax?.base;
    return MoneyUtil.parse(baseFare);
  }

  public getPaxTax(paxNumber: number, itinIndex: number, rateOptionIndex: number): MoneyUtil {
    const tax = this.response.itineraries[itinIndex].rateOptions[rateOptionIndex].paxInfo[paxNumber].farePerPax?.tax;
    return MoneyUtil.parse(tax);
  }

  public GetPrimaryTravelerBaseFare(itinIndex: number, rateOptionIndex: number): MoneyUtil {
    const paxNumber = this.GetPaxInfoIndex(PassengerTypeEnum.ADULT, undefined, itinIndex, rateOptionIndex);
    // If paxType is Unrecognized / Unknown then, send Total Fare
    const isInvalidPaxNumber = paxNumber === -1;
    if (isInvalidPaxNumber) {
      return this.GetBaseFare(itinIndex, rateOptionIndex);
    }

    return this.getPaxBaseFare(paxNumber, itinIndex, rateOptionIndex);
  }

  public GetPrimaryTravelerTaxFare(itinIndex: number, rateOptionIndex: number): MoneyUtil {
    const paxNumber = this.GetPaxInfoIndex(PassengerTypeEnum.ADULT, undefined, itinIndex, rateOptionIndex);
    // If paxType is Unrecognized / Unknown then, send Total Fare
    const isInvalidPaxNumber = paxNumber === -1;
    if (isInvalidPaxNumber) {
      return this.GetTax(itinIndex, rateOptionIndex);
    }

    return this.getPaxTax(paxNumber, itinIndex, rateOptionIndex);
  }

  public GetPrimaryTravelerFare(itinIndex: number, rateOptionIndex: number): MoneyUtil {
    return this.GetPrimaryTravelerBaseFare(itinIndex, rateOptionIndex).add(
      this.GetPrimaryTravelerTaxFare(itinIndex, rateOptionIndex),
    );
  }

  public GetTotalFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    return this.GetBaseFare(itinIndex, rateOptionIndex).add(this.GetTax(itinIndex, rateOptionIndex));
  }

  public GetTotalCheapestPublishedFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    return this.GetCheapestPublishedBaseFare(itinIndex, rateOptionIndex).add(
      this.GetCheapestPublishedTax(itinIndex, rateOptionIndex),
    );
  }

  public GetTotalLegCheapestPublishedFare(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    return this.GetLegCheapestPublishedBaseFare(itinIndex, rateOptionIndex).add(
      this.GetLegCheapestPublishedTax(itinIndex, rateOptionIndex),
    );
  }

  public GetNegotiatedSavings(itinIndex: number, rateOptionIndex = 0): MoneyUtil {
    return this.GetTotalCheapestPublishedFare(itinIndex, rateOptionIndex).subtract(
      this.GetTotalFare(itinIndex, rateOptionIndex),
    );
  }

  public getRateOptionFareType(itinIndex: number, rateOptionIndex: number): FareTypeEnum {
    return this.response.itineraries[itinIndex].rateOptions[rateOptionIndex].paxInfo[0].fareComponents[0].legInfo[0]
      .fareType;
  }

  // TODO: Extend this method going ahead, when we support multi passenger.
  private static getPassenger(fareInfo: IItineraryFareInfo): IItineraryFareInfoFarePassenger {
    return fareInfo.passenger[0];
  }

  public static getBrandName(fareInfo: IItineraryFareInfo): string {
    const passenger = AirSearchResponseManager.getPassenger(fareInfo);
    return passenger.brandName;
  }

  public static getCabins(fareInfo: IItineraryFareInfo): string[] {
    const passenger = AirSearchResponseManager.getPassenger(fareInfo);
    return passenger.flights.map((flightInfo) => CabinEnum[flightInfo.cabin]);
  }

  public static getBookingCodes(fareInfo: IItineraryFareInfo): string {
    const passenger = AirSearchResponseManager.getPassenger(fareInfo);
    return passenger.flights.map((f) => f.bookingCode).join(',');
  }

  public static getIsCorporateRate(fareInfo: IItineraryFareInfo): boolean {
    const passenger = AirSearchResponseManager.getPassenger(fareInfo);
    return passenger.fareType === FareTypeEnum.CORPORATE;
  }

  public static getIsUatpPassPlusRate(fareInfo: IItineraryFareInfo): boolean {
    const passenger = AirSearchResponseManager.getPassenger(fareInfo);
    return passenger.vendorProgramType === VendorProgramTypeEnum.UA_PASS_PLUS;
  }

  public static getIsMixedCabin(fareInfo: IItineraryFareInfo): boolean {
    const passenger = AirSearchResponseManager.getPassenger(fareInfo);
    return AirSearchResponseManager.hasMultipleUniqueCabins(passenger.flights.map((flightInfo) => flightInfo.cabin));
  }

  public static hasMultipleUniqueCabins(cabins: IItineraryFareInfoFareFlight['cabin'][]): boolean {
    return new Set(cabins).size > 1;
  }

  public static isFareOutOfPolicy(policyInfo?: PolicyInfo): boolean {
    if (!policyInfo) {
      return false;
    }

    if (policyInfo.violationMessage) {
      return true;
    }

    if (!policyInfo.ruleResultInfos || policyInfo.ruleResultInfos.length < 1) {
      return false;
    }

    return this.getFareOutOfPolicyRuleResultInfos(policyInfo).length > 0;
  }

  public static getFareOutOfPolicyRuleResultInfos(policyInfo?: PolicyInfo): PolicyInfo['ruleResultInfos'] {
    if (!policyInfo) {
      return [];
    }

    const farePolicyManager = new FarePolicyManager({
      policyInfo,
      bookingType: BookingType.Air,
    });
    return farePolicyManager.getOutOfPolicyRuleResultInfos();
  }

  public static isFareNotAllowed(policyInfo?: PolicyInfo): boolean {
    if (!policyInfo) {
      return false;
    }

    if (!policyInfo.ruleResultInfos || policyInfo.ruleResultInfos.length < 1) {
      return false;
    }

    return this.getFareNotAllowedRuleResultInfos(policyInfo).length > 0;
  }

  public static getFareNotAllowedRuleResultInfos(policyInfo?: PolicyInfo): PolicyInfo['ruleResultInfos'] {
    if (!policyInfo) {
      return [];
    }

    const farePolicyManager = new FarePolicyManager({
      policyInfo,
      bookingType: BookingType.Air,
    });
    return farePolicyManager.getNotAllowedRuleResultInfos();
  }

  public static getStopsInfoForFlightResult(airports: { code: string; name: string }[], layovers: number[]): string {
    if (airports.length <= 2) {
      return defineMessage('Non-Stop');
    }

    if (airports.length === 3) {
      const hasLayover = layovers.length > 0;
      const stopAirportCode = airports[1].code;

      let stopInfo = stopAirportCode;
      if (hasLayover && layovers[0] > 0) {
        const duration = minutesToDurationString(layovers[0]);
        stopInfo += ` (${duration})`;
      }
      return stopInfo;
    }

    return airports
      .slice(1, -1)
      .map((ap) => ap.code)
      .join(', ');
  }

  public static getTotalFareForFareInfo(fareInfo: IItineraryFareInfo | undefined): MoneyUtil {
    if (!fareInfo) {
      return MoneyUtil.zeroMoney();
    }
    return fareInfo.shouldShowLegBasedPricing ? fareInfo.totalLegFare : fareInfo.totalFare;
  }

  public static getTotalBaseFareForFareInfo(fareInfo: IItineraryFareInfo): MoneyUtil {
    return fareInfo.shouldShowLegBasedPricing ? fareInfo.totalLegBaseFare : fareInfo.totalItinBaseFare;
  }

  public static getTotalTaxFareForFareInfo(fareInfo: IItineraryFareInfo): MoneyUtil {
    return fareInfo.shouldShowLegBasedPricing ? fareInfo.totalLegTaxFare : fareInfo.totalItinTaxFare;
  }

  public static getPerPaxLegBaseFareForFareInfo(fareInfo: IItineraryFareInfo): MoneyUtil {
    return fareInfo.shouldShowLegBasedPricing ? fareInfo.legBaseFarePerPax : fareInfo.itinBaseFarePerPax;
  }

  public static getPerPaxLegTaxFareForFareInfo(fareInfo: IItineraryFareInfo): MoneyUtil {
    return fareInfo.shouldShowLegBasedPricing ? fareInfo.legTaxFarePerPax : fareInfo.itinTaxFarePerPax;
  }

  public static getPerPaxLegFareForFareInfo(fareInfo: IItineraryFareInfo): MoneyUtil {
    return fareInfo.shouldShowLegBasedPricing ? fareInfo.legFarePerPax : fareInfo.farePerPax;
  }

  public static getPerPaxCheapestLegFareForFareInfo(fareInfo: IItineraryFareInfo, totalPaxNum: number): MoneyUtil {
    const fare = fareInfo.shouldShowLegBasedPricing
      ? fareInfo.totalLegCheapestPublishedFare
      : fareInfo.totalCheapestPublishedFare;
    return fare.divide(totalPaxNum);
  }

  public getFareCategoryViewType(): IAvailableFareCategoryViewType {
    const metaDataFareCategoryView = this.response.metadata?.fareCategoryView;
    if (
      metaDataFareCategoryView !== MetadataFareCategoryView.NGS &&
      metaDataFareCategoryView !== MetadataFareCategoryView.CABIN
    ) {
      return MetadataFareCategoryView.NGS;
    }

    return metaDataFareCategoryView;
  }

  public getMinFareForItinerary(itinIndex: number): IItineraryFareInfo | undefined {
    const fares = this.getAllFaresForItineraryIndex(itinIndex);
    return AirSearchResponseManager.getMinFareForFares(fares);
  }

  public areAllFaresOutOfPolicyForItinerary(itinIndex: number): boolean {
    const fares = this.getAllFaresForItineraryIndex(itinIndex);

    return fares.every((fareInfo) => {
      if (!fareInfo.policyInfo) {
        return false;
      }
      const farePolicyManager = new FarePolicyManager({
        bookingType: BookingType.Air,
        policyInfo: fareInfo.policyInfo,
      });
      return farePolicyManager.checkFareOutOfPolicy();
    });
  }

  public static isFareMoreThanLLFViolation(fare: IItineraryFareInfo) {
    const { policyInfo } = fare;
    const hasPriceMoreThanLLFViolation = policyInfo?.ruleResultInfos?.find((ruleResultInfo) =>
      ruleResultInfo.violationInfos?.find(
        (violationInfos) =>
          violationInfos.predicate ===
            PolicyViolationInfoPredicate.AIR_MAX_PRICE_MORE_THAN_LLF_VIOLATION_EXCLUDING_TAX ||
          violationInfos.predicate === PolicyViolationInfoPredicate.AIR_MAX_PRICE_MORE_THAN_LLF_VIOLATION_INCLUDING_TAX,
      ),
    );
    return !!hasPriceMoreThanLLFViolation;
  }

  public getFaresGroupedByFareType(itinIndex: number): Dictionary<IItineraryFareInfo[]> {
    const fareCategoryViewType = this.getFareCategoryViewType();
    const fares = this.getAllFaresForItineraryIndex(itinIndex);
    const isNgsCategoryView = fareCategoryViewType === MetadataFareCategoryView.NGS;

    return groupBy(fares, (fare) => {
      const ngsCategory =
        fare.passenger[0]?.fareCategory?.ngsCategory ?? FareCategoryNGSFareCategory.UNKNOWN_NGS_CATEGORY;
      const cabinCategory =
        fare.passenger[0]?.fareCategory?.cabinViewCategory ?? FareCategoryCabinViewFareCategory.UNKNOWN_CABIN_CATEGORY;

      return isNgsCategoryView ? ngsCategory : cabinCategory;
    });
  }

  public getItineraryHasNegotiatedFares(itinIndex: number): boolean {
    const fares = this.getAllFaresForItineraryIndex(itinIndex);

    return fares.some((fareInfo) => getIsFareNegotiated(fareInfo.totalFare, fareInfo.fareType));
  }

  public getItineraryHasUnusedCreditsApplicable(itinIndex: number): boolean {
    const { rateOptions } = this.response.itineraries[itinIndex];

    return rateOptions.some((rate) => rate.hasApplicableCredits);
  }

  public getUnusedCreditsSearchResultsApplicable(): UnusedCreditInfo[] {
    return this.response.unusedCredit;
  }

  public getFlightsCountForUnusedCreditsApplicable(): number {
    let count = 0;
    const itinerariesLength = this.response.itineraries.length;
    for (let i = 0; i < itinerariesLength; i += 1) {
      if (this.getItineraryHasUnusedCreditsApplicable(i)) {
        count += 1;
      }
    }
    return count;
  }

  public static getMinFareForFares(fares: IItineraryFareInfo[]): IItineraryFareInfo | undefined {
    return fares[0];
  }

  public getFaresEnhanced(itinIndex: number): IItineraryFareInfoEnhanced[] {
    const fareCategoryViewType = this.getFareCategoryViewType();

    const isNGSCategories = fareCategoryViewType === MetadataFareCategoryView.NGS;

    if (isNGSCategories) {
      return flatten(
        availableNgsFareCategories.map((ngsCategory) =>
          this.getFaresEnhancedForFareCategory(itinIndex, {
            ngsCategory,
            cabinViewCategory: undefined,
          }),
        ),
      );
    }

    if (!isNGSCategories) {
      return flatten(
        availableCabinFareCategories.map((cabinViewCategory) =>
          this.getFaresEnhancedForFareCategory(itinIndex, {
            ngsCategory: undefined,
            cabinViewCategory,
          }),
        ),
      );
    }

    return [];
  }

  public getCurrentItinerary(): MetadataCurrentItinerary | undefined {
    return this.response.metadata?.currentItinerary;
  }

  public getIsLegbyLegPricingEnabled(): boolean {
    return !!this.response.metadata?.legByLegPricing;
  }

  public getFaresEnhancedForFareCategory(itinIndex: number, fareCategory: FareCategory): IItineraryFareInfoEnhanced[] {
    const fareCategoryViewType = this.getFareCategoryViewType();
    const fares = this.getAllFaresForItineraryIndexEnhanced(itinIndex);
    const isNgsCategoryView = fareCategoryViewType === MetadataFareCategoryView.NGS;

    const filteredFares = fares.filter((fareInfo) => {
      const { ngsCategory, cabinViewCategory: cabinCategory } = fareInfo.passenger[0].fareCategory ?? {};

      return isNgsCategoryView
        ? ngsCategory === fareCategory.ngsCategory
        : cabinCategory === fareCategory.cabinViewCategory;
    });
    return filteredFares;
  }

  static LookupForAircraftType(amenities: IFlightAmenity[]) {
    const needle = amenities.find(({ key }) => key === 'aircraft');

    return needle?.displayText;
  }

  public getAircraftType(itinIndex: number, flightNumber = 0): string {
    const fares = this.getAllFaresForItineraryIndexEnhanced(itinIndex);
    const amenities = fares?.[0]?.passenger?.[0]?.flights?.[flightNumber]?.amenities ?? [];

    return AirSearchResponseManager.LookupForAircraftType(amenities) ?? '';
  }

  public static getFlightMediaInfosForFare(fare: IItineraryFareInfoEnhanced, flightIndex: number): IFlightMediaInfo[] {
    const allUpas = fare.passenger[0].flights[flightIndex].upas;

    const upaMediaInfos = allUpas
      .map((upa) => {
        const isPhoto = upa.photo && upa.photo.length > 0;
        const isVideo = upa.video && upa.video.length > 0;

        if (!isPhoto && !isVideo) {
          return null;
        }

        const mediaType = isPhoto ? IFlightMediaInfoType.PHOTO : IFlightMediaInfoType.VIDEO;
        const { description, headline } = upa;

        const sortedImages = sortBy(upa.photo[0]?.images, (imageInfo) => {
          const width = imageInfo.dimensions?.width || 1;
          const height = imageInfo.dimensions?.height || 1;

          return width * height;
        });

        const thumbnailUrl = isPhoto ? first(sortedImages)?.url : first(upa.video[0].images)?.url;
        const mediaUrl = isPhoto ? last(sortedImages)?.url : last(upa.video[0].images)?.url;

        if (!mediaUrl) {
          return null;
        }

        return { mediaType, headline, description, mediaUrl, thumbnailUrl };
      })
      .filter((info) => !!info) as IFlightMediaInfo[];

    return upaMediaInfos;
  }

  static getFareInfoFromTotalFare(totalFare: FareAmount): IFareInfo {
    const { base, tax } = totalFare;
    const baseMoney = MoneyUtil.parse(base);
    const taxMoney = MoneyUtil.parse(tax);

    const points = baseMoney.getQantasPoints()?.amount || 0;
    const isQantasRewardsFare = baseMoney.isAmountZero() && points > 0;

    const totalAmount = baseMoney.add(taxMoney);

    return {
      totalAmount,
      qantasPoints: isQantasRewardsFare ? points : 0,
      shouldShowAmount: totalAmount.getAmount() > 0,
    };
  }

  public GetIsQantasRewardsFare(baseFare: MoneyUtil): boolean {
    const points = baseFare.getQantasPoints()?.amount || 0;
    return baseFare.isAmountZero() && points > 0;
  }

  private getAllFaresForRateOptions({
    rateOptions,
    itinIndex,
    legNumber,
    legIndex,
  }: IGetAllFaresForRateOptions): IItineraryFareInfo[] {
    return rateOptions.map((rate, rateOptionIndex) => {
      const baseFare = rate.paxInfo[0]?.fareComponents[0]?.legInfo[0]?.totalLegFare?.base;
      return {
        data: rate,
        rateOptionId: rate.rateOptionId,
        shouldShowLegBasedPricing: this.getIsLegbyLegPricingEnabled(),

        totalPaxNum: this.GetTotalPaxNum(itinIndex, rateOptionIndex),

        totalFare: this.GetTotalFare(itinIndex, rateOptionIndex),
        totalLegFare: this.GetTotalLegFare(itinIndex, rateOptionIndex),

        farePerPax: this.GetPrimaryTravelerFare(itinIndex, rateOptionIndex),
        legFarePerPax: this.GetLegFarePerPax(itinIndex, rateOptionIndex),

        itinBaseFarePerPax: this.GetPrimaryTravelerBaseFare(itinIndex, rateOptionIndex),
        itinTaxFarePerPax: this.GetPrimaryTravelerTaxFare(itinIndex, rateOptionIndex),

        legBaseFarePerPax: this.GetLegBaseFarePerPax(itinIndex, rateOptionIndex),
        legTaxFarePerPax: this.GetLegTaxFarePerPax(itinIndex, rateOptionIndex),

        totalItinBaseFare: this.GetBaseFare(itinIndex, rateOptionIndex),
        totalItinTaxFare: this.GetTax(itinIndex, rateOptionIndex),
        totalLegBaseFare: this.GetTotalLegBaseFare(itinIndex, rateOptionIndex),
        totalLegTaxFare: this.GetTotalLegTaxFare(itinIndex, rateOptionIndex),

        totalCheapestPublishedFare: this.GetTotalCheapestPublishedFare(itinIndex, rateOptionIndex),
        totalLegCheapestPublishedFare: this.GetTotalLegCheapestPublishedFare(itinIndex, rateOptionIndex),
        fareType: this.getRateOptionFareType(itinIndex, rateOptionIndex),
        policyInfo: rate.policyInfo,
        policyInfos: rate.policyInfos,
        passenger: this.getFarePassengerInfoForRateOption(rate, legIndex, legNumber),
        allowedFop: rate?.allowedFop ?? [],
        preferences: this.getLegPreferencesForRateOptions({ rate, legNumber }),
        isQantasRewardsFare: this.GetIsQantasRewardsFare(MoneyUtil.parse(baseFare)),
      };
    });
  }

  public getItineraryFareInfoFromRateOptions({
    rateOptions,
    legIndex,
    legNumber,
  }: IGetItneraryFareInfoForRateOption): IItineraryFareInfo[] {
    return rateOptions.map((rateOption) => {
      const partialItineraryFareInfo = getPartialItineraryFareInfoFromRateOption(rateOption, legNumber);
      const baseFare = rateOption.paxInfo[0]?.fareComponents[0]?.legInfo[0]?.totalLegFare?.base;

      return {
        ...partialItineraryFareInfo,
        shouldShowLegBasedPricing: this.getIsLegbyLegPricingEnabled(),
        passenger: this.getFarePassengerInfoForRateOption(rateOption, legIndex, legNumber),
        isQantasRewardsFare: this.GetIsQantasRewardsFare(MoneyUtil.parse(baseFare)),
      };
    });
  }

  public getFareInfoEnhancedFromRateOptions = (
    rateOptions: RateOption[],
    legIndex: number,
    legNumber = 0,
  ): IItineraryFareInfoEnhanced[] => {
    const fareInfos = this.getItineraryFareInfoFromRateOptions({ rateOptions, legIndex, legNumber });
    return this.getEnhancedFareInfo(fareInfos, legNumber);
  };

  public getAllFaresForItineraryIndex(itinIndex: number, legNumber = 0): IItineraryFareInfo[] {
    const { rateOptions, legIndices } = this.response.itineraries[itinIndex];
    const legIndex = legIndices[legNumber];

    return this.getAllFaresForRateOptions({ rateOptions, itinIndex, legNumber, legIndex });
  }

  public getFareInfoOfRateOptionId = ({
    itinIndex,
    legNumber,
    rateOptionId,
  }: GetFareInfoForRateOptionId): IItineraryFareInfo | undefined => {
    const allFares = this.getAllFaresForItineraryIndex(itinIndex, legNumber);
    const fareInfoOfRateOptionId = allFares.find((fare) => fare.rateOptionId === rateOptionId);
    return fareInfoOfRateOptionId;
  };

  public getFareInfoEnhancedOfRateOptionId = ({
    itinIndex,
    legNumber,
    rateOptionId,
  }: GetFareInfoForRateOptionId): IItineraryFareInfoEnhanced | undefined => {
    const allFares = this.getAllFaresForItineraryIndexEnhanced(itinIndex, legNumber);
    const fareInfoOfRateOptionId = allFares.find((fare) => fare.rateOptionId === rateOptionId);
    return fareInfoOfRateOptionId;
  };

  /**
   * Make sure this method is called either on:
   * 1. AirSearchResponseManager where asyncRouteHappy was false.
   * 2. AirSearchResponseManager initialized with both airSearchResponse and airRouteHappyResponse.
   */
  public getAllFaresForItineraryIndexEnhanced(itinIndex: number, legNumber = 0): IItineraryFareInfoEnhanced[] {
    const fareInfos = this.getAllFaresForItineraryIndex(itinIndex, legNumber);
    return this.getEnhancedFareInfo(fareInfos, legNumber);
  }

  private getFarePassengerInfoForRateOption(
    rateOption: RateOption,
    legIndex: number,
    legNumber: number,
  ): IItineraryFareInfoFarePassenger[] {
    const passengers = rateOption.paxInfo;
    return passengers.map((paxInfo) => {
      const fareLegInfo = paxInfo.fareComponents[0].legInfo[legNumber];
      return {
        data: { rateOption, legIndex, legNumber },
        fareType: fareLegInfo.fareType,
        fareCategory: fareLegInfo.fareCategory,
        brandName: fareLegInfo.brandName,
        brandCode: fareLegInfo.brandCode,
        flights: this.getFareFlightInfoForLegInfo(fareLegInfo, legIndex),
        vendorProgramType: fareLegInfo.vendorProgramType,
      };
    });
  }

  private getFareFlightInfoForLegInfo(
    legInfo: PaxInfoFareComponentLegInfo,
    legIndex: number,
  ): IItineraryFareInfoFareFlight[] {
    const flightInfos = legInfo.flightInfo;
    return flightInfos.map((flight, flightNumber) => {
      const flightData = this.getFlightInfoForLegIndexAndFlightNumber(legIndex, flightNumber);

      return {
        data: flight,
        cabin: flight.cabin,
        bookingCode: flight.bookingCode,
        flight: flightData,
      };
    });
  }

  public GetFareShelfInfoForCategory(category: FareCategory): IFareShelfInfo | undefined {
    const shelfInfo = this.response.metadata?.shelfInfo;
    if (!shelfInfo) {
      return undefined;
    }

    const categoryShelfInfo = shelfInfo.find((shelf) => {
      const { fareCategory } = shelf;
      if (category.cabinViewCategory) {
        return fareCategory?.cabinViewCategory === category.cabinViewCategory;
      }

      if (category.ngsCategory) {
        return fareCategory?.ngsCategory === category.ngsCategory;
      }

      return false;
    });
    const fareAmount = categoryShelfInfo?.totalFareAmount;
    const minimumDurationIso = categoryShelfInfo?.duration?.iso8601;
    const duration = minimumDurationIso ? getDurationString(minimumDurationIso) : '';

    return { fareAmount, duration };
  }

  public GetAvailableAirlines(): MetadataAirline[] {
    return (
      this.response.metadata?.availableAirlines?.slice().sort((airline1, airline2) => {
        if (airline1.name < airline2.name) {
          return -1;
        }
        if (airline1.name > airline2.name) {
          return 1;
        }
        return 0;
      }) ?? []
    );
  }

  public GetApplicableAlliances(): Alliance[] {
    return this.response.metadata?.applicableAlliances ?? [];
  }

  public GetFlightAirline(flightIndex: number): IFlightCodeDetails {
    const { marketing, operating, operatingAirlineName } = this.response.data.flights[flightIndex];
    return { marketing, operating, operatingAirlineName };
  }

  public GetLegAirlines(legIndex: number): IFlightCodeDetails[] {
    const flightIndices = this.GetLegFlights(legIndex);
    const airlines = flightIndices.map((flightIndex: number): IFlightCodeDetails => this.GetFlightAirline(flightIndex));
    return airlines;
  }

  public GetLegAircrafts(legIndex: number): string[] {
    const flightIndices = this.GetLegFlights(legIndex);
    const aircrafts = flightIndices.map(
      (flightIndex: number): string =>
        `${this.response.data.flights[flightIndex].marketing?.airline}${this.response.data.flights[flightIndex].marketing?.num}`,
    );
    return aircrafts;
  }

  public GetLegDetails({ itinIndex, legNumber }: IGetLegDetailsProps): IFlightLegDetails {
    const legIndex = this.response.itineraries[itinIndex].legIndices[legNumber];
    const itinerary: Itinerary | undefined = this.response.itineraries[itinIndex];
    return this.GetLegDetailsFromLegIndexAndItinerary(legIndex, legNumber, itinerary);
  }

  private GetFlightInfoFromFlightIndex(flightDataArrayIndex: number): IFlightWithStopsInfo {
    const flightData = this.response.data.flights[flightDataArrayIndex];
    const { origin, destination, departureDateTime, arrivalDateTime } = flightData;

    const hiddenStops = flightData.hiddenStops || [];

    const flightInfo: IFlightWithStopsInfo = {
      arrivalCountryCode: flightData.destination.country,
      departureCountryCode: flightData.origin.country,
      ...getFlightDateTimeAirportInformation(departureDateTime, arrivalDateTime, origin, destination),
      duration: this.GetFlightDuration(flightDataArrayIndex),
      airline: this.GetFlightCarrier(flightDataArrayIndex),
      marketingAirlineLabel: this.GetMarketingAirlineLabel(flightDataArrayIndex),
      operatingAirlineLabel: this.GetOperatingAirlineLabel(flightDataArrayIndex),
      hiddenLayovers: [],
    };

    hiddenStops.forEach((stopInfo) => {
      flightInfo.hiddenLayovers.push(getLayoverInformationFromHiddenStop(stopInfo));
    });

    return flightInfo;
  }

  public GetLegFlightDetails(itinIndex: number, legIndex = 0): IFlightLayoverInfo[] {
    const itinerary = this.response.itineraries[itinIndex];
    const legArrayIndex = itinerary.legIndices[legIndex];
    const flightIndices = this.GetLegFlights(legArrayIndex);
    const flightLayoverInfos: IFlightLayoverInfo[] = [];

    for (let flightNumber = 0; flightNumber < flightIndices.length; flightNumber += 1) {
      const flightIndex = flightIndices[flightNumber];
      const flightData = this.response.data.flights[flightIndex];
      const amenities = this.GetFlightAmenities(itinerary, legIndex, flightNumber);
      const flightInfo = this.GetFlightInfoFromFlightIndex(flightIndex);
      flightLayoverInfos.push({
        type: FlightDetailsInfoType.FLIGHT,
        data: {
          ...flightInfo,
          amenities,
        },
      });

      // Add layovers information between separate flight segments.
      if (flightNumber + 1 < flightIndices.length) {
        flightLayoverInfos.push({
          type: FlightDetailsInfoType.LAYOVER,
          data: {
            layoverAirportCode: flightData.destination.airport,
            layoverAirportName: flightData.destination.airportName,
            layoverDuration: this.GetFlightLayover(legArrayIndex, flightNumber + 1, flightNumber),
            overnight: this.GetFlightOvernight(legArrayIndex, flightNumber + 1, flightNumber),
            transfer: this.GetFlightTransfer(legArrayIndex, flightNumber + 1, flightNumber),
          },
        });
      }
    }

    return flightLayoverInfos;
  }

  public GetLegDetailsForTrips(itinIndex: number, legNumber: number, legIndex: number): IFlightLegDetails {
    const itinerary: Itinerary | undefined = this.response.itineraries[itinIndex];
    return this.GetLegDetailsFromLegIndexAndItinerary(legIndex, legNumber, itinerary);
  }

  private static getOperatingAirlinesSubText(flightDetails: IFlightDetail[]): string {
    const operatingAirlinesList = flightDetails.map((flight) => flight.operatingAirlineLabel);
    return uniq(operatingAirlinesList).join(', ');
  }

  /* @deprectaed after branded fares migration */
  private static GetBookingCodesForLeg(itinerary: Itinerary, legNumber: number): string[] {
    /* istanbul ignore next */
    const flightsInfo = itinerary.rateOptions[0].paxInfo[0].fareComponents[0].legInfo?.[legNumber]?.flightInfo ?? [];
    const bookingCodes: string[] = [];
    flightsInfo.forEach((flightDetail) => {
      bookingCodes.push(flightDetail.bookingCode);
    });
    return bookingCodes;
  }

  public GetLegDetailsFromLegIndexAndItinerary(
    legIndex: number,
    legNumber: number,
    itinerary: Itinerary | undefined,
  ): IFlightLegDetails {
    if (legIndex === -1 || !itinerary) {
      return {
        layovers: [],
        flightDetails: [],
        transfers: [],
        overnights: [],
        operatingAirlinesSubText: '',
      };
    }

    const flightIndices = this.GetLegFlights(legIndex);
    const flightDetails = flightIndices.map((_: number, flightNumber: number) => {
      const flightInfo = this.getFlightInfoForLegIndexAndFlightNumber(legIndex, flightNumber);
      return {
        ...flightInfo,
        cabinType: AirSearchResponseManager.GetFlightCabin(itinerary, legNumber, flightNumber),
        amenities: this.GetFlightAmenities(itinerary, legNumber, flightNumber),
        utas: this.GetLegUTAs(itinerary, legNumber),
        upas: this.GetFlightUpas(itinerary, legNumber, flightNumber),
      };
    });
    return {
      layovers: this.GetLegLayovers(legIndex),
      transfers: this.GetTransfers(legIndex),
      overnights: this.GetOvernights(legIndex),
      flightDetails,
      operatingAirlinesSubText: AirSearchResponseManager.getOperatingAirlinesSubText(flightDetails),
    };
  }

  public GetFlightCarrier(flightIndex: number): Carrier {
    const { marketing, operating, equipment } = this.response.data.flights[flightIndex];
    return {
      marketing,
      operating,
      equipment,
    };
  }

  public GetLegArrivalDateTime(legIndex: number): string | undefined {
    const arrivalFlightIndex = last(this.response.data.legs[legIndex].flightIndices);
    if (arrivalFlightIndex !== undefined) {
      return this.response.data.flights[arrivalFlightIndex].arrivalDateTime.iso8601;
    }
    return undefined;
  }

  public GetLegDepartureDateTime(legIndex: number): string | undefined {
    const departureFlightIndex = first(this.response.data.legs[legIndex].flightIndices);
    if (departureFlightIndex !== undefined) {
      return this.response.data.flights[departureFlightIndex].departureDateTime.iso8601;
    }
    return undefined;
  }

  public GetAirports(legIndex: number): { code: string; name: string }[] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const allFlights = this.response.data.flights;
    const airports: { code: string; name: string }[] = [];

    legFlights.forEach((legFlight: number) => {
      const lastAirport = last(airports);
      const flightInfo = allFlights[legFlight];

      if (lastAirport?.code !== flightInfo.origin.airport) {
        airports.push({ code: flightInfo.origin.airport, name: flightInfo.origin.airportName });
      }

      const hiddenStops = flightInfo.hiddenStops || [];
      hiddenStops.forEach((hiddenStopInfo) => {
        airports.push({
          code: hiddenStopInfo.airport?.airport || '',
          name: hiddenStopInfo.airport?.airportName || '',
        });
      });

      airports.push({
        code: flightInfo.destination.airport,
        name: flightInfo.destination.airportName,
      });
    });

    const filteredAirports = airports.filter((ap) => !!ap.code);
    return filteredAirports;
  }

  public GetAirportCities(legIndex: number): IItineraryCityInfo[] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const allFlights = this.response.data.flights;
    const airportCities: { cityName: string }[] = [];

    const allFlightsForGivenLeg: DataFlight[] = [];
    legFlights.forEach((legFlight: number) => {
      const flightInfo = allFlights[legFlight];
      allFlightsForGivenLeg.push(flightInfo);
    });

    airportCities.push({ cityName: first(allFlightsForGivenLeg)?.origin.cityName ?? '' });
    airportCities.push({ cityName: last(allFlightsForGivenLeg)?.destination.cityName ?? '' });

    return airportCities;
  }

  public GetFlightEquipment(flightIndex: number): DataFlightEquipment | undefined {
    return this.response.data.flights[flightIndex].equipment;
  }

  public GetSearchId(): string {
    return this.response.searchId;
  }

  public GetRateOptionId(itinIndex: number): string {
    return this.response.itineraries[itinIndex].rateOptions[0].rateOptionId;
  }

  public GetItinerariesIndices(): number[] {
    return [...this.response.itineraries.keys()];
  }

  public GetLegSummaryObjectForRateOption({
    rateOption,
    legIndex,
    legNumber,
    itinerary,
  }: IFlightLegSummaryInput): IFlightLegSummary {
    const { paxInfo, policyInfo, totalFare } = rateOption;
    const legSummaryForLegIndex = this.GetLegSummaryForLegIndex(legIndex);
    const legSummaryForItinerary = this.GetLegSummaryForItinerary(itinerary, legNumber);
    return {
      ...legSummaryForLegIndex,
      ...legSummaryForItinerary,
      totalFare: getTotalFareForFareAmount(totalFare),
      totalPaxNum: getTotalPaxNumForPaxInfo(paxInfo),
      policyViolationMessage: !!policyInfo?.violationMessage,
    };
  }

  public GetLegSummaryForLegIndex(legIndex: number): IFlightLegSummaryForLegIndex {
    return {
      arrivalDateTime: this.GetLegArrivalDateTime(legIndex),
      departureDateTime: this.GetLegDepartureDateTime(legIndex),
      airports: this.GetAirports(legIndex),
      airlines: this.GetLegAirlines(legIndex),
      aircrafts: this.GetLegAircrafts(legIndex),
      legDuration: this.GetLegDuration(legIndex),
      layovers: this.GetLegLayoverDurations(legIndex, true),
      governmentApprovalFlights: this.getGovernmentApprovalFlights(legIndex),
    };
  }

  private static GetBrandNameForLeg(itinerary: Itinerary, legNumber: number): string {
    const legInfo = first(first(first(itinerary.rateOptions)?.paxInfo)?.fareComponents)?.legInfo[
      legNumber
    ] as PaxInfoFareComponentLegInfo;

    return legInfo.brandName;
  }

  public GetLegSummaryForItinerary(itinerary: Itinerary, legNumber: number): IFlightLegSummaryForItinerary {
    return {
      cabinType: AirSearchResponseManager.GetLegCabin(itinerary, legNumber),
      fareType: AirSearchResponseManager.GetFareType(itinerary, legNumber),
      bookingCodes: AirSearchResponseManager.GetBookingCodesForLeg(itinerary, legNumber),
      preferences: AirSearchResponseManager.GetPreferences(itinerary, legNumber),
      brandName: AirSearchResponseManager.GetBrandNameForLeg(itinerary, legNumber),
    };
  }

  public GetLegSummary({ itinIndex, legNumber }: IGetLegSummaryProps): IFlightLegSummary {
    const { rateOption, itinerary, legIndex } = this.GetFlightLegSummaryInputForItinIndex(itinIndex, legNumber);
    return this.GetLegSummaryObjectForRateOption({ rateOption, itinerary, legIndex, legNumber });
  }

  public GetLegSummaryForPreviousLegs({ itinIndex, legNumber }: IGetLegSummaryProps): IFlightLegSummary | null {
    const itinerary = this.getCurrentSelectedItinerary();
    if (!itinerary) {
      return null;
    }

    const legIndex = itinerary.legIndices[legNumber];
    const rateOption = this.response.itineraries[itinIndex].rateOptions[0];

    return this.GetLegSummaryObjectForRateOption({ rateOption, itinerary, legIndex, legNumber });
  }

  public GetFlightLegSummaryInputForItinIndex(itinIndex: number, legNumber = 0): IFlightLegSummaryInput {
    const itinerary = this.response.itineraries[itinIndex];
    const rateOption = itinerary.rateOptions[0];
    const legIndex = itinerary.legIndices[legNumber];

    return { rateOption, legIndex, legNumber, itinerary };
  }

  private getEnhancedFareInfo(fareInfos: IItineraryFareInfo[], legNumber: number): IItineraryFareInfoEnhanced[] {
    return fareInfos.map((fareData) => ({
      ...fareData,
      passenger: fareData.passenger.map((passengerData, passengerIndex) => {
        const paxInfo = passengerData.data.rateOption.paxInfo[passengerIndex];
        const fareLegInfo = paxInfo.fareComponents[0].legInfo[legNumber];

        return {
          ...passengerData,
          flights: passengerData.flights.map((flightData) => {
            const amenities = flightData.data.amenitiesIndices?.map((index) => this.getFlightAmenitiesForIndex(index));
            const upas = flightData.data.upaIndices?.map((index) => this.getFlightUpaByIndex(index));

            return {
              ...flightData,
              amenities,
              upas,
            };
          }),
          utas: fareLegInfo.utaIndices.map((index) => this.getUTAForIndex(index)),
        };
      }),
    }));
  }

  public GetEnhancedFareInfosForPreviousLegs({
    itinIndex,
    legNumber,
  }: IGetEnhancedFareInfosForPreviousLegsProps): IItineraryFareInfoEnhanced[] {
    const itinerary = this.getCurrentSelectedItinerary();
    if (!itinerary) {
      return [];
    }
    const { rateOptions, legIndices } = itinerary;
    const legIndex = legIndices[legNumber];
    const fareInfos = this.getAllFaresForRateOptions({ rateOptions, itinIndex, legNumber, legIndex });
    return this.getEnhancedFareInfo(fareInfos, legNumber);
  }

  public GetCheapestOptions(): Itinerary[] {
    return this.response.cheapestOptions;
  }

  public getTotalLegDuration(legIndex: number) {
    return this.GetLegDuration(legIndex);
  }

  public GetFlightLayovers(itinIndex: number): number[] {
    return this.GetLegLayoverDurations(itinIndex, true);
  }

  public getCurrentSelectedItinerary(): Itinerary | undefined {
    return this.response.currSelectedItinerary;
  }

  public getItineraryForItineraryIndex(itinIndex: number): Itinerary {
    return this.response.itineraries[itinIndex];
  }

  private getTotalCo2EmissionsForLegFlights(flightInfo: PaxInfoFareComponentLegInfoFlightInfo[]): Weight | null {
    const legFlightsEmission = flightInfo
      .map((flight) => flight.co2EmissionDetail?.weight)
      .filter((weight): weight is Weight => weight !== undefined);

    // If co2EmissionDetail is undefined for all flights in this leg, return null
    if (legFlightsEmission.length === 0) {
      return null;
    }

    const totalLegEmission = sum(legFlightsEmission.map((weight) => weight.weight));
    return { weight: Math.round(totalLegEmission), unit: legFlightsEmission[0].unit };
  }

  public getItineraryMinCo2Emission(itinIndex: number): Weight[] | null {
    const legs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);
    const carbonEmissionForLegs = legs.map(({ legNumber }) => {
      const { flightInfo } =
        this.response.itineraries[itinIndex].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber];
      return this.getTotalCo2EmissionsForLegFlights(flightInfo);
    });

    // If co2EmissionDetail is null for all legs, return null
    if (carbonEmissionForLegs.every((leg) => leg === null)) {
      return null;
    }

    return carbonEmissionForLegs.filter((leg): leg is Weight => leg !== null);
  }

  private getTotalAverageCo2EmissionForLegFlights(flightInfo: PaxInfoFareComponentLegInfoFlightInfo[]): Weight | null {
    const weightUnit = flightInfo[0].co2EmissionDetail?.weight?.unit;
    const legFlightsEmission = flightInfo
      .map((flight) => flight.co2EmissionDetail?.averageEmissionValue)
      .filter((weight): weight is number => weight !== undefined);

    // If co2EmissionDetail is undefined for all flights in this leg, return null
    if (legFlightsEmission.length === 0 || !weightUnit) {
      return null;
    }
    const totalLegEmission = sum(legFlightsEmission.map((averageEmissionValue) => averageEmissionValue));
    return { weight: totalLegEmission * 1000, unit: weightUnit };
  }

  public getAverageEmissionForItinerary(itinIndex: number): Weight[] | null {
    const legs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);
    const carbonEmissionForLegs = legs.map(({ legNumber }) => {
      const { flightInfo } =
        this.response.itineraries[itinIndex].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber];
      return this.getTotalAverageCo2EmissionForLegFlights(flightInfo);
    });

    // If co2EmissionDetail is null for all legs, return null
    if (carbonEmissionForLegs.every((leg) => leg === null)) {
      return null;
    }

    return carbonEmissionForLegs.filter((leg): leg is Weight => leg !== null);
  }

  public GetCurrentSelectedItineraryDetails(
    currentSelectedItinerary: Itinerary,
    legNumber: number,
  ): ICurrentSelectedItineraryInfo {
    const legIndex = currentSelectedItinerary.legIndices[legNumber];

    const departureDateTime = this.GetLegDepartureDateTime(legIndex);
    const arrivalDateTime = this.GetLegArrivalDateTime(legIndex);
    const airlines = this.GetLegAirlines(legIndex);
    const legDuration = this.GetLegDuration(legIndex);

    // eslint-disable-next-line no-restricted-syntax
    const departureTime = getFormattedDateTime(departureDateTime, timeFormats.HR12_TIME) ?? '';
    // eslint-disable-next-line no-restricted-syntax
    const departureTimePeriod = getFormattedDateTime(departureDateTime, timeFormats.HR12_PERIOD) ?? '';
    // eslint-disable-next-line no-restricted-syntax
    const arrivalTime = getFormattedDateTime(arrivalDateTime, timeFormats.HR12_TIME) ?? '';
    // eslint-disable-next-line no-restricted-syntax
    const arrivalTimePeriod = getFormattedDateTime(arrivalDateTime, timeFormats.HR12_PERIOD) ?? '';
    const firstFlightMarketingAirline = first(airlines)?.marketing.airline ?? '';
    const flightMarketing = first(airlines)?.marketing;
    const marketingAirline = flightMarketing?.airline ?? '';
    const marketingNumber = flightMarketing?.num ?? '';
    // eslint-disable-next-line no-restricted-syntax
    const departureDate = getFormattedDateTime(departureDateTime, dateFormats.DAY_DATE_YEAR);
    const airportCities = this.GetAirportCities(legIndex);
    const departureAirport = first(airportCities);
    const departureAirportCity = departureAirport?.cityName ?? '';
    const arrivalAirport = last(airportCities);
    const arrivalAirportCity = arrivalAirport?.cityName ?? '';
    const totalLegs = currentSelectedItinerary.legIndices.length ?? 0;

    return {
      departureTime,
      departureTimePeriod,
      arrivalTime,
      arrivalTimePeriod,
      firstFlightMarketingAirline,
      marketingAirline,
      marketingNumber,
      departureDate,
      departureAirportCity,
      arrivalAirportCity,
      legDuration,
      airlines,
      totalLegs,
    };
  }

  public GetLegSummaryForTrips(itinIndex: number, legNumber: number, legIndex: number): IFlightLegSummary {
    const itinerary: Itinerary = this.response.itineraries[itinIndex];
    const rateOption = itinerary.rateOptions[0];
    return this.GetLegSummaryObjectForRateOption({ rateOption, itinerary, legIndex, legNumber });
  }

  /** @deprecated after migrating to ngs branded fares */
  public GetItineraryRateOptionId(itinIndex: number): string {
    return this.response.itineraries[itinIndex].rateOptions[0].rateOptionId;
  }

  public GetItinerarySource(itinIndex = 0): ThirdPartySource {
    return this.response.itineraries[itinIndex].rateOptions[0].source;
  }

  public GetItinerarySources(itinIndex: number): ItinerarySourceInfo[] {
    const sourceLabels = this.response.itineraries[itinIndex].rateOptions.map((x) => ({
      source: x.source,
      label: getItinerarySourceLabelAir(x.source, x.airline),
    }));

    const uniqSourceLabels = uniqBy(sourceLabels, 'label');

    return uniqSourceLabels;
  }

  public GetItinerarySourcePerLegInfo(legNumber: number, itinIndex = 0): ThirdPartySource {
    const multiSupplierInfo =
      this.response.itineraries[itinIndex].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber]
        ?.multiSupplierInfo;

    if (multiSupplierInfo) {
      return multiSupplierInfo?.source;
    }

    return this.GetItinerarySource(itinIndex);
  }

  public GetRateOptionAirline(itinIndex = 0): string {
    const { airline } = this.response.itineraries[itinIndex].rateOptions[0];
    return airline;
  }

  public GetPaginationParams(): PaginationParams {
    const totalNumPages = this.response?.paginationParams?.totalNumPages ?? 1;
    const currentPageSize = this.response?.paginationParams?.currentPageSize ?? 0;
    const totalNumResults = this.response?.paginationParams?.totalNumResults ?? 0;
    const currentPageNumber = this.response?.paginationParams?.currentPageNumber ?? 1;
    return { totalNumPages, currentPageSize, totalNumResults, currentPageNumber };
  }

  public GetItinerariesWithSameTicketNum(
    allLegsWithSameTicketNum: number[],
  ): { summary: IFlightLegSummary; legDetails: IFlightLegDetails }[] {
    const itineraryAllLegs = allLegsWithSameTicketNum.map((index) => ({ legNumber: index, currentLegIndex: index }));
    return itineraryAllLegs.map(({ legNumber, currentLegIndex }) => {
      const summary = this.GetLegSummaryForTrips(0, legNumber, currentLegIndex);
      const legDetails = this.GetLegDetailsForTrips(0, legNumber, currentLegIndex);
      return { summary, legDetails };
    });
  }

  public static GetExistingLegDetails(
    itineraries: {
      summary: IFlightLegSummary;
      legDetails: IFlightLegDetails;
    }[],
  ): {
    // TODO (CG-spotnana): Create a dedicated type for below
    departureDate: string | undefined;
    departureCity: string;
    arrivalDate: string;
    arrivalCity: string;
    duration: string;
    multiStopInfo: string;
  }[] {
    return itineraries.map(({ summary, legDetails }) => {
      const { airports, layovers } = summary;
      return {
        departureDate: summary.departureDateTime,
        arrivalDate: legDetails.flightDetails[legDetails.flightDetails.length - 1].arrivalDateTime,
        departureCity: legDetails.flightDetails[0].departureAirportCity,
        arrivalCity: legDetails.flightDetails[legDetails.flightDetails.length - 1].arrivalAirportCity,
        duration: summary.legDuration,
        multiStopInfo: AirSearchResponseManager.getStopsInfoForFlightResult(airports, layovers),
      };
    });
  }

  /** *******************************
   ****** private methods **********
   ******************************* */

  private static GetFareType(itinerary: Itinerary, legNumber: number): string {
    /* istanbul ignore next */
    const fareType =
      first(first(first(itinerary.rateOptions)?.paxInfo)?.fareComponents)?.legInfo?.[legNumber]?.fareType ?? 0;
    return FareTypeEnum[fareType] ?? '';
  }

  private static GetPreferences(itinerary: Itinerary, legNumber: number): Preference[] {
    const legInfo = first(first(first(itinerary.rateOptions)?.paxInfo)?.fareComponents)?.legInfo[legNumber];

    return legInfo?.preferences ?? [];
  }

  /* @deprecated */
  public GetPolicyViolationMessage(itinIndex: number): boolean {
    return !!first(this.response.itineraries[itinIndex].rateOptions)?.policyInfo?.violationMessage;
  }

  private static GetLegCabin(itinerary: Itinerary, legNumber: number): string {
    const cabins = new Set<string>();
    /* istanbul ignore next */
    const flights = itinerary.rateOptions[0].paxInfo[0].fareComponents[0].legInfo?.[legNumber]?.flightInfo ?? [];
    flights.forEach((_flight, index) =>
      cabins.add(AirSearchResponseManager.GetFlightCabin(itinerary, legNumber, index)),
    );
    if (cabins.size > 1) {
      return 'Mixed Cabins';
    }
    return cabins.values().next().value ?? '';
  }

  private GetMarketingAirlineLabel(flightIndex: number): string {
    const airlineId = this.response.data.flights[flightIndex].marketing?.airline ?? '';
    return airlinesMap[airlineId];
  }

  private GetOperatingAirlineLabel(flightIndex: number): string {
    const flightInfo = this.response.data.flights[flightIndex];
    if (flightInfo.operatingAirlineName) {
      return flightInfo.operatingAirlineName;
    }
    const airlineId = flightInfo.operating?.airline ?? '';
    return airlinesMap[airlineId] ?? airlineId;
  }

  private GetLegAirportsByFlightIndex(legIndex: number, flightIndex: number): [string, string] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const currentFlightIndex = legFlights.find((flight: number) => flight === flightIndex) ?? -1;
    return this.GetAirportsByFlightIndex(currentFlightIndex);
  }

  private GetAirportsByFlightIndex(flightIndex: number): [string, string] {
    const currentFlight = this.response.data.flights[flightIndex];
    return [currentFlight.origin.airport, currentFlight.destination.airport];
  }

  private GetLegAirportNamesByFlightIndex(legIndex: number, flightIndex: number): [string, string] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const currentFlightIndex = legFlights.find((flight: number) => flight === flightIndex) ?? -1;
    return this.GetAirportNamesByFlightIndex(currentFlightIndex);
  }

  private GetAirportNamesByFlightIndex(flightIndex: number): [string, string] {
    const currentFlight = this.response.data.flights[flightIndex];
    return [currentFlight.origin.airportName, currentFlight.destination.airportName];
  }

  private GetLegCountryCodesByFlightIndex(legIndex: number, flightIndex: number): [string, string] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const currentFlightIndex = legFlights.find((flight: number) => flight === flightIndex) ?? -1;
    return this.GetCountryCodesByFlightIndex(currentFlightIndex);
  }

  private GetCountryCodesByFlightIndex(flightIndex: number): [string, string] {
    const currentFlight = this.response.data.flights[flightIndex];
    return [currentFlight?.origin?.country ?? '', currentFlight?.destination?.country ?? ''];
  }

  protected GetLegAirportCityByFlightIndex(legIndex: number, flightIndex: number): [string, string] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const currentFlightIndex = legFlights.find((flight: number) => flight === flightIndex) ?? -1;
    return this.GetAirportCityByFlightIndex(currentFlightIndex);
  }

  private GetAirportCityByFlightIndex(flightIndex: number): [string, string] {
    const currentFlight = this.response.data.flights[flightIndex];
    return [currentFlight?.origin?.cityName ?? '', currentFlight?.destination?.cityName ?? ''];
  }

  private GetLegAirportTerminalsByFlightIndex(legIndex: number, flightIndex: number): [string, string] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const currentFlightIndex = legFlights.find((flight: number) => flight === flightIndex) ?? -1;
    return this.GetAirportTerminalsByFlightIndex(currentFlightIndex);
  }

  private GetAirportTerminalsByFlightIndex(flightIndex: number): [string, string] {
    const currentFlight = this.response.data.flights[flightIndex];
    const departingAirportTerminal = currentFlight.origin.terminal ?? '';
    const arrivalAirportTerminal = currentFlight.destination.terminal ?? '';
    return [departingAirportTerminal, arrivalAirportTerminal];
  }

  private GetLegDuration(legIndex: number): string {
    const flightDurations = this.response.data.legs[legIndex].flightIndices.map((flightIndex: number) =>
      getDurationMinutes(this.response.data.flights[flightIndex].duration?.iso8601 ?? ''),
    );
    const legLayovers = this.GetLegLayoverDurations(legIndex, false);
    const legDuration = [...flightDurations, ...legLayovers].reduce(
      (totalMinutes, currentMinutes) => totalMinutes + currentMinutes,
      0,
    );
    return minutesToDurationString(legDuration);
  }

  public GetLegFlights(legIndex: number): number[] {
    const legFlights = this.response.data.legs[legIndex].flightIndices;
    const flightsIndices: number[] = [];
    legFlights.forEach((legFlight: number) => {
      flightsIndices.push(legFlight);
    });
    return flightsIndices;
  }

  private GetTransfers(legIndex: number): string[] {
    const size = this.getTotalFlightsInLeg(legIndex);
    const transfers: string[] = [];
    if (size === 0) return transfers;
    for (let i = 0; i < size - 1; i += 1) {
      transfers.push(this.GetFlightTransfer(legIndex, i + 1, i));
    }
    return transfers;
  }

  private GetOvernights(legIndex: number): boolean[] {
    const size = this.getTotalFlightsInLeg(legIndex);
    const transfers: boolean[] = [];
    if (size === 0) return transfers;
    for (let i = 0; i < size - 1; i += 1) {
      transfers.push(this.GetFlightOvernight(legIndex, i + 1, i));
    }
    return transfers;
  }

  private GetFlightHiddenStopsLayoverDuration(legIndex: number, flightNumber: number): number[] {
    const flightIndex = this.GetFlightIndex(legIndex, flightNumber);
    const flightInfo = this.response.data.flights[flightIndex];
    const hiddenStops = flightInfo.hiddenStops || [];
    return hiddenStops.map((stop) => getDurationMinutes(stop.duration?.iso8601 ?? ''));
  }

  public getTotalFlightsInLeg(legIndex: number): number {
    return this.response.data.legs[legIndex].flightIndices.length;
  }

  public getTotalFlightsForItineraryLeg(itinIndex: number, legNumber: number) {
    const legIndex = this.response.itineraries[itinIndex].legIndices[legNumber];
    const totalFlights = this.getTotalFlightsInLeg(legIndex);
    return totalFlights;
  }

  private GetLegLayoverDurations(legIndex: number, includeHiddenStops: boolean): number[] {
    const totalFlights = this.getTotalFlightsInLeg(legIndex);
    const layoverDurations: number[] = [];

    if (totalFlights === 0) {
      return layoverDurations;
    }

    if (includeHiddenStops) {
      layoverDurations.push(...this.GetFlightHiddenStopsLayoverDuration(legIndex, 0));
    }
    for (let i = 1; i < totalFlights; i += 1) {
      const layover = this.GetFlightLayover(legIndex, i, i - 1);
      if (includeHiddenStops) {
        layoverDurations.push(...this.GetFlightHiddenStopsLayoverDuration(legIndex, i));
      }
      layoverDurations.push(layover);
    }

    return layoverDurations;
  }

  private GetLegLayovers(legIndex: number): number[] {
    const size = this.getTotalFlightsInLeg(legIndex);
    const layOvers: number[] = [];
    if (size === 0) return layOvers;
    for (let i = 0; i < size - 1; i += 1) {
      layOvers.push(this.GetFlightLayover(legIndex, i + 1, i));
    }
    return layOvers;
  }

  private GetFlightIndex(legIndex: number, flightNumber: number): number {
    return this.response.data.legs[legIndex].flightIndices[flightNumber];
  }

  private GetFlightDepartureDateTime(legIndex: number, flightNumber: number): string {
    const flightIndex = this.GetFlightIndex(legIndex, flightNumber);
    return this.GetFlightDepartureDateTimeForFlightIndex(flightIndex);
  }

  private GetFlightDepartureDateTimeForFlightIndex(flightIndex: number): string {
    return this.response.data.flights[flightIndex].departureDateTime.iso8601;
  }

  private GetFlightArrivalDateTime(legIndex: number, flightNumber: number): string {
    const flightIndex = this.GetFlightIndex(legIndex, flightNumber);
    return this.GetFlightArrivalDateTimeForFlightIndex(flightIndex);
  }

  private GetFlightArrivalDateTimeForFlightIndex(flightIndex: number): string {
    return this.response.data.flights[flightIndex].arrivalDateTime.iso8601;
  }

  private GetFlightDuration(flightIndex: number): string {
    return getDurationString(this.response.data.flights[flightIndex].duration?.iso8601 ?? '');
  }

  private GetFlightLayover(legIndex: number, laterFlight: number, earlierFlight: number): number {
    const arrival = this.GetFlightArrivalDateTime(legIndex, earlierFlight);
    const departure = this.GetFlightDepartureDateTime(legIndex, laterFlight);
    return getDateTimeDiff(departure, arrival);
  }

  private GetFlightDepartureAirport(legIndex: number, flightNumber: number): string {
    const flightIndex = this.GetFlightIndex(legIndex, flightNumber);
    return this.response.data.flights[flightIndex].destination.airport;
  }

  private GetFlightArrivalAirport(legIndex: number, flightNumber: number): string {
    const flightIndex = this.GetFlightIndex(legIndex, flightNumber);
    return this.response.data.flights[flightIndex].origin.airport;
  }

  private GetFlightOvernight(legIndex: number, laterFlight: number, earlierFlight: number): boolean {
    const priorFlightArrival = this.GetFlightArrivalDateTime(legIndex, earlierFlight);
    const laterFlightDeparture = this.GetFlightDepartureDateTime(legIndex, laterFlight);
    return getDateDiff(priorFlightArrival, laterFlightDeparture) >= 1;
  }

  private GetFlightTransfer(legIndex: number, laterFlight: number, earlierFlight: number): string {
    const priorFlightDestinationAirport = this.GetFlightDepartureAirport(legIndex, earlierFlight);
    const laterFlightOriginAirport = this.GetFlightArrivalAirport(legIndex, laterFlight);
    return priorFlightDestinationAirport === laterFlightOriginAirport
      ? ''
      : `transfer from ${priorFlightDestinationAirport} to ${laterFlightOriginAirport}`;
  }

  protected GetLegUTAs(itinerary: Itinerary, legNumber: number): ILegUTA[] {
    const { utaIndices } = itinerary.rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber];
    const { utas } = this.response.data;
    return utaIndices.map((utaIndex) => ({
      key: first(Object.keys(utas[utaIndex])) as UtaProps,
      displayText: getDisplayTextUTA(utas[utaIndex]),
      uta: utas[utaIndex],
    }));
  }

  private GetFlightUpas(itinerary: Itinerary, legNumber: number, flightNumber: number): Upa[] {
    const { upaIndices } =
      itinerary.rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber].flightInfo[flightNumber];
    return upaIndices.map((index) => this.getFlightUpaByIndex(index));
  }

  private getFlightUpaByIndex(upaIndex: number): Upa {
    return this.response.data.upas[upaIndex];
  }

  private GetFlightAmenities(itinerary: Itinerary, legNumber: number, flightNumber: number): IFlightAmenity[] {
    const { amenitiesIndices } =
      itinerary.rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber].flightInfo[flightNumber];

    return amenitiesIndices.map((amenityIndex: number) => this.getFlightAmenitiesForIndex(amenityIndex));
  }

  private getFlightAmenitiesForIndex(amenityIndex: number): IFlightAmenity {
    const { amenities } = this.response.data;
    const amenityKey = Object.keys(amenities[amenityIndex])[0];
    const amenityInfo = amenities[amenityIndex][amenityKey as AmenityProps];
    return {
      key: amenityKey,
      displayText: amenityInfo?.displayText ?? '',
    };
  }

  private getUTAForIndex(utaIndex: number): Uta {
    return this.response.data.utas[utaIndex];
  }

  /**
   * Get IFlightInfo details
   * @param legIndex Index of leg in this.response.data.legs
   * @param flightNumber Array index of flight in this.response.data.legs[legIndex].flightIndices array.
   * @returns IFlightInfo
   */
  public getFlightInfoForLegIndexAndFlightNumber(legIndex: number, flightNumber: number): IFlightInfo {
    const flightIndex = this.GetFlightIndex(legIndex, flightNumber);

    return {
      departureDateTime: this.GetFlightDepartureDateTime(legIndex, flightNumber),
      arrivalDateTime: this.GetFlightArrivalDateTime(legIndex, flightNumber),
      duration: this.GetFlightDuration(flightIndex),
      departureAirport: this.GetLegAirportsByFlightIndex(legIndex, flightIndex)[0],
      departureAirportName: this.GetLegAirportNamesByFlightIndex(legIndex, flightIndex)[0],
      departureAirportCity: this.GetLegAirportCityByFlightIndex(legIndex, flightIndex)[0],
      departureAirportTerminal: first(this.GetLegAirportTerminalsByFlightIndex(legIndex, flightIndex)) ?? '',
      departureCountryCode: this.GetLegCountryCodesByFlightIndex(legIndex, flightIndex)[0],
      arrivalAirport: this.GetLegAirportsByFlightIndex(legIndex, flightIndex)[1],
      arrivalAirportName: this.GetLegAirportNamesByFlightIndex(legIndex, flightIndex)[1],
      arrivalAirportCity: this.GetLegAirportCityByFlightIndex(legIndex, flightIndex)[1],
      arrivalAirportTerminal: this.GetLegAirportTerminalsByFlightIndex(legIndex, flightIndex)[1],
      arrivalCountryCode: this.GetLegCountryCodesByFlightIndex(legIndex, flightIndex)[1],
      airline: this.GetFlightCarrier(flightIndex),
      marketingAirlineLabel: this.GetMarketingAirlineLabel(flightIndex),
      operatingAirlineLabel: this.GetOperatingAirlineLabel(flightIndex),
    };
  }

  public getFlightInfoForFlightIndex(flightIndex: number): IFlightInfo {
    return {
      departureDateTime: this.GetFlightDepartureDateTimeForFlightIndex(flightIndex),
      arrivalDateTime: this.GetFlightArrivalDateTimeForFlightIndex(flightIndex),
      duration: this.GetFlightDuration(flightIndex),
      departureAirport: this.GetAirportsByFlightIndex(flightIndex)[0],
      departureAirportName: this.GetAirportNamesByFlightIndex(flightIndex)[0],
      departureAirportCity: this.GetAirportCityByFlightIndex(flightIndex)[0],
      departureAirportTerminal: first(this.GetAirportTerminalsByFlightIndex(flightIndex)) ?? '',
      departureCountryCode: this.GetCountryCodesByFlightIndex(flightIndex)[0],
      arrivalAirport: this.GetAirportsByFlightIndex(flightIndex)[1],
      arrivalAirportName: this.GetAirportNamesByFlightIndex(flightIndex)[1],
      arrivalAirportCity: this.GetAirportCityByFlightIndex(flightIndex)[1],
      arrivalAirportTerminal: this.GetAirportTerminalsByFlightIndex(flightIndex)[1],
      arrivalCountryCode: this.GetCountryCodesByFlightIndex(flightIndex)[1],
      airline: this.GetFlightCarrier(flightIndex),
      marketingAirlineLabel: this.GetMarketingAirlineLabel(flightIndex),
      operatingAirlineLabel: this.GetOperatingAirlineLabel(flightIndex),
    };
  }

  /**
   * @param {(Array<null | number>)} emissionValues
   * @param {('tonnes' | 'kg')} [unit]
   * (`'tonnes'` by default, as unprocessed values from BE are in `tonnes`,
   * but if calculating over preprocessed emissions, use `'kg'`, as they have already been converted to `kg`)
   */
  static GetTotalEmissionsForEmissionValues(
    emissionValues: Array<null | number>,
    unit?: 'tonnes' | 'kg',
  ): null | number {
    let totalEmissions = 0;
    for (let i = 0; i < emissionValues.length; i += 1) {
      const emissionValue = emissionValues[i];
      if (emissionValue === null) {
        return null;
      }
      totalEmissions += unit !== 'kg' ? Math.round(emissionValue * 1000) : emissionValue;
    }
    return totalEmissions;
  }

  static GetCountWithoutInfantForFare<T extends { paxType: PassengerType; numPax: number }>(paxInfos: T[]) {
    let totalNumPax = 0;

    paxInfos.forEach((paxInfo) => {
      if (paxInfo.paxType !== PassengerTypeEnum.INFANT_ON_LAP) {
        totalNumPax += paxInfo.numPax;
      }
    });

    return totalNumPax;
  }

  static GetTotalEmissionsForFlightsInfo(flightsInfo: PaxInfoFareComponentLegInfoFlightInfo[]): null | number {
    const emissionValues = flightsInfo.map((flightInfo) => flightInfo.co2EmissionDetail?.emissionValue ?? null);
    return AirSearchResponseManager.GetTotalEmissionsForEmissionValues(emissionValues);
  }

  static GetTotalEmissionsForFlights(flights: IItineraryFareInfoFareFlight[]): null | number {
    const emissionValues = flights.map((flight) => flight.data.co2EmissionDetail?.emissionValue ?? null);
    return AirSearchResponseManager.GetTotalEmissionsForEmissionValues(emissionValues);
  }

  static GetIsCo2EmissionValueApprox(flights: IItineraryFareInfoFareFlight[]): boolean | undefined {
    return flights[0].data.co2EmissionDetail?.isApproximate;
  }

  /**
   * Returns value in "gm" per kilometer of the whole flight duration
   */
  static GetTotalEmissionsForFlightsPerPassengerKm(flights: IItineraryFareInfoFareFlight[]): null | number {
    const emissionValues = flights.map((flight) => flight.data.co2EmissionDetail?.emissionValue ?? null);
    const totalFlightDistance = flights.reduce(
      (result, flight) => result + (flight.data.co2EmissionDetail?.flightDistanceKm ?? 0),
      0,
    );
    const totalEmissions = AirSearchResponseManager.GetTotalEmissionsForEmissionValues(emissionValues);

    return totalEmissions && totalFlightDistance ? Math.round((totalEmissions / totalFlightDistance) * 1000) : null;
  }

  public getFlightRestrictions(flightIndex: number): MetadataFlightRestrictionsRestriction[] {
    return (
      this.response.metadata?.flightRestrictions?.find(
        (flightRestriction: MetadataFlightRestrictions) => flightRestriction.flightIndex === flightIndex,
      )?.restrictions || []
    );
  }

  public getHoldDeadline(itinIndex: number): RateOption['holdDeadline'] {
    return this.response.itineraries[itinIndex].rateOptions[0].holdDeadline;
  }

  public getFlightNumberDisplayText(itinIndex: number): string {
    const legFlightDetails = this.GetLegFlightDetails(itinIndex);
    return getFlightNumberDisplayText(legFlightDetails);
  }

  public getEnabledFareCategories(): MetadataFareCategoryWithCode[] {
    return this.response.metadata?.enabledFareCategories ?? [];
  }

  private getTotalEmissionsForLegFlights(flightInfo: PaxInfoFareComponentLegInfoFlightInfo[]) {
    const totalEmission = flightInfo
      .map((flight) => flight.co2EmissionDetail?.emissionValue)
      .filter((emissionValue): emissionValue is number => emissionValue !== undefined);
    const emissionValue = sum(totalEmission);
    return emissionValue;
  }

  private getTotalAverageEmissionsForLegFlights(flightInfo: PaxInfoFareComponentLegInfoFlightInfo[]) {
    const totalAverageEmission = flightInfo
      .map((flight) => flight.co2EmissionDetail?.averageEmissionValue)
      .filter((averageEmissionValue): averageEmissionValue is number => averageEmissionValue !== undefined);
    const averageEmissionValue = sum(totalAverageEmission);
    return averageEmissionValue;
  }

  public getCO2EmissionInfoForLeg(itinIndex: number, legNumber: number) {
    const { flightInfo } =
      this.response.itineraries[itinIndex].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber];
    const emissionValue = this.getTotalEmissionsForLegFlights(flightInfo);
    const averageEmissionValue = this.getTotalAverageEmissionsForLegFlights(flightInfo);

    return { emissionValue, averageEmissionValue };
  }

  public getTotalCo2EmissionDeltaInPercentage(itinIndex: number) {
    const legs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);

    let totalEmissionValueAndAverageEmissionValue = { averageEmissionValue: 0, emissionValue: 0 };

    legs.forEach((leg) => {
      const currentLegEmissions = this.getCO2EmissionInfoForLeg(itinIndex, leg.legNumber);
      totalEmissionValueAndAverageEmissionValue = {
        averageEmissionValue:
          totalEmissionValueAndAverageEmissionValue.averageEmissionValue + currentLegEmissions.averageEmissionValue,
        emissionValue: totalEmissionValueAndAverageEmissionValue.emissionValue + currentLegEmissions.emissionValue,
      };
    });

    const percentageChange = getPercentageChange(
      totalEmissionValueAndAverageEmissionValue.emissionValue,
      totalEmissionValueAndAverageEmissionValue.averageEmissionValue,
    );

    return percentageChange;
  }

  static GetUtaPointsForFare(
    fare: IItineraryFareInfoEnhanced,
    type: 'rewards' | 'statusCredits' | 'companyEarn',
  ): UtaPoints | undefined {
    const utaList: Uta | undefined = fare.passenger[0].utas.find((uta) => Object.keys(uta)[0] === type);
    if (!utaList) {
      return undefined;
    }
    const pointsUta = utaList[type];
    return pointsUta;
  }

  private getGovernmentApprovalFlights(dataLegIndex: number): FlightNumber[] {
    const flightIndices = this.GetLegFlights(dataLegIndex);

    const governmentApprovalFlights: FlightNumber[] = [];
    flightIndices.forEach((flightIndex) => {
      const flightInfo = this.response.data.flights[flightIndex];
      if (flightInfo.subjectToGovtApproval) {
        governmentApprovalFlights.push(flightInfo.marketing);
      }
    });
    return governmentApprovalFlights;
  }

  public GetCountriesForItinIndex(): string[] {
    const { legs } = this.response.data;
    const flightIndices = legs.flatMap((_, index) => this.response.data.legs[index].flightIndices);
    const flights = flightIndices.map((_, index) => this.response.data.flights[index]);

    return flights.flatMap((f) => [f.origin.country || '', f.destination.country]).filter((c) => !!c);
  }

  public getIsVisaRequiredForItinerary(): boolean {
    const countries = this.GetCountriesForItinIndex();
    const uniqCountries = uniq(countries);

    return uniqCountries.length > 1;
  }

  private getZeroMoney(): MoneyUtil {
    const totalFare = this.GetTotalFare(0);
    const currency = totalFare.getCurrency();
    const originalCurrency = totalFare.getOriginalCurrency();
    const zeroMoney = MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency);
    return zeroMoney;
  }

  private getLegFlightsCarbonPrice(flightInfo: PaxInfoFareComponentLegInfoFlightInfo[]) {
    const zeroMoney = this.getZeroMoney();
    if (!flightInfo[0].co2EmissionDetail) {
      return zeroMoney;
    }
    let totalAmount: null | MoneyUtil = null;
    flightInfo.forEach((flight) => {
      if (flight.co2EmissionDetail) {
        const { cost } = flight.co2EmissionDetail;
        if (cost) {
          const costAmount = MoneyUtil.parse(cost);
          totalAmount = totalAmount ? totalAmount.add(costAmount) : costAmount;
        }
      }
    });

    return totalAmount ?? zeroMoney;
  }

  private getFlightsCarbonPrice(itinIndex: number): MoneyUtil {
    const { flightInfo } = this.response.itineraries[itinIndex].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[0];

    const zeroMoney = this.getZeroMoney();
    if (!flightInfo[0].co2EmissionDetail) {
      return zeroMoney;
    }
    let totalAmount: null | MoneyUtil = null;
    this.response.itineraries[itinIndex].rateOptions[0].paxInfo[0].fareComponents[0].legInfo.forEach((legInfo) => {
      legInfo.flightInfo.forEach((flight) => {
        if (flight.co2EmissionDetail) {
          const { cost } = flight.co2EmissionDetail;
          if (cost) {
            const costAmount = MoneyUtil.parse(cost);
            totalAmount = totalAmount ? totalAmount.add(costAmount) : costAmount;
          }
        }
      });
    });
    return totalAmount ?? zeroMoney;
  }

  public getCO2EmissionPercentageForLeg(itinIndex: number, legNumber: number) {
    const { emissionValue, averageEmissionValue } = this.getCO2EmissionInfoForLeg(itinIndex, legNumber);

    const percentageChange = getPercentageChange(emissionValue, averageEmissionValue);

    return percentageChange;
  }

  public getCountExcludingInfantOnLap(): number {
    const { paxInfo } = this.response.itineraries[0].rateOptions[0];

    return AirSearchResponseManager.GetCountWithoutInfantForFare(paxInfo);
  }

  public getCarbonEmissionsForItinerary(itinIndex: number): ICarbonEmissionsConfig | null {
    const carbonEmissionForLegs = this.getItineraryMinCo2Emission(itinIndex);
    const totalAverageEmissionsForLegs = this.getAverageEmissionForItinerary(itinIndex);

    if (carbonEmissionForLegs === null || totalAverageEmissionsForLegs === null) {
      return null;
    }

    const legs = this.GetItineraryAllLegsForItineraryIndex(itinIndex);

    const legEmissions = legs.map((leg) => {
      const { code: departureAirportCode } = this.GetAirports(leg.legIndex)[0];
      const arrivalAirportCode = last(this.GetAirports(leg.legIndex))?.code ?? '';

      return {
        departureAirportCode,
        arrivalAirportCode,
        co2EmissionDeltaInPercentage: this.getCO2EmissionPercentageForLeg(itinIndex, leg.legNumber),
        emissions: carbonEmissionForLegs[leg.legNumber],
        averageEmissions: totalAverageEmissionsForLegs[leg.legNumber],
      };
    });

    const countExcludingInfantOnLap = this.getCountExcludingInfantOnLap();

    const totalFlightEmissions = sum(carbonEmissionForLegs.map((weight) => weight.weight));
    const paxTotalEmissions = countExcludingInfantOnLap * totalFlightEmissions;
    const totalEmissions = { weight: paxTotalEmissions, unit: carbonEmissionForLegs[0].unit };

    const totalFlightAverageEmissions = sum(totalAverageEmissionsForLegs.map((weight) => weight.weight));
    const paxTotalAverageEmissions = countExcludingInfantOnLap * totalFlightAverageEmissions;
    const totalAverageEmissions = { weight: paxTotalAverageEmissions, unit: carbonEmissionForLegs[0].unit };

    const flightsCarbonPrice = this.getFlightsCarbonPrice(itinIndex);

    const totalCarbonPrice = flightsCarbonPrice.multiply(countExcludingInfantOnLap);

    const totalCo2EmissionDeltaInPercentage = this.getTotalCo2EmissionDeltaInPercentage(itinIndex);

    return {
      totalAverageEmissions,
      totalEmissions,
      totalCarbonPrice,
      totalCo2EmissionDeltaInPercentage,
      legEmissions,
    };
  }

  public getCo2EmissionsForFlights(flights: IItineraryFareInfoFareFlightEnhanced[]) {
    const flightInfo = flights.map((flight) => flight.data);
    const emissionValue = this.getTotalEmissionsForLegFlights(flightInfo);
    const averageEmissionValue = this.getTotalAverageEmissionsForLegFlights(flightInfo);
    const totalCo2EmissionDeltaInPercentage = getPercentageChange(emissionValue, averageEmissionValue);

    const countExcludingInfantOnLap = this.getCountExcludingInfantOnLap();

    const totalFlightEmissions = this.getTotalCo2EmissionsForLegFlights(flightInfo);
    const totalFlightAverageEmissions = this.getTotalAverageCo2EmissionForLegFlights(flightInfo);
    if (totalFlightEmissions === null || totalFlightAverageEmissions === null) {
      return null;
    }
    const paxTotalEmissions = countExcludingInfantOnLap * totalFlightEmissions.weight;
    const totalEmissions = { weight: paxTotalEmissions, unit: totalFlightEmissions.unit };

    const totalAverageEmissions = {
      weight: countExcludingInfantOnLap * totalFlightAverageEmissions.weight,
      unit: totalFlightAverageEmissions.unit,
    };
    const flightsCarbonPrice = this.getLegFlightsCarbonPrice(flightInfo);

    const totalCarbonPrice = flightsCarbonPrice.multiply(countExcludingInfantOnLap);

    return { totalCo2EmissionDeltaInPercentage, totalEmissions, totalCarbonPrice, totalAverageEmissions };
  }
}
