import type { IVGSForm } from '../../types/payments/vgs';
import type { IHeaders } from '../../hooks/useGetRequestHeader';
import type { PaymentSetupRequest } from '../../types/api/v2/obt/model/payment-setup-request';
import type { PaymentSetupResponse } from '../../types/api/v2/obt/model/payment-setup-response';
import type { InternalAPIError } from '../../types/internal-api-error';
import { submitVgsForm } from '../submitVgsForm';

export async function mutatePaymentSetupViaVgs(
  vgsForm: IVGSForm,
  paymentSetupRequest: PaymentSetupRequest,
  requestHeaders: IHeaders,
) {
  const response = await submitVgsForm(vgsForm, '/v2/payment/setup', {
    mapDotToObject: 'mergeArray',
    data: paymentSetupRequest,
    headers: requestHeaders,
  });

  const setupResponse = response.response as PaymentSetupResponse | InternalAPIError;

  if ('paymentSetupResponseId' in setupResponse) {
    return setupResponse;
  }

  throw setupResponse;
}
