import { FC, useCallback, useMemo, useState } from 'react';
import {
  createUserNameFromFullName,
  createUserOrgIdUsingUserId,
  delay,
  getFullName,
  useFeatureFlag,
  useProfileReadQuery,
  FareList,
} from 'obt-common';
import { useModalState } from '@spotnana/blocks/src/Modal';
import { SavedFareList } from '../SavedFareList';
import { DeleteFareList, RemoveFareFromFareList } from '../types';
import { SideDrawer } from '../../../shared/components/SideDrawer';
import { ShareItineraryHeader, ShareItineraryHeaderVariant } from './ShareItineraryHeader';
import { modal_secondary_background } from '../shared/styles';
import { FareListItineraries } from '../FareListItineraries';
import { DeleteFaresModal, DeleteFaresModalType } from '../DeleteFaresModal';
import { ShareItineraryModalContainer } from '../ShareItineraryModalContainer';
import { MODAL_ANIMATION_DURATION } from '../shared/constants';
import ShareItineraryFooter from './ShareItineraryFooter';

export interface IShareItineraryListDrawerProps {
  open: boolean;
  onClose: VoidFunction;
  currentLists: FareList[];
  selectedListUuid?: string;
  removeFareFromFareList: RemoveFareFromFareList;
  deleteFareList: DeleteFareList;
}

export const ShareItineraryListDrawer: FC<IShareItineraryListDrawerProps> = ({
  selectedListUuid,
  currentLists,
  open,
  onClose,
  removeFareFromFareList,
  deleteFareList,
}) => {
  const isPreferredNameEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const {
    open: isDeleteSingleFareModalOpen,
    openModal: openDeleteSingleFareModal,
    closeModal: closeDeleteSingleFareModal,
  } = useModalState();
  const {
    open: isShareItineraryModalOpen,
    openModal: openShareItineraryModal,
    closeModal: closeShareItineraryModal,
  } = useModalState();

  const [deleteType, setDeleteType] = useState<DeleteFaresModalType>(DeleteFaresModalType.SINGLE_FARE);

  const [selectedListId, setSelectedListId] = useState<string | undefined>(selectedListUuid);
  const selectedList = useMemo(
    () => currentLists.find((list) => list.uuid === selectedListId),
    [currentLists, selectedListId],
  );
  const [fareRateOptionToDelete, setFareRateOptionToDelete] = useState<string | undefined>();

  const travelerUserOrgId = createUserOrgIdUsingUserId(selectedList?.travelerUserId);
  const { data: selectedProfile } = useProfileReadQuery(travelerUserOrgId);
  const travelerName = isPreferredNameEnabled
    ? createUserNameFromFullName(selectedProfile?.user?.name)
    : getFullName(selectedProfile?.user?.name);

  const selectList = (listId: string) => {
    setSelectedListId(listId);
  };

  const handleClose = async () => {
    onClose();
    await delay(MODAL_ANIMATION_DURATION);
    setSelectedListId(undefined);
  };

  const onRemoveFare = (fareRateOptionId: string) => {
    setDeleteType(DeleteFaresModalType.SINGLE_FARE);
    openDeleteSingleFareModal();
    setFareRateOptionToDelete(fareRateOptionId);
  };

  const onRemoveFareList = () => {
    setDeleteType(DeleteFaresModalType.FARE_LIST);
    openDeleteSingleFareModal();
  };

  const onDeleteSingleFare = useCallback(() => {
    if (!fareRateOptionToDelete) {
      return;
    }

    removeFareFromFareList({
      fareListUuid: selectedListId || selectedListUuid || '',
      fareRateOptionId: fareRateOptionToDelete,
    });

    closeDeleteSingleFareModal();
  }, [closeDeleteSingleFareModal, fareRateOptionToDelete, removeFareFromFareList, selectedListId, selectedListUuid]);

  const onDeleteFareList = useCallback(
    (listUuid: string) => {
      deleteFareList(listUuid);
      closeDeleteSingleFareModal();
    },
    [closeDeleteSingleFareModal, deleteFareList],
  );

  const variant = selectedList ? ShareItineraryHeaderVariant.FARE_LIST_FARES : ShareItineraryHeaderVariant.FARE_LIST;

  return (
    <SideDrawer
      hideCloseButton
      open={open}
      onClose={handleClose}
      variant="medium"
      header={{
        content: (
          <ShareItineraryHeader
            travelerName={travelerName}
            onClose={handleClose}
            variant={variant}
            onRemoveFareList={onRemoveFareList}
          />
        ),
      }}
      css={modal_secondary_background}
      footer={{
        content: (
          <ShareItineraryFooter
            onBack={() => setSelectedListId(undefined)}
            onShare={openShareItineraryModal}
            selectedList={selectedList}
          />
        ),
      }}
    >
      {!selectedList ? (
        <SavedFareList fareList={currentLists} onClose={handleClose} onClick={selectList} />
      ) : (
        <FareListItineraries
          fares={selectedList.fares}
          onClose={handleClose}
          onRemoveFare={onRemoveFare}
          itineraryOwnerId={selectedList.travelerUserId}
        />
      )}
      {selectedList && (
        <>
          <DeleteFaresModal
            open={isDeleteSingleFareModalOpen}
            numberOfFares={selectedList.fares.length}
            onClose={closeDeleteSingleFareModal}
            onDeleteSingleFare={onDeleteSingleFare}
            onDeleteFareList={() => onDeleteFareList(selectedList.uuid)}
            travelerName={travelerName}
            type={deleteType}
          />
          <ShareItineraryModalContainer
            fareList={selectedList}
            open={isShareItineraryModalOpen}
            onClose={closeShareItineraryModal}
          />
        </>
      )}
    </SideDrawer>
  );
};
