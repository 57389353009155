import {
  ITraveler,
  ProcessedError,
  RBAC,
  useFeatureFlag,
  useHasUserAccess,
  useLoggedInUserBasicInfo,
  userRolesByFeature,
} from 'obt-common';
import useGetErrorMessageByCode from 'src/hooks/useGetErrorMessageByCode';
import { useTranslation } from 'react-i18next';
import { convertRichTextToPlainText } from 'src/components/RichTextEditor/utils';
import ViewErrorDetails from 'src/app/shared/components/ViewErrorDetails';
import { ROUTE_PATHS } from 'src/routes';
import { useCallback, useState } from 'react';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Button } from '@spotnana/blocks/src/Button';
import { useMarkerIo } from 'src/app/agent/SupportMenu/useMarkerIo';
import { Link } from 'react-router-dom';
import { action_items, error_details } from './styles';

interface IProps {
  error: ProcessedError;
  debugId: string;
  onClose: () => void;
  primaryTraveler: ITraveler | undefined;
}

interface IActionItemConfig {
  title: string;
  externalLink: string;
  callback: VoidFunction;
}

const ErrorBody = ({ error, debugId, onClose, primaryTraveler }: IProps) => {
  const { t: tt } = useTranslation('WEB');
  const getErrorMessage = useGetErrorMessageByCode();
  const { errorCode, errorParameters, sourceErrorMessages, errorDetail } = error;
  const isInAppBugReporting = useFeatureFlag('FE_IN_APP_BUG_REPORTING');
  const showOpenDebugToolForEveryone = useFeatureFlag('FE_AIR_SHOW_OPEN_DEBUG_TOOL_FOR_EVERYONE');

  const { marker, handleMarkerIoFormSubmit } = useMarkerIo({ primaryTraveler, error });
  const userBasicInfo = useLoggedInUserBasicInfo();

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const handleErrorDetailsClick = useCallback(() => setShowDetailsModal(true), [setShowDetailsModal]);
  const handleClose = useCallback(() => setShowDetailsModal(false), [setShowDetailsModal]);

  const errorMessage = getErrorMessage({
    errorCode,
    errorParameters,
  });

  const hasSourceErrorMessages = sourceErrorMessages !== undefined;
  const shouldShowErrorDetails = !!errorDetail && errorDetail !== error?.originalErrorMessage;

  const handleDebugLinkClick = () => {
    onClose();
  };

  function handleFeedbackClick(): void {
    handleMarkerIoFormSubmit();
  }

  const isGlobalTMCAdminOrAgent = useHasUserAccess(userRolesByFeature.globalTmcAdminAndAgentRoles);
  const showDebugToolLink =
    debugId && (showOpenDebugToolForEveryone || isGlobalTMCAdminOrAgent || userBasicInfo.isImpersonationSession);

  const isAgent = useHasUserAccess(userRolesByFeature.organizationSelector);

  const actionItems: IActionItemConfig[] = [];

  if (marker && isInAppBugReporting) {
    actionItems.push({
      title: tt('Report this error'),
      callback: handleFeedbackClick,
      externalLink: '',
    });
  }

  if (hasSourceErrorMessages && isAgent) {
    actionItems.push({
      title: tt('Error details'),
      callback: handleErrorDetailsClick,
      externalLink: '',
    });
  }

  if (showDebugToolLink) {
    actionItems.push({
      title: tt('Open debug tool'),
      callback: handleDebugLinkClick,
      externalLink: `${ROUTE_PATHS.SUPPORT_DEBUG_TOOL}?type=debugId&queryValue=${debugId}`,
    });
  }

  return (
    <div>
      <ViewErrorDetails
        code={errorCode ?? ''}
        sourceErrorMessages={sourceErrorMessages ?? []}
        open={showDetailsModal}
        handleClose={handleClose}
      />

      <Typography variant="body2">
        {/**
         * Some error reasons are stored in rich text in the backend. Convert these to plain text equivalent for rendering in ErrorSnackbar.
         * Alternatively in the future, we can choose to use the RichTextDisplay component to render the message with full formatting.
         */}
        {convertRichTextToPlainText(errorMessage)}
        <RBAC allowedRoles={userRolesByFeature.adminPages} secondaryPredicate={() => !hasSourceErrorMessages}>
          {` (${errorCode})`}
        </RBAC>
      </Typography>
      {shouldShowErrorDetails && (
        <RBAC allowedRoles={userRolesByFeature.adminPages}>
          <Typography css={error_details} variant="body3">
            {convertRichTextToPlainText(errorDetail)}
          </Typography>
        </RBAC>
      )}
      <div css={action_items}>
        {actionItems.map((actionItem, actionItemIndex) => {
          const button = (
            <Button
              key={actionItem.title}
              variant="ghost"
              edgePlacement={actionItemIndex === 0 ? 'left' : undefined}
              size="small"
              onClick={actionItem.callback}
            >
              <Typography variant="body2" color="link" kind="medium">
                {actionItem.title}
              </Typography>
            </Button>
          );

          const linkButton = (
            <Link to={actionItem.externalLink} target="_blank" key={actionItem.title}>
              {button}
            </Link>
          );

          return actionItem.externalLink ? linkButton : button;
        })}
      </div>
    </div>
  );
};

export default ErrorBody;
