import type { IVGSForm, IVGsSubmitOptions } from '../types/payments/vgs';

export function submitVgsForm(
  vgsForm: IVGSForm,
  path: string,
  options: IVGsSubmitOptions,
): Promise<{ status: string; response: any }> {
  return new Promise((resolve, reject) => {
    vgsForm.submit(
      path,
      options,
      (status: string, response: any) => {
        resolve({ status, response });
      },
      (errors: any) => {
        reject(Object.values(errors)[0]);
      },
    );
  });
}
