import Avatar, { AvatarProps } from '@spotnana/pixel-react/dist/Avatar';
import Box from '@spotnana/pixel-react/dist/Box';
import Image from '@spotnana/pixel-react/dist/Image';
import {
  RBAC,
  userRolesByFeature,
  checkForSeat1aRestrictions,
  TMCAgentAndAboveRoles,
  AgentAvailabilityStatus,
  getFullAssetPath,
  useFeatureFlag,
} from 'obt-common';
import { useTranslation } from 'react-i18next';
import { availabilityStatusColorsMap } from '../../app/agent/QueueDashboardPage/constants';

const defaultSeat1aAbsolutePositioning = { right: '1%', bottom: '1%' };
const Seat1ADetailsByAvatarSize: Record<AvatarProps['size'], { size: number; right: string; bottom: string }> = {
  extraSmall: { size: 8, ...defaultSeat1aAbsolutePositioning },
  small: { size: 16, right: '-10%', bottom: '0%' },
  medium: { size: 16, ...defaultSeat1aAbsolutePositioning },
  large: { size: 36, ...defaultSeat1aAbsolutePositioning },
  mediumSmall: { size: 36, ...defaultSeat1aAbsolutePositioning },
  extraLarge: { size: 36, ...defaultSeat1aAbsolutePositioning },
  '24X24': { size: 24, ...defaultSeat1aAbsolutePositioning },
};

interface UserAvatarProps extends AvatarProps {
  isSeat1a?: boolean;
  agentAvailabilityStatus?: AgentAvailabilityStatus;
}

const UserAvatar = ({ isSeat1a = false, agentAvailabilityStatus, ...restProps }: UserAvatarProps): JSX.Element => {
  const { t } = useTranslation('COMMON');
  const isConfigureSeat1AEnabled = !!useFeatureFlag('FE_CORP_CONFIGURE_SEAT1A');

  if (agentAvailabilityStatus) {
    return (
      <RBAC allowedRoles={TMCAgentAndAboveRoles}>
        <Box>
          <Avatar {...restProps} />
          <Box position="absolute" right={0} bottom={0}>
            <Box
              height="8px"
              width="8px"
              borderRadius="circle"
              bg={availabilityStatusColorsMap[agentAvailabilityStatus]}
            />
          </Box>
        </Box>
      </RBAC>
    );
  }

  if (!isSeat1a) {
    return <Avatar {...restProps} />;
  }

  const { size, right, bottom } = Seat1ADetailsByAvatarSize[restProps.size];

  return (
    <RBAC
      allowedRoles={userRolesByFeature.seat1aView}
      secondaryPredicate={checkForSeat1aRestrictions()}
      fallback={<Avatar {...restProps} />}
    >
      <Box position="relative">
        <Avatar {...restProps} />
        {!isConfigureSeat1AEnabled && (
          <Box position="absolute" right={right} bottom={bottom}>
            <Image
              height={size}
              width={size}
              src={getFullAssetPath('/v1-assets/images/seat1a-logo', 'svg')}
              alt={`Seat 1A ${t('USER')}`}
            />
          </Box>
        )}
      </Box>
    </RBAC>
  );
};

export default UserAvatar;
