import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'clipboard-copy';

import { ProcessedError } from 'obt-common';

import { Button } from '@spotnana/blocks/src/Button';
import { Typography } from '@spotnana/blocks/src/Typography';
import { Divider } from '@spotnana/blocks/src/Divider';
import { Modal } from '@spotnana/blocks/src/Modal';
import {
  modal_content,
  modal_line_item,
  modal_name_container,
  divider_container,
  copy_to_clipboard_button,
  modal_footer,
} from './styles';

export interface IProps {
  code: string;
  sourceErrorMessages: NonNullable<ProcessedError['sourceErrorMessages']>;
  open: boolean;
  handleClose: () => void;
}

const ViewErrorDetails: FC<IProps> = ({ code, sourceErrorMessages, open, handleClose }) => {
  const { t: tt } = useTranslation('WEB');

  const [copied, setCopied] = useState(false);

  const handleCopyToClipboardClick = useCallback(async () => {
    const error = {
      code,
      sourceErrorMessages,
    };

    await copyToClipboard(JSON.stringify(error));
    setCopied(true);
  }, [code, sourceErrorMessages, setCopied]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      header={{ title: tt('Error details') }}
      footer={{
        content: (
          <div css={modal_footer}>
            <Button
              variant="ghost"
              css={copy_to_clipboard_button}
              startIcon={copied ? 'CheckCircle' : 'Copy'}
              onClick={handleCopyToClipboardClick}
            >
              {copied ? tt('Copied') : tt('Copy to clipboard')}
            </Button>

            <Button css={copy_to_clipboard_button} onClick={handleClose}>
              {tt('Done')}
            </Button>
          </div>
        ),
      }}
    >
      <div css={modal_content}>
        <div css={modal_line_item}>
          <Typography css={modal_name_container} variant="body2">
            {tt('Error Code:')}
          </Typography>
          <Typography variant="body2">{code}</Typography>
        </div>

        <div css={divider_container}>
          <Divider />
        </div>

        {sourceErrorMessages.map(({ message }, index) => (
          <div css={modal_line_item}>
            <Typography css={modal_name_container} variant="body2">
              {index === 0 && tt('Error Messages:')}
            </Typography>
            <Typography variant="body2">{message}</Typography>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ViewErrorDetails;
