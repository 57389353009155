/* eslint-disable */
import type { Length } from '../../common/length';
import type { DoubleRange } from '../../common/range';
import type { PreSearchAnswers } from './user_defined_entity';

export enum PolicyType {
  UNKNOWN_TYPE = 0,
  GLOBAL = 1,
  DEFAULT = 2,
  GROUP = 3,
  /** @deprecated */
  INTERNAL = 4,
  UNRECOGNIZED = -1,
}

export enum PolicyCategory {
  CATEGORY_UNKNOWN = 0,
  EMPLOYEE = 1,
  GUEST = 2,
  UNRECOGNIZED = -1,
}

/**
 * Extra text information to be sent with Policy actions to customize the
 * text on the button. Currently being used for PreventBooking Action only.
 */
export enum ActionDisplayText {
  /** NOT_ALLOWED - Use Not Allowed as default value */
  NOT_ALLOWED = 0,
  OVER_BUDGET = 1,
  INSUFFICIENT_POINTS = 2,
  UNRECOGNIZED = -1,
}

export enum PolicyRuleType {
  POLICY_RULE_TYPE_UNKNOWN = 0,
  NAME = 1,
  CURRENCY = 2,
  APPROVAL_INFO = 3,
  USER_GROUPS = 4,
  IN_POLICY_APPROVAL_INFO = 5,
  OUT_OF_POLICY_APPROVAL_INFO = 6,
  AIR_OOP_REASON_CODES = 7,
  HOTEL_OOP_REASON_CODES = 8,
  CAR_OOP_REASON_CODES = 9,
  RAIL_OOP_REASON_CODES = 10,
  IN_POLICY_APPROVAL_INFOS = 11,
  OUT_OF_POLICY_APPROVAL_INFOS = 12,
  PARENT_POLICY_ID = 13,
  RESTRICTED_CONTINENTS = 101,
  RESTRICTED_COUNTRIES = 102,
  RESTRICTED_LOCATION = 103,
  RESTRICTED_AIRLINES = 201,
  RESTRICTED_AIRCRAFTS = 202,
  MAX_FLIGHT_BOOKING_PRICE_BY_DURATION = 203,
  /** @deprecated */
  HIGHEST_FLIGHT_CABIN_BY_DURATION = 204,
  FLIGHT_SEAT_UPGRADE = 205,
  FLIGHT_CABIN_UPGRADE = 206,
  FLIGHT_ADVANCE_BOOKING_WINDOW = 207,
  FLIGHT_TICKETS_REFUNDABLE = 208,
  FLIGHT_TICKETS_CHANGEABLE = 209,
  HIGHEST_FLIGHT_CABIN_BY_DURATION_DOMESTIC = 210,
  HIGHEST_FLIGHT_CABIN_BY_DURATION_INTERNATIONAL = 211,
  HIGHEST_FLIGHT_CABIN_FOR_OVERNIGHT = 212,
  DIFFERENCE_BETWEEN_FLIGHT_FARE_AND_MEDIAN_FARE = 213,
  CO2_EMISSION_PER_PASSENGER_PER_KM = 214,
  MAX_FLIGHT_BOOKING_PRICE_DOMESTIC = 215,
  MAX_FLIGHT_BOOKING_PRICE_INTERNATIONAL = 216,
  BASIC_ECONOMY_FARES_DOMESTIC = 217,
  BASIC_ECONOMY_FARES_INTERNATIONAL = 218,
  CABIN_CLASS_NOT_ALLOWED_DOMESTIC = 219,
  CABIN_CLASS_NOT_ALLOWED_INTERNATIONAL = 220,
  LOWEST_LOGICAL_FARE = 221,
  FLIGHT_ADVANCE_BOOKING_WINDOW_DOMESTIC = 222,
  FLIGHT_ADVANCE_BOOKING_WINDOW_INTERNATIONAL = 223,
  ALLOWED_AIR_ADDONS = 224,
  RESTRICTED_HOTELS = 301,
  MAX_HOTEL_PRICE_BY_LOCATION = 302,
  HOTEL_ADVANCE_BOOKING_WINDOW = 303,
  HOTEL_CANCELLATION = 304,
  HOTEL_RATING = 305,
  ALLOWED_HOTEL_CHAIN_CODES = 306,
  HOTEL_PAYMENT_OPTIONS = 307,
  HOTEL_RATE_CONDITIONS_NOT_ALLOWED = 308,
  HOTEL_MEDIAN_RATE_NIGHTLY = 309,
  HOTEL_RESTRICTED_KEYWORDS = 310,
  MAX_HOTEL_BOOKING_PRICE = 311,
  MAX_CAR_PRICE_PER_NUMBER_OF_DAYS = 401,
  ALLOWED_CAR_TYPES = 402,
  CAR_TYPES_NOT_ALLOWED = 403,
  CAR_ENGINE_TYPES_NOT_ALLOWED = 404,
  RAIL_ADVANCE_BOOKING_WINDOW = 501,
  MAX_RAIL_BOOKING_PRICE_BY_DURATION = 502,
  HIGHEST_RAIL_TRAVEL_CLASS_BY_DURATION = 503,
  RAIL_TICKETS_REFUNDABLE = 504,
  UNRECOGNIZED = -1,
}

/**
 * Rail Travel classes in hierarchy.
 * numbering higher to keep scope for future additions in between.
 */
export enum TravelClassHierarchy {
  UNKNOWN = 0,
  STANDARD = 100,
  COACH = 150,
  STANDARD_PREMIUM = 200,
  BUSINESS = 300,
  BUSINESS_PREMIUM = 350,
  FIRST = 400,
  SLEEPER = 500,
  UNRECOGNIZED = -1,
}

export interface PolicyId {
  id: string;
}

/** This contains the pre search answer and/or the custom fields. */
export interface CorporateInfo {
  preSearchAnswers?: PreSearchAnswers;
}

/** Whether to allow booking if a rule is violated. */
export interface PreventBooking {
  prevent: boolean;
  /** Reason describing why was that specific itinerary not allowed to book. */
  reason: string;
  /** Display text to show on the "not-allowed" button. */
  displayText?: ActionDisplayText;
}

/** Keyword that are restricted in hotel names and the reason for restriction. */
export interface Keyword {
  keyword: string;
  reason: string;
}

export interface HotelMedianRateProps {
  /** Search radius for the hotel. */
  searchRadius?: Length;
  /** Rating range for the hotel. */
  ratingRange?: DoubleRange;
}

export interface SplitPaymentInfo {
  shouldSplitPayment: boolean;
  chargeTotalFareOnPersonalFop: boolean;
}
