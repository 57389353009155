/**
 * For Languages supporting multiple plurals, the exact translation to be used is consumed via the `count` options passed to translations
 * This Enum makes sure that in situations when a particular plural version is required, we can pass this count to use the same
 * @see https://www.i18next.com/translation-function/plurals#languages-with-multiple-plurals
 *
 */
export enum TRANSLATION_PLURAL_COUNT {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  FEW = 3,
  MANY = 11,
  OTHER = 100,
}

// Regular expression for ISO 8601 date format (YYYY-MM-DD)
export const ISO_8601_DATE_ONLY_REGEXP = /^\d{4}-\d{2}-\d{2}$/;
