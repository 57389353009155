import { storage } from '../Storage';
import { StorageKeys } from '../../types/storage';

const SABRE_SIM_DOMAINS: Record<string, string> = {
  'giata-rest': 'http://sp-dashboards-sharedsvc.services.spotnana.com:9001',
  'sabre-rest': 'http://sp-dashboards-sharedsvc.services.spotnana.com:9001',
  'sabre-soap': 'http://sp-dashboards-sharedsvc.services.spotnana.com:9001',
  'rh-consolidated': 'http://sp-dashboards-sharedsvc.services.spotnana.com:9001',
  farelogix: 'http://sp-dashboards-sharedsvc.services.spotnana.com:9003',
  'trainline-auth-url': 'http://sp-dashboards-sharedsvc.services.spotnana.com:9011',
  'trainline-base-url': 'http://sp-dashboards-sharedsvc.services.spotnana.com:9011',
};

const appendSabreSimulatorRedirectHeader = async () => {
  const shouldRedirect = (await storage.getItem(StorageKeys.SHOULD_REDIRECT_REQUESTS_TO_SABRE_SIMULATOR)) === 'true';
  if (!shouldRedirect) {
    return {};
  }

  const rawUser = await storage.getItem(StorageKeys.CURRENT_USER);
  const rawValidTestUsers = await storage.getItem(StorageKeys.VALID_REDIRECT_TO_SABRE_USER_LIST);

  if (rawUser && rawValidTestUsers) {
    const currentUser = JSON.parse(rawUser);
    const validTestUsers = rawValidTestUsers.split(',');
    const shouldRedirectForCurrentUser = validTestUsers.includes(currentUser.pemail);

    if (!shouldRedirectForCurrentUser) {
      return {};
    }
  }

  const workflowId = (await storage.getItem(StorageKeys.SABRE_SIM_WORKFLOW_ID)) ?? '';
  const legsDate = (await storage.getItem(StorageKeys.SABRE_SIM_LEGS_DATE)) ?? '';

  const sabreSimHeaderObj = {
    ...SABRE_SIM_DOMAINS,
    workflow_id: workflowId,
    legs_date: legsDate,
  };

  return { 'X-External-Api-Forward': btoa(JSON.stringify(sabreSimHeaderObj)) };
};
export default appendSabreSimulatorRedirectHeader;
