/* eslint-disable */

const WINDOW_KEY = 'low-level-requests-interceptor';
const LOG_PREFIX = `[${WINDOW_KEY}]`;
const DEFAULT_SKIP_URL_PATTERNS = [/static/, /.+\.js$/, /.+\.css$/];

const shouldSkipRequestTracking = (skipUrlPatterns = [], request) => {
  const url = request.url;
  return skipUrlPatterns.some(pattern => {
    if (typeof pattern === 'string') {
      return url.includes(pattern);
    }

    return false;
  });
};

const lowLevelRequestsInterceptor = {
  disableInterceptor: function disableInterceptor() {
    if (!window[WINDOW_KEY]) {
      return;
    }

    window[WINDOW_KEY].interceptorDisabled = true;
  },

  setup: function setup() {
    window[WINDOW_KEY] = {
      instance: this,
      interceptorDisabled: false,
      requests: [],
      skipUrlPatterns: DEFAULT_SKIP_URL_PATTERNS,
    };

    if (typeof FormData.prototype.entries === 'undefined') {
      polyfillFormDataEntries();
    }

    if (typeof window.fetch === 'function') {
      replaceFetch();
    }

    replaceXHR();

    function replaceFetch() {
      const originalFetch = window.fetch;
      window.fetch = function() {
        const request = {
          method: 'GET',
          requestHeaders: {},
          requestBody: undefined,
          url: '',
        };
        const input = arguments[0];
        const init = arguments[1];
        if (typeof input === 'string') {
          request.url = input;
        } else if (input instanceof URL) {
          request.url = input.href;
        } else if (input instanceof Request) {
          const clonedRequest = input.clone();
          request.requestBody = clonedRequest.text();
          request.url = clonedRequest.url;
          request.requestHeaders = parseHeaders(clonedRequest.headers);
          request.method = clonedRequest.method;
        } else {
          request.requestBody = input.body;
        }
        if (init) {
          if (typeof init.body !== 'undefined') request.requestBody = parsePayload(init.body);
          if (typeof init.method !== 'undefined') request.method = init.method;
          request.requestHeaders = parseHeaders(init.headers);
        }
        addPendingRequest(request);

        return originalFetch
          .apply(window, arguments)
          .then(function(response) {
            const clonedResponse = response.clone();
            const responsePromise = clonedResponse.text();

            Promise.all([request.requestBody, responsePromise]).then(function(results) {
              completeFetchRequestSuccess(request, {
                requestBody: results[0],
                body: results[1],
                statusCode: clonedResponse.status,
                headers: parseHeaders(clonedResponse.headers),
              });
            });

            return response;
          })
          .catch(err => {
            if (err.name === 'AbortError') {
              Promise.resolve(request.requestBody).then(requestBody => {
                completeFetchRequestAbort(request, {
                  requestBody,
                });
              });
            }

            throw err;
          });
      };
    }

    function replaceXHR() {
      const originalOpen = XMLHttpRequest.prototype.open;
      const originalSend = XMLHttpRequest.prototype.send;
      const originalSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;
      const originalAbort = XMLHttpRequest.prototype.abort;
      const handleDoneRequest = function(xhr, wasAborted = false) {
        if (xhr.readyState === XMLHttpRequest.prototype.DONE) {
          const req = xhr.currentRequest;
          req.statusCode = xhr.status;
          req.headers = xhr.getAllResponseHeaders();
          const parsed = parseBody(xhr, req);
          if (!parsed.deferred) {
            completeXHRRequest(req, parsed.body);
          }
        } else if (wasAborted) {
          const req = xhr.currentRequest;
          req.__aborted = Date.now();
          replaceStoredRequest(req);
        }
      };
      XMLHttpRequest.prototype.open = function() {
        this.currentMethod = arguments[0];
        this.currentURL = arguments[1];
        originalOpen.apply(this, arguments);
      };
      XMLHttpRequest.prototype.send = function() {
        this.currentRequest = {
          method: this.currentMethod.toUpperCase(),
          requestHeaders: this.currentRequestHeaders || {},
          requestBody: parsePayload(arguments[0]),
          url: this.currentURL.toString(),
          __processed: Number(new Date()),
        };
        this.__processed = this.currentRequest.__processed;
        addPendingRequest(this.currentRequest);
        originalSend.apply(this, arguments);

        const that = this;
        this.addEventListener('load', function() {
          handleDoneRequest(that);
        });
      };
      XMLHttpRequest.prototype.setRequestHeader = function() {
        if (!this.currentRequestHeaders) {
          this.currentRequestHeaders = {};
        }
        this.currentRequestHeaders[arguments[0]] = arguments[1];
        originalSetRequestHeader.apply(this, arguments);
      };
      XMLHttpRequest.prototype.abort = function() {
        handleDoneRequest(this, true);
        originalAbort.apply(this, arguments);
      };
    }

    function parseBody(xhr, request) {
      if (xhr.responseType === 'arraybuffer') {
        return {
          body: new TextDecoder().decode(xhr.response),
        };
      }
      if (xhr.responseType === 'blob') {
        const fr = new FileReader();
        fr.addEventListener('load', function() {
          completeXHRRequest(request, new TextDecoder().decode(this.result));
        });
        fr.readAsArrayBuffer(xhr.response);
        return { deferred: true };
      }
      return {
        body: xhr.response || xhr.responseText,
      };
    }

    function parseHeaders(headers) {
      if (headers instanceof Headers) {
        return Array.from(headers.entries())
          .map(function(x) {
            return x.join(': ');
          })
          .join('\r\n');
      }
      return headers || '';
    }

    function parsePayload(payload) {
      if (typeof payload === 'string') {
        return payload;
      }
      if (payload instanceof FormData) {
        const parsed = {};
        const entries = payload.entries();
        let item;
        while (((item = entries.next()), !item.done)) {
          parsed[item.value[0]] = item.value.slice(1);
        }
        return JSON.stringify(parsed);
      }
      if (payload instanceof ArrayBuffer) {
        return String.fromCharCode.apply(null, payload);
      }
      if (payload instanceof URLSearchParams) {
        return payload.toString();
      }

      try {
        return JSON.stringify(payload);
      } catch (e) {
        console.error(`${LOG_PREFIX} Failed to stringify XHR payload as JSON!`, e);
      }
      return '';
    }

    function addPendingRequest(request) {
      if (shouldSkipRequestTracking(window[WINDOW_KEY].skipUrlPatterns, request)) {
        return;
      }
      request.__processed = Date.now();
      window[WINDOW_KEY].requests.push(request);
    }

    function completeFetchRequestAbort(request) {
      request.requestBody = completedRequest.requestBody;
      request.__aborted = Date.now();
      replaceStoredRequest(request);
    }

    function completeFetchRequestSuccess(request, completedRequest) {
      request.requestBody = completedRequest.requestBody;
      request.body = completedRequest.body;
      request.headers = completedRequest.headers;
      request.statusCode = completedRequest.statusCode;
      request.__fulfilled = Date.now();
      replaceStoredRequest(request);
    }

    function completeXHRRequest(request, responseBody) {
      request.body = responseBody;
      request.__fulfilled = Date.now();
      replaceStoredRequest(request);
    }

    function replaceStoredRequest(request) {
      if (window[WINDOW_KEY].interceptorDisabled) {
        return;
      }
      const storedRequests = window[WINDOW_KEY].requests;
      for (let storedReqNumber = 0; storedReqNumber < storedRequests.length; storedReqNumber += 1) {
        const r = storedRequests[storedReqNumber];
        if (r.__processed === request.__processed && r.url === request.url && r.method === request.method) {
          storedRequests[storedReqNumber] = request;
          break;
        }
      }
    }

    function polyfillFormDataEntries() {
      const originalAppend = FormData.prototype.append;
      FormData.prototype.append = function() {
        this.__entries = this.__entries || [];
        this.__entries.push(Array.prototype.slice.call(arguments));
        originalAppend.apply(this, arguments);
      };
      FormData.prototype.entries = function() {
        return this.__entries;
      };
    }
  },

  setSkipUrlPatterns: function setSkipUrlPatterns(urlPatterns) {
    if (!window[WINDOW_KEY]) {
      return;
    }

    if (!Array.isArray(urlPatterns) || !urlPatterns.every(pattern => typeof pattern === 'string')) {
      throw new Error(`${LOG_PREFIX} 'setSkipUrlPatterns' accepts only an array of strings`);
    }

    window[WINDOW_KEY].skipUrlPatterns = urlPatterns;

    // Filtering out requests that were already stored
    window[WINDOW_KEY].requests = window[WINDOW_KEY].requests.filter(
      r => !shouldSkipRequestTracking(window[WINDOW_KEY].skipUrlPatterns, r),
    );
  },

  getRequests: function getRequest(options) {
    if (!window[WINDOW_KEY]) {
      return;
    }

    function isCompleted(request) {
      return typeof request.__fulfilled === 'number' && typeof request.__aborted === 'undefined';
    }

    function isPending(request) {
      return typeof request.__fulfilled === 'undefined' && typeof request.__aborted === 'undefined';
    }

    function isAborted(request) {
      return typeof request.__fulfilled === 'undefined' && typeof request.__aborted === 'number';
    }

    function normalizeRequestHeaders(headers) {
      const normalized = {};
      Object.keys(headers).forEach(key => {
        normalized[key.toLowerCase()] = headers[key];
      });
      return normalized;
    }

    function parseBody(str) {
      let body;
      try {
        body = JSON.parse(str);
      } catch (e) {
        body = str;
      }
      return body;
    }

    function parseResponseHeaders(rawHeader) {
      const headers = {};
      if (!rawHeader) {
        return headers;
      }
      const lines = rawHeader.trim().split(/(?:\r?\n)+/);
      for (const line of lines) {
        if (!line) {
          continue;
        }
        const parts = line.split(':');
        const key = parts[0].trim().toLowerCase();
        const value = parts
          .slice(1)
          .join(':')
          .trim();
        if (typeof headers[key] === 'undefined') {
          headers[key] = value;
        } else if (typeof headers[key] === 'string') {
          headers[key] = `${headers[key]}, ${value}`;
        }
      }
      return headers;
    }

    function transformRequest(req) {
      if (!req) {
        return;
      }

      const transformedRequest = {
        url: req.url,
        method: req.method && req.method.toUpperCase(),
        headers: normalizeRequestHeaders(req.requestHeaders),
        body: parseBody(req.requestBody),
        pending: true,
        aborted: false,
      };

      if (req.__fulfilled) {
        transformedRequest.pending = false;
        transformedRequest.response = {
          headers: parseResponseHeaders(req.headers),
          body: parseBody(req.body),
          statusCode: req.statusCode,
        };
      }

      if (req.__aborted) {
        transformedRequest.pending = false;
        transformedRequest.aborted = true;
      }

      return transformedRequest;
    }

    const requests = window[WINDOW_KEY].requests || [];
    const includePending = Boolean(options.includePending);
    const includeAborted = Boolean(options.includeAborted);

    const completedRequests = requests.filter(r => isCompleted(r));
    const pendingRequests = requests.filter(r => isPending(r));
    const abortedRequests = requests.filter(r => isAborted(r));
    return completedRequests
      .concat(includePending ? pendingRequests : [])
      .concat(includeAborted ? abortedRequests : [])
      .map(transformRequest);
  },

  clearRequests: function clearRequests() {
    if (!window[WINDOW_KEY]) {
      return;
    }

    window[WINDOW_KEY].requests = [];
  },

  hasPendingRequests: function hasPendingRequests() {
    if (!window[WINDOW_KEY]) {
      return;
    }

    const allRequests = window[WINDOW_KEY].requests || [];
    return allRequests.some(r => typeof r.__fulfilled === 'undefined' && typeof r.__aborted === 'undefined');
  },

  hasAbortedRequests: function hasAbortedRequests() {
    if (!window[WINDOW_KEY]) {
      return;
    }

    const allRequests = window[WINDOW_KEY].requests || [];
    return allRequests.some(r => typeof r.__fulfilled === 'undefined' && typeof r.__aborted === 'number');
  },
};

export default lowLevelRequestsInterceptor;
